import React, { useParams, useRef, useState, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, useRouteMatch, NavLink, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function Requests(props) {
    const [selected, setSelected] = useState(0)
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [requestById, setRequestById] = useState([])
    const [userName, setUserName] = useState('');
    const [count, setCount] = useState('');
    const [interestType, setInterestType] = useState('');
    const [description, setDescription] = useState('');
    const refText = useRef('myTextarea');
    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [monthsActive, setMonthsActive] = useState('');
    const [likesCount, setLikesCount] = useState('');
    const [pollsAnsweredCount, setPollsAnsweredCount] = useState('');
    const [pollsCreatedCount, setPollsCreatedCount] = useState('');
    const [redirect, setRedirect] = useState(false)
    const [topicDetails, setTopicDetails] = useState([])

    let { path, url } = useRouteMatch();
   // console.log(path)

    useEffect(() => {
        getAllUsersRoleRequests();
    }, []);
    const getAllUsersRoleRequests = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getPendingRoleRequests', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setRequests(response.data.getPendingRoleRequests)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllUsersRoleRequestsById = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + `user/getPendingRoleRequests/${id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setRequestById(response.data.getPendingRoleRequests)
                    setInterestType(response.data.getPendingRoleRequests[0].roleType)
                    setMonthsActive(response.data.monthsActive)
                    setLikesCount(response.data.likesCount)
                    setPollsAnsweredCount(response.data.pollAnsweredCount)
                    setPollsCreatedCount(response.data.pollCreatedCount)
                    setTopicDetails(response.data.topicDetails)
                    setUserName(response.data.userName)
                    setCount(response.data.applyCount)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const acceptAndRejectRequests = (data, flag) => {
      //  console.log("edit", data)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "requestId": data.id,
            "flag": flag,
            "interestType": interestType,
            "description": description
        }
     //   console.log("payload", payload)
         var self = this
        if (interestType === 'SME' && flag === 1) {
            updateSmeOfferingStatus(data.userId,flag)
        }
        axios.post(config.userUrl + 'user/acceptAndReject', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    if (interestType === 'SME' && flag === 1) {
                        updateSmeOfferingStatus(data.userId,flag)
                    }
                    window.location.href = '/history/interests'
                   // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const updateSmeOfferingStatus = (id, statusId) => {
      //  console.log("edit", id, statusId)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "userId": id,
            "status": statusId
        }
        //console.log("payload1", payload)
        axios.post(config.userUrl + 'user/updateSmeOfferingStatus', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                 //   console.log("true")
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    if (!sessionStorage.getItem('junkStore')) {
        return <Redirect to='/' />;
    }
    if (isLoading) {
        return <div></div>
    }
    const updatedDate = requests.map((i, idx) => {
        var a = new Date(i.createdAt)
        var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return updated;
    })
    const showAccept = () => {
        document.getElementById('accept').style.display = 'flex'
    }
    const showAcceptNone = () => {
        document.getElementById('accept').style.display = 'none'
    }
    const showReject = () => {
        document.getElementById('reject').style.display = 'flex'
    }
    const showRejectNone = () => {
        document.getElementById('reject').style.display = 'none'
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                <Row style={{ width: "100%" }}>
                    <Col md={4}>
                        <div class="scrolling-area-review">
                            <div class="scrolling-element-inside">
                                <div className="inbox">Requests</div>
                                {requests.length !== 0 ?
                                    <div>
                                        {requests.map((data, i) => (
                                            <div>
                                                <Card style={{ border: "none" }} onClick={() => getAllUsersRoleRequestsById(data.id)}>
                                                    <NavLink to={`${path}/${data.id}`} className="inbox_Card link" activeClassName="_Active" key={i} >
                                                        <Row style={{ width: "100%" }}>
                                                            <Col md={2}>
                                                                <img src={orange} style={{ width: "32px" }} />
                                                            </Col>
                                                            <Col md={7}>
                                                                <div className="article-approved">{data.name} </div>
                                                                <div className="messages">{data.roleType}</div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mt-1 time">{updatedDate[i]}</div>
                                                            </Col>
                                                        </Row>
                                                    </NavLink>
                                                </Card>
                                                <hr /></div>
                                        ))}
                                    </div>
                                    : <p>No Requests to Accept or Reject</p>}
                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <Card style={{ height: "85vh" }}>
                            {requestById.map((data, i) => (
                                <Route key={i} path={`${path}/${data.id}`} >
                                    <div>
                                        <div class="scrolling-area-review-queue">
                                            <div class="scrolling-element-inside">
                                                <p className="article-title mt-2"> {userName} Expression of Interest as {data.roleType}</p>
                                                <div><b>Covering Letter</b></div>
                                                <Card><div style={{ fontSize: "12px", padding: "0.5rem", height: "140px", marginLeft: "5px" }} dangerouslySetInnerHTML={{ __html: data.intent }} /></Card>
                                                <div className="mt-2"><b>Profile on Sceem</b></div>
                                                <Card className="mt-2">
                                                    <div style={{ fontSize: "12px", display: "flex", padding: "0.5rem" }}>
                                                        <div className="ml-1">Months Active <br />{monthsActive}</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div>Likes<br /> {likesCount}</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div>Polls Answered <br />{pollsAnsweredCount}</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div>Polls Created<br /> {pollsCreatedCount}</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div>Times Applied<br /> {count}</div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="mt-3 form-group">
                                                <textarea
                                                    rows={2}
                                                    name="description"
                                                    value={data.description}
                                                    placeholder="Write your comments"
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    className="form-control"
                                                    style={{ marginLeft: "18px", fontSize: "12px", width: "95%", resize: "none", wordWrap: "break-word", }}
                                                />
                                            </div>
                                            <div className="float_Right mr-3" >
                                                <div>{description !== "" ?
                                                    // <div ><Button variant="default" onClick={() => togglePopup(data.id, 1)} className="sendreview-btn mt-1 ml-4">Accept</Button><Button onClick={() => togglePopup(data.id, 0)} className="save-btn mt-1 ml-3" variant="default">Reject</Button></div> 
                                                    <div ><Button onClick={showAccept} className="sendreview-btn mt-1 ml-4" variant="default">Accept</Button>
                                                        <div id="accept" className="modal_new">
                                                            <form className="modal-content-log">
                                                                <div className="md-container">
                                                                    <p className="mt-2">Do you really want to Accept?</p>
                                                                    <center><Button variant="default" onClick={() => acceptAndRejectRequests(data, 1)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                        <Button variant="default" onClick={showAcceptNone} className="deletebutton">No</Button></center>
                                                                </div>
                                                            </form>
                                                        </div><Button variant="default" onClick={showReject} className="save-btn mt-1 ml-3">Reject</Button>
                                                        <div id="reject" className="modal_new">
                                                            <form className="modal-content-log">
                                                                <div className="md-container">
                                                                    <p className="mt-2">Do you really want to Reject?</p>
                                                                    <center><Button variant="default" onClick={() => acceptAndRejectRequests(data, 0)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                        <Button variant="default" onClick={showRejectNone} className="deletebutton">No</Button></center>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div> :
                                                    <div><Button variant="default" disabled="true" className="sendreview-btn mt-1 ml-4">Accept</Button><Button disabled="true" className="save-btn mt-1 ml-3" variant="default">Reject</Button></div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Route>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div >
        </div >
    );
}






