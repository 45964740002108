import { useState } from 'react';
import { useRef } from 'react';

function Editor() {
  const [name, setName] = useState('');
  const [htmlName, setHtmlName] = useState('');
  const refText = useRef('myTextarea');
  /*
    const ref1 = useRef('myDivarea');
  
    function handleB1(e) {
      e.preventDefault();
      //var n = parseMarkdown(name);
      //const {name}= this.state.name;
      let textVal = ref1.current.innerHTML;
      let sel = document.getSelection();//toString();
      console.log("bn", sel);
  
    }
  
     <div style={{border: '1px solid black'}} contentEditable 
        onInput={(e) => parseMarkdown(e.target.innerHTML)}
        onBlur={(e) => parseMarkdown(e.target.innerHTML)}
        ref={ref1}
        >
          
        </div>
  */
  function handleB(e) {
    e.preventDefault();
    let textVal = refText.current;
    let cursorStart = textVal.selectionStart;
    let cursorEnd = textVal.selectionEnd;
    var selectedText = name.substring(cursorStart, cursorEnd)
    var updatedText = name.replace(selectedText, "*" + selectedText + "*");
    setName(updatedText);
    parseMarkdown(updatedText);
  }

  function handleI(e) {
    e.preventDefault();
    let textVal = refText.current;
    let cursorStart = textVal.selectionStart;
    let cursorEnd = textVal.selectionEnd;
    var selectedText = name.substring(cursorStart, cursorEnd)
    var updatedText = name.replace(selectedText, "%" + selectedText + "%");
    setName(updatedText);
    parseMarkdown(updatedText);
  }
  function handleU(e) {
    e.preventDefault();
    let textVal = refText.current;
    let cursorStart = textVal.selectionStart;
    let cursorEnd = textVal.selectionEnd;
    var selectedText = name.substring(cursorStart, cursorEnd)
    var updatedText = name.replace(selectedText, "_" + selectedText + "_");
    setName(updatedText);
    parseMarkdown(updatedText);
  }

  function handleH(e) {
    e.preventDefault();
    let textVal = refText.current;
    var allTextLines = textVal.value.substring(0).split("\n");
    var textLines = textVal.value.substring(0, textVal.selectionStart).split("\n");
    var currentLineNumber = textLines.length;
    console.log("currentLineNumber", currentLineNumber)

    var currentTextLine = allTextLines[currentLineNumber - 1];
    console.log(currentTextLine);
    var updatedTextLine = name.replace(currentTextLine, "#" + currentTextLine);

    setName(updatedTextLine);
    parseMarkdown(updatedTextLine);
  }

  function handleL(e) {
    e.preventDefault();
    let textVal = refText.current;
    var allTextLines = textVal.value.substring(0).split("\n");
    var textLines = textVal.value.substring(0, textVal.selectionStart).split("\n");
    var currentLineNumber = textLines.length;
    console.log("currentLineNumber", currentLineNumber)
    var currentTextLine = allTextLines[currentLineNumber - 1];
    console.log(currentTextLine);
    var updatedTextLine = name.replace(currentTextLine, ">" + currentTextLine);
    setName(updatedTextLine);
    parseMarkdown(updatedTextLine);
  }

  function parseMarkdown(markdownText) {
    var htmlText = markdownText
      .replace(/^#(.*$)/gim, '<h3>$1</h3>')
      .replace(/^>(.*$)/gim, '<li>$1</li>')
      .replace(/\_(.+?)\_/gim, '<u>$1</u>')
      .replace(/\%(.+?)\%/gim, '<i>$1</i>')
      .replace(/\*(.+?)\*/gim, '<b>$1</b>')
      .replace(/\n*$/gim, '<br />')

    setName(markdownText);
    setHtmlName(htmlText.trim())
    return htmlText.trim();
  }

  return (
    <div className="App">
      <form>
        <textarea name="name" value={name}
          rows={20}
          cols={100}
          ref={refText}
          onChange={(e) => parseMarkdown(e.target.value)} />
        <button onClick={handleH} type="submit">
          H
        </button>

        <button onClick={handleB} type="submit">
          B
        </button>
        <button onClick={handleU} type="submit">
          U
        </button>

        <button onClick={handleI} type="submit">
          I
        </button>

        <button onClick={handleL} type="submit">
          L
        </button>
      </form>
      <span dangerouslySetInnerHTML={{ __html: htmlName }}></span>
    </div>
  );
}

export default Editor;
