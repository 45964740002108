import React from 'react';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

class CreateSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            edit: true,
            planId:"",
            planName: "",
            planDescription: "",
            planDuration:"",
            currency:"",
            planAmount: "",
            status:"",
            redirect: false
        }
    }
    componentDidMount() {
       // console.log("props")
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    createSubscription = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "planName": this.state.planName,
            "planDuration": this.state.planDuration,
            "planDescription": this.state.planDescription,
            "planAmount":parseInt(this.state.planAmount),
            "currency":this.state.currency,
            "status": val
        }
      //  console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'user/createPlan', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.props.getAllSubscriptions()
                    self.props.closePopup()
                 //   console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    }
    render() {
        const { edit, planName, planDescription, status, planDuration,planAmount,currency } = this.state
        return (
            <Modal
                size="sm"
                show={edit}
                onHide={this.props.closePopup}
                centered
            >
                <Modal.Header closeButton>
                    <h5 style={{ color: "#FF942C", fontFamily: "poppins" }}>&nbsp;Add Subscription</h5>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Form style={{ marginTop: "-0.8rem" }}>
                        <div className="form-group">
                            <input type="text"
                                className="mt-2 web-font form-control text-box-radius"
                                aria-describedby="text"
                                name="planName"
                                value={planName}
                                onChange={this.onChange}
                                placeholder="Plan Name"
                            />
                        </div>
                        <div className="form-group">
                            <select name="planDuration" value={planDuration} onChange={this.onChange} class="mt-2 web-font form-control text-box-radius">
                                <option value="">Select Plan Duration</option>
                                <option value={"monthly"}>Monthly</option>
                                <option value={"yearly"}>Yearly</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <select name="currency" value={currency} onChange={this.onChange} class="mt-2 web-font form-control text-box-radius">
                                <option value="">Select Currency</option>
                                <option value={"INR"}>INR</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <input type="text"
                                className="mt-2 web-font form-control text-box-radius"
                                aria-describedby="text"
                                name="planAmount"
                                value={planAmount}
                                onChange={this.onChange}
                                placeholder="Plan Amount"
                            />
                        </div>
                        <div className="form-group">
                            <textarea name="planDescription"
                                onChange={this.onChange}
                                value={planDescription}
                                placeholder="Plan Description"
                                className="web-font richTextField_feature description-radius"
                                style={{ overflowY: "none", resize: "none" }}
                            />
                        </div>
                        <div>{planName !== "" && planDescription != "" && planDuration !== "" && planAmount != "" &&  currency != "" ?
                            <Button variant="default" onClick={()=> this.createSubscription(1)} className="sendreview-btn mt-1 ml-2">Create</Button> :
                            <Button variant="default" disabled="true" className="sendreview-btn mt-1 ml-2">Create</Button>}
                            <Button variant="default" onClick={()=> this.createSubscription(2)} className="close-btn-popup mt-1">Save</Button></div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default CreateSubscription;

