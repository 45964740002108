import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, Container, Col, Row, Image } from "react-bootstrap";
import { Link, NavLink, Switch, Route } from 'react-router-dom';
import writearticle from '../images/writearticle.svg';
import reviewqueue from '../images/reviewqueue.svg';
import reviews from '../images/reviews.svg';
import user from '../images/user.svg';
import './Settings.css';
import Settings from "./Settings";

export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commentsOnTopics: true,
            replies: true,
            mentions: true,
            likesOnTopics: true,
            likesOnComments: true,
            others: true
        }
        this._onTopics = this._onTopics.bind(this);
        this.onChangeReplies = this.onChangeReplies.bind(this)
        this.onChangeMentions = this.onChangeMentions.bind(this)
        this.onChangeLikesTopic = this.onChangeLikesTopic.bind(this)
        this.onChangeLikesComment = this.onChangeLikesComment.bind(this)
        this.onChangeOthers = this.onChangeOthers.bind(this)
    }
    _onTopics() {
        this.setState({ commentsOnTopics: !this.state.commentsOnTopics });
    }
    onChangeReplies() {
        this.setState({ replies: !this.state.replies });
    }
    onChangeMentions() {
        this.setState({ mentions: !this.state.mentions });
    }
    onChangeLikesTopic() {
        this.setState({ likesOnTopics: !this.state.likesOnTopics });
    }
    onChangeLikesComment() {
        this.setState({ likesOnComments: !this.state.likesOnComments });
    }
    onChangeOthers() {
        this.setState({ others: !this.state.others });
    }
    render() {

        return (
            <div>
                <Settings />
                <div style={{ marginLeft: "460px", marginTop: "5rem" }}>
                    <Card style={{ width: "80%" }}>
                        <p className="notification-heading">Notifications</p>
                        <p className="notifications-sm">Manage your personal account notification preferences for new comments,<br /> mentions, likes and topics.</p>
                        <form style={{ marginTop: "2rem" }}>
                            <label className="label-text">Notify me about comments on my topics</label>  <label className="float-right">
                                <input ref="switch1" checked={this.state.commentsOnTopics} onChange={this._onTopics} className="switch1" type="checkbox" />
                                <div>
                                    <div></div>
                                </div>
                            </label>
                            <hr className="notification-b-line" />
                            <label className="label-text">Notify me about replies to my comments</label><label className="float-right">
                                <input ref="switch1" checked={this.state.replies} onChange={this.onChangeReplies} className="switch1" type="checkbox" />
                                <div>
                                    <div></div>
                                </div>
                            </label>
                            <hr className="notification-b-line" />
                            <label className="label-text">Notify me about mentions</label><label className="float-right">
                                <input ref="switch1" checked={this.state.mentions} onChange={this.onChangeMentions} className="switch1" type="checkbox" />
                                <div>
                                    <div></div>
                                </div>
                            </label>
                            <hr className="notification-b-line" />
                            <label className="label-text">Notify me about likes on my topics</label><label className="float-right">
                                <input ref="switch1" checked={this.state.likesOnTopics} onChange={this.onChangeLikesTopic} className="switch1" type="checkbox" />
                                <div>
                                    <div></div>
                                </div>
                            </label>
                            <hr className="notification-b-line" />
                            <label className="label-text">Notify me about likes on my comments</label><label className="float-right">
                                <input ref="switch1" checked={this.state.likesOnComments} onChange={this.onChangeLikesComment} className="switch1" type="checkbox" />
                                <div>
                                    <div></div>
                                </div>
                            </label>
                            <hr className="notification-b-line" />
                            <label className="label-text">Other (Recommendations, tips and prompts)</label><label className="float-right">
                                <input ref="switch1" checked={this.state.others} onChange={this.onChangeOthers} className="switch1" type="checkbox" />
                                <div>
                                    <div></div>
                                </div>
                            </label>
                        </form>
                    </Card><br />
                </div>
            </div >
        );
    };
}