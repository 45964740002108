import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Settings.css';
import securityalert from '../images/securityalert.svg';
import windows from '../images/windows.svg';
import key from '../images/key.svg';
import Settings from "./Settings";

export default class Security extends Component {
    constructor(props) {
        super(props);
        this.state = {
            securityAlert: true,
            password: "",
            newPassord: ""
        }
        this._onSecurityChange = this._onSecurityChange.bind(this);
    }
    _onSecurityChange = (e) => {
        this.setState({ securityAlert: !this.state.securityAlert })
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleSubmit = (e) => {
        e.preventDefault();
       // console.log(this.state)
    }
    render() {
        return (
            <div>
                <Settings />
                <div style={{ marginTop: "4.8rem", marginLeft: "460px" }} >
                    <Card style={{ marginTop: "10px", width: "75%" }}><br />
                        <div className="margin-left">
                            <div className="upi">Security</div>
                            <div className="recommend">Recommanded</div>
                            <div className="mt-2 display">
                                <img src={securityalert} className="securityalert-img" />
                                <div className="securityalert-text">Security Alert</div>
                            </div>
                            <div className="mt-2 ml-4 display">
                                <div className="t-text">Improve the security of your account by getting alerts when someone tries logging<br />
                                    in to your account from an unknown device or browser</div>

                                <div style={{ marginLeft: "4rem", marginRight: "1rem" }}><input ref="switch1" checked={this.state.securityAlert} onChange={this._onSecurityChange} className="switch1" type="checkbox" />
                                    <div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="recommend">Where You're Logged in</div>
                            <div className="mt-2 display">
                                <img src={windows} className="securityalert-img" />
                                <div className="securityalert-text">Windows - Hyderabad, TG, India</div>
                            </div>
                            <div className="ml-4 t-text">Chrome - <span style={{ color: "#268200" }}>Active now</span></div>

                        </div>
                        <hr style={{ width: "40%", marginLeft: "20px" }} />
                        <div className="out">Sign out of all devices</div>
                    </Card>
                    <Card style={{ marginTop: "10px", width: "75%" }}><br />
                        <div className="margin-left">
                            <div className="upi">Login</div>
                            <div className="mt-2 display">
                                <img src={key} className="securityalert-img" />
                                <div className="securityalert-text">Change Password</div>
                            </div>
                            <Form className="ml-4" style={{ width: "35%" }} onSubmit={this.handleSubmit}>
                                <div className="mt-2 form-group">
                                    <input type="password"
                                        className="form-control"
                                        aria-describedby="text"
                                        name="password"
                                        placeholder="Enter Old Password"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <input type="password"
                                        className="form-control"
                                        aria-describedby="text"
                                        name="newPassword"
                                        placeholder="Enter New Password"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div>
                                    <Button variant="default" className="update-btn">Update</Button>
                                    <Button variant="default" className="ml-2 discard-btn">Discard</Button>
                                </div>
                            </Form>
                        </div><br />
                    </Card><br />
                </div>
            </div >
        );
    };
}


