import React, { useParams, useRef, useState, useEffect } from "react";
import { Button, Form, Navbar, Nav, OverlayTrigger, Popover, Card, DropdownButton, Col, Row, Dropdown, Container } from "react-bootstrap";
import { Link, Route, useRouteMatch, NavLink, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import opportunity from '../images/opportunity-white.svg';
import poll from '../images/poll-white.svg';
import campaign from '../images/advertisement-white.svg';
import articles from '../images/articles-published-white.svg';
import redeem from '../images/redeem-icon.png';
import sceemlore from '../images/sceemlore-white.svg';
import question from '../images/qna-white.svg';
import pricing from '../images/pricing-white.svg';
import gameicon from '../images/games-white.svg';
import user from '../images/user-white.svg';
import referralWhite from '../images/referral-white.svg';
import { GiGamepadCross } from "react-icons/gi";
import rewards from '../images/rewards-white.svg';
import share from '../images/share-white.png';
import author from '../images/author.svg';
// import { UsersGraph } from "./UsersGraph";
// import { CampaignsGraph } from "./CampaignsGraph";
// import { OpportunitiesGraph } from "./OpportunitiesGraph";
// import { PollsGraph } from "./PollsGraph";
// import { SceemloreGraph } from "./SceemloreGraph";
// import { QuestionsGraph } from "./QuestionsGraph";
// import { ReferralsGraph } from "./ReferralsGraph";
// import { OffersGraph } from "./OffersGraph";
// import { FourEsGraph } from "./FourEsGraph";
import ArticleTopics from "./ArticleTopics";
import { ArticlesGraph } from "./ArticlesGraph";
import Users from "./Users";
import AllCounts from "./AllCounts";
import ViewsRewardsCount from "./ViewsRewardsCount";
import { UserRoles } from "./UserRoles";
import { FourEs } from "./FourEs";
import { UsersGraph } from "./UsersGraph";
import { PurchasesGraph } from "./PurchasesGraphs";
import { PollsGraph } from "./PollsGraph";
import { ReferralsGraph } from "./ReferralsGraph";
import { ReadsGraph } from "./ReadsGraph";
import { LikesGraph } from "./LikesGraph";
import AllData from "./AllData";
import Chart from 'chart.js/auto';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
import { PollsParticipatedGraph } from "./PollsParticipatedGraph";
import { TopThreeRewardsGraph } from "./TopThreeRewardsGraph";
import EachTopicGraph from "./EachTopicGraph";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function Dashboard() {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [campaignsCount, setCampaignsCount] = useState("");
    const [likesCount, setLikesCount] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [pollsCount, setPollsCount] = useState("");
    const [publishedPostsCount, setPublishedPostsCount] = useState("");
    const [registeredUserCount, setRegisteredUserCount] = useState("");
    const [totalArticleViews, setTotalArticleViews] = useState("");
    const [totalQuestions, setTotalQuestions] = useState("");
    const [opportunitiesCount, setOpportunitiesCount] = useState("");
    const [cardManagementCount, setCardManagementCount] = useState("");
    const [topicDetails, setTopicDetails] = useState([]);
    const [eachTopicView, setEachTopicView] = useState([]);
    const [gamesPlayed, setGamesPlayed] = useState();
    const [totalRewards, setTotalRewards] = useState("");
    const [games, setGames] = useState([]);
    const [usersCount, setUsersCount] = useState("");
    const [authorsCount, setAuthorsCount] = useState("");
    const [transactionsCount, setTransactionsCount] = useState("");
    const [transactionsCountValue, setTransactionsCountValue] = useState("");
    const [referralsCount, setReferralsCount] = useState("");
    const [redemptionPoints, setRedemptionPoints] = useState("");
    const [sharedCount, setSharedCount] = useState("");
    const [cardsAvailable, setCardsAvailable] = useState("");
    const [userRegisteredCardsCount, setUserRegisteredCardsCount] = useState("");
    const [userCompletedCardsCount, setUserCompletedCardsCount] = useState("");
    const [allRewards, setAllRewards] = useState([]);
    const [topThreeArticles, setTopThreeArticles] = useState([]);
    const [overlay, setOverlay] = useState(false);
    const [aReads, setAReads] = useState("");
    const [aLikes, setALikes] = useState("");
    const [aPublished, setAPublished] = useState("");
    const [aPCreated, setAPCreated] = useState("");
    const [aPAnswered, setAPAnswered] = useState("");

    useEffect(() => {
        getAllCountDetails();
        getTotalRewardPoints();
        getSharedArticlesCount();
        getSceemloreCards();
        getRewardsForActivities();
        getTopThreeArticlesBasedOnLikesAndReads();
    }, []);

    const getAllCountDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/aboutPageDetails', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    setCampaignsCount(response.data.campaignsCount);
                    setGamesPlayed(response.data.gamesPlayed.length);
                    setUsersCount(response.data.users);
                    setAuthorsCount(response.data.totalAuthors);
                    setTransactionsCount(response.data.totalTransactions);
                    setTransactionsCountValue(response.data.totalTransactionsValue);
                    setReferralsCount(response.data.totalReferrals);
                    setRedemptionPoints(response.data.redemption);
                    setEachTopicView(response.data.eachTopicViews);
                    setGames(response.data.gamesPlayed)
                    setPollsCount(response.data.pollsCount);
                    setPublishedPostsCount(response.data.publishedPostsCount);
                    setRegisteredUserCount(response.data.registeredUserCount);
                    setTopicDetails(response.data.topicDetails);
                    setTotalArticleViews(response.data.totalArticleViews);
                    setTotalQuestions(response.data.totalQuestions);
                    setOpportunitiesCount(response.data.totalOpportunitiesCount);
                    setCardManagementCount(response.data.cardManagementCount)

                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const getSceemloreCards = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getSceemloreCards', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setCardsAvailable(response.data.cardsAvailable);
                    setUserRegisteredCardsCount(response.data.userRegisteredCount);
                    setUserCompletedCardsCount(response.data.userCompletedCount);

                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const getRewardsForActivities = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getRewardsForActivities', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    setAllRewards(response.data.getAllRewardsPoint);
                    setAReads(response.data.getAllRewardsPoint[2].rewardPoints)
                    setALikes(response.data.getAllRewardsPoint[7].rewardPoints)
                    setAPublished(response.data.getAllRewardsPoint[10].rewardPoints)
                    setAPCreated(response.data.getAllRewardsPoint[11].rewardPoints)
                    setAPAnswered(response.data.getAllRewardsPoint[12].rewardPoints)
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const getTopThreeArticlesBasedOnLikesAndReads = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getTopThreeArticlesBasedOnReads', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    setTopThreeArticles(response.data.topPosts);

                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const getTotalRewardPoints = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getTotalRewardPoints', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setTotalRewards(response.data.totalRewardPoints);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const getSharedArticlesCount = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getSharedArticlesCount', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setSharedCount(response.data.sharedArticlesCount);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    let filterTopics = [];
    for (let i = 0; i < topicDetails.length; i++) {
        if (topicDetails[i].postsCount >= 2) {
            filterTopics.push(topicDetails[i]);
        }
    }
    // console.log("labels", filterTopics)

    const articleTopicsData = {
        labels: topicDetails.map((data) => data.topicName.replace(/ *\([^]*\) */g, "")),
        datasets: [
            {
                data: topicDetails.map((data) => data.postsCount),
                backgroundColor: ['#ff942c', '#31197C'],
                borderWidth: 1,
                barThickness: 5,
                borderThickness: 20
            }

        ]
    }
    const eachTopicData = {
        labels: eachTopicView.map((data) => data.topicName.replace(/ *\([^]*\) */g, "")),
        datasets: [
            {
                data: eachTopicView.map((data) => data.count),
                backgroundColor: ['#31197c', "#ff942c", "#fafa2a", "#f54b42", "#f725ed", "#f20c1f", "#7d77f2", "#77f2c1", "#70e5ff", "#a691ba", "#c6ff70", "#fffd70", "#db97f0", "#fc4cb0"],
                borderWidth: 1,
                barThickness: 5,
                borderThickness: 20
            }

        ]
    }
    const articleCountsData = {
        labels: ['Published Articles', 'Reads', 'Likes'],
        datasets: [
            {
                data: [publishedPostsCount, totalArticleViews, likesCount],
                fill: true,
                backgroundColor: [
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    // 'rgba(255, 159, 64, 0.8)',
                    // 'rgba(54, 162, 235, 0.8)',
                    "#31197C"],
                borderWidth: 1
            }
        ]
    }
    const gamesData = {
        labels: games.map((data) => data.gameName),
        datasets: [
            {
                data: games.map((data) => data.count),
                backgroundColor: [,

                    '#ff942c',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(255, 159, 64, 1)'],
                borderWidth: 1,
            }
        ]
    }
    const sceemloreData = {
        labels: ["Cards Available", "User Registered For Cards", "User Completed Cards"],
        datasets: [
            {
                data: [cardsAvailable, userRegisteredCardsCount, userCompletedCardsCount],
                backgroundColor: [
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(255, 159, 64, 0.8)'],
                borderWidth: 1,
            }
        ]
    }
    const rewardsData = {
        labels: ["Article Reads","Article Likes","Articles Published","Polls Created","Polls Answered"],
        datasets: [
            {
                data:[aReads,aLikes,aPublished,aPCreated,aPAnswered],
                backgroundColor: ['#31197c', "#fafa2a", "#f725ed", "#f20c1f",  "#70e5ff", "#a691ba", "#c6ff70", "#fffd70", "#db97f0", "#fc4cb0"],
                borderWidth: 1,
                barThickness: 5,
                borderThickness: 20
            }

        ]
    }
    const topThreeArticlesData = {
        labels: topThreeArticles.map((data) => data.name != null ? data.name : "Annonymous"),
        datasets: [
            {
                data: topThreeArticles.map((data) => data.views),
                backgroundColor: ["#54b2c7", "#4cfcbb", "#d97766"],
                borderWidth: 1,
            }
        ]
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "4.6rem", marginLeft: "255px" }}>
                <div className="mr-3">
                    <div className="row no-gutters">
                        <Col>
                            <Card className="mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(50, 25, 124,0.1)" }}>
                                <div style={{ background: "#31197C", borderRadius: "5px", width: "35px", height: "35px" }}><img src={user} style={{ width: "35px", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{usersCount}</div></center>
                                    <center><div className="graph-heading-T">Users</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(255, 150, 44,0.1)" }}>
                                <div style={{ background: "#FF942c", borderRadius: "5px", width: "35px", height: "35px" }}><img src={opportunity} style={{ width: "34px", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{opportunitiesCount}</div></center>
                                    <center><div className="graph-heading-T">Opportunities</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(52, 232, 235,0.1)" }}>
                                <div style={{ background: "#34e8eb", borderRadius: "5px", width: "35px", height: "35px" }}><img src={campaign} style={{ width: "34px", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{campaignsCount}</div></center>
                                    <center><div className="graph-heading-T">Campaigns</div></center>
                                </div>
                            </Card>
                        </Col>
                        {/* <GiGamepadCross style={{ marginTop: "5px", color: "white", paddingRight: "15px", width: "50px" }} /> */}
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(134, 80, 235,0.1)" }}>
                                <div style={{ background: "#8650eb", borderRadius: "5px", width: "35px", height: "35px" }}><GiGamepadCross size={"34px"} style={{ color: "white", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{gamesPlayed}</div></center>
                                    <center><div className="graph-heading-T">Games</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(192, 84, 12,0.1)" }}>
                                <div style={{ background: "#c0540c", borderRadius: "5px", width: "35px", height: "35px" }}><img src={rewards} style={{ width: "34px", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{totalRewards}</div></center>
                                    <center><div className="graph-heading-T">Rewards</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(50, 168, 82,0.1)" }}>
                                <div style={{ background: "#32a852", borderRadius: "5px", width: "35px", height: "35px" }}><img src={articles} style={{ width: "34px", padding: "6px 5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{publishedPostsCount}</div></center>
                                    <center><div className="graph-heading-T">Articles</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(252, 73, 217,0.1)" }}>
                                <div style={{ background: "#fc49d9", borderRadius: "5px", width: "35px", height: "35px" }}><img src={pricing} style={{width: "34px",padding: "5px" }}/></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{transactionsCount}</div></center>
                                    <center><div className="graph-heading-T">Transactions</div></center>
                                </div>
                            </Card>
                        </Col>
                    </div>
                    <div className="mt-2 row no-gutters">
                        <Col>
                            <Card className="mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(50, 25, 124,0.1)" }}>
                                <div style={{ background: "#31197C", borderRadius: "5px", width: "35px", height: "35px" }}><img src={referralWhite} style={{ width: "34px", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{referralsCount}</div></center>
                                    <center><div className="graph-heading-T">Referrals</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(255, 150, 44,0.1)" }}>
                                <div style={{ background: "#FF942c", borderRadius: "5px", width: "35px", height: "35px" }}><img src={poll} style={{ width: "34px", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{pollsCount}</div></center>
                                    <center><div className="graph-heading-T">Polls</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(52, 232, 235,0.1)" }}>
                                <div style={{ background: "#34e8eb", borderRadius: "5px", width: "35px", height: "35px" }}><img src={question} style={{ width: "34px", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{totalQuestions}</div></center>
                                    <center><div className="graph-heading-T">Questions</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(134, 80, 235,0.1)" }}>
                                <div style={{ background: "#8650eb", borderRadius: "5px", width: "35px", height: "35px" }}><img src={sceemlore} style={{ width: "34px", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{cardManagementCount}</div></center>
                                    <center><div className="graph-heading-T">Sceemlore</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(192, 84, 12,0.1)" }}>
                                <div style={{ background: "#c0540c", borderRadius: "5px", width: "35px", height: "35px" }}><img src={redeem} style={{ width: "34px", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{redemptionPoints}</div></center>
                                    <center><div className="graph-heading-T">Redemption</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(50, 168, 82,0.1)" }}>
                                <div style={{ background: "#32a852", borderRadius: "5px", width: "35px", height: "35px" }}><img src={author} style={{ width: "34px", padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{authorsCount}</div></center>
                                    <center><div className="graph-heading-T">Authors</div></center>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(252, 73, 217,0.1)" }}>
                                <div style={{ background: "#fc49d9", borderRadius: "5px", width: "35px", height: "35px" }}><img src={pricing} style={{ width: "34px",padding: "5px" }} /></div>
                                <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                    <center><div className="counts">{transactionsCountValue}</div></center>
                                    <center><div className="graph-heading-T">Value</div></center>
                                </div>
                            </Card>
                        </Col>
                    </div>
                    <Row style={{ width: "100%", height: "100%" }} className="mt-2 no-gutters">
                        <Col md={3}>
                            <UsersGraph />
                        </Col>
                        <Col md={4}>
                            <TopThreeRewardsGraph />
                        </Col>
                        <Col md={3}>
                            <PurchasesGraph />
                        </Col>
                        <Col md={2}>
                            <PollsParticipatedGraph />

                        </Col>
                    </Row>
                    <Row style={{ marginTop: "0.45rem", width: "100%" }} className="no-gutters">
                        <Col md={4}>
                            <Card className="bar-graph-card mr-1 box-shad" style={{ background: "rgba(255, 150, 44,0.1)" }}>
                                <center><div className="graph-heading-T">Articles per Topic</div></center>
                                <Bar data={articleTopicsData} style={{ marginTop: "-9px" }}
                                    options={{
                                        elements: {
                                            line: {
                                                tension: 1
                                            },
                                        },
                                        scales: {
                                            indexAxis: 'y',
                                            x: {
                                                stacked: true,
                                                ticks: {
                                                    display: false
                                                },
                                                grid: {
                                                    drawBorder: false,
                                                    display: false,
                                                },

                                            },
                                            y: {
                                                stacked: true,
                                                ticks: {
                                                    display: true,
                                                },
                                                grid: {
                                                    drawBorder: false,
                                                    display: false,
                                                },
                                            },
                                        },
                                        plugins: {
                                            title: {
                                                display: false,
                                            },
                                            legend: {
                                                display: false,
                                            },
                                        }
                                    }
                                    } />
                            </Card>
                        </Col>
                        <Col md={2}>
                                <Card className="ml-1 bar-graph-card box-shad mr-1" style={{ background: "rgba(252, 246, 61,0.1)" }}>
                                    <center className="graph-heading-T">Reads per Topic</center>
                                    <div style={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                        <Doughnut data={eachTopicData} className="ml-2 mb-2 mr-2" style={{marginTop:"5.5px"}}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                }
                                            }
                                            } />
                                    </div>
                                </Card>
                        </Col>
                        <Col md={6}>
                            <div className="row no-gutters" style={{ width: "100%" }}>
                                <Col md={5}>
                                    <ArticlesGraph />
                                </Col>
                                <Col md={3}>
                                    <LikesGraph />
                                    <ReadsGraph />
                                </Col>
                                <Col md={4}>
                                    <Card className="ml-2 mr-1 bar-graph-card box-shad" style={{ border: "none", background: "rgba(192, 84, 12,0.1)" }}>
                                        <div style={{ background: "#c0540c", borderRadius: "5px", width: "35px", height: "35px" }}><img src={share} style={{ width: "34px", padding: "5px" }} /></div>
                                        <div style={{ padding: "10px 15px", marginTop: "-1.8rem" }}>
                                            <center><div className="counts">{sharedCount}</div></center>
                                            <center><div className="graph-heading-T mb-2">Shared Articles</div></center>
                                        </div>
                                    </Card>
                                    <Card className="ml-2 mt-2 mr-1 bar-graph-card box-shad" style={{ background: "rgba(76, 252, 187,0.1)" }}>
                                      
                                        {/* <table class="mt-1 table-striped" >
                                                    <thead style={{ background: "#eee" }}>
                                                        <tr>
                                                            <th scope="col" className="gh-heading">Name</th>
                                                            <th scope="col" className="gh-heading">Title</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {topThreeArticles.map((data, i) => (
                                                            <tr className={i / 2 === 0 ? "" : "green"}>
                                                                <td className="gh-text">{data.name}</td>
                                                                <td className="gh-text">{data.title}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table> */}
                                            <center><div className="graph-heading-T">Top Three Articles</div>
                                                <div style={{ width: "110px" }}>
                                                    <Pie data={topThreeArticlesData} className="mt-1 mb-1 ml-2 mr-2" style={{ marginTop: "9px", padding: "10px" }}
                                                        options={{
                                                            plugins: {
                                                                legend: {
                                                                    display: false,
                                                                },
                                                            }
                                                        }
                                                        } />
                                                </div>
                                            </center>
                                        {/* <div className="mt-1 tableGraph-article">
                                            <table class="ml-1 table-striped" >
                                                <thead style={{ background: "#7d56f5" }}>
                                                    <tr>
                                                        <th scope="col" className="gh-heading" style={{padding:"3px 6px"}}>Name</th>
                                                        <th scope="col" className="gh-heading" style={{padding:"3px 6px"}}>Title</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {topThreeArticles.map((data, i) => (
                                                        <tr >
                                                            <td className="gh-text" style={{padding:"3px 6px"}}>{data.name}</td>
                                                            <td className="gh-text" style={{padding:"3px 6px"}}>
                                                                {(data.title.length) > 12 ?
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        trigger={overlay ?
                                                                            ""
                                                                            : "click"}

                                                                        rootClose
                                                                        overlay={(
                                                                            <Popover
                                                                                id="test">
                                                                                <Popover.Content >
                                                                                    <div style={{ marginTop: "-5px" }}><b>{data.title}</b></div>
                                                                                </Popover.Content>
                                                                            </Popover>
                                                                        )}>
                                                                        {overlay ?
                                                                            "" :
                                                                            <span style={{ cursor: "pointer", fontSize: "11px", marginTop: "1px", marginLeft: "1px" }}>{data.title.substring(0, 12)}...</span>
                                                                        }
                                                                    </OverlayTrigger>
                                                                    :
                                                                    <span style={{ fontSize: "11px", marginTop: "1px", marginLeft: "1px" }}>{data.title}</span>}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div> */}
                                    </Card>

                                    {/* <FourEs /> */}
                                </Col>
                            </div>
                        </Col>
                        {/* <Col md={2}>
                            <div className="row">
                                <Col>
                                    <Card className="ml-1 bar-graph-card box-shad" style={{ border: "none" }}>
                                        <div style={{ background: "#FF942c", borderRadius: "5px", width: "35px", height: "35px" }}><img src={campaign} style={{ width: "34px", padding: "5px" }} /></div>
                                        <div style={{ padding: "15px 25px", marginTop: "-1.5rem" }}>
                                            <center><h4><b>{totalRewards}</b></h4></center>
                                            <center><div className="graph-heading-T">Rewards</div></center>
                                        </div>
                                    </Card>
                                </Col>
                            </div>
                            <div className="mt-3 row" style={{ marginTop: "4px" }}>
                                <Col>
                                    <div className="ml-1">
                                        <PollsGraph />
                                    </div>
                                </Col>
                            </div>
                        </Col> */}
                    </Row>
                    <Row style={{ marginTop: "0.45rem", width: "100%" }} className="no-gutters">
                        <Col>
                            <Card className="mr-1 bar-graph-card box-shad" style={{ background: "rgba(255, 206, 86,0.1)" }}>
                                <center><div className="graph-heading-T">Games Played</div>
                                    <div style={{ width: "150px" }}>
                                        <Pie data={gamesData} className="mt-1 mb-1 ml-2 mr-2" style={{ marginTop: "10px", padding: "10px" }}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                }
                                            }
                                            } />
                                    </div>
                                </center>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ background: "rgba(86, 255, 241,0.1)" }}>
                                <center><div className="graph-heading-T">Sceemlore</div>
                                    <div style={{ width: "150px" }}>
                                        <Pie data={sceemloreData} className="mt-1 mb-1 ml-2 mr-2" style={{ marginTop: "10px", padding: "10px" }}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                }
                                            }
                                            } />
                                    </div>
                                </center>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{ background: "rgba(247, 5, 171,0.1)" }}>
                                <center><div className="graph-heading-T">Rewards</div>
                                    <div style={{ width: "150px" }}>
                                        <Pie data={rewardsData} className="mt-1 mb-1 ml-2 mr-2" style={{ marginTop: "10px", padding: "10px" }}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                }
                                            }
                                            } />
                                    </div>
                                </center>
                            </Card>
                        </Col>
                        <Col>
                            <FourEs />
                        </Col>
                        <Col>
                            <UserRoles />
                        </Col>
                    </Row>
                </div>

                {/* <Row style={{  marginTop: "1rem",width: "100%" }}>
                <Col md={6}>
                        <ArticleTopics />
                    </Col>
                    <Col md={6}>
                    </Col>
                </Row> */}
                {/* <Row style={{ marginTop:"0.7rem",width: "100%" }}>
                    <Col md={6}>
                        <CampaignsGraph/>
                    </Col>
                    <Col md={6}>
                        <OpportunitiesGraph/>
                    </Col>
                </Row>
                <Row style={{ marginTop:"0.7rem",width: "100%" }}>
                    <Col md={6}>
                        <PollsGraph/>
                    </Col>
                    <Col md={6}>
                        <SceemloreGraph/>
                    </Col>
                </Row>
                <Row style={{ marginTop:"0.7rem",width: "100%" }}>
                    <Col md={6}>
                        <QuestionsGraph/>
                    </Col>
                    <Col md={6}>
                        <ReferralsGraph/>
                    </Col>
                </Row>
                <Row style={{ marginTop:"0.7rem",width: "100%" }}>
                    <Col md={6}>
                        <OffersGraph/>
                    </Col>
                    <Col md={6}>
                        <FourEsGraph/>
                    </Col>
                </Row> */}
            </div>
        </div >
    );
}

