// "__method__": "GET",
// "__input__": "",
// "__errors__": [
//     "100 - Some error occurred while retrieving the details"
// ],
export const ReviewJson = [
    {
        id: 0,
        date: "Nov 21st 2021 9:20 AM",
        reviewerName: "James Sitepu",
        status: "Published",
        articleName: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        rating: 5,
        comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    },
    {
        id: 1,
        date: "Dec 21st 2021 9:20 AM",
        reviewerName: "James",
        status: "Rejected",
        articleName: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        rating: 3,
        comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    },
    {
        id: 2,
        date: "Nov 21st 2021 9:20 AM",
        reviewerName: "James",
        status: "Published",
        articleName: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        rating: 4,
        comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    },
    {
        id: 3,
        date: "Dec 21st 2021 9:20 AM",
        reviewerName: "James",
        status: "Published",
        articleName: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        rating: 4,
        comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    },
    {
        id: 4,
        date: "Nov 21st 2021 9:20 AM",
        reviewerName: "James",
        status: "Published",
        articleName: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        rating: 3,
        comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    },
    {
        id: 5,
        date: "Dec 21st 2021 9:20 AM",
        reviewerName: "James",
        status: "Rejected",
        articleName: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        rating: 5,
        comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    },
    {
        id: 6,
        date: "Nov 21st 2021 9:20 AM",
        reviewerName: "James",
        status: "Rejected",
        articleName: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        rating: 4,
        comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    }
]