import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Navbar, Nav, Container, NavDropdown, Form, FormControl } from "react-bootstrap";
import navlogo from '../images/nav-logo.svg';
import notifications from '../images/notifications.svg';
import settings from '../images/author1.svg';
import './NavBar.css';

export default class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = { clicked: false, role: sessionStorage.getItem('role'), name: sessionStorage.getItem('name') }
    }
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        const roleName = this.state.role.charAt(0).toUpperCase() + this.state.role.slice(1)
        return (
            <Navbar bg="#31197c" className="nav-height" fixed='top'>
                <Navbar.Brand href="#"> <img className="navbar-logo" src={navlogo} /> </Navbar.Brand>
                <Nav>
                </Nav>
                <Nav className=" ml-auto mr-right">
                    <div style={{ color: "white", fontSize: "12px" }}>{this.state.name} ({roleName})</div>

                </Nav>
            </Navbar>

        )
    }
}