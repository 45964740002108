import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import adv from '../images/advertisement.png';
import './Advertisement.css';
import axios from 'axios';
import sceemimage from '../images/sceem-logo.svg';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i
);
export default class CreateAnnouncement extends Component {
    constructor(props) {
        super(props);
        var today = new Date();
        var currentDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            image: "",
            campaignName: "",
            type: "",
            announcement: "",
            startDate: "",
            endDate: "",
            campaignUrl: "",
            imageUrl: "",
            campaignPaymentType: "",
            advertiserName: "",
            advertiserEmail: "",
            advertiserNumber: "",
            paidOrFree: "",
            payment: "",
            campaignNameError: '',
            addError: '',
            announcementError: '',
            startDateError: '',
            endDateError: '',
            nameError: '',
            emailError: '',
            phoneError: '',
            currentDate: currentDate,
            redirect: false

        }
        this.onChange = this.onChange.bind(this);
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    componentDidMount() {
     //   console.log(this.state.currentDate)
    }


    // saveAnnouncement= () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token,
    //         "applicationType": "web"
    //     }
    //     var payload = {
    //         "announcement": this.state.announcement,
    //         "status":0
    //     }
    //     console.log("payload", payload)
    //     var self = this
    //     axios.post(config.userUrl + 'user/createAnnouncementByAdmin', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status == true) {
    //                 self.setState({
    //                     redirect: true
    //                 })
    //                 console.log("success", response.data.message, payload);
    //             } else {
    //                 console.log("Error", payload);
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("error", error, payload);
    //         });
    // };
    createAnnouncement = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "announcement": this.state.announcement,
            "status":id
        }
        //console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/createAnnouncementByAdmin', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                   // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    render() {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to="/announcement" />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5rem", marginLeft: "275px" }} >
                    <Form onSubmit={this.handleSubmit}>

                        <Card style={{ width: "80%" }}>
                            <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                                <h5 className="mt-2 font-heading-web">Create Announcement</h5><br />

                                <label className="poll-date">Announcement<span className="red">*</span></label>
                                <div className="form-group">
                                    <textarea type="text"
                                        className="web-font form-control"
                                        aria-describedby="text"
                                        rows="5"
                                        name="announcement"
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    <div className="div">{this.state.announcementError && (
                                        <div className="error">{this.state.announcementError}</div>
                                    )}</div>
                                </div><br />

                            </div>
                        </Card><br />
                        {(this.state.announcement !== "")?
                            // <div><Button onClick={this.createAnnouncement} variant="default" className="camp-next">Publish</Button><Button variant="default" onClick={this.saveCampaign} className="ml-2 camp-done">Save</Button></div> :
                            //  <div><Button onClick={this.createAnnouncement} variant="default" className="camp-next" disabled={true}>Publish</Button><Button variant="default" onClick={this.saveCampaign} disabled={true} className="ml-2 camp-done">Save</Button></div>}
                            <div className="cancel-right"><Button variant="default" onClick={()=>this.createAnnouncement(1)} className="sendreview-btn mt-1 ml-4">Submit</Button><Button onClick={()=>this.createAnnouncement(0)} className="save-btn mt-1 ml-3" variant="default">Save</Button></div> :
                            <div className="cancel-right"><Button variant="default" onClick={this.createAnnouncement} disabled={true} className="sendreview-btn mt-1 ml-4">Submit</Button><Button onClick={this.createAnnouncement} disabled={true} className="save-btn mt-1 ml-3" variant="default">Save</Button></div>}
                        <br />
                    </Form>
                </div >
            </div >
        );
    };
}

