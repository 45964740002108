import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class EditOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            topic: "",
            offerName: "",
            startDate: "",
            endDate: "",
            url: "",
            rewardPoints: "",
            topicId: "",
            isLoading: true,
            submit: 1,
            save: 0,
            dateErr: "",
            startDateErr: "",
            endDateErr: ""
        }
    }
    componentDidMount() {
        this.getOfferById();
       // console.log("Id", this.props.match.params.id)

    }
    getOfferById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
      //  console.log("token", this.state.token)
        var self = this
        axios.get(config.userUrl + `user/getOfferById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status === true) {
                 //   console.log("success!!");
                    self.setState({
                        offerName: response.data.offer[0].offerName,
                        rewardPoints: response.data.offer[0].rewardPoints,
                        url: response.data.offer[0].url,
                        startDate: response.data.offer[0].startDate,
                        endDate: response.data.offer[0].endDate,
                        isLoading: false
                    })
                    var x = new Date()
                    var todaysDate = x.getFullYear() + '-' + (x.getMonth() + 1) + '-' + x.getDate();
                    var y = new Date(response.data.offer[0].startDate)
                    var newStartDate = y.getFullYear() + '-' + (y.getMonth() + 1) + '-' + y.getDate();
                    if ((Date.parse(newStartDate) < Date.parse(todaysDate))) {
                        self.setState({ startDateErr: "Start Date should be greater than Today's Date" })
                    } else {
                        self.setState({ startDateErr: "" })
                    }
                } else {
                  //  console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    changeStartDate(date) {
        this.setState({ startDateErr: "", startDate: date })
    }
    changeEndDate(date) {
        this.setState({ endDate: date })

    }
    handleSubmit = (e) => {
        e.preventDefault();
        //console.log(this.state)
    }
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    editOffer = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "status": data,
            "offerId": Number(this.props.match.params.id),
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "offerName": this.state.offerName,
            "rewardPoints": Number(this.state.rewardPoints),
            "url": this.state.url
        }
      //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/editOffer', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                //    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    render() {
        const { redirect, startDate, endDate, isLoading } = this.state

        if (isLoading) {
            return <div className="App"></div>;
        }
        var a = new Date(startDate)
        var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
        var b = new Date(endDate)
        var endD = parseInt(b.getMonth() + 1) + "-" + b.getDate() + "-" + b.getFullYear();
        const selectedStartDate = new Date(start)
        const selectedEndDate = new Date(endD)
      //  console.log("start", startDate)

        if (redirect) {
            return <Redirect to="/offers" />
        }
        var x = new Date()
        var todaysDate = x.getFullYear() + '-' + (x.getMonth() + 1) + '-' + x.getDate();
        var y = new Date(this.state.startDate)
        var newStartDate = y.getFullYear() + '-' + (y.getMonth() + 1) + '-' + y.getDate();
        var z = new Date(this.state.endDate)
        var newEndDate = z.getFullYear() + '-' + (z.getMonth() + 1) + '-' + z.getDate();
       // console.log("dtes", todaysDate, newStartDate, newEndDate)

        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5 className="font-heading-web">Edit Offer</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <div className="mt-3 poll-date">Offer Name</div>
                        <div className="form-group" style={{ width: "71.5%" }}>
                            <input type="text"
                                className="web-font form-control form-bg"
                                aria-describedby="text"
                                value={this.state.offerName}
                                name="offerName"
                                onChange={this.onChange}
                                placeholder="Offer name"
                            />
                        </div>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Start Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={selectedStartDate}
                                        minDate={new Date(Date.now())}
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => this.changeStartDate(date)}
                                        filterDate={(date) => date.getDay() === 1}
                                    />
                                    <div className="error">{this.state.startDateErr}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">End Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={selectedEndDate}
                                        minDate={new Date(selectedStartDate + 5 * 86400000)}
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => this.changeEndDate(date)}
                                        filterDate={(date) => date.getDay() === 5}
                                    />
                                    <div className="error">{this.state.endDateErr}</div>
                                </div>

                            </Col>
                        </Row>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Reward Points</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="rewardPoints"
                                        placeholder="Reward Points"
                                        value={this.state.rewardPoints}
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Url</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="url"
                                        placeholder="url"
                                        value={this.state.url}
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                        </Row><br />
                        <div className="float_right_poll">
                            {/* {console.log("data", (newStartDate > todaysDate < newEndDate),(newStartDate > todaysDate), (todaysDate < newEndDate))} */}

                            <>
                                <Button onClick={this.showPublish} variant="default" className="sendreview-btn ml-3">Submit </Button>
                                <div id="publish" className="modal_new">
                                    <form className="modal-content-log">
                                        <div className="md-container">
                                            <p className="mt-2">Do you want to publish the Offer?</p>
                                            <center><Button variant="default" onClick={() => this.editOffer(this.state.submit)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                <Button variant="default" onClick={this.showNone} className="deletebutton">No</Button></center>
                                        </div>
                                    </form>
                                </div>
                                <Button className="save-btn ml-3" onClick={() => this.editOffer(this.state.save)} variant="default">Update</Button></>

                            <Link to="/offers"><Button variant="default" className="cancelreview-btn ml-4">Back</Button></Link></div>
                    </Form><br /><br />
                </div >
            </div >
        );
    };
}





