import React, { Component } from "react";
import { Button, Form, Table, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class SequenceGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            publishedSequenceCards: [],
            redirect: false,
            isLoading: false,
            arr: [
                { id: 1, groupValue: 'Property' },
                { id: 2, groupValue: 'Purchase' },
                { id: 3, groupValue: 'Theme' },
                { id: 4, groupValue: 'Tax' },
            ]
        }
    }
    componentDidMount() {
        this.getAllPublishedSequenceCards();
    }


    getAllPublishedSequenceCards = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllPublishedSequenceCards', { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        publishedSequenceCards: response.data.allSequenceCards,
                        isLoading: false
                    })
                //    console.log("publishedSequenceCards", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { arr, isLoading, publishedSequenceCards } = this.state
        const b = publishedSequenceCards.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        // const move = (option, originalArray) => {
        //     var newArray = originalArray.slice();
        //     newArray.splice(0, 0, newArray.filter(item => item.groupValue == option)[0]);
        //     return newArray;
        // }
        // console.log(move("Theme", arr));
        const goFirst=(d, first)=>{
            var r = [];
            d.forEach((e) => {
              if (e['groupValue'] === first)
                r.unshift(e);
              else
                r.push(e);
            })
          
            return r;
          }
         // console.log(goFirst(arr, 'Theme'));
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >

                    <div >
                        <div> <Link to="/sequence/createcard"><Button variant="default" className="createadv-btn">+ &nbsp;Create</Button></Link>
                            {/* <Link to="/sequence/savedcards"><Button variant="default" className="ml-3 createadv-btn">&nbsp;Saved</Button></Link> */}

                        </div>
                        <div className="mt-3 active-add-h-text">Sequence Game</div>
                        <div>  {publishedSequenceCards.length == 0 ? <center><h4>No Sequence Cards</h4></center> :
                            <div class="mt-3 tableFixHead" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr>
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Theme</th>
                                            <th scope="col" className="th-heading" style={{ width: "28%" }}>Card Phrase</th>
                                            <th scope="col" className="th-heading" style={{ width: "17%" }}>Description</th>
                                            <th scope="col" className="th-heading" style={{ width: "10%" }}>Sequence No</th>
                                            <th scope="col" className="th-heading" style={{ width: "10%" }}>Status</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Created At</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {publishedSequenceCards.map((data, i) => (
                                            <tr className={i / 2 === 0 ? "" : "green"}>
                                                <td className="td-text" >&nbsp;&nbsp;{i + 1}</td>
                                                <td className="td-text">{data.theme}</td>
                                                <td className="td-text">{data.cardPhrase}</td>
                                                <td className="td-text">{data.description}</td>
                                                <td className="td-text">{data.sequenceNo}</td>
                                                <td className="td-text">{data.status == 1 ? "Active" : "Saved"}</td>
                                                <td className="td-text">{b[i]}</td>
                                                <td className="cursor"><Link to={`/sequence/viewsequence/${data.id}`} className="poll-link"> <i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                    </div>


                </div>
            </div >
        );
    };
}

