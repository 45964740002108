import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Poll.css';
import edit from '../images/edit.svg';
import pollData from './Poll.json';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class SavedPolls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            savedPolls: [],
            poll: pollData.savedPolls,
            isLoading: true,
            selPoll: 0
        }
    }
    componentDidMount() {
        this.getSavedPolls();
    }
    showLogout = (id) => {
        this.setState({ selPoll: id.target.getAttribute("data-position") })
        document.getElementById('modify').style.display = 'flex'

    }
    showLogoutNone = (id) => {
        document.getElementById('modify').style.display = 'none'
    }
    onEdit = (id) => {
        document.getElementById('modify').style.display = 'none'
        const elementsIndex = this.state.poll.findIndex(element => element.id == id)
    }
    getSavedPolls = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getSavedPolls', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        savedPolls: response.data.polls,
                        isLoading: false
                    })
                    //console.log("getSavedPolls", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    deletePoll = () => {
        var position = this.state.selPoll;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "pollId": position
        }
      //  console.log("payload", position, payload)
        var self = this
        axios.post(config.userUrl + 'user/deletePoll', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    document.getElementById('modify').style.display = 'none'
                    self.getSavedPolls();
                  //  console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    render() {
        const { isLoading } = this.state
        if (isLoading) {
            return <div className="App"></div>;
        }
        const updatedDate = this.state.savedPolls.map((i, idx) => {
            var a = new Date(i.updatedAt)
            // console.log("publishOn", i.publishOn, a.getDate(), (a.getMonth() + 1), a.getFullYear())
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;

        })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <div className="mt-3 active-add-h-text">Saved Polls</div>
                    {this.state.savedPolls.length === 0 ? <center className="mt-3"><h4>You have no Saved Polls.</h4></center> :
                        <div class="mt-3 tableFixHead-saved">
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr>
                                        <th scope="col" className="th-heading" style={{ width: "40%" }}>Poll Question</th>
                                        <th scope="col" className="th-heading" style={{ width: "40%" }}>Topic</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Updated On</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.savedPolls.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text">{data.pollQuestion}</td>
                                            <td className="td-text">{data.topicName}</td>
                                            <td className="td-text">{updatedDate[i]}</td>
                                            <td className="cursor" ><a href={`/poll/editpoll/${data.id}`} className="border-none"> <i class="fa fa-pencil left" aria-hidden="true"></i></a> </td>
                                            <td className="cursor" ><i key={i} data-position={data.id} onClick={this.showLogout} class="fa fa-trash" aria-hidden="true"></i></td>
                                            <div id="modify" className="modal_new">
                                                <form className="modal-content-log">
                                                    <div className="md-container">
                                                        <span class="_close" onClick={this.showLogoutNone}>&times;</span>
                                                        <p className="mt-2">Do you want to</p>
                                                        <center ><Button id="modify1" data-position1={data.id} variant="default" onClick={this.deletePoll} className="modifybutton">Delete</Button>&nbsp; &nbsp;
                                                            <Button variant="default" onClick={this.showLogoutNone} className="stopbutton">Cancel</Button></center>
                                                    </div>
                                                </form>
                                            </div>
                                        </tr>
                                    ))}<br />
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div >
        );
    };
}

