import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import adv from '../images/advertisement.png';
import './Advertisement.css';
import axios from 'axios';
import sceemimage from '../images/sceem-logo.svg';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i
);
export default class EditAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            announcement: "",
            redirect: false
        }
    }
    componentDidMount(){
        this.getAnnouncement();
    }
    onChange=(event)=>{
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    editAnnouncement = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "announcementId":parseInt(this.props.match.params.id),
            "announcement": this.state.announcement,
            "status":val
        }
       // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/updateAnnouncementByAdmin', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                  //  console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    getAnnouncement = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "announcementId": this.props.match.params.id,
        }
      //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/getAnnouncementById', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        announcement:response.data.getAnnouncement[0].announcement
                    })
                 //   console.log("success", response.data.getAnnouncement[0].announcement,response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    render() {
        const { redirect,announcement } = this.state;
        if (redirect) {
            return <Redirect to="/announcement" />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5rem", marginLeft: "275px" }} >
                    <Form onSubmit={this.handleSubmit}>

                        <Card style={{ width: "80%" }}>
                            <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                                <h5 className="mt-2 font-heading-web">Edit Announcement</h5><br />

                                <label className="poll-date">Announcement<span className="red">*</span></label>
                                <div className="form-group">
                                    <textarea type="text"
                                        className="web-font form-control"
                                        aria-describedby="text"
                                        rows="5"
                                        value={announcement}
                                        name="announcement"
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    {/* <div className="div">{this.state.announcementError && (
                                        <div className="error">{this.state.announcementError}</div>
                                    )}</div> */}
                                </div><br />

                            </div>
                        </Card><br />
                        {(this.state.announcement !== "")?
                            // <div><Button onClick={this.createAnnouncement} variant="default" className="camp-next">Publish</Button><Button variant="default" onClick={this.saveCampaign} className="ml-2 camp-done">Save</Button></div> :
                            //  <div><Button onClick={this.createAnnouncement} variant="default" className="camp-next" disabled={true}>Publish</Button><Button variant="default" onClick={this.saveCampaign} disabled={true} className="ml-2 camp-done">Save</Button></div>}
                            <div className="cancel-right"><Button variant="default" onClick={()=>this.editAnnouncement(1)} className="sendreview-btn mt-1 ml-4">Submit</Button><Button onClick={()=>this.editAnnouncement(0)} className="save-btn mt-1 ml-3" variant="default">Save</Button></div> :
                            <div className="cancel-right"><Button variant="default" disabled={true} className="sendreview-btn mt-1 ml-4">Submit</Button><Button onClick={this.saveAnnouncement} disabled={true} className="save-btn mt-1 ml-3" variant="default">Save</Button></div>}
                        <br />
                    </Form>
                </div >
            </div >
        );
    };
}

