import React, { Component } from "react";
import { Button, Form, Table, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../Advertisements/Advertisement.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class PublishedGames extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            games: [],
            redirect: false,
            isLoading: true
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
       // console.log("offers", this.state.offers)
        this.getAllPublishedGames();
    }

    handleChange = (e) => {
        const id = e.target.id;
        const elementsIndex = this.state.offers.findIndex(element => element.id == id)
        let newArray = [...this.state.offers]
        newArray[elementsIndex] = { ...newArray[elementsIndex], value: !newArray[elementsIndex].value }
        this.setState({ offers: newArray });
       // console.log("hi", newArray)
    }
    getAllPublishedGames = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllPublishedGames', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        games: response.data.games,
                        isLoading: false
                    })
                  //  console.log("getPublishedgames", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, games } = this.state
        const createdDate = games.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        const publishedDate = games.map((i, idx) => {
            var end = new Date(i.publishOn)
            var updatedEndDate = end.getDate() + "-" + parseInt(end.getMonth() + 1) + "-" + end.getFullYear();
            return updatedEndDate;
        })
        // if (isLoading) {
        //     return <div className="App"></div>;
        // }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >

                    <div >
                        <div> <Link to="/games/creategame"><Button variant="default" className="createadv-btn">+ &nbsp;Create</Button></Link>
                            <Link to="/games/savedgames"><Button variant="default" className="ml-3 createadv-btn">&nbsp;Saved</Button></Link>
                            {/* <Link to="/term"><Button variant="default" className="ml-3 createadv-btn">&nbsp;Term</Button></Link>
                            <Link to="/sequence"><Button variant="default" className="ml-3 createadv-btn">&nbsp;Sequence</Button></Link> */}
                        </div>
                        <div className="mt-3 active-add-h-text">Published Games</div>
                        {games.length === 0 ? <center><h4>No Published Games</h4></center> :
                            <div class="mt-3 tableFixHead" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr>
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Game Name</th>
                                            <th scope="col" className="th-heading" style={{ width: "17%" }}>Description</th>
                                            <th scope="col" className="th-heading" style={{ width: "12%" }}>No.of Trials</th>
                                            <th scope="col" className="th-heading" style={{ width: "12%" }}>Reward Points</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Game Url</th>
                                            <th scope="col" className="th-heading" style={{ width: "12%" }}>Created At</th>
                                            <th scope="col" className="th-heading" style={{ width: "12%" }}>Published On</th>
                                            <th ></th>
                                            <th ></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {games.map((data, i) => (
                                            <tr className={i / 2 === 0 ? "" : "green"}>
                                                <td className="td-text" >&nbsp;&nbsp;{i + 1}</td>
                                                <td className="td-text">{data.gameName}</td>
                                                <td className="td-text">{data.description}</td>
                                                <td className="td-text">{data.numberOfTrials}</td>
                                                <td className="td-text">{data.rewardPoints}</td>
                                                <td className="td-text">{data.gameUrl}</td>
                                                <td className="td-text">{createdDate[i]}</td>
                                                <td className="td-text">{publishedDate[i]}</td>
                                                <td className="cursor"><Link to={`/games/viewgame/${data.id}`} className="poll-link"> <i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                                                <td className="cursor" ><Link to={`/games/editgame/${data.id}`} className="poll-link"> <i class="fa fa-pencil left" aria-hidden="true"></i></Link> </td>
                                            </tr>
                                        ))}
                                          <tr>
                                                <td className="td-text" >&nbsp;&nbsp;{games.length+1}</td>
                                                <td className="td-text">Sceem QZ</td>
                                                <td className="td-text">Quiz</td>
                                                <td className="td-text">1</td>
                                                <td className="td-text">10</td>
                                                <td className="td-text">-</td>
                                                <td className="td-text">6-02-2024</td>
                                                <td className="td-text">6-02-2024</td>
                                                <td className="cursor"></td>
                                                <td className="cursor" ></td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>


                </div>
            </div >
        );
    };
}

