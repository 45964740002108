// import React, { Component } from "react";
// import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
// import { Link, Route, NavLink, Router } from 'react-router-dom';
// import NavBar from "../NavBar/NavBar";
// import Sidebar from "../Sidebar/Sidebar";
// import axios from 'axios';
// import { config } from '../Config/Config';
// import Chart from 'chart.js/auto';
// // import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
// import { ScatterChart, Scatter,LineChart,Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
// import SimpleCrypto from "simple-crypto-js";
// var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
// var simpleCrypto = new SimpleCrypto(key)

// export class UserRoles extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
//             usersData: []
//         }
//     }

//     componentDidMount() {
//         this.getUserRoles()
//     }
//     getUserRoles = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + this.state.token,
//             "applicationType": "web"
//         }
//         var self = this
//         axios.get(config.userUrl + 'user/getUserRoles', { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status == true) {
//                     self.setState({
//                         usersData: response.data.userRolesData,
//                     })
//                 }
//             })
//             .catch(function (error) {
//                 console.log("Error");
//             });
//     };

//     render() {
//         const { usersData } = this.state;

//         const fourEs = {
//             labels: usersData.map((data) => data.roleType),
//             datasets: [
//                 {
//                     label: 'Roles',
//                     data: usersData.map((data) => data.count),
//                     backgroundColor: [
//                         'rgba(255, 99, 132, 0.2)',
//                     ],
//                     borderColor: [
//                         'rgba(255, 99, 132, 1)',
//                     ],
//                     borderWidth: 1,
//                     borderThickness: 20
//                 }
//             ]
//         }
//         return (
//             <div>
//                 <Card className="mt-3 bar-graph-card">
//                 <center><h5>User Roles</h5></center>
//                     {/* <ScatterChart
//                             width={500}
//                             height={300}
//                             margin={{
//                                 top: 10,
//                                 right: 10,
//                                 bottom: 5,
//                                 left: 5,
//                             }}
//                         >
//                             <XAxis   dataKey={(usersData) => usersData.roleType.charAt(0).toUpperCase() + usersData.roleType.slice(1)} style={{ fontFamily: 'Open Sans', fontSize: "10px" }} />
//                             <YAxis type="number"
//                                 dataKey={(usersData) => parseInt(usersData.count)}
//                                 style={{ fontFamily: 'Open Sans', fontSize: "10px" }} />
//                             <Tooltip cursor={false} style={{ fontFamily: 'Open Sans', fontSize: "10px" }} />
//                             <Scatter data={usersData} fill="#8884d8" />
//                         </ScatterChart> */}
//                         {/* {(usersData) => usersData.roleType.charAt(0).toUpperCase() + usersData.roleType.slice(1)} */}
//                     <LineChart
//                         width={380}
//                         height={192}
//                         data={usersData}
//                         margin={{
//                             top: 5,
//                             right: 10,
//                             left: 2,
//                             bottom: 5
//                         }}
//                     >
//                         <XAxis dataKey={(usersData) => usersData.roleType.charAt(0).toUpperCase() + usersData.roleType.slice(1)} style={{ fontFamily: 'Open Sans', fontSize: "10px" }} />
//                         <YAxis type="number"
//                             dataKey={(usersData) => parseInt(usersData.count)}
//                             style={{ fontFamily: 'Open Sans', fontSize: "10px" }} />
//                         <Tooltip cursor={false} style={{ fontFamily: 'Open Sans', fontSize: "10px" }} />
//                         <Line
//                             type="monotone"
//                             dataKey="count"
//                             stroke="#8884d8"
//                             activeDot={{ r: 8 }}
//                         />
//                     </LineChart>
//                 </Card>
//             </div>
//         );
//     };
// }

import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import Chart from 'chart.js/auto';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export class UserRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            usersData: []
        }
    }

    componentDidMount() {
        this.getUserRoles()
    }

    getUserRoles = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getUserRoles', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        usersData: response.data.userRolesData,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { usersData, selectedValue } = this.state;

        const users = {
            labels: usersData.map((data) => data.roleType.charAt(0).toUpperCase() + data.roleType.slice(1)),
            datasets: [
                {
                    data: usersData.map((data) => data.count),
                    backgroundColor: [
                        'white',
                    ],
                    borderWidth: 1,
                    barThickness: 10,
                    borderThickness: 20
                }
            ]
        }
        return (
            <div>
                <Card className="ml-1 bar-graph-card box-shad" style={{backgroundColor:"#fcefc7"}}>
                    <center><div className="graph-heading-T">User Roles</div></center>
                <div style={{marginTop:"-4px",height:"148px",width:"auto"}}>
                <Bar data={users}
                        options={{
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        display: false,
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                    text: "Users"
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
                </Card>
            </div>
        );
    };
}