// "__method__": "GET",
// "__input__": "",
// "__errors__": [
//     "100 - Some error occurred while retrieving the details"
// ],
export const FollowersJson = [
    {
        id: 1,
        image: "../author1.svg",
        name: "UserName"
    },
    {
        id: 2,
        image: "../author2.svg",
        name: "UserName"
    },
    {
        id: 3,
        image: "../author3.svg",
        name: "UserName"
    },
    {
        id: 4,
        image: "../author4.svg",
        name: "UserName"
    },
    {
        id: 5,
        image: "../author1.svg",
        name: "UserName"
    },
    {
        id: 6,
        image: "../author2.svg",
        name: "UserName"
    },
    {
        id: 7,
        image: "../author3.svg",
        name: "UserName"
    },
    {
        id: 8,
        image: "../author4.svg",
        name: "UserName"
    },
    {
        id: 9,
        image: "../author1.svg",
        name: "UserName"
    },
    {
        id: 10,
        image: "../author2.svg",
        name: "UserName"
    },
    {
        id: 11,
        image: "../author3.svg",
        name: "UserName"
    },
    {
        id: 12,
        image: "../author4.svg",
        name: "UserName"
    },
    {
        id: 13,
        image: "../author1.svg",
        name: "UserName"
    },
    {
        id: 14,
        image: "../author2.svg",
        name: "UserName"
    },
    {
        id: 15,
        image: "../author3.svg",
        name: "UserName"
    },
    {
        id: 16,
        image: "../author4.svg",
        name: "UserName"
    },
    {
        id: 17,
        image: "../author1.svg",
        name: "UserName"
    },
    {
        id: 18,
        image: "../author2.svg",
        name: "UserName"
    }
]
