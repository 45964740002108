import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
// import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer } from 'recharts';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function EachTopicGraph() {

    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [eachTopicView, setEachTopicView] = useState([]);
    useEffect(() => {
        getEachTopicViews();
    }, []);
    const getEachTopicViews = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getEachTopicViews', { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status === true) {
                    if (response.data.status === true) {
                        setEachTopicView(response.data.eachTopicViews);

                }
            }}) 
            .catch(function (error) {
                console.log("error", error);
            })
    };
    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
    }
    const data = {
        labels:  eachTopicView.map((data) => data.topicName),
        datasets: [
            {
                data: eachTopicView.map((data) => data.count),
                fill: true,
                backgroundColor: [
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    // 'rgba(255, 159, 64, 0.8)',,
                
                    '#31197C',],
                borderWidth: 1
            }
        ]
    }


    const COLORS = ['#0088FE', '#ff942c', '#00C49F', '#FFBB28', '#ff0000', '#00ff00', '#b64042', '#03fca5', '#0000ff',];
    return (
        <div>
            <Card className="bar-graph-card box-shad mr-1">
                <center className="graph-heading-T">Article Counts</center>
                <Doughnut data={data} className="mt-2 ml-2 mb-2 mr-2"
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                        }
                    }
                    } />
            </Card>
        </div >
    )
}
