import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ViewIncident(props) {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [reason, setReason] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("")
    const [createdAt, setCreatedAt] = useState("")
    const [incidentId, setIncidentId] = useState()
    const [name, setName] = useState("")
    const [resolutionDate, setResolutionDate] = useState()
    const [redirect, setRedirect] = useState(false)

    useEffect(() => {
        getIncidentById()
    }, []);
    const getIncidentById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + `user/getIncidentById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    let data = response.data.incident[0];
                    setTitle(data?.title)
                    setDescription(data?.description)
                    setCreatedAt(data?.createdAt)
                    setStatus(data?.status)
                    setIncidentId(data?.id)
                    setResolutionDate(data?.resolutionDate)
                    setName(data?.name)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    if (redirect) {
        return <Redirect to="/games" />
    }

    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                <h5>View Incident</h5>
                <Form>
                    <Row style={{ width: "74%" }}>
                        <Col md={6}>
                            <div className="poll-date">Username</div>
                            <div className="form-group">
                                <input className="web-font form-control" name="name"
                                    placeholder="Name"
                                    value={name}
                                    readOnly />
                            </div>

                        </Col>
                        <Col md={6}>
                            <div className="poll-date">Created At</div>
                            <div className="form-group">
                                <input className="web-font form-control" name="numberOfTrials"
                                    placeholder="Created At"
                                    value={moment(createdAt).format("DD-MM-YYYY")}
                                    readOnly />
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-1 poll-date">Title</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <input className="web-font form-control" name="title"
                            placeholder="Title"
                            value={title}
                            readOnly />
                    </div>
                    <div className="mt-1 poll-date">Description</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="description"
                            placeholder="Description"
                            value={description}
                            rows={2}
                            style={{ resize: "none" }}
                            readOnly />
                    </div>
                    <div className="mt-1 poll-date">Reason</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="reason"
                            placeholder="Reason"
                            rows={5}
                            style={{ resize: "none" }}
                            value={reason}
                            readOnly
                        />
                    </div>
                    <Row style={{ width: "74%" }}>
                        <Col md={6}>
                            <div className="poll-date">Status</div>
                            <div className="form-group">
                                <input className="web-font form-control" name="status"
                                    placeholder="Status"
                                    value={status == 1 ? "Active" : status == 2 ? "Closed" : "Rejected"}
                                    readOnly />
                            </div>

                        </Col>
                        <Col md={6}>
                            <div className="poll-date">Resolution Date</div>
                            <div className="form-group">
                                <input className="web-font form-control" name="resolutionDate"
                                    placeholder="Resolution Date"
                                    value={status == 1 ? "" : moment(resolutionDate).format("DD-MM-YYYY")}
                                    readOnly />
                            </div>
                        </Col>
                    </Row>
                    <Link to="/incidents" className="float_right_poll"><Button variant="default" className="cancelreview-btn mt-2">Back</Button></Link>

                </Form>
            </div >
        </div >
    );
};
