import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import addimage from '../images/addimage.svg'
import './Advertisement.css';
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewUserCampaignById extends Component {
    constructor(props) {
        super(props);
        this.imgRef=React.createRef()
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            imageUrl: "",
            image:"",
            campaignName: "",
            type: "",
            description: "",
            startDate: "",
            endDate: "",
            campaignUrl: "",
            campaignPaymentType: "",
            advertiserName: "",
            advertiserEmail: "",
            advertiserNumber: "",
            location: "",
            url: "",
            paidOrFree: "",
            tireToAdvertise: "",
            payment: "",
            publish: true,
            save: false,
            isLoading: true

        }
    }
    componentDidMount = () => {
        this.getCampaignById();
    }
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "advertisementId":this.props.match.params.id
        }
        var self = this;
        axios.post(config.userUrl + "user/getAdvertisementById", payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                //    console.log("success!!");
                    self.setState({
                        campaignName: response.data.advertisement[0].campaignName,
                        campaignPaymentType: response.data.advertisement[0].advertiserPaymentType,
                        description: response.data.advertisement[0].description,
                        startDate: response.data.advertisement[0].startDate,
                        type: response.data.advertisement[0].type,
                        advertiserName: response.data.advertisement[0].advertiserName,
                        advertiserEmail: response.data.advertisement[0].advertiserEmail,
                        advertiserNumber: response.data.advertisement[0].advertiserNumber,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { startDate, endDate, isLoading,image,imgRef } = this.state
        var a = new Date(startDate)
        var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
        const selectedStartDate = new Date(start)
        //console.log("start", startDate)

        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <Card style={{ width: "80%" }}><br />
                        <h5 style={{ marginLeft: "1.2rem" }}>View Campaign</h5><br />
                        <Form style={{ marginLeft: "1.2rem", width: "70%" }}>
                            <label className="poll-date">Campaign Name<span className="red">*</span></label>
                            <div className="form-group">
                                <input type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    name="campaignName"
                                    readOnly
                                    value={this.state.campaignName}
                                />
                            </div>
                            <div class="row" >
                                <div class="col-md-6">
                                    <label className="poll-date">Type of Advertisement<span className="red">*</span></label>
                                    <select readOnly value={this.state.type} name="type" class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="commercial">Commercial</option>
                                        <option value="non-commercial">Non-Commercial</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Paid / Free</label>
                                    <select readOnly value={this.state.campaignPaymentType} name="campaignPaymentType" class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="paid">Paid</option>
                                        <option value="free">Free</option>
                                    </select>
                                </div>
                            </div><br />
                            <label className="poll-date">Description<span className="red">*</span></label>
                            <div className="form-group">
                                <textarea type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    rows="5"
                                    name="description"
                                    readOnly
                                    value={this.state.description}
                                />
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Start Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedStartDate}
                                            minDate={new Date(Date.now())}
                                            dateFormat="dd-MM-yyyy"
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">End Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        <DatePicker
                                            className="web-font form-control form-bg"
                                            readOnly
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>
                                </div>
                            </div><br />
                            <label className="poll-date">URL</label>
                            <div className="form-group">
                                <input type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    name="campaignUrl"
                                    value={this.state.campaignUrl}
                                    readOnly
                                />
                            </div><br />
                            <div className="form-group">
                                <label className="poll-date">Image<span className="red">*</span></label><br />
                                {/* <img src={config.userUrl + this.state.imageUrl} alt="" className="create-imageurl" /> */}
                                <div style={{ height: "120px", textAlign: "center", objectFit: "contain" }}>
                                    <label htmlFor="upload-button">
                                        {image != "" ? (
                                            <img src={image === "uploads/1649942289609advertisement.png" ? config.userUrl + "uploads/1649942289609advertisement.png" : image} style={{ width: "100px", height: "100px" }} />
                                        ) : (
                                            <>
                                                <i class="fa fa-upload fa-2x" style={{ cursor: "pointer" }} aria-hidden="true"></i>
                                                <div className="up-load">Upload Image</div>
                                            </>
                                        )}
                                    </label>
                                    <input
                                        type="file"
                                        disabled={true}
                                        style={{ display: "none" }}
                                        id="upload-button"
                                        name="image"
                                        ref={imgRef}
                                        readOnly
                                    />
                                </div>

                            </div>
                        </Form><br />
                    </Card><br />
                    <Card style={{ width: "80%" }}><br />
                        <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                            <h5 >Advertiser's Details</h5><br />
                            <div>
                                <label className="poll-date">Advertiser Name<span className="red">*</span></label>
                                <input type="text"
                                    className="web-font form-control"
                                    name="advertiserName"
                                    value={this.state.advertiserName}
                                    readOnly
                                />
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Email<span className="red">*</span></label>
                                    <input type="email"
                                        className="web-font form-control"
                                        name="advertiserEmail"
                                        readOnly
                                        value={this.state.advertiserEmail}
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Mobile Number<span className="red">*</span></label>
                                    <input type="number"
                                        className="web-font form-control"
                                        name="advertiserNumber"
                                        readOnly
                                        value={this.state.advertiserNumber}
                                    />
                                </div>
                            </div>
                        </div><br />
                    </Card><br />
                    <Link to="/advertisement"><div className="cancel-right"><Button variant="default" className="cancelreview-btn mt-1 ml-4">Cancel</Button>
                    </div></Link>
                </div>
            </div>
        );
    };
}

