import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import Chart from 'chart.js/auto';
// import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export class FourEs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            usersFourEsData: []
        }
    }

    componentDidMount() {
        this.getAllFourEsData()
    }
    getAllFourEsData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllFourEsData', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        usersFourEsData: response.data.fourEsData,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { usersFourEsData } = this.state;

        const fourEs = {
            labels: usersFourEsData.map((data) => data.fourEs),
            datasets: [
                {
                    label: 'Four Es',
                    data: usersFourEsData.map((data) => data.count),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1,
                    borderThickness: 20
                }
            ]
        }

        const color = ['#6cd4fc', 'rgba(153, 102, 255, 0.8)', '#0c145b', '#ff942c']
        return (
            <div>
                <Card className="ml-1 bar-graph-card mr-1 box-shad" style={{background:"rgba(128, 240, 17,0.1)", height: "21.7vh"}}>
                    <center><div className="graph-heading-T">4Es Distribution</div></center>
                    {usersFourEsData.map((data, i) => (
                        <div className="fourEs_data"
                            style={{
                                display: "flex", alignItems: "center", justifyContent: "center",
                                position: "absolute", top: `${i == 0 ? 26 : (i == 1 ? 45 : (i == 2 ? 27 : 13))}%`, zIndex: `${i == 0 ? 1 : (i == 1 ? 3 : (i == 2 ? 2 : 4))}`, left: "60%", transform: `translateX(${i == 0 ? -25 : (i == 1 ? -75 : (i == 2 ? -116 : (i == 3 ? -85 : -40)))}%)`, width: `${data.count * 3.8}px`, borderRadius: "50%",
                                background: color[i % color.length], height: `${data.count * 3.8}px`, fontSize: `${data.count > 20 ? 30 : data.count > 10 ? 20 : data.count < 10 ? 15 : 30}px`
                            }}
                        >
                            {data.count}</div>
                    ))}
                    <div style={{
                        display: "flex", position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)", bottom: "0.6rem", alignItems: "center", justifyContent: "center", textAlign: "center"
                    }}>
                        {usersFourEsData.map((data, i) => (
                            <div style={{ padding: "1px 2px", fontSize: "1.4vh", fontFamily: "Open Sans" }}><div style={{ width: "15px", height: "8px", background: color[i % color.length] }}></div> {data.fourEs}</div>
                        ))}
                    </div>
                </Card>
            </div>
        );
    };
}