import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, Container, Col, Row, Image } from "react-bootstrap";
import { Link, NavLink, Redirect, Switch, Route } from 'react-router-dom';
import opportunity from '../images/opportunity.png';
import article from '../images/article.svg';
import reviewqueue from '../images/reviewqueue.svg';
import advertisement from '../images/advertisement.svg';
import announcement from '../images/announcement.svg';
import reviews from '../images/reviews.svg';
import downarrow from '../images/downarrow.png';
import rightarrow from '../images/rightarrow.png';
import transaction from '../images/pricing.png';
import requests from '../images/requests.svg';
import { BsGrid1X2 } from "react-icons/bs";
import { GiGamepadCross } from "react-icons/gi";
import sceemlore from '../images/sceemlore-logo.svg';
import historyRequests from '../images/requests history.svg';
import user from '../images/user.svg';
import bug from '../images/bug.svg';
import poll from '../images/poll.svg';
import audit from '../images/Audit.png';
import logout from '../images/logout.svg';
import nebutech from '../images/nebutech.svg';
import './Sidebar.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            role: sessionStorage.getItem('role'),
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            redirect: false,
            game: false,
            configuration: false
        }

        this.showLogout = this.showLogout.bind(this);
    }
    handleClick = id => {
        this.setState({ activeLink: id });
    };
    showLogout = () => {
        document.getElementById('logout').style.display = 'flex'
    }
    showGames = () => {
        this.setState({ game: !this.state.game })
    }
    showConfig = () => {
        this.setState({ configuration: !this.state.configuration })
    }
    // logoutFromApp = () => {
    //     document.getElementById('logout').style.display = 'none'
    //     sessionStorage.clear();
    //     this.setState({ redirect: true })
    // }
    logoutSession = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.apiUrl + 'auth/logout', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    sessionStorage.clear();
                    self.setState(
                        {
                            redirect: true
                        })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    showLogoutNone = () => {
        document.getElementById('logout').style.display = 'none'
    }
    componentDidUpdate() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
    }
    // getNewFeatureByMerchantTypeNumber = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token,
    //         "applicationType": "web"
    //     }
    //     var self = this
    //     axios.get(config.userUrl + 'user/getMenuItem', { headers: headers })
    //         .then(function (response) {
    //             console.log("menu",response);
    //             if (response.data.status === true) {
    //                 self.setState({
    //                     features: response.data.menuItems[0].features,
    //                     merchantType: response.data.menuItems[0].planId
    //                 })
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error", error);
    //         });
    // };
    render() {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to="/" />
        }
        if (this.state.role === "author") {
            return (
                <div>
                    <div class="sidebar">
                        <NavLink to="/articles" activeClassName="active"><img src={article} className="sidebar-icons" /><span className="text-left">Articles</span></NavLink>
                        <NavLink to="/poll" activeClassName="active"><img src={poll} className="sidebar-icons" /><span className="text-left">Polls</span></NavLink>
                        <a href="#" style={{ cursor: "pointer" }} onClick={this.showLogout} ><img src={logout} className="sidebar-icons" /><span className="text-left">Logout</span></a>
                        <div id="logout" className="modal_new">
                            <form className="modal-content-log">
                                <div className="md-container">
                                    <p className="mt-2">Do you really want to logout from your account?</p>
                                    <center><Button variant="default" onClick={this.logoutSession} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                        <Button variant="default" onClick={this.showLogoutNone} className="deletebutton">No</Button></center>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="bottom-nebutech">
                        <img alt="" src={nebutech} className="nebutech-img" />
                    </div>
                </div >
            );
        } else if (this.state.role === "reviewer") {
            return (
                <div>
                    <div class="sidebar">
                        <NavLink to="/reviewqueue" activeClassName="active"><img src={reviewqueue} className="sidebar-icons" /><span className="text-left">Reviews</span></NavLink>
                        <NavLink to="/reviews" activeClassName="active"><img src={reviews} className="sidebar-icons" /><span className="text-left">Reviewed (History)</span></NavLink>
                        <NavLink to="/poll" activeClassName="active"><img src={poll} className="sidebar-icons" /><span className="text-left">Polls</span></NavLink>
                        <a href="#" style={{ cursor: "pointer" }} onClick={this.showLogout} ><img src={logout} className="sidebar-icons" /><span className="text-left">Logout</span></a>
                        <div id="logout" className="modal_new">
                            <form className="modal-content-log">
                                <div className="md-container">
                                    <p className="mt-2">Do you really want to logout from your account?</p>
                                    <center><Button variant="default" onClick={this.logoutSession} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                        <Button variant="default" onClick={this.showLogoutNone} className="deletebutton">No</Button></center>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="bottom-nebutech">
                        <img alt="" src={nebutech} className="nebutech-img" />
                    </div>
                </div >
            );
        } else if (this.state.role === "sme") {
            return (
                <div>
                    <div class="sidebar">
                        <NavLink to="/smequeue" activeClassName="active"><img src={reviewqueue} className="sidebar-icons" /><span className="text-left">Questions</span></NavLink>
                        <NavLink to="/carddetails" activeClassName="active"><img src={advertisement} className="sidebar-icons" /><span className="text-left">Sceemlore</span></NavLink>
                        <NavLink to="/poll" activeClassName="active"><img src={poll} className="sidebar-icons" /><span className="text-left">Polls</span></NavLink>
                        <a href="#" style={{ cursor: "pointer" }} onClick={this.showLogout} ><img src={logout} className="sidebar-icons" /><span className="text-left">Logout</span></a>
                        <div id="logout" className="modal_new">
                            <form className="modal-content-log">
                                <div className="md-container">
                                    <p className="mt-2">Do you really want to logout from your account?</p>
                                    <center><Button variant="default" onClick={this.logoutSession} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                        <Button variant="default" onClick={this.showLogoutNone} className="deletebutton">No</Button></center>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="bottom-nebutech">
                        <img alt="" src={nebutech} className="nebutech-img" />
                    </div>
                </div >
            );
        } else {
            return (
                <div>
                    <div class="sidebar">
                        <NavLink to="/dashboard" activeClassName="active"><BsGrid1X2 style={{color:"#31197c"}} className="sidebar-icons"/><span className="text-left">Dashboard</span></NavLink>
                        <NavLink to="/advertisement" activeClassName="active"><img src={advertisement} className="sidebar-icons" /><span className="text-left">Advertisements</span></NavLink>
                        <NavLink to="/announcement" activeClassName="active"><img src={announcement} className="sidebar-icons-announcement" /><span className="ml-1">&nbsp;Announcements</span></NavLink>
                        <NavLink to="/carddetails" activeClassName="active"><img src={sceemlore} className="sidebar-icons" /><span className="text-left">Sceemlore</span></NavLink>
                        <NavLink to="/poll" activeClassName="active"><img src={poll} className="sidebar-icons" /><span className="text-left">Polls</span></NavLink>
                        <NavLink to="/games" activeClassName="active"><GiGamepadCross size={"20px"} style={{color:"#31197c"}} className="sidebar-icons" /><span className="text-left">Games</span></NavLink><img onClick={this.showGames} src={this.state.game ? rightarrow : downarrow} style={{marginTop:"-20px"}} className="downarrow" />
                        {this.state.game ? <div className="ml-4"><NavLink to="/term" activeClassName="active"><img src={poll} className="sidebar-icons" /><span className="text-left">Term</span></NavLink>
                            <NavLink to="/sequence" activeClassName="active"><img src={poll} className="sidebar-icons" /><span className="text-left">Sequence</span></NavLink>
                            <NavLink to="/quiz" activeClassName="active"><img src={sceemlore} className="sidebar-icons" /><span className="text-left">Sceem QZ</span></NavLink></div> : ""}
                        {/* <NavLink to="/term" activeClassName="active"><img src={poll} className="sidebar-icons" /><span className="text-left">Term</span></NavLink>
                        <NavLink to="/sequence" activeClassName="active"><img src={poll} className="sidebar-icons" /><span className="text-left">Sequence</span></NavLink> */}
                        <div style={{ marginTop: "-15px", marginBottom: "-15px" }}><hr /></div>
                        <NavLink to="/articles" activeClassName="active"><img src={reviewqueue} className="sidebar-icons" /><span className="text-left">Publish Article</span></NavLink>
                        <NavLink to="/tobereviewedarticles" activeClassName="active"><img src={reviewqueue} className="sidebar-icons" /><span className="text-left">Reviews</span></NavLink>
                        <NavLink to="/requests" activeClassName="active"><img src={requests} className="sidebar-icons" /><span className="text-left">Expression of Interest</span></NavLink>
                        <NavLink to="/reportrequests" activeClassName="active"><img src={historyRequests} className="sidebar-icons" /><span className="text-left">Block Article</span></NavLink>
                        <NavLink to="/opportunities" activeClassName="active"><img src={opportunity} className="sidebar-icons" /><span className="text-left">Opportunities</span></NavLink>
                        <NavLink to="/purchases" activeClassName="active"><img src={transaction} className="sidebar-icons" /><span className="text-left">Transactions</span></NavLink><div style={{ marginTop: "-15px", marginBottom: "-15px" }}><hr /></div>
                        <NavLink to="/history/usersreach" activeClassName="active"><img src={audit} className="sidebar-icons-audit" /><span className="text-left">Reports</span></NavLink>
                        <NavLink to="/configuration/list" activeClassName="active"><img src={audit} className="sidebar-icons-audit" /><span className="text-left">Configuration</span></NavLink><img onClick={this.showConfig} src={this.state.configuration ? rightarrow : downarrow} style={{marginTop:"-20px"}} className="downarrow" />
                       
                        {this.state.configuration ? <div className="ml-4"> <NavLink to="/rewards" activeClassName="active"><img src={sceemlore} className="sidebar-icons" /><span className="text-left">Rewards</span></NavLink>
                        <NavLink to="/offers" activeClassName="active"><img src={sceemlore} className="sidebar-icons" /><span className="text-left">Offers</span></NavLink></div>:""}
                        {/* <a className="sidenav-text"><img alt="" src={referral} className="usericon" />&nbsp; &nbsp; {items[4]} <img onClick={this.showReferral} src={this.state.referral ? rightarrow : downarrow} className="downarrow" />
                                {this.state.referral ? <span>
                                    <Link to="/referafriend" className="toggle-text link">&nbsp;&nbsp;Refer a Friend</Link>
                                    <Link to="/referralstatus" className="toggle-text link">&nbsp;&nbsp;Referral Status</Link>

                                </span> : null}
                            </a></div> */}
                        <NavLink to="/incidents" activeClassName="active"><img src={bug} className="sidebar-icons-audit" /><span className="text-left">Incidents</span></NavLink>
                        <a href="#" style={{ cursor: "pointer" }} onClick={this.showLogout} ><img src={logout} className="sidebar-icons" /><span className="text-left">Logout</span></a>
                        <div id="logout" className="modal_new">
                            <form className="modal-content-log">
                                <div className="md-container">
                                    <p className="mt-2">Do you really want to logout from your account?</p>
                                    <center><Button variant="default" onClick={this.logoutSession} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                        <Button variant="default" onClick={this.showLogoutNone} className="deletebutton">No</Button></center>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="bottom-nebutech">
                        <img alt="" src={nebutech} className="nebutech-img" />
                    </div>
                </div >
            );
        }
    }
}