import React, { useState, useEffect } from 'react';
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Link, Redirect } from 'react-router-dom';
import SimpleCrypto from "simple-crypto-js";
import NavBar from '../NavBar/NavBar';
import Sidebar from '../Sidebar/Sidebar';

var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)
const EditQuiz = (props) => {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [topicName, setTopicName] = useState('');
    const [topicId, setTopicId] = useState();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [question, setQuestion] = useState('');
    const [points, setPoints] = useState('');
    const [duration, setDuration] = useState('');
    const [options, setOptions] = useState(['', '', '', '']);
    const [correctAnswerIndex, setCorrectAnswerIndex] = useState(0);
    const [redirect, setRedirect] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [quesNo, setQuesNo] = useState(1)
    const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(1);


    useEffect(() => {
        getQuizById();
    }, [props.match.params.id]);

    useEffect(() => {
        // Filter questions based on the search query
        const filtered = questions.filter(question =>
            question.question.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredQuestions(filtered);
    }, [searchQuery, questions]);

    const handleQuestionSelect = (selectedQuestion, index) => {
        // Set the selected question and its options in the input boxes
        setQuestion(selectedQuestion.question);
        setOptions([...selectedQuestion.options]);
        setCorrectAnswerIndex(selectedQuestion.correctAnswerIndex);
        setPoints(selectedQuestion.points);
        setDuration(selectedQuestion.duration);
        // Set the selected question number based on the length of the main questions list
        setSelectedQuestionNumber(index + 1);
        setCurrentQuestionIndex(index)
        //  console.log("len", index + 1)
        setSearchQuery("")
    };

    const handleSearchKeyPress = (e) => {
        // Show filtered items on pressing "Enter"
        if (e.key === 'Enter') {
            setFilteredQuestions(questions.filter(question =>
                question.question.toLowerCase().includes(searchQuery.toLowerCase())
            ));
            setSearchQuery("")
        }
    };
    // useEffect(() => {
    //     // Filter questions based on the search query
    //     const filtered = questions.filter(question =>
    //         question.question.toLowerCase().includes(searchQuery.toLowerCase())
    //     );
    //     setFilteredQuestions(filtered);
    // }, [searchQuery, questions]);
    // const handleQuestionSelect = (selectedQuestion) => {
    //     // Set the selected question and its options in the input boxes
    //     setQuestion(selectedQuestion.question);
    //     setOptions([...selectedQuestion.options]);
    //     setCorrectAnswerIndex(selectedQuestion.correctAnswerIndex);
    //     setQuesNo(currentQuestionIndex + 1)
    //     let currentQuestionNumber = currentQuestionIndex + 1;

    //     console.log("index", selectedQuestion.correctAnswerIndex, currentQuestionNumber)
    //     setSearchQuery("")
    // };
    // const handleSearchKeyPress = (e) => {
    //     // Show filtered items on pressing "Enter"
    //     if (e.key === 'Enter') {
    //         setFilteredQuestions(questions.filter(question =>
    //             question.question.toLowerCase().includes(searchQuery.toLowerCase())
    //         ));
    //         setSearchQuery("")
    //     }
    // };
    const getQuizById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }

        axios.get(config.userUrl + `user/getQuizById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setQuestions(response.data.quizData[0]?.questions)
                    setTopicName(response.data.topicDetails.topicName)
                    setTopicId(response.data.quizData[0]?.topicId)
                    // Set default values for the first question
                    setQuestion(response.data.quizData[0]?.questions[0]?.question || '');
                    setOptions([...response.data.quizData[0]?.questions[0]?.options] || ['', '', '', '']);
                    setCorrectAnswerIndex(response.data.quizData[0]?.questions[0]?.correctAnswerIndex || 0);
                    setPoints(response.data.quizData[0]?.questions[0]?.points || '');
                    setDuration(response.data.quizData[0]?.questions[0]?.duration || '');
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setQuestions(prevQuestions => {
                const newQuestions = [...prevQuestions];
                newQuestions[currentQuestionIndex] = { question, options, correctAnswerIndex, points, duration };
                return newQuestions;
            });
            const prevIndex = currentQuestionIndex - 1;
            setQuestion(filteredQuestions[prevIndex].question);
            setOptions([...filteredQuestions[prevIndex].options]);
            setCorrectAnswerIndex(filteredQuestions[prevIndex].correctAnswerIndex);
            setPoints(filteredQuestions[prevIndex].points);
            setDuration(filteredQuestions[prevIndex].duration);
            setCurrentQuestionIndex(prevIndex);
            setSelectedQuestionNumber(prevIndex)
            //  console.log("prev", prevIndex)
        }
    };

    const handleNext = () => {
        setQuestions(prevQuestions => {
            const newQuestions = [...prevQuestions];
            newQuestions[currentQuestionIndex] = { question, options, correctAnswerIndex, points, duration };
            return newQuestions;
        });

        if (currentQuestionIndex < questions.length - 1) {
            const nextIndex = currentQuestionIndex + 1;
            setQuestion(filteredQuestions[nextIndex].question);
            setOptions([...filteredQuestions[nextIndex].options]);
            setCorrectAnswerIndex(filteredQuestions[nextIndex].correctAnswerIndex);
            setPoints(filteredQuestions[nextIndex].points);
            setDuration(filteredQuestions[nextIndex].duration);
            setCurrentQuestionIndex(nextIndex);
            setSelectedQuestionNumber(nextIndex)
            //  console.log("next", nextIndex, currentQuestionIndex)
        } else {
            setQuestions(prevQuestions => [...prevQuestions, { question: '', options: ['', '', '', ''], correctAnswerIndex: 0, points: '', duration: '' }]);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setSelectedQuestionNumber(currentQuestionIndex + 1)
            //  console.log("next else", currentQuestionIndex + 1, currentQuestionIndex)
            setQuestion('');
            setOptions(['', '', '', '']);
            setCorrectAnswerIndex(0);
            setPoints('');
            setDuration('');
        }
    };

    const handleEdit = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        let newQuestions = [...questions];
        newQuestions[currentQuestionIndex] = { question, options, correctAnswerIndex, points, duration };
        setQuestions(newQuestions);
        let newQuestions1 = newQuestions.filter(q =>
            q.question?.trim() !== '' &&
            q.options.length > 0 &&
            q.correctAnswerIndex !== undefined
        );
        setQuestions(newQuestions1);
        // const updatedQuestions = newQuestions1.map(question => ({
        //     ...question, // Spread existing question properties
        //     points: 2, // Set points to 2
        //     duration: 5 // Set duration to 5
        // }));

        // let newQuestions = [...questions];
        // newQuestions[currentQuestionIndex] = { question, options, correctAnswerIndex };
        // setQuestions(prevQuestions => {
        //     let updatedQuestions = [...prevQuestions];
        //     updatedQuestions[currentQuestionIndex] = { question, options, correctAnswerIndex };
        //     return updatedQuestions;
        // });

        // let newQuestions1 = newQuestions.filter(q =>
        //     q.question.trim() !== '' &&
        //     q.options.length > 0 &&
        //     q.correctAnswerIndex !== undefined
        // );

        // setQuestions(newQuestions1);
        const payload = {
            "quizId": props.match.params.id,
            "topicId": parseInt(topicId),
            "questions": JSON.stringify(newQuestions1),
            "status": 1,
        };
        console.log("payload", payload)
        axios.post(config.userUrl + `user/editQuiz`, payload, { headers: headers })
            .then(function (response) {
                //  console.log("success", response);
                setRedirect(true)
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const goToFirstQuestion = () => {
        setCurrentQuestionIndex(0);
        const firstQuestionData = questions[0];
        setQuestion(firstQuestionData.question);
        setOptions([...firstQuestionData.options]);
        setCorrectAnswerIndex(firstQuestionData.correctAnswerIndex);
        setPoints(firstQuestionData.points);
        setDuration(firstQuestionData.duration);
        setSelectedQuestionNumber(1)
    };

    const goToLastQuestion = () => {
        setCurrentQuestionIndex(questions.length - 1);
        const lastQuestionData = questions[questions.length - 1];
        setQuestion(lastQuestionData.question);
        setOptions([...lastQuestionData.options]);
        setCorrectAnswerIndex(lastQuestionData.correctAnswerIndex);
        setPoints(lastQuestionData.points);
        setDuration(lastQuestionData.duration);
        setSelectedQuestionNumber(questions.length)
    };
    const handleQuestionChange = (index, value) => {
        setQuestions(prevQuestions => {
            const newQuestions = [...prevQuestions];
            newQuestions[index].question = value;
            return newQuestions;
        });
    };

    const handleOptionChange = (questionIndex, optionIndex, value) => {
        setQuestions(prevQuestions => {
            const newQuestions = [...prevQuestions];
            newQuestions[questionIndex].options[optionIndex] = value;
            return newQuestions;
        });
    };
    const handlePointsChange = (index, value) => {
        setQuestions(prevQuestions => {
            const newQuestions = [...prevQuestions];
            newQuestions[index].points = value;
            return newQuestions;
        });
    };
    const handleDurationChange = (index, value) => {
        setQuestions(prevQuestions => {
            const newQuestions = [...prevQuestions];
            newQuestions[index].duration = value;
            return newQuestions;
        });
    };
    // const handleCorrectAnswerChange = (index, value) => {
    //     setQuestions(prevQuestions => {
    //         const newQuestions = [...prevQuestions];
    //         newQuestions[index].correctAnswerIndex = value;
    //         console.log("newQuestions", value, newQuestions[index].correctAnswerIndex, newQuestions)
    //         return newQuestions;
    //     });
    // };
    const handleCorrectAnswerChange = (questionIndex, optionIndex) => {
        setCorrectAnswerIndex(optionIndex);

        // Update the correctAnswerIndex for the specific question in filteredQuestions
        setFilteredQuestions(prevFilteredQuestions => {
            const updatedQuestions = [...prevFilteredQuestions];
            updatedQuestions[questionIndex].correctAnswerIndex = optionIndex;
            //  console.log("newQuestions", optionIndex,  updatedQuestions[questionIndex].correctAnswerIndex, updatedQuestions)
            return updatedQuestions;
        });
    };
    const currentQuestionNumber = currentQuestionIndex + 1;
    if (redirect) {
        return <Redirect to="/quiz" />
    }

    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                <h5>Edit Quiz</h5>
                <div className="poll-date">Topic</div>
                <div className="form-group" style={{ width: "72%" }}>
                    <input type="text"
                        className="web-font form-control form-bg"
                        aria-describedby="text"
                        value={topicName}
                        name="topicName"
                        placeholder="Topic Name"
                    />
                </div>
                <div className="mb-3" style={{ display: 'flex', justifyContent: 'space-between', width: "72%" }}>


                    <div>  {searchQuery.length == 0 ? <>Question No: {currentQuestionNumber}</> : <></>}</div>

                    <div style={{ width: "25%" }}>
                        <input
                            className="web-font form-control form-bg"
                            type="text"
                            value={searchQuery}
                            placeholder={`Search Question`}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyPress={handleSearchKeyPress}
                        />
                    </div>
                </div>
                {/* {searchQuery.length == 0 ? <></> : <div className='quiz-box-z'>{filteredQuestions.map((q, index) => (
                    <div key={index} onClick={() => handleQuestionSelect(q, questions.indexOf(q))}>
                 {`Ques ${questions.indexOf(q)+ 1}: ${q.question}`}
                    </div>
                ))}
                </div>
                } */}
                {searchQuery.length == 0 ? <></> : <>{filteredQuestions.map((q, index) => (
                    <div key={index}>
                        <div>Question No: {questions.indexOf(q) + 1}</div>
                        <Card style={{ width: "72%" }}>
                            <div className="ml-3 mr-3 mt-3">
                                <input
                                    type="text"
                                    className="web-font form-control form-bg"
                                    aria-describedby="text"
                                    value={q.question}
                                    name="question"
                                    placeholder="Write Your Question"
                                    onChange={(e) => handleQuestionChange(questions.indexOf(q), e.target.value)}
                                />
                                <div style={{ width: "98%" }}>
                                    <div>
                                        {q.options.map((option, index) => (
                                            <div key={index} className="form-group mt-3 display">
                                                <input
                                                    type="text"
                                                    className="web-font form-control"
                                                    placeholder={`Write Your Option ${index + 1}`}
                                                    value={option}
                                                    // onChange={(e) => {
                                                    //     const newOptions = [...options];
                                                    //     newOptions[index] = e.target.value;
                                                    //     setOptions(newOptions);
                                                    // }}
                                                    onChange={(e) => handleOptionChange(questions.indexOf(q), index, e.target.value)}
                                                />
                                                <div className='ml-1 display'>
                                                    <input
                                                        type="radio"
                                                        checked={q.correctAnswerIndex === index}
                                                        // onChange={() => setCorrectAnswerIndex(index)}
                                                        // onChange={() => handleCorrectAnswerChange(index, index)}
                                                        onChange={() => {
                                                            const updatedQuestions = [...questions];
                                                            const originalIndex = questions.indexOf(q);
                                                            updatedQuestions[originalIndex].correctAnswerIndex = index;
                                                            //  console.log("data",index,originalIndex,updatedQuestions[originalIndex].correctAnswerIndex)
                                                            setQuestions(updatedQuestions);
                                                        }}
                                                    />
                                                    <span className='mt-1 ml-1'>  Correct&nbsp;Answer</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Row style={{ width: "100%" }} className='no-gutters'>
                                    <Col>
                                        <div className='mr-1'>
                                        <div className="poll-date">Points</div>
                                            <input
                                                type="number"
                                                className="web-font form-control form-bg"
                                                aria-describedby="number"
                                                value={points}
                                                name="points"
                                                placeholder="Points"
                                                onChange={(e) => handlePointsChange(questions.indexOf(q), e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='ml-1'>
                                        <div className="poll-date">Duration in seconds</div>
                                            <input
                                                type="text"
                                                className="web-font form-control form-bg"
                                                aria-describedby="text"
                                                value={duration}
                                                name="duration"
                                                placeholder="Duration in seconds"
                                                onChange={(e) => handleDurationChange(questions.indexOf(q), e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                            </div>
                        </Card>
                    </div>
                ))}
                </>
                }
                <div>{filteredQuestions.length > 0 && searchQuery.length > 0 ? <></> :
                    <Card style={{ width: "72%" }}>
                        <div className="ml-3 mr-3 mt-3">
                            <input
                                type="text"
                                className="web-font form-control form-bg"
                                aria-describedby="text"
                                value={question}
                                name="question"
                                placeholder="Write Your Question"
                                onChange={(e) => setQuestion(e.target.value)}
                            />
                            <div style={{ width: "98%" }}>
                                <div>
                                    {options.map((option, index) => (
                                        <div key={index} className="form-group mt-3 display">
                                            <input
                                                type="text"
                                                className="web-font form-control"
                                                placeholder={`Write Your Option ${index + 1}`}
                                                value={option}
                                                onChange={(e) => {
                                                    const newOptions = [...options];
                                                    newOptions[index] = e.target.value;
                                                    setOptions(newOptions);
                                                }}
                                            />
                                            <div className='ml-1 display'>
                                                <input
                                                    type="radio"
                                                    checked={correctAnswerIndex === index}
                                                    onChange={() => setCorrectAnswerIndex(index)}
                                                />
                                                <span className='mt-1 ml-1'>  Correct&nbsp;Answer</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Row style={{ width: "100%" }} className='no-gutters'>
                                <Col>
                                    <div className='mr-1'>
                                    <div className="poll-date">Points</div>
                                        <input
                                            type="number"
                                            className="web-font form-control form-bg"
                                            aria-describedby="number"
                                            value={points}
                                            name="points"
                                            placeholder="Points"
                                            onChange={(e) => setPoints(e.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className='ml-1'>
                                    <div className="poll-date">Duration in seconds</div>
                                        <input
                                            type="text"
                                            className="web-font form-control form-bg"
                                            aria-describedby="text"
                                            value={duration}
                                            name="duration"
                                            placeholder="Duration in seconds"
                                            onChange={(e) => setDuration(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <br />
                    </Card>
                }
                    {/* <div onClick={goToFirstQuestion}>
                        first
                    </div>
                    <div onClick={goToLastQuestion}>
                        last
                    </div> */}
                    {searchQuery.length == 0 ?
                        <div style={{ display: 'flex', marginTop: "7px", justifyContent: 'center', gap: "15px", width: "72%" }}>
                            <div onClick={handlePrevious} disabled={currentQuestionIndex === 0} className=' bg-fa'>
                                <center>  <i className="fa fa-lg mt-2" style={currentQuestionIndex === 0 ? { color: "lightgrey" } : { color: "black", cursor: "pointer" }}>&#xf060;</i></center>
                            </div>
                            <div onClick={goToFirstQuestion} className='bg-fa'>
                                <center>    <i class="fa fa-step-backward mt-2 fa-lg" aria-hidden="true"></i></center>
                            </div>
                            <div onClick={goToLastQuestion} className='bg-fa'>
                                <center>    <i class="fa fa-step-forward mt-2 fa-lg" aria-hidden="true"></i></center>
                            </div>
                            <div onClick={handleNext} className=' bg-fa'>
                                <center>  <i className="fa fa-lg mt-2" style={{ color: "black", cursor: "pointer" }}>&#xf061;</i></center>
                            </div>
                        </div> : <></>}

                    <div className="float_right_poll mt-4">
                        <Button variant="default" className="sendreview-btn ml-4" onClick={handleEdit}>Edit </Button>
                        <Link to="/quiz">
                            <Button variant="default" className="cancelreview-btn ml-4">Back </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditQuiz;