import React, { useState, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown, Tab, Tabs } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import Popup from "./Popup";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function UsersReach() {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [userDetails, setUserDetails] = useState([])
    const [lastOneMonthUsersReach, setLastOneMonthUsersReach] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [incompleteUsers, setInCompleteUsers] = useState([])
    const [usersReach, setUsersReach] = useState([])
    const [expressionOfInterest, setExpressionOfInterest] = useState([])
    const [referrals, setReferrals] = useState([])
    const [purchases, setPurchases] = useState([])
    const [posts, setPosts] = useState([])
    const [popup, setPopup] = useState(false)
    const [userId, setUserId] = useState()
    const [key, setKey] = useState('incompleteUsers')

    useEffect(() => {
        getInCompleteUsers();
        getUserDetails();
        getUserDetailsReach();
        getLastOneMonthUsers();
    }, [])
    // const getLastActivityData = (lastActivity) => {
    //     let currentDate = new Date()
    //     let lastActivityDate = new Date(lastActivity)
    //     let newValue = Math.round(((currentDate - lastActivityDate) / 3600000) / 24)
    //     console.log("rr", currentDate, lastActivityDate, currentDate - lastActivityDate, newValue, currentDate - lastActivityDate >= 60)
    //     return newValue >= 60
    // }
    const getUserDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getUserDetails', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    setUserDetails(response.data.user)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getUserDetailsReach = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getUserDetailsReach', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    setUsersReach(response.data.user)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getInCompleteUsers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getInactiveUsers', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    setInCompleteUsers(response.data.user)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getLastOneMonthUsers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getLastOneMonthUsers', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    setLastOneMonthUsersReach(response.data.user)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getUserTimeline = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + `user/getUserTimeline/${id}`, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    setUsers(response.data.user)
                    setExpressionOfInterest(response.data.expressionOfInterest)
                    setPurchases(response.data.purchases)
                    setReferrals(response.data.referrals)
                    setPosts(response.data.posts)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const sendEmailRemainder = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "email": data.email,
            "name": data.name,
            "createdAt": data.createdAt
        }
        axios.post(config.userUrl + `user/sendEmailRemainder`, payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    document.getElementById('publish').style.display = 'flex'
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const sendParticipationEmail = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "email": data.email,
            "name": data.name,
            "createdAt": data.createdAt
        }
        axios.post(config.userUrl + `user/sendParticipationEmail`, payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    document.getElementById('sentEmail').style.display = 'flex'
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const showPopup = (id) => {
        setPopup(!popup)
        setUserId(id)
    }
    const togglePopup = () => {
        setPopup(false)
    }

    const showNone = () => {
        document.getElementById('publish').style.display = 'none'
        window.location.reload(true)
    }
    const showSuccessEmail = () => {
        document.getElementById('sentEmail').style.display = 'flex'
    }
    const hide = () => {
        document.getElementById('sentEmail').style.display = 'none'
        getUserDetails();
    }

    if (isLoading) {
        return <div className="App"></div>;
    }
    // const incompleteCreatedAt = incompleteUsers.map((i, idx) => {
    //     var a = new Date(i.createdAt)
    //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
    //     return updated;
    // })
    // const userDetailsCreatedAt = userDetails.map((i, idx) => {
    //     var a = new Date(i.createdAt)
    //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
    //     return updated;
    // })
    // const userDetailsEmailSentDate = userDetails.map((i, idx) => {
    //     var a = new Date(i.emailParticipationSentOn)
    //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
    //     if (i.emailParticipationSentOn == null)
    //         return "-";
    //     else {
    //         return updated;
    //     }
    // })
    // const userDetailsUpdatedAt = userDetails.map((i, idx) => {
    //     var a = new Date(i.updatedAt)
    //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
    //     return updated;
    // })
    // const usersReachCreatedAt = usersReach.map((i, idx) => {
    //     var a = new Date(i.createdAt)
    //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
    //     return updated;
    // })
    // const usersReachUpdatedAt = usersReach.map((i, idx) => {
    //     var a = new Date(i.updatedAt)
    //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
    //     return updated;
    // })
    return (
        <div>
            {popup && <Popup id={userId} closePopup={togglePopup} />}
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                <div style={{ display: "flex" }}>
                    <NavLink to="/history/usersreach" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Users Reach</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/articles" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Articles</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/polls" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Polls</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/sceemlorereach" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Sceemlore </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/advertisements" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Advertisements</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/referrals" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Referrals </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/opportunities" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Opportunities </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/q&a" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Q&A </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/interests" activeClassName="history-active" className="Link" >
                        <div className="history-Text">EOI</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/blocked" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Blocked Articles</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <NavLink to="/history/claimhistory" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Claims</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                    <NavLink to="/history/reviewers" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Reviewers</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/gamesreach" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Games</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/smereach" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Sme</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                </div >
                {/* <div className="mt-3 active-add-h-text">User Reach (Last 90 days)</div> */}
                <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 mt-4 inCompleteUser" style={{ color: "#ff942c" }}
                >
                    <Tab eventKey="incompleteUsers" title="Incomplete">
                        {incompleteUsers.length === 0 ? <center><h4>No Incomplete Users.</h4></center> :
                            <div class="mt-3 tableFixHead" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr>
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Name</th>
                                            <th scope="col" className="th-heading" style={{ width: "20%" }}>Email</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Role</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Joined On</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Email Sent</th>
                                            <th scope="col" className="th-heading" style={{ width: "10%" }}>Count</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>{incompleteUsers.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text">{i + 1}</td>
                                            <td className="td-text">{data.name}</td>
                                            <td className="td-text">{data.email}</td>
                                            <td className="td-text">{data.userRoleId === 1 ? "Admin" : (data.userRoleId === 2) ? "General User" : (data.userRoleId === 3) ? "Author" : "Reviewer"}</td>
                                            {/* <td className="td-text">{incompleteCreatedAt[i]}</td> */}
                                            <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                            <td className="td-text">{data.remainderCount === 0 ? <div>-</div> : moment(data.updatedAt).format("DD-MM-YYYY")}</td>
                                            <td className="td-text">{data.remainderCount}</td>
                                            <td>{data.remainderCount === 5 ? <div></div> : <i onClick={() => sendEmailRemainder(data)} class="fa fa-bell cursor" aria-hidden="true"></i>}</td>

                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <div id="publish" className="modal_new">
                                    <form className="modal-content-log">
                                        <div className="md-container">
                                            <p className="mt-2">Email sent Successfully!</p>
                                            <center>
                                                <Button variant="default" onClick={showNone} className="deletebutton">Ok</Button></center>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </Tab>
                    <Tab eventKey="inactiveUsers" title="Inactive">
                        {userDetails.length === 0 ? <center><h4>No Users.</h4></center> :
                            <div class="mt-3 tableFixHead" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr  >
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Name</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Email</th>
                                            <th scope="col" className="th-heading" style={{ width: "13%" }}>Role</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Joined On</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Last Activity</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Email sent</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>{userDetails.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text">{i + 1}</td>
                                            <td className="td-text">{data.name}</td>
                                            <td className="td-text">{data.email}</td>
                                            <td className="td-text">{data.userRoleId === 1 ? "Admin" : (data.userRoleId === 2) ? "General User" : (data.userRoleId === 3) ? "Author" : "Reviewer"}</td>
                                            {/* <td className="td-text">{data.status === ("peopleFollowed" || "topicsAdded" || "verified" || "descriptionAdded" || "approvedForAuthor" || "approvedForReviewer" || "reviewerStatusPending" || "authorStatusPending") && data.isValid === true ? "Active" : (data.status === "unverified") ? "Inactive" : "Active"}</td> */}
                                            {/* <td className="td-text">{userDetailsCreatedAt[i]}</td> */}
                                            <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                            <td className="td-text">{moment(data.updatedAt).format("DD-MM-YYYY")}</td>
                                            <td className="td-text">{data.emailParticipationSentOn !== null ? moment(data.emailParticipationSentOn).format("DD-MM-YYYY") : "-"}</td>
                                            {/* <td className="td-text">{userDetailsUpdatedAt[i]}</td> */}
                                            {/* <td className="td-text">{userDetailsEmailSentDate[i]}</td> */}
                                            <td ><i class="fa fa-eye cursor" aria-hidden="true" onClick={() => showPopup(data.userId)}></i></td>
                                            <td className="cursor"><i onClick={() => sendParticipationEmail(data)} class="fa fa-repeat" aria-hidden="true"></i>
                                                {/* {data.status === "unverified" ? <i onClick={() => sendParticipationEmail(data.email, data.name)} class="fa fa-repeat" aria-hidden="true"></i>
                                            : (data.lastActivity != null && getLastActivityData(data.lastActivity)) ? <i onClick={() => sendEmailRemainder(data.email, data.name)} class="fa fa-repeat" aria-hidden="true"></i> : ""}
                                       */}
                                            </td>

                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <div id="sentEmail" className="modal_new">
                                    <form className="modal-content-log">
                                        <div className="md-container">
                                            <p className="mt-2">Email sent Successfully!</p>
                                            <center>
                                                <Button variant="default" onClick={hide} className="deletebutton">Ok</Button></center>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </Tab>
                    <Tab eventKey="users" title="Active">
                        {usersReach.length === 0 ? <center><h4>No Users.</h4></center> :
                            <div class="mt-3 tableFixHead" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr  >
                                            <th>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "25%" }}>Name</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Email</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Role</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Status</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Joined On</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Last Activity</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>{usersReach.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            {/* {console.log("dates",moment(data.createdAt).format("DD-MM-YYYY"),moment(data.createdAt).format("DD-MM-YYYY").setMonth(moment(data.createdAt).format("DD-MM-YYYY").getMonth() - 3))} */}
                                            <td className="td-text">{i + 1}</td>
                                            <td className="td-text">{data.name}</td>
                                            <td className="td-text">{data.email}</td>
                                            <td className="td-text">{data.userRoleId === 1 ? "Admin" : (data.userRoleId === 2) ? "General User" : (data.userRoleId === 3) ? "Author" : "Reviewer"}</td>
                                            <td className="td-text">{data.status === ("peopleFollowed" || "profileAdded" || "topicsAdded" || "verified" || "descriptionAdded" || "approvedForAuthor" || "approvedForReviewer" || "reviewerStatusPending" || "authorStatusPending") && data.isValid === true ? "Active" : (data.status === "unverified") ? "Inactive" : "Active"}</td>
                                            {/* <td className="td-text">{usersReachCreatedAt[i]}</td>
                                            <td className="td-text">{usersReachUpdatedAt[i]}</td> */}
                                            <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                            <td className="td-text">{moment(data.updatedAt).format("DD-MM-YYYY")}</td>

                                            <td><i class="fa fa-eye cursor" aria-hidden="true" onClick={() => showPopup(data.uuid)}></i></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>}
                    </Tab>
                    <Tab eventKey="lastMonthUsers" title="New">
                        {lastOneMonthUsersReach.length === 0 ? <center><h4>No New Users.</h4></center> :
                            <div class="mt-3 tableFixHead" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr  >
                                            <th>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "25%" }}>Name</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Email</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Role</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Status</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Joined On</th>

                                        </tr>
                                    </thead>

                                    <tbody>{lastOneMonthUsersReach.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            {/* {console.log("dates",moment(data.createdAt).format("DD-MM-YYYY"),moment(data.createdAt).format("DD-MM-YYYY").setMonth(moment(data.createdAt).format("DD-MM-YYYY").getMonth() - 3))} */}
                                            <td className="td-text">{i + 1}</td>
                                            <td className="td-text">{data.name}</td>
                                            <td className="td-text">{data.email}</td>
                                            <td className="td-text">{data.userRoleId === 1 ? "Admin" : (data.userRoleId === 2) ? "General User" : (data.userRoleId === 3) ? "Author" : "Reviewer"}</td>
                                            <td className="td-text">{data.status === ("peopleFollowed" || "profileAdded" || "topicsAdded" || "verified" || "descriptionAdded" || "approvedForAuthor" || "approvedForReviewer" || "reviewerStatusPending" || "authorStatusPending") && data.isValid === true ? "Active" : (data.status === "unverified") ? "Inactive" : "Active"}</td>
                                            {/* <td className="td-text">{usersReachCreatedAt[i]}</td>
                                            <td className="td-text">{usersReachUpdatedAt[i]}</td> */}
                                            <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>


                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>}
                    </Tab>
                </Tabs>

            </div>
        </div >
    );
};