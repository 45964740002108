import React from 'react';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

class PopupCard extends React.Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this.state = {
            edit: true,
            cardTitle: "",
            cardDescription: "",
            image: "",
            count: 1,
            cardVal: "",
            redirectCard: false
        }
    }
    componentDidMount() {
       // console.log("props", this.props.cardLength)
    }
    addEachCard = (val) => {
        this.setState((prevState, props) => ({
            count: prevState.count + 1
        }));
      ///  console.log("prevState", this.state.count, this.state.cardVal)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "cardManagementId": this.props.cardManagementId,
            "cardTitle": this.state.cardTitle,
            "cardNumber": this.props.cardLength + 1,
            "cardDescription": this.state.cardDescription,
            "cardPicture": this.state.image
        }
      //  console.log("payload", payload, this.state.count)
        var self = this;
        axios.post(config.userUrl + 'user/addCardDetail', payload, { headers: headers })
            .then(function (response) {
           //     console.log(response);
                if (response.data.status == true) {
                //    console.log("success", response.data.message, payload);
                    self.setState({ cardTitle: "", cardDescription: "", cardPicture: "", image: "", cardVal: self.state.count, redirectCard: true })
                    self.props.getCardById()
                    self.props.redirectCardTrue()
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
       // console.log("count", this.state.count, this.state.cardVal)
    };
     uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
      //  console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
              //  console.log("res", res)
                this.setState({ image: res })
            }
        }
    }
    render() {
        const { edit, cardTitle, cardDescription, image, count, imgRef, cardVal } = this.state
        return (
            <Modal
                size="sm"
                show={edit}
                onHide={this.props.closePopup}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <h5 style={{ color: "#FF942C", fontFamily: "poppins" }}>&nbsp;Card # {this.props.cardLength + 1}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Form style={{ marginTop: "-0.8rem" }}>
                        <div className="form-group">
                            <input type="text"
                                className="mt-2 web-font form-control text-box-radius"
                                aria-describedby="text"
                                name="cardTitle"
                                value={cardTitle}
                                placeholder="Card Title"
                                onChange={e => this.setState({ cardTitle: e.target.value })}
                            />
                        </div>
                        <div style={{ height: "120px", textAlign: "center", objectFit: "contain" }}>
                            <label htmlFor="upload-button">
                                {image != "" ? (
                                    <img src={image} style={{ width: "100px", height: "100px" }} />
                                ) : (
                                    <>
                                        <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                        <div className="up-load">Upload Image</div>
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                id="upload-button"
                                name="image"
                                ref={imgRef}
                                onChange={(e) => this.handleImage(e)}
                            />
                        </div>
                        <div className="form-group">
                            <textarea name="cardDescription" value={cardDescription}
                                placeholder="Card Description"
                                className="web-font richTextField_card description-radius"
                                style={{ overflowY: "none", resize: "none" }}
                                onChange={e => this.setState({ cardDescription: e.target.value })}
                            />
                        </div>

                        {(cardTitle != "") && (cardDescription != "") && (image != "") ?
                            <div>
                                <div><Button variant="default" onClick={() => this.addEachCard(count)} className="sendreview-btn mt-1 ml-2">Save Card</Button>
                                    <Button variant="default" onClick={this.props.closePopup} className="close-btn-popup mt-1">Close</Button></div>
                            </div>
                            : <div><Button variant="default" disabled={true} className="sendreview-btn mt-1 ml-2">Save Card</Button>
                                <Button variant="default" onClick={this.props.closePopup} className="close-btn-popup mt-1">Close</Button></div>}
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}
export default PopupCard;





// import React, { useRef, useState, useEffect } from 'react'
// import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import { config } from '../Config/Config';
// import SimpleCrypto from "simple-crypto-js";
// var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
// var simpleCrypto = new SimpleCrypto(key)

// export default function PopupCard(props) {
//     const imgRef = useRef()
//     const [edit, setEdit] = useState(true)
//     const [cardTitle, setCardTitle] = useState("")
//     const [cardDescription, setCardDescription] = useState("")
//     const [image, setImage] = useState("")
//     const [count, setCount] = useState(1)
//     const [cardVal, setCardVal] = useState("")
//     const [redirectCard, setRedirectCard] = useState(false)

//     useEffect(() => {
//         console.log("props", props)

//     }, [])
//     const addEachCard = () => {
//         setCount(prevState => prevState + 1);
//         console.log("prevState", count, cardVal)
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
//             "applicationType": "web"
//         }
//         var payload = {
//             "cardManagementId": props.cardManagementId,
//             "cardTitle": cardTitle,
//             "cardDescription": cardDescription,
//             "cardPicture": image
//         }
//         console.log("payload", payload, count)
//         axios.post(config.userUrl + 'user/addCardDetail', payload, { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status == true) {
//                     console.log("success", response.data.message, payload);
//                     setCardTitle("")
//                     setCardDescription("")
//                     setImage("")
//                     setCardVal(count)
//                     setRedirectCard(true)
//                     props.getCardById()
//                     props.redirectCardTrue()
//                 } else {
//                     console.log("Error", payload);
//                 }
//             })
//             .catch(function (error) {
//                 console.log("error", error, payload);
//             });
//         console.log("count", count, cardVal)
//     };
//     const uploadImage = async (image) => {
//         let data = new FormData()
//         data.append("file", image)
//         data.append("upload_preset", "dpx0yz1k8")
//         data.append("cloud_name", "dpx0yz1k8")
//         const resp = await fetch(`https://api.cloudinary.com/v1_1/dpx0yz1k8/image/upload`, {
//             method: "post",
//             body: data
//         })
//         let res = await resp.json();
//         return res.secure_url
//     }

//     const handleImage = async (e) => {
//         console.log("fgh", e.target.files)
//         if (e.target.files && e.target.files[0]) {
//             const res = await uploadImage(e.target.files[0]);
//             if (res) {
//                 console.log("res", res)
//                 setImage(res)
//             }
//         }
//     }
//     return (
//         <Modal
//             size="sm"
//             show={edit}
//             onHide={this.props.closePopup}
//             backdrop="static"
//             keyboard={false}
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//         >
//             <Modal.Header>
//                 <Modal.Title >
//                     <h5 style={{ marginTop: "5px", fontFamily: "poppins" }}>&nbsp;Card # {cardVal == "" ? 1 : cardVal}</h5>
//                 </Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <Form >
//                     <div className="form-group">
//                         <input type="text"
//                             className="mt-2 web-font form-control text-box-radius"
//                             aria-describedby="text"
//                             name="cardTitle"
//                             value={cardTitle}
//                             placeholder="Card Title"
//                             onChange={e => setCardTitle(e.target.value)}
//                         />
//                     </div>
//                     <div style={{ height: "120px", textAlign: "center", objectFit: "contain" }}>
//                         <label htmlFor="upload-button">
//                             {image != "" ? (
//                                 <img src={image} style={{ width: "100px", height: "100px" }} />
//                             ) : (
//                                 <>
//                                     <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
//                                     <div className="up-load">Upload Image</div>
//                                 </>
//                             )}
//                         </label>
//                         <input type="file" id="upload-button" className='hidden' ref={imgRef}
//                             onChange={(e) => handleImage(e)} />
//                     </div>
//                     <div className="form-group">
//                         <textarea name="cardDescription" value={cardDescription}
//                             placeholder="Card Description"
//                             className="web-font richTextField_card description-radius"
//                             style={{ overflowY: "none", resize: "none" }}
//                             onChange={e => setCardDescription(e.target.value)}
//                         />
//                     </div>

//                     {(cardTitle != "") && (cardDescription != "") ?
//                         <div>
//                             <div><Button variant="default" onClick={() => addEachCard(count)} className="sendreview-btn mt-1 ml-2">Save Card</Button>
//                                 <Button variant="default" onClick={props.closePopup} className="cancelreview-btn mt-1">Exit</Button></div>
//                         </div>
//                         : <div><Button variant="default" disabled={true} className="sendreview-btn mt-1 ml-2">Save Card</Button>
//                             <Button variant="default" onClick={props.closePopup} className="cancelreview-btn mt-1">Exit</Button></div>}
//                 </Form>
//             </Modal.Body>
//         </Modal>
//     );
// }




