import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ArticleTopics() {

    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [campaignsCount, setCampaignsCount] = useState("");
    const [likesCount, setLikesCount] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [pollsCount, setPollsCount] = useState("");
    const [publishedPostsCount, setPublishedPostsCount] = useState("");
    const [registeredUserCount, setRegisteredUserCount] = useState("");
    const [totalArticleViews, setTotalArticleViews] = useState("");
    const [totalQuestions, setTotalQuestions] = useState("");
    const [opportunitiesCount, setOpportunitiesCount] = useState("");
    const [cardManagementCount, setCardManagementCount] = useState("");
    const [topicDetails, setTopicDetails] = useState([]);
    useEffect(() => {
        getAllCountDetails();
    }, []);

    const getAllCountDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/aboutPageDetails', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setTopicDetails(response.data.topicDetails);

                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };

    let filterTopics = [];
    for (let i = 0; i < topicDetails.length; i++) {
        if (topicDetails[i].postsCount >= 2) {
            filterTopics.push(topicDetails[i]);
        }
    }
   // console.log("labels", filterTopics)


    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
    }
    const data = {
        // labels: [topicDetails[0].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[1].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[2].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[3].topicName.replace(/ *\([^]*\) */g, "")],
        labels: topicDetails.map((data) => data.topicName.replace(/ *\([^]*\) */g, "")),
        datasets: [
            {
                data: topicDetails.map((data) => data.postsCount),
                backgroundColor: ['rgba(255, 203, 113, 0.2)'],
                borderColor: ['#fa8f01'],
                borderWidth: 1,
            }

        ]
    }
    return (
        <div>
            <Card className="mt-2 bar-graph-card">
                <div style={{width:"150px",height:"150px"}}>
                    <Line data={data} className="mt-3"
                        options={{
                            aspectRatio:1,
                            elements: {
                                line: {
                                    tension: 1
                                },
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false, 
                                        drawBorder: false, 
                                        lineWidth: 0,
                                    },
                                    ticks: {
                                        display: false,
                                        beginAtZero: true
                                    },
                                },
                                y: {
                                    grid: {
                                        display: false,
                                        drawBorder: false,
                                        lineWidth: 0,
                                    },
                                    ticks: {
                                     display:false,
                                     beginAtZero: true
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                                tooltip:{
                                    enabled:false,
                                }
                            }
                        }
                        } />
                    {/* <Bar data={data}
                        options={{
                            scales: {
                                scaleOverride: true,
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            label: {
                                display: true
                            },
                            plugins: {
                                legend: { display: false }
                            },
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem) {
                                        return tooltipItem.yLabel;
                                    }
                                }
                            }

                        }
                        }
                    /> */}
                </div>
            </Card>
        </div >
    )
}

