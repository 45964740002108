import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Redirect, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Poll.css';
import createpoll from '../images/createpoll.svg';
import savedpolls from '../images/savedpolls.svg';
import pollData from './Poll.json'
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Poll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            poll: pollData.poll,
            polls: [],
            isLoading: true
        }
    }
    componentDidMount() {
       // console.log("poll", this.state.poll)
        this.getCreatedPolls();
    }
    getCreatedPolls = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getCreatedPolls', { headers: headers })
            .then(function (response) {
           //     console.log(response);
                if (response.data.status == true) {
                 //   console.log("getCreatedPolls");
                    self.setState({ polls: response.data.polls, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, polls } = this.state;
        const b = polls.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        const endDate1 = polls.map((i, idx) => {
            var end = new Date(i.endDate)
            var update = end.getDate() + "-" + parseInt(end.getMonth() + 1) + "-" + end.getFullYear();
            return update;

        })
        const startDate1 = polls.map((i, idx) => {
            var start = new Date(i.startDate)
            var update = start.getDate() + "-" + parseInt(start.getMonth() + 1) + "-" + start.getFullYear();
            return update;

        })
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >

                    <div> <Link to="/poll/createpoll"><Button variant="default" className="createadv-btn">+ &nbsp;Create</Button></Link>
                        <Link to="/poll/savedpolls"><Button variant="default" className="ml-3 createadv-btn">&nbsp;Saved</Button></Link>
                    </div>
                    <div className="mt-3 active-add-h-text">Active Polls</div>
                    {polls.length === 0 ? <center><h4>You have not created polls</h4></center> :
                        <div class="mt-3 tableFixHead">
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr >
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Poll Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Topic</th>
                                        <th scope="col" className="th-heading" style={{ width: "12%" }}>Created On</th>
                                        <th scope="col" className="th-heading" style={{ width: "12%" }}>Start Date</th>
                                        <th scope="col" className="th-heading" style={{ width: "12%" }}>End Date</th>
                                        <th scope="col" className="th-heading" ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {polls.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text" key={i} >{data.pollQuestion}</td>
                                            <td className="td-text" key={i} >{data.topicName}</td>
                                            <td className="td-text" key={i} >{b[i]}</td>
                                            <td className="td-text" key={i}>{startDate1[i]}</td>
                                            <td className="td-text" key={i}>{endDate1[i]}</td>
                                            <td className="cursor" ><Link to={`/poll/viewpoll/${data.id}`} className="poll-link"> <i class="fa fa-eye" aria-hidden="true"></i></Link> </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                </div>
            </div >
        );
    };
}

