import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Advertisement.css';
import advertisementData from './Advertisement.json';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Rewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            rewards: [],
            redirect: false,
            selPost: 0,
            editData:[],
            isLoading: true
        }
    }
    componentDidMount() {
        this.getIndividualRewardPoints();
    }

    getIndividualRewardPoints = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getIndividualRewardPoints', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        rewards: response.data.getAllRewardsPoint,
                        isLoading: false
                    })
                   // console.log("getIndividualRewardPoints", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    // showLogout = (id) => {
    //     this.setState({ selPost: id.target.getAttribute("data-position") })
    //     document.getElementById('modify').style.display = 'flex'

    // }
    // showLogoutNone = (id) => {
    //     document.getElementById('modify').style.display = 'none'
    // }

    // handleChange = (e) => {
    //     const id = e.target.id;
    //     const elementsIndex = this.state.adv.findIndex(element => element.id == id)
    //     let newArray = [...this.state.adv]
    //     newArray[elementsIndex] = { ...newArray[elementsIndex], value: !newArray[elementsIndex].value }
    //     this.setState({ adv: newArray });
    //     console.log("hi", newArray)
    // }
    editReward = (data) => {
        this.setState({ redirect:true,editData: data })
    }

    render() {
        const { isLoading,editData,redirect, rewards } = this.state
        if (isLoading) {
            return <div className="App"></div>;
        }
        if (redirect) {
           // console.log("data", editData)
            return <Redirect
                to={{
                    pathname: `/rewards/editreward/${editData.id}`,
                    state: { data: editData }
                }}
            />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <div className="mt-3 active-add-h-text">Rewards</div>
                    <div>
                        {rewards.length === 0 ? <center><h4></h4></center> :
                            <div class="mt-3 tableFixHead-saved" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr >
                                            <th scope="col" className="th-heading" style={{ width: "22%" }}>Name</th>
                                            <th scope="col" className="th-heading" style={{ width: "11%" }}>Reward Points</th>
                                            <th scope="col" className="th-heading" style={{ width: "11%" }}>General User</th>
                                            <th scope="col" className="th-heading" style={{ width: "8%" }}>Author</th>
                                            <th scope="col" className="th-heading" style={{ width: "8%" }}>Reviewer</th>
                                            <th scope="col" className="th-heading" style={{ width: "8%" }}>Sme</th>
                                            <th scope="col" className="th-heading" style={{ width: "8%" }}>Anticipator</th>
                                            <th scope="col" className="th-heading" style={{ width: "8%" }}>Integrator</th>
                                            <th scope="col" className="th-heading" style={{ width: "8%" }}>Collaborator</th>
                                            <th scope="col" className="th-heading" style={{ width: "8%" }}>Orchestrator</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {rewards.map((data, i) => (
                                            <tr className={i / 2 === 0 ? "" : "green"}>
                                                <td className="td-text">{data.rewardType}</td>
                                                <td className="td-text">{data.rewardPoints}</td>
                                                <td className="td-text">{data.generalUser}</td>
                                                <td className="td-text">{data.author}</td>
                                                <td className="td-text">{data.reviewer}</td>
                                                <td className="td-text">{data.sme}</td>
                                                <td className="td-text">{data.anticipator}</td>
                                                <td className="td-text">{data.integrator}</td>
                                                <td className="td-text">{data.collaborator}</td>
                                                <td className="td-text">{data.orchestrator}</td>
                                                <td className="cursor"> <i onClick={() => this.editReward(data)} class="fa fa-pencil left" aria-hidden="true"></i></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>

                </div >
            </div >
        );
    };
}

