import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ViewSequence(props) {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [cardPhrase, setCardPhrase] = useState("")
    const [description, setDescription] = useState("")
    const [theme, setTheme] = useState("")
    const [status, setStatus] = useState("")
    const [redirect, setRedirect] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getSequenceCardById();
    }, []);
    const getSequenceCardById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        //console.log("token", token)
        axios.get(config.userUrl + `user/getSequenceCardById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                 //   console.log("success!!", response);
                    setTheme(response.data.sequenceCardById[0].theme)
                    setCardPhrase(response.data.sequenceCardById[0].cardPhrase)
                    setDescription(response.data.sequenceCardById[0].description)
                    setStatus(response.data.sequenceCardById[0].status)
                    setIsLoading(false)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    }
    if (isLoading) {
        return <div className="App"></div>;
    }
    if (redirect) {
        return <Redirect to="/sequence" />
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                <h5 className="font-heading-web">View Sequence Card</h5>
                <Form onSubmit={handleSubmit}>
                    <div className="mt-1 poll-date">Add Theme</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <input type="text" className="web-font form-control" name="theme"
                            placeholder="Theme"
                            value={theme}
                            readOnly
                            style={{ resize: "none" }}
                            onChange={e => setTheme(e.target.value)} />
                    </div>
                    <div className="mt-3 poll-date">Add Card Phrase</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="cardPhrase"
                            placeholder="Card Phrase"
                            value={cardPhrase}
                            rows={3}
                            readOnly
                            style={{ resize: "none" }}
                            onChange={e => setCardPhrase(e.target.value)} />
                    </div>
                    <div className="mt-3 poll-date">Description</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="description"
                            placeholder="Description"
                            value={description}
                            rows={2}
                            readOnly
                            style={{ resize: "none" }}
                            onChange={e => setDescription(e.target.value)} />
                    </div>
                    <br />
                    <Link to="/sequence" className="float_right_poll"><Button variant="default" className="cancelreview-btn mt-2">Back</Button></Link>
                </Form><br /><br />
            </div >
        </div >
    );
};





