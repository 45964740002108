import React, { Component } from "react";
import { Button, Form, Col, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import logo from '../images/logo.svg';
import nebutech from '../images/nebutech.svg';
import verifyemail from '../images/verifyemail.png';
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';

export default class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.location.state.email,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            registerFlow: true,
            newPassword: false,
            redirect: false,
            authKey: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
     //   console.log("hello")
    }
    handleChange(value1, event) {
        this.setState({ [value1]: event.target.value });
    }

    handleSubmit(event) {
      //  console.log(this.state);
        event.preventDefault()
        this.verifyForgotPasswordEmail();
    }
    verifyForgotPasswordEmail = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var self = this;
        var payload = {
            "email": this.state.email,
            "otp": this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        }
        axios.post(config.apiUrl + 'auth/forgotPassword/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                   // console.log("Forgot Password Email verified successfully!!")
                   // console.log("payload", payload, response)
                    self.setState({
                        newPassword: true,
                        authKey: response.data.authkey
                    })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
               // console.log("payload", payload)
                console.log(error);
            });
    }

    resendOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "email": this.state.email,
            "registerFlow": this.state.registerFlow
        }
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = "Otp sent successfully!!";
                if (response.data.status === true) {
                    //console.log("Otp sent successfully!!")
                   // console.log("payload", payload)
                    document.getElementById('otp').innerText = msg
                } else {
                  //  console.log(response.data.message)
                    console.log("err", payload)
                }
            })
            .catch(function (error) {
               // console.log("payload", payload)
                console.log(error);
            });
    }
    inputfocus = (elmnt) => {
        if (elmnt.key === "Backspace" || elmnt.key === "Delete") {
            const next = elmnt.target.tabIndex - 2;
          //  console.log("ext", elmnt.target.tabIndex, next)
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
           // console.log("next", elmnt.target.tabIndex, next);
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }

    render() {
        const { newPassword, redirect } = this.state
        if (newPassword) {
            return <Redirect
                to={{
                    pathname: "/newpassword",
                    state: { email: this.state.email, authKey: this.state.authKey }
                }} />
        }
        return (
            <div class="log">
                <center>  <img src={logo} className="logo-width" /></center>
                {/* <center> <img src={verifyemail} className="forgotImg" /></center> */}
                <div className="login mt-2">Verify your email</div>
                <div className="login-sm">Please check your email to take 6 digit code for continue.</div><br />

                <Form className="forgot-email" onSubmit={this.handleSubmit}>
                    <center>
                        <div style={{ width: "80%" }} className="form-group verify">
                            <input
                                name="otp1"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp1}
                                onKeyPress={this.keyPressed}
                                onChange={e => this.handleChange("otp1", e)}
                                tabIndex="1" maxLength={1} onKeyUp={e => this.inputfocus(e)}

                            />
                            <input
                                name="otp2"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp2}
                                onChange={e => this.handleChange("otp2", e)}
                                tabIndex="2" maxLength={1} onKeyUp={e => this.inputfocus(e)}

                            />
                            <input
                                name="otp3"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp3}
                                onChange={e => this.handleChange("otp3", e)}
                                tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                            />
                            <input
                                name="otp4"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp4}
                                onChange={e => this.handleChange("otp4", e)}
                                tabIndex="4" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                            />

                            <input
                                name="otp5"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp5}
                                onChange={e => this.handleChange("otp5", e)}
                                tabIndex="5" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                            />
                            <input
                                name="otp6"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp6}
                                onChange={e => this.handleChange("otp6", e)}
                                tabIndex="6" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                            />
                        </div><center><div id="status" className="errormsg"></div></center>
                        <div className="mb-2"></div>
                        <Button variant="default" type="submit" className="btn-block reset-btn">Verify</Button><br />
                        <p >Did not get the OTP?<span className="try" onClick={this.resendOtp}> Resend OTP</span></p>
                        <div id="otp" className="errormsg"></div>
                    </center>
                </Form>

                <div className="nebutech" ><img src={nebutech} /></div>
            </div>
        );
    }
}