import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Poll.css';
import pollData from './Poll.json'
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class CreatePoll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            allTopics: [],
            topic: "",
            startDate: "",
            endDate: "",
            options: ["", "", "", ""],
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            pollQuestion: "",
            savePoll: false,
            dateErr: "",
            // pollQuestion: [{
            //     question: "",
            //     options: ["", "", "", ""]
            // }],
            topicError: '',
            questionError: '',
            optionError1: '',
            optionError2: '',
            optionError3: '',
            optionError4: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.onChange = this.onChange.bind(this);
        this.onChangeTopic = this.onChangeTopic.bind(this);
        // this.handleBlur = this.handleBlur.bind(this);
        // this.validateQuestion = this.validateQuestion.bind(this);
    }
    componentDidMount() {
        //console.log("poll")
        this.getAllTopics();
    }
    onChangeTopic(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    changeStartDate(date) {
        if (date.toDateString() === new Date().toDateString()) {
            this.setState({ dateErr: "You cannot select today" })
        } else {
          //  console.log("start", date.toDateString(), "new", new Date().toDateString())
            this.setState({
                startDate: date,
                dateErr: ""
            })
        }
    }
    changeEndDate(date) {
        this.setState({ endDate: date })
    }
    function(date) {
        return (date.getDay() === 0 || date.getDay() === 6);  // disable weekends
    }

    getAllTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllTopics', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    //console.log("getAllTopics");
                    self.setState({ allTopics: response.data.topics })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    createPoll = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "topicId": parseInt(this.state.topic),
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "pollQuestion": this.state.pollQuestion,
            "options": [this.state.option1, this.state.option2, this.state.option3, this.state.option4]
        }
       // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/createPoll', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                    // window.location.href = "/poll"
                //    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    savePoll = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "topicId": parseInt(this.state.topic),
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "pollQuestion": this.state.pollQuestion,
            "options": [this.state.option1, this.state.option2, this.state.option3, this.state.option4]
        }
        //console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/savePoll', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        savePoll: true
                    })
                    // window.location.href = "/poll"
                 //   console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };

    handleBlur(event) {
      //  console.log("cgbh")
    }

    handleSubmit(event) {
      //  console.log("startDate", this.state)
        event.preventDefault();
    }
    validateField(name) {
        let isValid = false;
        if (name === "topic") isValid = this.validateTopic();
        else if (name === "question") isValid = this.validateQuestion();
        else if (name === "options[0]") isValid = this.validateOption1();
        else if (name === "options[1]") isValid = this.validateOption2();
        else if (name === "options[2]") isValid = this.validateOption3();
        else if (name === "options[3]") isValid = this.validateOption4();
        return isValid;
    }
    validateTopic() {
        let topicError = "";
        const value = this.state.topic;
        if (value.trim() === "") topicError = "Topic is required";
        this.setState({
            topicError
        });
        return topicError === "";
    }
    validateQuestion() {
        let questionError = "";
        const value = this.state.pollQuestion[0].question;
        if (value.trim() === "") questionError = "Question is required";
        this.setState({
            questionError
        });
        return questionError === "";
    }
    validateOption1() {
        let optionError1 = "";
        const value = this.state.pollQuestion[0].options[0];
        if (value.trim() === "") optionError1 = "Please enter the option";
        this.setState({
            optionError1
        });
        return optionError1 === "";
    }
    validateOption2() {
        let optionError2 = "";
        const value = this.state.pollQuestion[0].options[1];
        if (value.trim() === "") optionError2 = "Please enter the option";
        this.setState({
            optionError2
        });
        return optionError2 === "";
    }
    validateOption3() {
        let optionError3 = "";
        const value = this.state.pollQuestion[0].options[2];
        if (value.trim() === "") optionError3 = "Please enter the option";
        this.setState({
            optionError3
        });
        return optionError3 === "";
    }
    validateOption4() {
        let optionError4 = "";
        const value = this.state.pollQuestion[0].options[3];
        if (value.trim() === "") optionError4 = "Please enter the option";
        this.setState({
            optionError4
        });
        return optionError4 === "";
    }
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    render() {
        const { redirect, savePoll } = this.state;
        if (redirect) {
            return <Redirect to="/poll" />
        }
        if (savePoll) {
            return <Redirect to="/poll" />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5>Create Poll</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <div className="poll-date">Topic</div>
                        <div className="form-group" style={{ width: "72%" }}>
                            <select className="web-font form-control" name="topic" onChange={this.onChangeTopic} onBlur={this.handleBlur}>
                                <option className="web-font" value="">Select Topic</option>
                                {this.state.allTopics.map((topic, id) => (
                                    <option className="web-font" value={topic.id}>{topic.topicName}</option>

                                ))}
                            </select>
                        </div><Row style={{ width: "74.5%" }}>
                            <Col md={6}>
                                <div className="poll-date">Start Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={this.state.startDate}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        onChange={(date) => this.changeStartDate(date)}
                                        filterDate={(date) => date.getDay() === 1}
                                        placeholderText="dd/mm/yyyy"
                                    />
                                    <div className="error">{this.state.dateErr}</div>
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className="poll-date">End Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={this.state.endDate}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date(Date.now() + 5 * 86400000)}
                                        onChange={(date) => this.changeEndDate(date)}
                                        filterDate={(date) => date.getDay() === 5}
                                        placeholderText="dd/mm/yyyy"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Card style={{ width: "72%" }}>
                            <div className="ml-3 mt-3">
                                <div >
                                    <div className="form-group mt-1" style={{ width: "98%" }}>
                                        <input type="text"
                                            className="web-font form-control form-bg"
                                            aria-describedby="text"
                                            value={this.state.pollQuestion}
                                            row="3"
                                            name="pollQuestion"
                                            placeholder="Write Your Question"
                                            onChange={this.onChangeTopic}
                                            onBlur={this.handleBlur}
                                        />
                                        <div className="div">{this.state.questionError && (
                                            <div className="error">{this.state.questionError}</div>
                                        )}</div>
                                    </div>
                                    <div style={{ width: "98%" }} >
                                        <div className="form-group mt-3 display">
                                            <div className="radio-check"></div>&nbsp;&nbsp;
                                            <input type="text" className="web-font form-control"
                                                name="option1"
                                                placeholder={`Write your option 1`}
                                                value={this.state.option1}
                                                onBlur={this.handleBlur}
                                                onChange={this.onChangeTopic}
                                            />  <div className="div">{this.state.optionError1 && (
                                                <div className="error">{this.state.optionError1}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-3 display">
                                            <div className="radio-check"></div>&nbsp;&nbsp;
                                            <input type="text" className="web-font form-control"
                                                name="option2"
                                                placeholder={`Write your option 2`}
                                                value={this.state.option2}
                                                onBlur={this.handleBlur}
                                                onChange={this.onChangeTopic}
                                            />  <div className="div">{this.state.optionError2 && (
                                                <div className="error">{this.state.optionError2}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-3 display">
                                            <div className="radio-check"></div>&nbsp;&nbsp;
                                            <input type="text" className="web-font form-control"
                                                name="option3"
                                                placeholder={`Write your option 3`}
                                                value={this.state.option3}
                                                onBlur={this.handleBlur}
                                                onChange={this.onChangeTopic}
                                            /> <div className="div">{this.state.optionError3 && (
                                                <div className="error">{this.state.optionError3}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-3 display">
                                            <div className="radio-check"></div>&nbsp;&nbsp;
                                            <input type="text" className="web-font form-control"
                                                name="option4"
                                                placeholder={`Write your option 4`}
                                                value={this.state.option4}
                                                onBlur={this.handleBlur}
                                                onChange={this.onChangeTopic}
                                            /> <div className="div">{this.state.optionError4 && (
                                                <div className="error">{this.state.optionError4}</div>
                                            )}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        {((this.state.topic !== "") && (this.state.pollQuestion !== "") && (this.state.option1 !== "") && (this.state.option2 !== "") && (this.state.option3 !== "") && (this.state.option4 !== "") && (this.state.startDate !== "") && (this.state.endDate !== "")) ?

                            <div className="float_right_poll mt-4"> <Button variant="default" onClick={this.showPublish} className="sendreview-btn ml-4">Submit </Button> <Button className="save-btn ml-3" onClick={this.savePoll} variant="default">Save</Button></div> :
                            <div className="float_right_poll mt-4"> <Button variant="default" disabled="true" onClick={this.showPublish} className="sendreview-btn ml-4">Submit </Button> <Button className="save-btn ml-3" disabled="true" variant="default">Save</Button></div>}
                        <div id="publish" className="modal_new">
                            <form className="modal-content-log">
                                <div className="md-container">
                                    <p className="mt-2">Do you want to publish the poll?</p>
                                    <center><Button variant="default" onClick={this.createPoll} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                        <Button variant="default" onClick={this.showNone} className="deletebutton">No</Button></center>
                                </div>
                            </form>
                        </div>
                    </Form>
                </div >
            </div >
        );
    };
}
