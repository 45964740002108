import React from 'react';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

class ViewPopupCard extends React.Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this.state = {
            edit: true,
            cardTitle: this.props.cardTitle,
            cardDescription: this.props.cardDescription,
            cardNumber: this.props.cardNumber,
            cardPicture: this.props.cardPicture,
            cardId: this.props.cardId,
            cardManagementId: this.props.cardManagementId,
            image: this.props.cardPicture,
            count: 1,
            cardVal: "",
            redirectCard: false
        }
    }
    componentDidMount() {
       // console.log("props", this.props)
    }
    render() {
        const { edit, cardTitle, cardDescription, cardNumber, image, count, imgRef, cardVal } = this.state
        return (
            <Modal
                size="sm"
                show={edit}
                onHide={this.props.closePopup}
                centered
            >
                <Modal.Header closeButton>
                    <h5 style={{ color: "#FF942C", fontFamily: "poppins" }}>&nbsp;Card # {cardNumber}</h5>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Form style={{ marginTop: "-0.8rem" }}>
                        <div className="form-group">
                            <input type="text"
                                className="mt-2 web-font form-control text-box-radius"
                                aria-describedby="text"
                                name="cardTitle"
                                value={cardTitle}
                                placeholder="Card Title"
                            />
                        </div>
                        <div style={{ height: "120px", textAlign: "center", objectFit: "contain" }}>
                            <label htmlFor="upload-button">
                                {image != "" ? (
                                    <img src={image} style={{ width: "100px", height: "100px" }} />
                                ) : (
                                    <>
                                        <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                        <div className="up-load">Upload Image</div>
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                id="upload-button"
                                ref={imgRef}
                            />
                        </div>
                        <div className="form-group">
                            <textarea name="cardDescription" value={cardDescription}
                                placeholder="Card Description"
                                className="web-font richTextField_card description-radius"
                                style={{ overflowY: "none", resize: "none" }}
                            />
                        </div>
                        <div><Button variant="default" disabled="true" className="sendreview-btn mt-1 ml-2">Save</Button>
                            <Button variant="default" onClick={this.props.closePopup} className="close-btn-popup mt-1">Close</Button></div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ViewPopupCard;

