import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            gameName: "",
            gameUrl: "",
            rewardPoints: "",
            numberOfTrials: "",
            description: "",
            picture: "",
            howToPlay: "",
            isLoading: true
        }
    }
    componentDidMount() {
        this.getGameById();
    }
    getGameById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
       // console.log("token", this.state.token)
        var self = this
        axios.get(config.userUrl + `user/getGameById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
               //     console.log("success!!", response);
                    self.setState({
                        gameName: response.data.game[0].gameName,
                        numberOfTrials: response.data.game[0].numberOfTrials,
                        picture: response.data.game[0].picture,
                        rewardPoints: response.data.game[0].rewardPoints,
                        gameUrl: response.data.game[0].gameUrl,
                        description: response.data.game[0].description,
                        howToPlay: response.data.game[0].howToPlay.replace(/<br\s*\/?>/g, '\n'),
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
        //console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
                //console.log("res", res)
                this.setState({ picture: res })
            }
        }
    }
    render() {
        const { isLoading,imgRef,picture } = this.state

        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5 className="font-heading-web">View Game</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Game Name</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="gameName"
                                        placeholder="Game Name"
                                        value={this.state.gameName}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Number of Trials</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="numberOfTrials"
                                        placeholder="Number of Trials"
                                        value={this.state.numberOfTrials}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Reward Points</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="rewardPoints"
                                        placeholder="Reward Points"
                                        value={this.state.rewardPoints}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Game Url</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="gameUrl"
                                        placeholder="gameUrl"
                                        value={this.state.gameUrl}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-1 poll-date">Description</div>
                        <div className="form-group" style={{ width: "71.5%" }}>
                            <textarea className="web-font form-control" name="description"
                                placeholder="Description"
                                rows={2}
                                value={this.state.description}
                                style={{resize:"none"}}
                                readOnly
                                onChange={this.onChange} />
                        </div>
                        <div className="mt-1 poll-date">How to Play</div>
                        <div className="form-group" style={{ width: "71.5%" }}>
                            <textarea className="web-font form-control" name="howToPlay"
                                placeholder="How To Play"
                                rows={5}
                                style={{resize:"none"}}
                                value={this.state.howToPlay}
                                readOnly
                                onChange={this.onChange} />
                                  
                        </div>
                        <center style={{ height: "120px",width:"120px", textAlign: "center", objectFit: "contain" }}>
                            <label htmlFor="upload-button">
                                {picture != "" ? (
                                    <img src={picture} style={{ width: "100px", height: "100px" }} />
                                ) : (
                                    <>
                                        <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                        <div className="up-load">Upload Image</div>
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                id="upload-button"
                                name="image"
                                ref={imgRef}
                                onChange={(e) => this.handleImage(e)}
                            />
                        </center>
                        <Link to="/games" className="float_right_poll"><Button variant="default" className="cancelreview-btn mt-2">Back</Button></Link>

                    </Form><br /><br />
                </div >
            </div >
        );
    };
}




