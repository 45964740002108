import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Purchases extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            transactions: [],
            isLoading: true

        }
    }

    componentDidMount() {
        this.getReferralReach()
    }
    getReferralReach = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllTransactionDetails', { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status == true) {
                    self.setState({ transactions: response.data.getAllTransaction, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, transactions } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        // const updatedDate = transactions.map((i, idx) => {
        //     var a = new Date(i.updatedAt)
        //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        //     return updated;
        // })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    {/* <div style={{ display: "flex" }}>
                        <NavLink to="/history/interests" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Expression of Interest</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/blocked" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Block Article </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/articles" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Article </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/polls" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Poll </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/advertisements" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Advertisement </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/usersreach" activeClassName="history-active" className="Link" >
                            <div className="history-Text">User </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/q&a" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Q&A </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/opportunities" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Opportunity </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/referrals" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Referrals </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div > */}
                    
                    <Link to="/verifytransactions"><Button variant="default" className="mt-2 trans-btn">Verify Transactions</Button></Link>
                    <div className="mt-3 active-add-h-text">Transactions</div>
                    {transactions.length === 0 ? <center><h4>No Transactions.</h4></center> :
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr  >
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Transaction Id</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Plan Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Amount (&#8377;)</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Created On</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Time</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Payment Method</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Status</th>

                                    </tr>
                                </thead>

                                <tbody>{transactions.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text">{data.name}</td>
                                        <td className="td-text">{data.transactionId}</td>
                                        <td className="td-text">{data.planName}</td>
                                        <td className="td-text">{data.planAmount}</td>
                                        <td className="td-text">{data.createdAt.split("T")[0].split("-").reverse().join("-")}</td>
                                        <td className="td-text">{data.time}</td>
                                        <td className="td-text">{data.paymentMethod}</td>
                                        <td className="td-text">{data.status == 5 ? "Refunded" : (data.status == 2 ? "Completed" : (data.status == 3 ? "Accepted" : (data.status == 4 ? "Rejected" : (data.status == 6 ? "Refund Raised" :"Waiting"))))}</td>
                                        {/* <td className="cursor" >{data.status == 6 ?  <Link to={`/refund/${data.id}`} className="poll-link"><i class="fa fa-pencil left" aria-hidden="true"></i></Link>:""}</td> */}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div >
        );
    };
}