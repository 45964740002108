import React from 'react';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

class CreateNewFeature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            edit: true,
            feature: "",
            description: "",
            subFeatures: "",
            status: "",
            relatedTo: "",
            redirect: false
        }
    }
    componentDidMount() {
        //console.log("props")
        // var a="abc"
        // var array = a.split(',');
        // console.log("array",array)
        // console.log("abc",array.toString())
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    createNewFeature = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var subItems = this.state.subFeatures.split(',');
        if (this.state.subFeatures.length == 0) {
            subItems = []
        } else {
            subItems=this.state.subFeatures.split(',');
        }
        var payload = {
            "feature": this.state.feature,
            "relatedTo": parseInt(this.state.relatedTo),
            "description": this.state.description,
            "subFeature": subItems,
            "status": val
        }
       // console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'user/createNewFeature', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.props.getAllFeatures()
                    self.props.closePopup()
                   // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    }
    render() {
        const { edit, feature, description, subFeatures, status, relatedTo } = this.state
        return (
            <Modal
                size="sm"
                show={edit}
                onHide={this.props.closePopup}
                centered
            >
                <Modal.Header closeButton>
                    <h5 style={{ color: "#FF942C", fontFamily: "poppins" }}>&nbsp;Add New Feature</h5>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Form style={{ marginTop: "-0.8rem" }}>
                        <div className="form-group">
                            <input type="text"
                                className="mt-2 web-font form-control text-box-radius"
                                aria-describedby="text"
                                name="feature"
                                value={feature}
                                onChange={this.onChange}
                                placeholder="Feature Name"
                            />
                        </div>
                        <div className="form-group">
                            <input type="text"
                                className="mt-2 web-font form-control text-box-radius"
                                aria-describedby="text"
                                name="subFeatures"
                                value={subFeatures}
                                onChange={this.onChange}
                                placeholder="Sub Features"
                            />
                        </div>
                        <div className="form-group">
                            <select name="relatedTo" value={relatedTo} onChange={this.onChange} class="mt-2 web-font form-control text-box-radius">
                                <option value="">Select Related To</option>
                                <option value={1}>Mobile App</option>
                                <option value={2}>Web App Author</option>
                                <option value={3}>Web App Reviewer</option>
                                <option value={4}>Web App Sme</option>
                                <option value={5}>Web App Admin</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <textarea name="description"
                                onChange={this.onChange}
                                value={description}
                                placeholder="Description"
                                className="web-font richTextField_feature description-radius"
                                style={{ overflowY: "none", resize: "none" }}
                            />
                        </div>
                        <div>{feature !== "" && relatedTo != "" && description !== "" ?
                            <Button variant="default" onClick={() => this.createNewFeature(1)} className="sendreview-btn mt-1 ml-2">Create</Button> :
                            <Button variant="default" disabled="true" className="sendreview-btn mt-1 ml-2">Create</Button>}
                            <Button variant="default" onClick={() => this.createNewFeature(2)} className="close-btn-popup mt-1">Save</Button></div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default CreateNewFeature;

