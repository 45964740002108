import React, { Component } from "react";
import { Button, Form, Table, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Term extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            terms: [],
            filteredTerms: [],
            redirect: false,
            isLoading: true,
            filterTerm: ""
        }
    }
    componentDidMount() {
        //console.log("terms", this.state.terms)
        this.getActiveTerms();
    }


    getActiveTerms = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getActiveTerms', { headers: headers })
            .then(function (response) {
           //     console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        filteredTerms: response.data.terms,
                        terms: response.data.terms,
                        isLoading: false
                    })
                 //   console.log("getSavedPosts", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    handleFilterChange = (event) => {
        const filterTerm = event.target.value;
        this.setState({
            filterTerm,
            filteredTerms: this.filterTerms(filterTerm),
        });
    };

    filterTerms = (filterTerm) => {
        // Filter terms based on the input filter term
        return this.state.terms.filter(term =>
            term.term.toLowerCase().includes(filterTerm.toLowerCase())
        );
    };
    render() {
        const { isLoading, terms, filteredTerms, filterTerm } = this.state
        const b = filteredTerms.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >

                    <div >
                        <div> <Link to="/term/createterm"><Button variant="default" className="createadv-btn">+ &nbsp;Create</Button></Link>
                            {/* <Link to="/term/savedterms"><Button variant="default" className="ml-3 createadv-btn">&nbsp;Saved</Button></Link> */}

                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="mt-3 active-add-h-text">Active Terms ({filteredTerms.length})</div>

                            <div className="mr-5 mt-2">
                                <input
                                    className="web-font form-control form-bg"
                                    type="text"
                                    placeholder="Filter by Term"
                                    value={this.state.filterTerm}
                                    onChange={this.handleFilterChange}
                                />
                            </div>
                        </div>
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr>
                                        <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Term</th>
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Definition</th>
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Explanation</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Created At</th>
                                        <th></th>
                                        {/* <th scope="col" className="th-heading" style={{ width: "6%" }}></th> */}
                                        {/* <th ></th> */}
                                    </tr>
                                </thead>

                                <tbody>
                                    {filteredTerms.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text" >&nbsp;&nbsp;{i + 1}</td>
                                            <td className="td-text">{data.term}</td>
                                            <td className="td-text">{data.definition}</td>
                                            <td className="td-text">{data.usage}</td>
                                            <td className="td-text">{b[i]}</td>
                                            <td className="cursor"><Link to={`/term/editterm/${data.id}`} className="poll-link"> <i class="fa fa-pencil left" aria-hidden="true"></i></Link> </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div >
        );
    };
}

