import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Redirect, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import createpoll from '../images/createpoll.svg';
import savedpolls from '../images/savedpolls.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class SavedGames extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            savedGames: [],
            isLoading: true
        }
    }
    componentDidMount() {
       // console.log("SavedGames")
        this.getSavedGames();
    }
    getSavedGames = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getSavedGames', { headers: headers })
            .then(function (response) {
               // console.log("savedGames", response);
                if (response.data.status == true) {
                   // console.log("getSavedGames");
                    self.setState({ savedGames: response.data.games, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, savedGames } = this.state;
        const createdDate = savedGames.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
   
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <div className="mt-3 active-add-h-text">Saved Games</div>
                    {savedGames.length === 0 ? <center><h4>No Saved Games</h4></center> :
                        <div class="mt-3 tableFixHead-saved">
                                             <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr>
                                        <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Game Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "17%" }}>Description</th>
                                        <th scope="col" className="th-heading" style={{ width: "12%" }}>No.of Trials</th>
                                        <th scope="col" className="th-heading" style={{ width: "12%" }}>Reward Points</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Game Url</th>
                                        <th scope="col" className="th-heading" style={{ width: "12%" }}>Created At</th>
                                        <th ></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {savedGames.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text" >&nbsp;&nbsp;{i + 1}</td>
                                            <td className="td-text">{data.gameName}</td>
                                            <td className="td-text">{data.description}</td>
                                            <td className="td-text">{data.numberOfTrials}</td>
                                            <td className="td-text">{data.rewardPoints}</td>
                                            <td className="td-text">{data.gameUrl}</td>
                                            <td className="td-text">{createdDate[i]}</td>
                                            <td className="cursor" ><Link to={`/games/editgame/${data.id}`} className="poll-link"> <i class="fa fa-pencil left" aria-hidden="true"></i></Link> </td>
                                            </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                </div>
            </div >
        );
    };
}

