import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class EditTerm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            term: "",
            definition: "",
            usage: "",
            submit: true,
            save: false,
            termError: '',
            definitionError: '',
            usageError: '',
        }
    }
    componentDidMount() {
        this.getTermById();
       // console.log("term", this.state.term, this.props.match.params.id)

    }
    getTermById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
      //  console.log("token", this.state.token)
        var self = this
        axios.get(config.userUrl + `user/getTermById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                  //  console.log("success!!");
                    self.setState({
                        term: response.data.terms[0].term,
                        definition: response.data.terms[0].definition,
                        usage: response.data.terms[0].usage
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        //console.log(this.state)
    }
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    editTerm = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "isActive": data,
            "termId": this.props.match.params.id,
            "term": this.state.term,
            "definition": this.state.definition,
            "usage": this.state.usage,
        }
      //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/editTerm', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                //    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    render() {
        const { redirect, term, definition, usage, isLoading } = this.state

        if (isLoading) {
            return <div className="App"></div>;
        }
        if (redirect) {
            return <Redirect to="/term" />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5 className="font-heading-web">Edit Term</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <div className="poll-date">Term</div>
                        <div className="form-group" style={{ width: "72%" }}>
                            <input type="text"
                                className="web-font form-control form-bg"
                                aria-describedby="text"
                                value={this.state.term}
                                name="term"
                                placeholder="Add Term"
                                onChange={this.onChange}
                                onBlur={this.handleBlur}
                            />
                            <div className="div">{this.state.termError && (
                                <div className="error">{this.state.termError}</div>
                            )}</div>
                        </div>
                        <div className="poll-date">Definition</div>
                        <div className="form-group" style={{ width: "72%" }}>
                            <input type="text"
                                className="web-font form-control form-bg"
                                aria-describedby="text"
                                value={this.state.definition}
                                name="definition"
                                placeholder="Add Definition"
                                onChange={this.onChange}
                                onBlur={this.handleBlur}
                            />
                            <div className="div">{this.state.definitionError && (
                                <div className="error">{this.state.definitionError}</div>
                            )}</div>
                        </div>
                        <label className="poll-date">Usage</label>
                        <div className="form-group" style={{ width: "72%" }}>
                            <textarea type="text"
                                className="web-font form-control"
                                aria-describedby="text"
                                rows="5"
                                name="usage"
                                value={this.state.usage}
                                style={{ resize: "none" }}
                                onChange={this.onChange}
                                onBlur={this.handleBlur}
                            />
                            <div className="div">{this.state.usageError && (
                                <div className="error">{this.state.usageError}</div>
                            )}</div>
                        </div>
                        <div className="float_right_poll">
                            {((this.state.term !== "") && (this.state.definition !== "") && (this.state.usage !== "")) ?
                                <>
                                    <Button onClick={() => this.editTerm(this.state.submit)} variant="default" className="sendreview-btn ml-3">Submit </Button>
                                    {/* <div id="publish" className="modal_new">
                                        <form className="modal-content-log">
                                            <div className="md-container">
                                                <p className="mt-2">Do you want to publish term?</p>
                                                <center><Button variant="default" onClick={() => this.editTerm(this.state.submit)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                    <Button variant="default" onClick={this.showNone} className="deletebutton">No</Button></center>
                                            </div>
                                        </form>
                                    </div> */}
                                    {/* <Button className="save-btn ml-3" onClick={() => this.editTerm(this.state.save)} variant="default">Update</Button> */}
                                </> :
                                <>                                 <Button onClick={() => this.editTerm(this.state.submit)} variant="default" disabled="true" className="sendreview-btn ml-3">Submit </Button>
                                    {/* <Button className="save-btn ml-3" onClick={() => this.editTerm(this.state.save)} disabled="true" variant="default">Update</Button> */}
                                </>}
                            <Link to="/term"><Button variant="default" className="cancelreview-btn ml-4">Back</Button></Link></div>
                    </Form><br /><br />
                </div >
            </div >
        );
    };
}





