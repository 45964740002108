import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import Chart from 'chart.js/auto';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export class TopThreeRewardsGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            topThreeRewardsData: [],
            selectedValue: 11
        }
    }

    componentDidMount() {
        this.getTopThreeRewards()
    }
    getTopThreeRewards = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getTopThreeRewards', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        topThreeRewardsData: response.data.topThreeRewards,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { topThreeRewardsData } = this.state;

        const users = {
            labels: topThreeRewardsData.map((data) => data.name),
            datasets: [
                {
                    label: 'Rewards',
                    data: topThreeRewardsData.map((data) => data.rewardPoints),
                    backgroundColor: [
                        'rgba(208, 56, 242, 0.8)',
                    ],
                    borderColor: [
                        'rgba(208, 56, 242, 1)',
                    ],
                    borderWidth: 1,
                    fill: {
                        target: 'origin',
                        above: 'rgb(208, 56, 242,0.2)',   // Area will be red above the origin
                        below: '#000000'    // And blue below the origin
                    }
                }
            ]
        }
        return (
            <div>
                <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{background:"#c2fce7"}} >
                    <center><div className="graph-heading-T">Top Three Users Rewards</div></center>
                    <div style={{ marginTop: "-4px", height: "158px", width: "100%" }}>
                       <div className="tableGraph">
                            <table class="ml-1 mt-3 table-striped" >
                                <thead style={{ background: "#7d56f5" }}>
                                    <tr>
                                        <th scope="col" className="gh-heading">Name</th>
                                        <th scope="col" className="gh-heading">4E's</th>
                                        <th scope="col" className="gh-heading">Role</th>
                                        <th scope="col" className="gh-heading">Rewards</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {topThreeRewardsData.map((data, i) => (
                                        <tr >
                                            <td className="gh-text">{data.name}</td>
                                            <td className="gh-text">{data.fourEs}</td>
                                            <td className="gh-text">{data.roleType.charAt(0).toUpperCase() + data.roleType.slice(1)}</td>
                                            <td className="gh-text">{data.rewardPoints}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                        {/* <Line data={users}
                                options={{
                                    responsive: true,
                                    elements: {
                                        line: {
                                            tension: 1
                                        }
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                display: false,
                                            }
                                        },
                                        y: {
                                            ticks: {
                                                precision: 0
                                            },
                                            beginAtZero: true,
                                            grid: {
                                                display: false,
                                            },
                                        },
                                    },
                                    plugins: {
                                        title: {
                                            display: false,
                                            text: ""
                                        },
                                        legend: {
                                            display: false
                                        },
                                    }
                                }
                                } /> */}
                    </div>
                </Card>
            </div>
        );
    };
}