import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class AddTerm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            term: "",
            definition: "",
            usage: "",
            saveTerm: false,
            termError: '',
            definitionError: '',
            usageError: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
      //  console.log("terms")
    }
    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    createTerm = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "term": this.state.term,
            "definition": this.state.definition,
            "usage": this.state.usage,
            "status": data
        }
       // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/createTerm', payload, { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                    // window.location.href = "/poll"
                  //  console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    // saveTerm = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": `Bearer ${this.state.token}`,
    //         "applicationType": "web"
    //     }
    //     var payload = {
    //         "term": this.state.term,
    //         "definition": this.state.definition,
    //         "usage": this.state.usage,
    //     }
    //     console.log("payload", payload)
    //     var self = this
    //     axios.post(config.userUrl + 'user/savePoll', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status == true) {
    //                 self.setState({
    //                     saveTerm: true
    //                 })
    //                 // window.location.href = "/poll"
    //                 console.log("success", response.data.message, payload);
    //             } else {
    //                 console.log("Error", payload);
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("error", error, payload);
    //         });

    // };

    handleBlur(event) {
     //   console.log("cgbh")
    }

    handleSubmit(event) {
       // console.log("startDate", this.state)
        event.preventDefault();
    }
    validateField(name) {
        let isValid = false;
        if (name === "term") isValid = this.validateTerm();
        else if (name === "definition") isValid = this.validateDefinition();
        else if (name === "usage") isValid = this.validateUsage();
        return isValid;
    }
    validateTerm() {
        let termError = "";
        const value = this.state.term;
        if (value.trim() === "") termError = "Please add the term";
        this.setState({
            termError
        });
        return termError === "";
    }
    validateDefinition() {
        let definitionError = "";
        const value = this.state.definition;
        if (value.trim() === "") definitionError = "Please add definition";
        this.setState({
            definitionError
        });
        return definitionError === "";
    }
    validateUsage() {
        let usageError = "";
        const value = this.state.usage;
        if (value.trim() === "") usageError = "Please add usage/explanation";
        this.setState({
            usageError
        });
        return usageError === "";
    }
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    render() {
        const { redirect, saveTerm } = this.state;
        if (redirect) {
            return <Redirect to="/term" />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5>Add Term</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <div className="poll-date">Term</div>
                        <div className="form-group" style={{ width: "72%" }}>
                            <input type="text"
                                className="web-font form-control form-bg"
                                aria-describedby="text"
                                value={this.state.term}
                                name="term"
                                placeholder="Add Term"
                                onChange={this.onChange}
                                onBlur={this.handleBlur}
                            />
                            <div className="div">{this.state.termError && (
                                <div className="error">{this.state.termError}</div>
                            )}</div>
                        </div>
                        <div className="poll-date">Definition</div>
                        <div className="form-group" style={{ width: "72%" }}>
                            <input type="text"
                                className="web-font form-control form-bg"
                                aria-describedby="text"
                                value={this.state.definition}
                                name="definition"
                                placeholder="Add Definition"
                                onChange={this.onChange}
                                onBlur={this.handleBlur}
                            />
                            <div className="div">{this.state.definitionError && (
                                <div className="error">{this.state.definitionError}</div>
                            )}</div>
                        </div>
                        <label className="poll-date">Usage</label>
                        <div className="form-group" style={{ width: "72%" }}>
                            <textarea type="text"
                                className="web-font form-control"
                                aria-describedby="text"
                                rows="5"
                                name="usage"
                                style={{ resize: "none" }}
                                onChange={this.onChange}
                                onBlur={this.handleBlur}
                            />
                            <div className="div">{this.state.usageError && (
                                <div className="error">{this.state.usageError}</div>
                            )}</div>
                        </div>
                        {((this.state.term !== "") && (this.state.definition !== "") && (this.state.usage !== "")) ?

                            <div className="float_right_poll mt-4"> <Button variant="default" onClick={this.showPublish} className="sendreview-btn ml-4">Submit </Button> <Link to="/term"><Button variant="default" className="cancelreview-btn ml-4">Back</Button></Link></div> :
                            <div className="float_right_poll mt-4"> <Button variant="default" disabled="true" onClick={this.showPublish} className="sendreview-btn ml-4">Submit </Button><Link to="/term"><Button variant="default" className="cancelreview-btn ml-3">Back</Button></Link></div>}
                        <div id="publish" className="modal_new">
                            <form className="modal-content-log">
                                <div className="md-container">
                                    <p className="mt-2">Do you want to add the term?</p>
                                    <center><Button variant="default" onClick={() => this.createTerm("active")} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                        <Button variant="default" onClick={this.showNone} className="deletebutton">No</Button></center>
                                </div>
                            </form>
                        </div>
                    </Form>
                </div >
            </div >
        );
    };
}
