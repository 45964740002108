import React, { Component } from "react";
import { Button, Form, Col, Row, Card, } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './AuthorProfile.css';
import authorprofile from '../images/authorprofile.svg';
import { FollowersJson } from "./Followersjson";

export default class Following extends Component {

    render() {
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "4.8rem", marginLeft: "260px" }}>
                    <div style={{ display: "flex" }}> <NavLink to="/profile/followers" activeClassName="profile-active" className="link" >
                        <div className="followers-Text ml-2 mr-2">Followers </div></NavLink>
                        <NavLink to="/profile/following" activeClassName="profile-active" className="link" >
                            <div className="followers-Text ml-2 mr-2">Following</div></NavLink>
                    </div><br />
                    <Card className="followers-card">
                        <Row style={{ width: "100%" }}>
                            {FollowersJson.map((data, i) => (
                                <Col md={2}>
                                    <center>
                                        <img src={data.image} style={{ width: "80%" }} />
                                        <div>{data.name}</div><br />
                                    </center>
                                </Col>
                            ))}
                        </Row>
                    </Card>
                </div>
            </div >
        );
    }
}
