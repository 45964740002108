import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            topic: "",
            startDate: "",
            endDate: "",
            pollQuestion: "",
            startDate: "",
            endDate: "",
            isLoading: true
        }
    }
    componentDidMount() {
        this.getOfferById();
    }
    getOfferById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
       // console.log("token", this.state.token)
        var self = this
        axios.get(config.userUrl + `user/getOfferById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                //    console.log("success!!",response);
                    self.setState({
                        offerName: response.data.offer[0].offerName,
                        rewardPoints: response.data.offer[0].rewardPoints,
                        url: response.data.offer[0].url,
                        startDate: response.data.offer[0].startDate,
                        endDate: response.data.offer[0].endDate,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { startDate, endDate, isLoading } = this.state
        var a = new Date(startDate)
        var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
        var b = new Date(endDate)
        var endD = parseInt(b.getMonth() + 1) + "-" + b.getDate() + "-" + b.getFullYear();

        const selectedStartDate = new Date(start)
        const selectedEndDate = new Date(endD)
       // console.log("start", startDate)

        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5 className="font-heading-web">View Offer</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <div className="mt-3 poll-date">Offer Name</div>
                        <div className="form-group" style={{ width: "71.5%" }}>
                            <input type="text"
                                className="web-font form-control form-bg"
                                aria-describedby="text"
                                value={this.state.offerName}
                                readOnly
                                name="offerName"
                                placeholder="Offer Name"
                            />
                        </div>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Start Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={selectedStartDate}
                                        dateFormat="dd-MM-yyyy"
                                        minDate={new Date()}
                                        readOnly
                                        filterDate={(date) => date.getDay() === 1}
                                    />
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className="poll-date">End Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={selectedEndDate}
                                        dateFormat="dd-MM-yyyy"
                                        readOnly
                                        minDate={new Date(Date.now() + 5 * 86400000)}
                                        filterDate={(date) => date.getDay() === 5}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Reward Points</div>
                                <input type="text"
                                    className="web-font form-control form-bg"
                                    aria-describedby="text"
                                    value={this.state.rewardPoints}
                                    readOnly
                                    name="rewardPoints"
                                    placeholder="Reward Points"
                                />

                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Url</div>
                                <input type="text"
                                    className="web-font form-control form-bg"
                                    aria-describedby="text"
                                    value={this.state.url}
                                    readOnly
                                    name="url"
                                    placeholder="Url"
                                />
                            </Col>
                        </Row>
                        <Link to="/offers" className="float_right_poll"><Button variant="default" className="cancelreview-btn mt-2">Back</Button></Link>

                    </Form><br /><br />
                </div >
            </div >
        );
    };
}




