import React, { Component } from "react";
import { Button, Form, Table, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Advertisement.css';
import advertisementData from './Advertisement.json';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Configuration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
        }
    }
    render() {
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <div> <Link to="/configuration/features"><Button variant="default" className="createadv-btn">Features</Button></Link>
                        <Link to="/configuration/subscription"><Button variant="default" className="ml-3 createadv-btn">Subscription</Button></Link>
                        <Link to="/configuration/features&subscription"><Button variant="default" className="ml-3 createadv-btn">Both</Button></Link>
                        <Link to="/configuration/addfeaturetext"><Button variant="default" className="ml-3 createadv-btn">Add Text</Button></Link>
                    </div>
                </div >
            </div>
        );
    };
}

