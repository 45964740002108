import React, { Component } from "react";
import { Button, Form, Col, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import logo from '../images/logo.svg';
import nebutech from '../images/nebutech.svg';
import forgotImg from '../images/forgotImg.png';
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            registerFlow: false,
            redirect: false
        }
    }

    sendOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "email": this.state.email,
            "registerFlow": this.state.registerFlow
        }
        var self = this;
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                  //  console.log("Otp sent successfully!!")
                  //  console.log("payload", payload)
                    self.setState({ redirect: true })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                alert("Error")
              //  console.log("payload", payload)
                console.log(error);
            });
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {
        const { redirect } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/verify",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow }
                }} />
        }
        return (
            <div>
                <div class="log">
                    <center><img src={logo} className="logo-width" /></center>
                    <div className="login mt-2">Forgot Password</div>
                    <div className="login-sm">Enter registered email address for recovery your account</div><br />
                    <form >
                        <div id="border" class="input-border">
                            <input type="text" name="email" onChange={this.onChange} class="text" required />
                            <label className="label">Email Address</label>
                            <div class="border"></div>
                            <div className="mb-2"></div>
                            <center id="status" className="errormsg"></center>
                        </div>
                        <center>
                            {/* <div className="login-top"><span className="color-grey">Or </span><span style={{ color: "#31179C" }}>login</span><span className="color-grey"> with</span> <img src={google} className="google" /></div> */}
                            <Button variant="default" onClick={this.sendOtp} className="btn-block reset-btn">Reset Password</Button>
                            <div className="mt-2 try">Try another ways</div>
                        </center>
                    </form>

                </div>
                <div className="nebutech" ><img src={nebutech} /></div>
                {/* <img src={loginlogo} className="login-logo" />
                <center> <img src={forgotImg} className="forgotImg" />
                    <p className="forgot-text">Forgot password?</p>
                    <p className="forgot-text-sm">Enter registered email address for recovery<br />your account</p></center>
                <Form className="forgot-email">
                    <center>
                        <div style={{ width: "80%" }}>
                            <input type="email"
                                className="form-control form-bg text-lowercase"
                                aria-describedby="text"
                                value={this.state.email}
                                name="email"
                                placeholder="Email Address"
                                onChange={this.onChange}
                            />
                        </div>
                        <div id="status" className="mt-2 errormsg"></div><br />
                        <div><Button variant="default" onClick={this.sendOtp} className="btn-block login-btn">Reset Password</Button><br /></div>
                        <p className="try">Try another ways</p>
                    </center>
                </Form> */}
            </div>
        );
    }
}
