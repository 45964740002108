import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import createpoll from '../images/createpoll.svg';
import savedarticles from '../images/savedpolls.svg';
import addimage from '../images/addimage.svg';
import pollData from '../Poll/Poll.json'
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Articles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            poll: pollData.poll,
            posts: [],
            isLoading: true
        }
    }
    componentDidMount() {
        if (sessionStorage.getItem('role') === "admin") {
            console.log("admin")
            this.setState({ isLoading: !this.state.isLoading })
        } else {
            this.getCreatedPosts();
        }
    }
    getCreatedPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getCreatedPosts', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("getCreatedPosts");
                    self.setState({ posts: response.data.posts, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {

        const { isLoading, posts } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        const statusNew = posts.map((i, idx) => {
            var a = (i.status).charAt(0).toUpperCase() + (i.status).slice(1)
            return a;
        })
        const updatedDate = posts.map((i, idx) => {
            var a = new Date(i.updatedAt)
            var update = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return update;
        })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >

                    <div> <Link to="/articles/writearticle"><Button variant="default" className="createadv-btn">+ &nbsp;Create</Button></Link>
                        <Link to="/articles/savedarticles"><Button variant="default" className="ml-3 createadv-btn">&nbsp;Saved</Button></Link>
                    </div>
                    {/* <div><Link to="/articles/writearticle"><img src={addimage} className="create-poll-img" /></Link>
                        <Link to="/articles/savedarticles"><img src={savedarticles} className="ml-3 create-poll-img" /></Link>
                    </div><br /> */}
                    <div className="mt-3 active-add-h-text">{sessionStorage.getItem('role') === 'admin' ? "Articles" : "Published Articles"}</div>
                    {this.state.posts.length === 0 ? <center><h4>You have not created articles. Go to Saved Articles.</h4></center> :
                        <div class="mt-3 tableFixHead">
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr>
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Title</th>
                                        {sessionStorage.getItem('role') === "admin" ? <th scope="col" className="th-heading" style={{ width: "15%" }}>Name</th> : <th></th>}
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Topic</th>
                                        <th scope="col" className="th-heading" style={{ width: "13%" }}>Status</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Updated On</th>
                                        <th scope="col" className="th-heading" style={{ width: "4%" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.posts.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text" key={i} >{data.title}</td>
                                            {sessionStorage.getItem('role') === "admin" ? <td scope="col" className="td-text">{data.name}</td> : <td></td>}
                                            <td className="td-text" key={i} >{data.topicName}</td>
                                            <td className="td-text" key={i}>{statusNew[i]}</td>
                                            <td className="td-text" key={i}>{updatedDate[i]}</td>
                                            <td className="cursor" ><Link to={`/articles/viewarticle/${data.id}`} className="poll-link"> <i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                                        </tr>
                                    ))}<br />
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div >
        );
    };
}