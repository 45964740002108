import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Poll.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ShowPoll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            topic: this.props.location.state.data.topic,
            pollQuestion: this.props.location.state.data.pollQuestion,
            options: [this.props.location.state.data.option1, this.props.location.state.data.option2, this.props.location.state.data.option3, this.props.location.state.data.option4],
            startDate: this.props.location.state.data.startDate,
            endDate: this.props.location.state.data.endDate,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
     //   console.log("poll", this.props.location.state.data)
    }
    handleSubmit(event) {
        event.preventDefault();
      //  console.log(this.state)
        this.createPoll();
    }
    createPoll = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`
        }
        var payload = {
            "topicId": parseInt(this.state.topic),
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "pollQuestion": this.state.pollQuestion,
            "options": this.state.options
        }
       // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/createPoll', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                   // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    render() {
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.4rem", marginLeft: "270px" }} >
                    <Form onSubmit={this.handleSubmit}>

                        <Card style={{ width: "70%" }}><br />
                            <Row style={{ width: "100%" }}>

                                <Col>
                                    <div className="ml-3 poll_Topic">  Topic <span className="poll_Topic_Name">"{this.state.topic}"</span></div>
                                </Col>
                                <Col>
                                    <span>StartDate: {this.state.startDate}</span>
                                </Col>
                                <Col>
                                    <span>EndDate: {this.state.endDate}</span>
                                </Col>
                            </Row>
                            <hr className="ml-3 mr-4" />
                            <div className="ml-3">
                                <div> <div className="display">1. <div className="ml-2">{this.state.pollQuestion}</div></div><br />
                                    <div style={{ width: "50%", marginLeft: "19px" }} >
                                        <div className="display"><div className="radio"></div> &nbsp;&nbsp;{this.state.options[0]}</div><br />
                                        <div className="display"><div className="radio"></div> &nbsp;&nbsp;{this.state.options[1]}</div><br />
                                        <div className="display"><div className="radio"></div> &nbsp;&nbsp;{this.state.options[2]}</div><br />
                                        <div className="display"><div className="radio"></div> &nbsp;&nbsp;{this.state.options[3]}</div><br />
                                    </div>
                                </div>
                            </div>

                        </Card><br />
                        <div className="float_right"><Button className="save-poll-btn" variant="default">Save</Button>
                            <Button type="submit" className="ml-2 show-poll-post-btn" variant="default">Post</Button></div>
                    </Form><br /><br />
                </div >
            </div >
        );
    };
}

