import React, { useState, useRef, useEffect } from "react";
import { Button, Form, OverlayTrigger, Tooltip, Card, Popover, Col, Row } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import addimage from '../images/addimage.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import PopupCard from "./PopupCard";
import SequencePopupCards from "./SequencePopupCards";
import EditPopupCard from "./EditPopupCard";
import DeletePopupCard from "./DeletePopupCard";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function Cards() {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [cardManagementId, setCardManagementId] = useState("")
    const [formValues, setFormValues] = useState([{ titleC: "", descriptionC: "" }])
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [cardTitle, setCardTitle] = useState("")
    const [cardDescription, setCardDescription] = useState("")
    const [cardId, setCardId] = useState("")
    const [cardNumber, setCardNumber] = useState("")
    const [cardPicture, setCardPicture] = useState("")
    const [popup, setPopup] = useState(false)
    const [addCard, setAddCard] = useState(false)
    const [redirectCard, setRedirectCard] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [savedPost, setSavedPost] = useState(false)
    const [editPopup, setEditPopup] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    const [sequencePopup, setSequencePopup] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [topicId,setTopicId]=useState("")
    const [allTopics,setAllTopics]=useState([])
    const [cardManagementData, setCardManagementData] = useState([])
    const [cards, setCards] = useState([])
    const imgRef = useRef()

    const fileHandler = (e) => {
        setImage(e.target.files[0])
    }
    useEffect(() => {
        getAllTopics()
    }, []);
    const addCardManagement = () => {
        if (!cardManagementId) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + token,
                "applicationType": "web"
            }
            var payload = {
                "topicId": parseInt(topicId),
                "title": title,
                "description": description
            }
          //  console.log("payload", payload)
            axios.post(config.userUrl + 'user/addCardManagement', payload, { headers: headers })
                .then(function (response) {
                    //console.log(response);
                    if (response.data.status == true) {
                        setCardManagementId(response.data.cardManagement.id)
                        setPopup(true)
                        //setRedirectCard(true)
                      //  console.log("success", response.data.message, payload);
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        } else {
            setAddCard(false)
            setPopup(true)
        }
    };
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, { 
        // data.append("file", image)
        // data.append("upload_preset", "feddUp")
        // data.append("cloud_name", "feddup")
        // const resp = await fetch(`https://api.cloudinary.com/v1_1/feddup/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    const handleImage = async (e) => {
        //console.log("fgh")
        if (e.target.files && e.target.files[0]) {
            const res = await uploadImage(e.target.files[0]);
            if (res) {
               // console.log("res", res)
                setImage(res)
            }
        }
    }
    const getCardById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "cardManagementId": cardManagementId
        }
        axios.post(config.userUrl + "user/getCardDetailByAdmin", payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                  //  console.log("success!!");
                    setCardManagementData(response.data.cardManagementData)
                    setCards(response.data.cardDetail)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllTopics', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    //console.log("getAllTopics");
                    setAllTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const publishCards = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "cardManagementId": cardManagementId
        }
        //console.log("payload", payload)
        axios.post(config.userUrl + 'user/cardPublishedByCardManagementId', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    setRedirect(true)
                 //   console.log("success", response.data.message, payload);
                } else {
                 //   console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    if (redirect || savedPost) {
        return <Redirect to="/carddetails" />;
    }
    const togglePopup = () => {
        setPopup(!popup)
    }
    const togglePopupData = (data, id) => {
        setCardId(id)
        setCardTitle(data.cardTitle)
        setCardNumber(data.cardNumber)
        setCardDescription(data.cardDescription)
        setCardPicture(data.cardPicture)
       // console.log("dataPopup", popup, data)
    }
    const redirectCardTrue = () => {
        setRedirectCard(true)
    }
    const openEditPopUp = () => {
        setEditPopup(!editPopup)
        setPopup(false)
    }
    const toggleEditPopup = () => {
        setEditPopup(false)
        setPopup(false)
    }
    const openSequencePopUp = () => {
        setSequencePopup(!sequencePopup)
        setPopup(false)
    }
    const toggleSequencePopup = () => {
        setSequencePopup(false)
        setPopup(false)
    }
    const openDeletePopUp = () => {
        setDeletePopup(!deletePopup)
        setPopup(false)
    }
    const toggleDeletePopup = () => {
        setDeletePopup(false)
        setPopup(false)
    }
    const openBox = () => {
        document.getElementById('open').style.display = 'flex'
    }
    const closeBox = () => {
        document.getElementById('open').style.display = 'none'
    }
    const onChangeTopic=(event)=> {
       setTopicId(event.target.value)
    }
    return (
        <div>
            {popup && <PopupCard closePopup={togglePopup} cardLength={cards.length} cardManagementId={cardManagementId} redirectCard={redirectCard} redirectCardTrue={redirectCardTrue} getCardById={getCardById} />}
            {/* {popupCard && <PopupCard closePopup={this.openPopupCard} cardManagementId={cardManagementId} getCardById={this.getCardById} />} */}
            {sequencePopup && <SequencePopupCards closePopup={toggleSequencePopup} data={cards} getCardById={getCardById} />}
            {editPopup && <EditPopupCard closePopup={toggleEditPopup} getCardById={getCardById} cardNumber={cardNumber} cardId={cardId} cardManagementId={cardManagementId} cardTitle={cardTitle} cardDescription={cardDescription} cardPicture={cardPicture} />}
            {deletePopup && <DeletePopupCard closePopup={toggleDeletePopup} getCardById={getCardById} cardNumber={cardNumber} cardId={cardId} cardManagementId={cardManagementId} cardTitle={cardTitle} cardDescription={cardDescription} cardPicture={cardPicture} />}
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.4rem", marginLeft: "275px" }} >
                <div className="ml-3 create-learning-card-heading">Create Sceemlore</div>
                <Card className="ml-3 mt-4 mb-2 l-card-create">
                    <Row style={{ width: "100%" }}>
                        <Col md={6}>
                            <Form style={{ marginLeft: "5px", width: "100%" }} >
                                <div className="form-group">
                                    <select name="topicId" 
                                        style={{ marginTop: "5px" }} onChange={onChangeTopic} class="web-font form-control text-box-radius">
                                        <option value="">Select Topic</option>
                                        {allTopics.map((topic, id) => (
                                            <option value={topic.id}>{topic.topicName}</option>

                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <input type="text"
                                        className="web-font form-control text-box-radius"
                                        aria-describedby="text"
                                        name="title"
                                        value={title}
                                        disabled={redirectCard}
                                        placeholder="Title"
                                        onChange={e => setTitle(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <textarea name="description" value={description}
                                        placeholder="Description"
                                        className="web-font richTextField-c description-radius"
                                        style={{ overflowY: "none", resize: "none" }}
                                        disabled={redirectCard}
                                        onChange={(e) => setDescription(e.target.value)} />
                                </div>
                                {/* {((title !== "") && (description !== "")) ?
                                    <div><Button variant="default" disabled={addCard} onClick={addCardManagement} className="sendreview-btn mt-1 ml-4">Add Card</Button>
                                        {cards.length >= 1 ?
                                            <Link to="/carddetails"><Button variant="default" className="savelearncards-btn mt-1">Save</Button></Link> :
                                            <Link to="/carddetails"><Button variant="default" disabled={true} className="savelearncards-btn mt-1">Save</Button></Link>}

                                    </div> :
                                    <div> <Button variant="default" disabled={true} className="sendreview-btn mt-1 ml-3">Add Card</Button>
                                        {cards.length >= 1 ?
                                            <Link to="/carddetails"><Button variant="default" className="savelearncards-btn mt-1">Save</Button></Link>
                                            : <Link to="/carddetails"><Button variant="default" disabled={true} className="savelearncards-btn mt-1">Save</Button></Link>
                                        }</div>} */}
                                {cards.length == 0 ?
                                    <Button variant="default" onClick={openBox} disabled={true} className="sendreview-btn mt-1 ml-4">Publish</Button>
                                    : <Button variant="default" onClick={openBox} className="sendreview-btn mt-1 ml-4">Publish</Button>}
                                <div id="open" className="modal_new">
                                    <form className="modal-content-log">
                                        <div className="md-container">
                                            <p className="mt-2">Do you want to Publish?</p>
                                            <center><Button variant="default" onClick={publishCards} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                <Button variant="default" onClick={closeBox} className="deletebutton">No</Button></center>
                                        </div>
                                    </form>
                                </div>
                                {((topicId !== "") && (title !== "") && (description !== "")) ?
                                    <Link to="/carddetails"><Button variant="default" style={{ float: "left" }} onClick={addCardManagement} className="sendreview-btn mt-1">Save</Button></Link>
                                    :
                                    <Link to="/carddetails"><Button variant="default" disabled={true} style={{ float: "left" }} className="sendreview-btn mt-1">Save</Button></Link>}
                            </Form>
                        </Col>
                        <Col md={6}>
                            <Card className="ml-2 mt-1 l-card-create-height">
                                {redirectCard ?
                                    <div><div className="row no-gutters">
                                        {/* {cards.map((data, i) => (
                                        <Col md={3}>
                                            <Card className="_card" >
                                                <center className="mt-2"><img src={data.cardPicture} style={{ width: "50px",height:"50px" }} /></center>
                                                {data.cardTitle !== null && data.cardTitle > data.cardTitle.substring(0, 12) ?
                                                     <div className="l-card-title">{data.cardTitle.substring(0, 12)}...</div>
                                                    : <div className="l-card-title">{data.cardTitle}</div>}
                                            </Card><br /> */}
                                        {cards.map((data, i) => (
                                            <div className="col-md-3" style={{ padding: "2px" }}>
                                                <OverlayTrigger
                                                    placement="left"
                                                    trigger={overlay ?
                                                        ""
                                                        : "click"}

                                                    rootClose
                                                    overlay={(
                                                        <Popover className={editPopup || sequencePopup || deletePopup ? "hidden" : "index"}
                                                            id="test">
                                                            <Popover.Content >
                                                                <div className="mt-1 sequence-hover" onClick={openSequencePopUp}>Sequence</div>
                                                                <div className="mt-1 sequence-hover" onClick={openEditPopUp}>Edit</div>
                                                                <div className="mt-1 sequence-hover" onClick={openDeletePopUp}>Delete</div>
                                                            </Popover.Content>
                                                        </Popover>
                                                    )}>
                                                    {overlay ?
                                                        "" :
                                                        <Card className="_card" onClick={() => togglePopupData(data, data.id)}>
                                                            <center><img src={data.cardPicture} style={{ width: "50px", height: "50px" }} /></center>
                                                            {data.cardTitle !== null && data.cardTitle > data.cardTitle.substring(0, 18) ?
                                                                <div className="l-card-description">{data.cardTitle.substring(0, 18)}...</div>
                                                                : <div className="l-card-description">{data.cardTitle}</div>}
                                                        </Card>
                                                    }
                                                </OverlayTrigger><br />
                                            </div>
                                        ))}
                                    </div>
                                        <Button variant="default" onClick={addCardManagement} className="publish-btn mt-1 ml-3">Add Card</Button>
                                        {/* <Button variant="default" className="publish-btn mt-1 ml-3" onClick={publishCards}>Save Cards</Button> */}
                                    </div>
                                    :
                                    // <center className="center-text">No Cards Added</center>
                                    <div>
                                        <center className="center-text" style={{ marginTop: "30%" }}>No Cards Added</center>
                                        {((topicId !== "") && (title !== "") && (description !== "")) ?
                                            <Button variant="default" onClick={addCardManagement} className="publish-btn mt-1" style={{ float: "right" }}>Add Card</Button>
                                            :
                                            <Button variant="default" disabled="true" className="publish-btn mt-1 ml-3">Add Card</Button>}
                                    </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </div>
        </div >
    );
}

