import React, { useState, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Redirect, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function AllQuizzes() {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [allQuizzes, setAllQuizzes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getAllQuizzes()
    }, []);
    const getAllQuizzes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllQuizzes', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status == true) {
                    setAllQuizzes(response.data.quizData)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };


    if (isLoading) {
        return <div className="App"></div>;
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >

                <div> <Link to="/quiz/createquiz"><Button variant="default" className="createadv-btn">+ &nbsp;Create</Button></Link>
                </div>
                <div className="mt-3 active-add-h-text">Quizzes</div>
                {allQuizzes?.length === 0 ? <center><h4>You have not created quizzes</h4></center> :
                    <div class="mt-3 tableFixHead">
                        <table class="table-striped" >
                            <thead style={{ background: "#eee" }}>
                                <tr >
                                    <th scope="col" className="th-heading" style={{ width: "10%" }}>S No</th>
                                    <th scope="col" className="th-heading" style={{ width: "30%" }}>Topic</th>
                                    <th scope="col" className="th-heading" style={{ width: "12%" }}>Questions</th>
                                    <th scope="col" className="th-heading" style={{ width: "12%" }}>Created On</th>
                                    <th scope="col" className="th-heading" style={{ width: "12%" }}>Points</th>
                                    <th scope="col" className="th-heading" style={{ width: "15%" }}>Time taken (min)</th>
                                    <th scope="col" className="th-heading" ></th>
                                    <th scope="col" className="th-heading" ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {allQuizzes.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text" key={i} >{1 + i}</td>
                                        <td className="td-text" key={i} >{data.topicName}</td>
                                        <td className="td-text" key={i} >{data.questions}</td>
                                        <td className="td-text" key={i}>{data.createdAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                        <td className="td-text" key={i} >{data.totalpoints}</td>
                                        <td className="td-text" key={i} >{data.totalduration}</td>
                                        <td className="cursor" ><Link to={`/quiz/viewquiz/${data.id}`} className="poll-link"> <i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                                        <td className="cursor" ><Link to={`/quiz/editquiz/${data.id}`} className="poll-link"> <i class="fa fa-pencil" aria-hidden="true"></i></Link> </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
            </div>
        </div >
    );
};

