import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router, Redirect } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Purchases extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            transactions: [],
            picture: "",
            isLoading: true

        }
    }

    componentDidMount() {
        this.getTransactionById()
    }
    getTransactionById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            transactionId: this.props.match.params.id
        }
        var self = this
        axios.post(config.userUrl + 'user/getTransactionById', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({ transactions: response.data.getTransactionById, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    refundTransaction = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "id": parseInt(this.props.match.params.id),
            "refTransactionImage": this.state.picture,
            "status": 5,
            "refundedReason": "reason"
        }
       // console.log("pay",payload)
        var self = this;
        axios.post(config.userUrl + 'user/refundTransaction', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                 //   console.log('status')
                    self.setState({ redirect: true })
                }
            }).catch((error) => {
                console.log(error, "error")
            });
    };
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
       // console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
              //  console.log("res", res)
                this.setState({ picture: res })
            }
        }
    }
    render() {
        const { isLoading, transactions, imgRef,redirect, picture } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        if (redirect) {
            return <Redirect to="/purchases"/>;
        }
        // const updatedDate = transactions.map((i, idx) => {
        //     var a = new Date(i.updatedAt)
        //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        //     return updated;
        // })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <Link to="/verifytransactions"><Button variant="default" className="mt-2 trans-btn">Verify Transactions</Button></Link>
                    <div className="mt-3 active-add-h-text">Refund Transaction</div>
                    {transactions.length === 0 ? <center><h4>No Transactions.</h4></center> :
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr  >
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Transaction Id</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Plan Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Amount (&#8377;)</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Created On</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Status</th>

                                    </tr>
                                </thead>

                                <tbody>{transactions.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text">{data.transactionId}</td>
                                        <td className="td-text">{data.planName}</td>
                                        <td className="td-text">{data.planAmount}</td>
                                        <td className="td-text">{data.createdAt.split("T")[0].split("-").reverse().join("-")}</td>
                                        <td className="td-text">{data.status == 5 ? "Refunded" : (data.status == 2 ? "Completed" : (data.status == 3 ? "Accepted" : (data.status == 4 ? "Rejected" : (data.status == 6 ? "Refund Raised" : "Waiting"))))}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }
                    <div style={{marginTop:"-40vh"}}>
                                    <label htmlFor="upload-button">
                                        {picture != "" ? (
                                            <img src={picture} style={{ width: "250px", height: "250px" }} />
                                        ) : (
                                            <>
                                                <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                                <div className="up-load">Upload Image</div>
                                            </>
                                        )}
                                    </label>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        id="upload-button"
                                        name="picture"
                                        onChange={(e) => this.handleImage(e)}
                                    />
                    </div>
                    <div className="float_Right mr-3" >{picture !== "" ?
                        <Button onClick={this.refundTransaction} style={{position:"absolute",bottom:"1rem",right:"1rem"}} className="sendreview-btn mt-1 mr-4" variant="default">Submit</Button>
                        : <Button disabled={true} style={{position:"absolute",bottom:"1rem",right:"1rem"}} className="sendreview-btn mt-1 mr-4" variant="default">Submit</Button>}
                    </div>

                </div>
            </div >
        );
    };
}