import React, { useState, useEffect } from 'react';
import { Button, Form, Card, ProgressBar, Col, Row } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Link } from 'react-router-dom';
import SimpleCrypto from "simple-crypto-js";
import NavBar from '../NavBar/NavBar';
import Sidebar from '../Sidebar/Sidebar';

var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ViewQuiz = (props) => {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [topicName, setTopicName] = useState('');
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState(['', '', '', '']);
    const [correctAnswerIndex, setCorrectAnswerIndex] = useState(0);
    const [points, setPoints] = useState('');
    const [duration, setDuration] = useState('');

    useEffect(() => {
        getQuizById()
    }, []);

    useEffect(() => {
        if (questions.length > 0 && currentQuestionIndex >= 0 && currentQuestionIndex < questions.length) {
            setQuestion(questions[currentQuestionIndex]?.question || '');
            setOptions([...questions[currentQuestionIndex]?.options] || ['', '', '', '']);
            setCorrectAnswerIndex(questions[currentQuestionIndex]?.correctAnswerIndex || 0);
            setPoints(questions[currentQuestionIndex]?.points || '');
            setDuration(questions[currentQuestionIndex]?.duration || '');
        }
    }, [currentQuestionIndex, questions]);


    const getQuizById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + `user/getQuizById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
               //console.log(response);
                if (response.data.status == true) {
                   // console.log("getAllTopics");
                    setQuestions(response.data.quizData[0]?.questions)
                    setTopicName(response.data.topicDetails.topicName)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prevIndex => prevIndex - 1);
        }
    };

    const handleNext = () => {
        const newQuestions = [...questions];
        newQuestions[currentQuestionIndex] = { question, options, correctAnswerIndex,points,duration };
        setQuestions(newQuestions);

        if (currentQuestionIndex < newQuestions.length - 1) {
            setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        }
    };
    const goToFirstQuestion = () => {
        setCurrentQuestionIndex(0);
        const firstQuestionData = questions[0];
        setQuestion(firstQuestionData.question);
        setOptions([...firstQuestionData.options]);
        setCorrectAnswerIndex(firstQuestionData.correctAnswerIndex);
        setPoints(firstQuestionData.points);
        setDuration(firstQuestionData.duration);
      };
    
      const goToLastQuestion = () => {
        setCurrentQuestionIndex(questions.length - 1);
        const lastQuestionData = questions[questions.length - 1];
        setQuestion(lastQuestionData.question);
        setOptions([...lastQuestionData.options]);
        setCorrectAnswerIndex(lastQuestionData.correctAnswerIndex);
        setPoints(lastQuestionData.points);
        setDuration(lastQuestionData.duration);
      };
      const currentQuestionNumber = currentQuestionIndex + 1;
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                <h5>View Quiz</h5>
                <div className="poll-date">Topic</div> Question:{currentQuestionNumber}
                <div className="form-group" style={{ width: "72%" }}>
                    <input type="text"
                        className="web-font form-control form-bg"
                        aria-describedby="text"
                        value={topicName}
                        readOnly
                        name="topicName"
                        placeholder="Write Your Question"
                    />
                </div>
                <div>
                    <Card style={{ width: "72%" }}>
                        <div className="ml-3 mr-3 mt-3">
                            <input
                                type="text"
                                className="web-font form-control form-bg"
                                aria-describedby="text"
                                value={question}
                                name="question"
                                readOnly
                                placeholder="Write Your Question"
                            />
                            <div style={{ width: "98%" }}>
                                <div>
                                    {options.map((option, index) => (
                                        <div key={index} className="form-group mt-3 display">
                                            <input type="text" className="web-font form-control" placeholder={`Write Your Option ${index + 1}`} value={option} readOnly />
                                            <div className='ml-1 display'>
                                                <input
                                                    type="radio"
                                                    checked={correctAnswerIndex === index}
                                                    readOnly
                                                />
                                                <span className='mt-1 ml-1'>  Correct&nbsp;Answer</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Row style={{ width: "100%" }} className='no-gutters'>
                            <Col>
                                <div className='mr-1'>
                                <div className="poll-date">Points</div>
                                    <input
                                        type="number"
                                        className="web-font form-control form-bg"
                                        aria-describedby="number"
                                        value={points}
                                        name="points"       
                                        readOnly
                                        placeholder="Points"
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className='ml-1'>
                                <div className="poll-date">Duration in seconds</div>
                                    <input
                                        type="text"
                                        className="web-font form-control form-bg"
                                        aria-describedby="text"
                                        value={duration}
                                        readOnly
                                        name="duration"
                                        placeholder="Duration"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        </div>
                    </Card>

                    {/* <div style={{ display: 'flex', marginTop: "7px", justifyContent: 'space-between', width: "72%" }}>
                        <div onClick={handlePrevious} disabled={currentQuestionIndex === 0}>
                            <i className="fa" style={currentQuestionIndex === 0 ? { color: "lightgrey" } : { color: "black", cursor: "pointer" }}>&#xf060;</i>
                        </div>
                        <div onClick={handleNext}>
                            <i className="fa" style={currentQuestionIndex === questions.length - 1 ? { color: "lightgrey" } : { color: "black", cursor: "pointer" }}>&#xf061;</i>
                        </div>
                    </div> */}
                        <div style={{ display: 'flex', marginTop: "7px", justifyContent: 'center', gap: "15px", width: "72%" }}>
                        <div onClick={handlePrevious} disabled={currentQuestionIndex === 0} className=' bg-fa'>
                            <center>  <i className="fa fa-lg mt-2" style={currentQuestionIndex === 0 ? { color: "lightgrey" } : { color: "black", cursor: "pointer" }}>&#xf060;</i></center>
                        </div>
                        <div onClick={goToFirstQuestion} className='bg-fa'>
                            <center>    <i class="fa fa-step-backward mt-2 fa-lg" aria-hidden="true"></i></center>
                        </div>
                        <div onClick={goToLastQuestion} className='bg-fa'>
                            <center>    <i class="fa fa-step-forward mt-2 fa-lg" aria-hidden="true"></i></center>
                        </div>
                        <div onClick={handleNext} className=' bg-fa'>
                            <center>  <i className="fa fa-lg mt-2" style={currentQuestionIndex === questions.length - 1 ? { color: "lightgrey" } : { color: "black", cursor: "pointer" }}>&#xf061;</i></center>
                        </div>
                    </div>


                    <Link to="/quiz" className="float_right_poll"><Button variant="default" className="cancelreview-btn mt-1 ml-4">Back</Button></Link>

                </div>
            </div>
        </div>
    );
};

export default ViewQuiz;
