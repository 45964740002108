import React, { useState, useEffect } from 'react';
import { Button, Form, Card, ProgressBar, Col, Row } from "react-bootstrap";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import NavBar from '../NavBar/NavBar';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CreateQuiz = () => {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState(['', '', '', '']);
    const [correctAnswerIndex, setCorrectAnswerIndex] = useState(0);
    const [redirect, setRedirect] = useState(false);
    const [points, setPoints] = useState();
    const [duration, setDuration] = useState();

    useEffect(() => {
        getAllQuizTopics()
    }, []);
    const getAllQuizTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllQuizTopics', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    setTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const handleTopicChange = (e) => {
        setSelectedTopic(e.target.value);
    };

    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleOptionChange = (e, index) => {
        const newOptions = [...options];
        newOptions[index] = e.target.value;
        setOptions(newOptions);
    };

    const handleCorrectAnswerChange = (index) => {
        setCorrectAnswerIndex(index);
    };
    const handlePointsChange = (e) => {
        setPoints(e.target.value);
    };
    const handleDurationChange = (e) => {
        setDuration(e.target.value);
    };
    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            const newQuestions = [...questions];
            newQuestions[currentQuestionIndex] = { question, options, correctAnswerIndex, points, duration };
            setQuestions(newQuestions);

            setCurrentQuestionIndex(currentQuestionIndex - 1);

            const prevQuestionData = newQuestions[currentQuestionIndex - 1];
            setQuestion(prevQuestionData.question);
            setOptions([...prevQuestionData.options]);
            setCorrectAnswerIndex(prevQuestionData.correctAnswerIndex);
            setPoints(prevQuestionData.points);
            setDuration(prevQuestionData.duration);
        }
    };

    const handleNext = () => {
        const newQuestions = [...questions];
        newQuestions[currentQuestionIndex] = { question, options, correctAnswerIndex, points, duration };
        setQuestions(newQuestions);

        if (currentQuestionIndex < newQuestions.length - 1) {
            const nextQuestionData = newQuestions[currentQuestionIndex + 1];
            setQuestion(nextQuestionData.question);
            setOptions([...nextQuestionData.options]);
            setCorrectAnswerIndex(nextQuestionData.correctAnswerIndex);
            setPoints(nextQuestionData.points);
            setDuration(nextQuestionData.duration);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setQuestions([...newQuestions, { question: '', options: ['', '', '', ''], correctAnswerIndex: 0, points: '', duration: '' }]);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setQuestion('');
            setOptions(['', '', '', '']);
            setCorrectAnswerIndex(0);
            setPoints('');
            setDuration('');
        }
    };

    const handleSave = () => {
        const quizData = {
            topic: selectedTopic,
            questions,
        };
        // console.log('Saved Quiz Data:', quizData);
    };

    const createQuiz = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        let newQuestions = [...questions];
        newQuestions[currentQuestionIndex] = { question, options, correctAnswerIndex, points, duration };
        setQuestions(newQuestions);
        newQuestions = newQuestions.filter(q =>
            q.question.trim() !== '' &&
            q.options.length > 0 &&
            q.correctAnswerIndex !== undefined &&
            q.points?.trim() !== '' &&
            q.duration?.trim() !== ''
        );
        setQuestions(newQuestions);
        var payload = {
            "topicId": selectedTopic,
            "questions": JSON.stringify(newQuestions),
            "status": 1,
        }
        console.log("payload", payload, questions)
        axios.post(config.userUrl + 'user/createQuiz', payload, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    // console.log("success", response.data.message, payload);
                    setRedirect(true)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    if (redirect) {
        return <Redirect to="/quiz" />
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                <h5>Create Quiz</h5>
                <div className="poll-date">Topic</div>
                <div className="form-group" style={{ width: "72%" }}>
                    <select className="web-font form-control" name="topic" onChange={handleTopicChange} >
                        <option className="web-font" value="">Select Topic</option>
                        {topics.map((topic, id) => (
                            <option className="web-font" value={topic.id}>{topic.topicName}</option>

                        ))}
                    </select>
                </div>
                <div>
                    <Card style={{ width: "72%" }}>
                        <div className="ml-3 mr-3 mt-3">
                            <input type="text"
                                className="web-font form-control form-bg"
                                aria-describedby="text"
                                value={question}
                                name="question"
                                placeholder="Write Your Question"
                                onChange={handleQuestionChange}
                            />
                            <div style={{ width: "98%" }} >
                                <div >
                                    {options.map((option, index) => (
                                        <div key={index} className="form-group mt-3 display">
                                            <input type="text" className="web-font form-control" placeholder={`Write Your Option ${index + 1}`} value={option} onChange={(e) => handleOptionChange(e, index)} />
                                            <div className='ml-1 display'>

                                                <input
                                                    type="radio"
                                                    checked={correctAnswerIndex === index}
                                                    onChange={() => handleCorrectAnswerChange(index)}
                                                />
                                                <span className='mt-1 ml-1'>  Correct&nbsp;Answer</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Row style={{ width: "100%" }} className='no-gutters'>
                                <Col>
                                    <div className='mr-1'>
                                    <div className="poll-date">Points</div>
                                        <input
                                            type="number"
                                            className="web-font form-control form-bg"
                                            aria-describedby="number"
                                            value={points}
                                            name="points"
                                            placeholder="Points"
                                            onChange={handlePointsChange}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className='ml-1'>
                                    <div className="poll-date">Duration in seconds</div>
                                        <input
                                            type="text"
                                            className="web-font form-control form-bg"
                                            aria-describedby="text"
                                            value={duration}
                                            name="duration"
                                            placeholder="Duration in seconds"
                                            onChange={handleDurationChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <br />
                        </div>
                    </Card>

                    <div style={{ display: 'flex', marginTop: "7px", justifyContent: 'space-between', width: "72%" }}>
                        <div onClick={handlePrevious} disabled={currentQuestionIndex === 0}>
                            <i class="fa" style={currentQuestionIndex === 0 ? { color: "lightgrey" } : { color: "black", cursor: "pointer" }}>&#xf060;</i>
                        </div>
                        <div onClick={handleNext}> <i class="fa" style={{ color: "black", cursor: "pointer" }}>&#xf061;</i></div>
                    </div>
                    {((selectedTopic)) ?
                        <div className="float_right_poll mt-4"> <Button variant="default" onClick={createQuiz} className="sendreview-btn ml-4">Submit </Button>
                            <Link to="/quiz">
                                <Button variant="default" className="cancelreview-btn ml-4">Back </Button>
                            </Link></div>
                        :
                        <div className="float_right_poll mt-4"> <Button variant="default" disabled="true" onClick={createQuiz} className="sendreview-btn ml-4">Submit </Button>
                            <Link to="/quiz">
                                <Button variant="default" className="cancelreview-btn ml-4">Back </Button>
                            </Link></div>
                    }
                    {/* <div className="float_right_poll mt-4">
                        <Button variant="default" className="sendreview-btn ml-4" onClick={handleEdit}>Edit </Button>
                        <Link to="/quiz">
                            <Button variant="default" className="cancelreview-btn ml-4">Back </Button>
                        </Link>
                    </div> */}

                </div>
            </div>
        </div>

    );
};

export default CreateQuiz;