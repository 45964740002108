import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../Poll/Poll.css';
import edit from '../images/edit.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class SavedArticles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            posts: [],
            articleImages: [],
            selPost: 0,
            isLoading: true

        }
        this.deletePost = this.deletePost.bind(this);
    }
    componentDidMount() {
       // console.log("poll", this.state.poll)
        if (sessionStorage.getItem('role') === "admin") {
            this.getAdminSavedPosts();
        } else {
            this.getSavedPosts();
        }
    }
    getSavedPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getSavedPosts', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        posts: response.data.posts,
                        isLoading: false
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };
    getAdminSavedPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAdminSavedPosts', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        posts: response.data.posts,
                        isLoading: false
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };
    deletePost = () => {
        var position = this.state.selPost;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "postId": position
        }
       // console.log("payload", position, payload)
        var self = this
        axios.post(config.userUrl + 'user/deletePost', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    document.getElementById('modify').style.display = 'none'
                    self.getSavedPosts();
                  //  console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    showLogout = (id) => {
        this.setState({ selPost: id.target.getAttribute("data-position") })
        document.getElementById('modify').style.display = 'flex'

    }
    showLogoutNone = (id) => {
        document.getElementById('modify').style.display = 'none'
    }

    render() {

        const { isLoading } = this.state
        const b = this.state.posts.map((i, idx) => {
            var a = new Date(i.updatedAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;

        })
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <div className="mt-3 active-add-h-text">Saved Articles</div>
                    {this.state.posts.length === 0 ? <center><h4>You have no Saved Articles.</h4></center> :
                        <div class="mt-3 tableFixHead-saved">
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr>
                                        <th scope="col" className="th-heading" style={{ width: "40%" }}>Title</th>
                                        {sessionStorage.getItem('role') === "admin" ? <th scope="col" className="th-heading" style={{ width: "15%" }}>Name</th> : <th></th>}
                                        <th scope="col" className="th-heading" style={{ width: "40%" }}>Topic</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Updated On</th>
                                        <th scope="col" ></th>
                                        <th scope="col" ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.posts.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text">{data.title}</td>
                                            {sessionStorage.getItem('role') === "admin" ? <td scope="col" className="td-text">{data.name}</td> : <td></td>}
                                            <td className="td-text">{data.topicName}</td>
                                            <td className="td-text">{b[i]}</td>
                                            <td className="cursor" ><a href={`/articles/editarticle/${data.id}`} className="border-none"> <i class="fa fa-pencil left" aria-hidden="true"></i></a> </td>
                                            {/* <td className="cursor" ><Link to={`/articles/editarticle/${data.id}`}> <img src={edit} className="edit" /></Link> </td> */}
                                            <td td className="cursor" > <i key={i} data-position={data.id} onClick={this.showLogout} class="fa fa-trash" aria-hidden="true"></i></td>
                                            <div id="modify" className="modal_new">
                                                <form className="modal-content-log">
                                                    <div className="md-container">
                                                        <span class="_close" onClick={this.showLogoutNone}>&times;</span>
                                                        <p className="mt-2">Do you want to</p>
                                                        <center ><Button id="modify1" data-position1={data.id} variant="default" onClick={this.deletePost} className="modifybutton">Delete</Button>&nbsp; &nbsp;
                                                            <Button variant="default" onClick={this.showLogoutNone} className="stopbutton">Cancel</Button></center>
                                                    </div>
                                                </form>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }

                </div>
            </div >
        );
    };
}

