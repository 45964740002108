import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import Chart from 'chart.js/auto';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export class UsersGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            usersData: [],
            selectedValue: 11
        }
    }

    componentDidMount() {
        this.getUsersAddedByMonth()
    }
    subtractMonths = (val, date = new Date()) => {
        date.setMonth(date.getMonth() - val);
      //  console.log("date", date)
        return date;
    }
    onChange = (e) => {
        this.setState({ selectedValue: e.target.value })
        this.getUsersAddedByMonth(e.target.value)
    }
    getUsersAddedByMonth = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        if (val == undefined) {
            val = 11
        } else {
            val = val
        }
        var startD = this.subtractMonths(parseInt(val), new Date()).getFullYear() + "-" + parseInt(this.subtractMonths(parseInt(val), new Date()).getMonth() + 1) + "-" + this.subtractMonths(parseInt(val), new Date()).getDate();
        var endD = new Date().getFullYear() + "-" + parseInt(new Date().getMonth() + 1) + "-" + new Date().getDate();
        var payload = {
            "startDate": startD,
            "endDate": endD
        }
      //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/getUsersAddedByMonth', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        usersData: response.data.usersData,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { usersData, selectedValue } = this.state;

        const users = {
            labels: usersData.map((data) => data.month),
            datasets: [
                {
                    label: 'Users',
                    data: usersData.map((data) => data.count),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.8)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1,
                    fill: {
                        target: 'origin',
                        above: 'rgb(255, 0, 0,0.2)',   // Area will be red above the origin
                        below: '#000000'    // And blue below the origin
                    }
                }
            ]
        }
        return (
            <div>
                {/* <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{minHeight:"175px"}}>
                    <div style={{ display: "flex", flexDirection: "column", rowGap: "20px",minHeight:"100%" }}>
                        <div style={{ alignItems: "center", display: "flex" }}>
                            <center><div className="graph-heading-T">Users</div></center>
                            <form style={{ position: "absolute", right: "10px", marginTop: "-2px", width: "35%" }}> <select name="selectedValue" value={selectedValue} onChange={this.onChange} class="mt-2 select-font form-drop form-control">
                                <option value={2}>3 Months</option>
                                <option value={5}>6 Months</option>
                                <option value={11}>1 Year</option>
                            </select> </form>
                        </div>
                        <div style={{ maxWidth: "25vw", minHeight:"18vh" }}> */}
                <Card className="mr-1 bar-graph-card box-shad" style={{background:"rgba(255, 99, 132, 0.1)"}}>
                            <center><div className="graph-heading-T">Users</div></center>
                            <form style={{ position: "absolute", marginTop:"-4px", right: "10px", width: "35%"}}> <select style={{background:"rgba(255, 99, 132, 0.01)"}} name="selectedValue" value={selectedValue} onChange={this.onChange} class="mt-2 select-font form-drop form-control">
                                <option value={2}>3 Months</option>
                                <option value={5}>6 Months</option>
                                <option value={11}>1 Year</option>
                            </select> </form>
                            <div style={{marginTop:"-4px",height:"158px",width:"auto"}}>
                            <Line data={users}
                                options={{
                                    responsive: true,
                                    elements: {
                                        line: {
                                            tension: 0.5
                                        }
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                display: false,
                                            }
                                        },
                                        y: {
                                            ticks: {
                                                precision: 0
                                            },
                                            beginAtZero: true,
                                            grid: {
                                                display: false,
                                            },
                                        },
                                    },
                                    plugins: {
                                        title: {
                                            display: false,
                                            text: "Users"
                                        },
                                        legend: {
                                            display: false
                                        },
                                    }
                                }
                                } />
                        </div>
                </Card>
            </div>
        );
    };
}