import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Advertisement.css';
import advertisementData from './Advertisement.json';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewAllStatusAdds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            adv: [],
            redirect: false,
            selPost: 0,
            isLoading: true
        }
    }
    componentDidMount() {
        this.getAdvertisementDetail();
    }

    getAdvertisementDetail = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAdvertisementDetail', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        adv: response.data.advertisement,
                        isLoading: false
                    })
                 //   console.log("getSavedPosts", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    // deleteCampaign = () => {
    //     var position = this.state.selPost;
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": `Bearer ${this.state.token}`,
    //         "applicationType": "web"
    //     }
    //     var payload = {
    //         "campaignId": position
    //     }
    //     console.log("payload", position, payload)
    //     var self = this
    //     axios.post(config.userUrl + 'user/deleteCampaign', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status == true) {
    //                 document.getElementById('modify').style.display = 'none'
    //                 console.log("success", response.data.message, payload);
    //                 self.setState({
    //                     adv: response.data.savedCampaigns
    //                 })
    //             } else {
    //                 console.log("Error", payload);
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("error", error, payload);
    //         });

    // };
    showLogout = (id) => {
        this.setState({ selPost: id.target.getAttribute("data-position") })
        document.getElementById('modify').style.display = 'flex'

    }
    showLogoutNone = (id) => {
        document.getElementById('modify').style.display = 'none'
    }

    // handleChange = (e) => {
    //     const id = e.target.id;
    //     const elementsIndex = this.state.adv.findIndex(element => element.id == id)
    //     let newArray = [...this.state.adv]
    //     newArray[elementsIndex] = { ...newArray[elementsIndex], value: !newArray[elementsIndex].value }
    //     this.setState({ adv: newArray });
    //     console.log("hi", newArray)
    // }

    render() {

        const { isLoading, adv } = this.state
        if (isLoading) {
            return <div className="App"></div>;
        }
        const updatedDate = this.state.adv.map((i, idx) => {
            var a = new Date(i.updatedAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <div className="mt-3 active-add-h-text">Advertisements</div>
                    <div>
                        {adv.length === 0 ? <center><h4>You have no Advertisements.</h4></center> :
                            <div class="mt-3 tableFixHead-saved" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr >
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "55%" }}>Campain Name</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Status</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Updated At</th>
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}></th>
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.adv.map((data, i) => (
                                            <tr className={i / 2 === 0 ? "" : "green"}>
                                                <td className="td-text">&nbsp;&nbsp;{i + 1}</td>
                                                <td className="td-text">{data.campaignName}</td>
                                                <td className="td-text">{data.status==1?"Active":(data.status==3?"Need Information":(data.status==4?"Published":"Rejected"))}</td>
                                                <td className="td-text">{updatedDate[i]}</td>
                                                <td className="td-text"><a href={`/advertisement/viewuseradd/${data.id}`} className="border-none"><i class="fa fa-eye left" aria-hidden="true"></i></a></td>
                                                <td className="td-text">{data.status==1?<a href={`/advertisement/publishuseradvertisement/${data.id}`} className="border-none"><i class="fa fa-plus left" aria-hidden="true"></i></a>:null}</td>
                                                {/* <td className="cursor" ><i key={i} data-position={data.id} onClick={this.showLogout} class="fa fa-trash" aria-hidden="true"></i></td>
                                                <div id="modify" className="modal_new">
                                                    <form className="modal-content-log">
                                                        <div className="md-container">
                                                            <span class="_close" onClick={this.showLogoutNone}>&times;</span>
                                                            <p className="mt-2">Do you want to</p>
                                                            <center ><Button id="modify1" data-position1={data.id} variant="default" onClick={this.deleteCampaign} className="modifybutton">Delete</Button>&nbsp; &nbsp;
                                                                <Button variant="default" onClick={this.showLogoutNone} className="stopbutton">Cancel</Button></center>
                                                        </div>
                                                    </form>
                                                </div> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>

                </div >
            </div >
        );
    };
}

