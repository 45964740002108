import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Poll.css';
import pollData from './Poll.json'
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewPoll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            allTopics: [],
            topic: "",
            startDate: "",
            endDate: "",
            options: ["", "", "", ""],
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            pollQuestion: "",
            startDate: "",
            endDate: "",
            isLoading: true
        }
    }
    componentDidMount() {
        this.getPollById();
    }
    getPollById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
       // console.log("token", this.state.token)
        var self = this
        axios.get(config.userUrl + `user/getPollById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                   // console.log("success!!");
                    self.setState({
                        pollQuestion: response.data.pollQuestion,
                        options: response.data.pollData,
                        topic: response.data.topicName,
                        startDate: response.data.pollDetails[0].startDate,
                        endDate: response.data.pollDetails[0].endDate,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { startDate, endDate, isLoading } = this.state
        var a = new Date(startDate)
        var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
        var b = new Date(endDate)
        var endD = parseInt(b.getMonth() + 1) + "-" + b.getDate() + "-" + b.getFullYear();

        const selectedStartDate = new Date(start)
        const selectedEndDate = new Date(endD)
       //console.log("start", startDate)

        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5 className="font-heading-web">View Poll</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <div className="poll-date">Topic</div>
                        <div className="form-group" style={{ width: "72%" }}>
                            <select name="topic" readOnly onChange={this.onChangeTopic} onBlur={this.handleBlur} class="web-font form-control">
                                <option value="">{this.state.topic}</option>
                            </select>
                        </div>
                        <Row style={{ width: "74.5%" }}>
                            <Col md={6}>
                                <div className="poll-date">Start Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={selectedStartDate}
                                        dateFormat="dd-MM-yyyy"
                                        minDate={new Date()}
                                        readOnly
                                        filterDate={(date) => date.getDay() === 1}
                                    />
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className="poll-date">End Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={selectedEndDate}
                                        dateFormat="dd-MM-yyyy"
                                        readOnly
                                        minDate={new Date(Date.now() + 5 * 86400000)}
                                        filterDate={(date) => date.getDay() === 5}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Card style={{ width: "72%" }}>
                            <div className="ml-3 mt-3">
                                <div >
                                    <div className="form-group mt-1" style={{ width: "98%" }}>
                                        <input type="text"
                                            className="web-font form-control form-bg"
                                            aria-describedby="text"
                                            value={this.state.pollQuestion}
                                            row="3"
                                            readOnly
                                            name="pollQuestion"
                                            placeholder="Write Your Question"
                                        />
                                    </div>
                                    <div style={{ width: "98%" }} >

                                        {this.state.options.map((option, idx) => {
                                            return (
                                                <div className="web-font form-group mt-3 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" readOnly className="web-font form-control"
                                                        value={option.option}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Link to="/poll" className="float_right_poll"><Button variant="default" className="cancelreview-btn mt-1 ml-4">Back</Button></Link>

                    </Form><br /><br />
                </div >
            </div >
        );
    };
}




