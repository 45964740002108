import React, { Component } from "react";
import { Button, Form, Navbar, Modal, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import EditFeature from "./EditFeature";
import CreateSubscription from "./CreateSubscription";
import UpdateSubscription from "./UpdateSubscription";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class EditFeatureAndSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            edit: true,
            popup: false,
            subscriptions: this.props.data,
            features: this.props.features,
            newItems: []

        }
    }
    componentDidMount() {
        //console.log("props", this.props.features)
    }
    editPlanBaseFeature = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = this.state.newItems
        //console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'user/editPlanBaseFeature', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    self.props.closePopup()
                    self.props.getPlanBaseFeature()
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    checkEachfeature = (e, item) => {
        const { features } = this.state
        let newItems = []
        var newArr = [];
        const updated = features.map(feature => (feature.id === item) ?
            { ...feature, status: !feature.status } : feature)
        //console.log("Arr", updated)
        this.setState({ features: updated })
        for (var i = 0; i < updated.length; i++) {
            newArr[i] = ({ planId: updated[i].planId, featureId: updated[i].id, status: updated[i].status })
        }
        this.setState({ newItems: newArr })
      //  console.log("newItems", newArr)
    }
    render() {
        const { edit, subscriptions, features, popup } = this.state;

        return (
            <div>
                <Modal
                    size="sm"
                    show={edit}
                    onHide={this.props.closePopup}
                    centered
                >
                    <Modal.Header closeButton>
                        <h5 style={{ color: "#FF942C", fontFamily: "poppins" }}>Edit</h5>
                    </Modal.Header>
                    <Modal.Body style={{ marginLeft: "10px", marginRight: "10px" }}>
                        <div>
                            <div className="pName">{subscriptions.planName}   <span className="pDuration">{subscriptions.planDuration}</span></div>
                        </div>
                        {features.map((item, i) => (
                            <div key={i}>
                                <label><input type="checkbox" className="mt-2 mb-2" checked={item.status} value={item.id}
                                    onChange={(e) => this.checkEachfeature(e, item.id)} /></label><span className="ml-1">{item.feature}</span><br/>
                                    <div style={{marginTop:"-10px",fontSize:"12px"}}>{item.subFeature != null? <div>{item.subFeature.map((data,i)=>(<div>{data},</div>))}</div>: ""}</div></div>
                        ))}
                        <div>
                            <Button variant="default" onClick={() => this.editPlanBaseFeature()} className="sendreview-btn mt-3 ml-2">Update</Button>
                            <Button variant="default" onClick={this.props.closePopup} className="close-btn-popup mt-3">Close</Button></div>

                    </Modal.Body>
                </Modal>
                {/* {subscriptions.map((data, i) => (
                            <Card style={{ width: "95%", padding: "10px" }}>
                                <div style={{ display: "flex" }}>
                                    <div>{data.planName}</div>
                                    <div className="ml-4">{data.planDuration}</div>
                                </div>
                                {data.features.map((item, i) => (
                                    <div key={i}><input type="checkbox" checked={item.status} value={item.id+data.planId}
                                        onChange={(e) => this.checkEachfeature(e, data)} />{item.feature}</div>
                                ))}
                                <div><Button variant="default" onClick={this.editPlanBaseFeature} className="sendreview-btn mt-1 ml-2">Submit</Button></div>
                            </Card>
                        ))} */}
            </div >
        );
    };
}