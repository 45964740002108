import React, { Component } from "react";
import { Button, Form, Table, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class SavedTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            terms: [],
            selTerm:0,
            redirect: false,
            isLoading: true
        }
    }
    componentDidMount() {
       // console.log("term", this.state.terms)
        this.getSavedTerms();
    }
    getSavedTerms = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getSavedTerms', { headers: headers })
            .then(function (response) {
            //    console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        terms: response.data.terms,
                        isLoading: false
                    })
                 //   console.log("getSavedTerms", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    showLogout = (id) => {
        this.setState({ selTerm: id.target.getAttribute("data-position") })
        document.getElementById('modify').style.display = 'flex'

    }
    showLogoutNone = (id) => {
        document.getElementById('modify').style.display = 'none'
    }
    deleteTerm = () => {
        var position = this.state.selTerm;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "termId": position
        }
        console.log("payload", position, payload)
        var self = this
        axios.post(config.userUrl + 'user/deleteTerm', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    document.getElementById('modify').style.display = 'none'
                    self.getSavedTerms();
                    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    render() {
        const { isLoading, terms } = this.state
        const b = terms.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    {terms.length === 0 ? <center><h4>No Saved Terms</h4></center> :
                        <div>
                            <div className="mt-3 active-add-h-text">Saved Terms</div>
                            <div class="mt-3 tableFixHead" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr>
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "10%" }}>Term</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Definition</th>
                                            <th scope="col" className="th-heading" style={{ width: "30%" }}>Usage</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Created At</th>
                                            <th></th>
                                            <th></th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {terms.map((data, i) => (
                                            <tr className={i / 2 === 0 ? "" : "green"}>
                                                <td className="td-text" >&nbsp;&nbsp;{i + 1}</td>
                                                <td className="td-text">{data.term}</td>
                                                <td className="td-text">{data.definition}</td>
                                                <td className="td-text">{data.usage}</td>
                                                <td className="td-text">{b[i]}</td>
                                                <td className="cursor"><Link to={`/term/editterm/${data.id}`} className="poll-link"> <i class="fa fa-pencil left" aria-hidden="true"></i></Link> </td>
                                                <td className="cursor" ><i key={i} data-position={data.id} onClick={this.showLogout} class="fa fa-trash" aria-hidden="true"></i></td>
                                                <div id="modify" className="modal_new">
                                                    <form className="modal-content-log">
                                                        <div className="md-container">
                                                            <span class="_close" onClick={this.showLogoutNone}>&times;</span>
                                                            <p className="mt-2">Do you want to</p>
                                                            <center ><Button id="modify1" data-position1={data.id} variant="default" onClick={this.deleteTerm} className="modifybutton">Delete</Button>&nbsp; &nbsp;
                                                                <Button variant="default" onClick={this.showLogoutNone} className="stopbutton">Cancel</Button></center>
                                                        </div>
                                                    </form>
                                                </div>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div >
        );
    };
}

