import React, { useState, useRef, useEffect } from "react";
import { Button, Form, OverlayTrigger, Tooltip, Col, Row, Card } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import addimage from '../images/addimage.svg';
import './WriteArticle.css';
import axios from 'axios';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function EditArticle(props) {
    const [allTopics, setAllTopics] = useState([]);
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [image, setImage] = useState("")
    const [topic, setTopic] = useState("")
    const [title, setTitle] = useState("")
    const [maxLength, setMaxLength] = useState(500)
    const [content, setContent] = useState("")
    const [articleImages, setArticleImages] = useState([]);
    const [redirect, setRedirect] = useState(false)
    const [savedArticles, setSavedArticles] = useState(false)
    const [review, setReview] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [update, setUpdate] = useState(false)
    const [htmlName, setHtmlName] = useState('');
    const [topicId, setTopicId] = useState("")
    const refText = useRef('myTextarea');
    const [showPopup, setShowPopup] = useState(false)
    const [question, setQuestion] = useState('')
    const [option1, setOption1] = useState('')
    const [option2, setOption2] = useState('')
    const [option3, setOption3] = useState('')
    const [option4, setOption4] = useState('')
    const [error, setError] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [wLength, setWLength] = useState(750)

    const modules = {
        toolbar: [
            [{ 'header': '1' }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }]
        ]
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "list",
        "bullet",

    ];
    const onChange = (e) => {
        if (e.target.name === "topicId") {
            setTopicId(parseInt(e.target.value))
        } else {
            setTopic(e.target.value)
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "topicId": parseInt(e.target.value)
        }
        axios.post(config.userUrl + 'user/getAllArticlesUrlByTopicId', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                  //  console.log("getAllArticlesUrlByTopicId");
                    setArticleImages(response.data.getAllArticlesUrlByTopicId)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    }
    function countWords(content) {
        const arr = content.split(' ');
        return arr.filter(word => word !== '').length;
    }
    const fileHandler = (e) => {
        setImage(e.target.files[0])
    }
    const goBack = () => {
        props.history.goBack()
    }
    useEffect(() => {
        getAllTopics();
        getPostById();
    }, []);
    const getAllTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllTopics', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                   // console.log("getAllTopics");
                    setAllTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getPostById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + `user/getPostById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    setTopicId(response.data.topicDetails.id)
                    setTopic(response.data.topicDetails.topicName)
                    setTitle(response.data.post[0].title)
                    setImage(response.data.post[0].image)
                    setContent(response.data.post[0].content)
                    setQuestion(response.data.post[0]?.question)
                    setOption1(response.data.postQuestionData[0]?.option)
                    setOption2(response.data.postQuestionData[1]?.option)
                    setOption3(response.data.postQuestionData[2]?.option)
                    setOption4(response.data.postQuestionData[3]?.option)
                    setUserName(response.data.userName)
                    setIsLoading(false)
                    // parseMarkdown(response.data.post[0].content)
                    var payload = {
                        "topicId": response.data.topicDetails.id
                    }
                    axios.post(config.userUrl + 'user/getAllArticlesUrlByTopicId', payload, { headers: headers })
                        .then(function (response) {
                          //  console.log(response);
                            if (response.data.status == true) {
                               // console.log("getAllArticlesUrlByTopicId");
                                setArticleImages(response.data.getAllArticlesUrlByTopicId)
                            }
                        })
                        .catch(function (error) {
                            console.log("Error");
                        });
                    console.log("success", response.data);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const editPost = data => {
       // console.log("edit", data)
        // if (question && (!option1 || !option2 || !option3 || !option4)) {
        //     setError("Please add Options")
        //     console.log("eroor12345", question, option1, option2, option3, option4)
        //     return
        // }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "postId": props.match.params.id,
            "topicId": topicId,
            "title": title,
            "imageUrl": image,
            "content": content,
            "sendToReview": data,
            "question": question,
            "options": [option1, option2, option3, option4]
        }
       // console.log("payload", topic, topicId, payload)
        axios.post(config.userUrl + 'user/editPost', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    if (data === true) {
                        setRedirect(true)
                    } else {
                        setSavedArticles(true)
                    }
                   // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const getAllArticlesUrlByTopicId = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "topicId": topicId
        }
        axios.post(config.userUrl + 'user/getAllArticlesUrlByTopicId', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                   // console.log("getAllArticlesUrlByTopicId");
                    setArticleImages(response.data.getAllArticlesUrlByTopicId)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    function handleB(e) {
        e.preventDefault();
        let textVal = refText.current;
        let cursorStart = textVal.selectionStart;
        let cursorEnd = textVal.selectionEnd;
        var selectedText = content.substring(cursorStart, cursorEnd)
        var updatedText = content.replace(selectedText, "*" + selectedText + "*");
        setContent(updatedText);
        parseMarkdown(updatedText);
        refText.current.focus()
    }

    function handleI(e) {
        e.preventDefault();
        let textVal = refText.current;
        let cursorStart = textVal.selectionStart;
        let cursorEnd = textVal.selectionEnd;
        var selectedText = content.substring(cursorStart, cursorEnd)
        var updatedText = content.replace(selectedText, "%" + selectedText + "%");
        setContent(updatedText);
        parseMarkdown(updatedText);
        refText.current.focus()
    }
    function handleU(e) {
        e.preventDefault();
        let textVal = refText.current;
        let cursorStart = textVal.selectionStart;
        let cursorEnd = textVal.selectionEnd;
        var selectedText = content.substring(cursorStart, cursorEnd)
        var updatedText = content.replace(selectedText, "_" + selectedText + "_");
        setContent(updatedText);
        parseMarkdown(updatedText);
        refText.current.focus()
    }

    function handleH(e) {
        e.preventDefault();
        let textVal = refText.current;
        var allTextLines = textVal.value.substring(0).split("\n");
        var textLines = textVal.value.substring(0, textVal.selectionStart).split("\n");
        var currentLineNumber = textLines.length;
       // console.log("currentLineNumber", currentLineNumber)
        var currentTextLine = allTextLines[currentLineNumber - 1];
        var updatedTextLine
        if (currentTextLine.length === 0)
            updatedTextLine = content.concat("#");
        else
            updatedTextLine = content.replace(currentTextLine, "#" + currentTextLine);
        setContent(updatedTextLine);
        parseMarkdown(updatedTextLine);
        refText.current.focus()
    }
    function handleL(e) {
        e.preventDefault();
        let textVal = refText.current;
        var allTextLines = textVal.value.substring(0).split("\n");
        var textLines = textVal.value.substring(0, textVal.selectionStart).split("\n");
        var currentLineNumber = textLines.length;
        //console.log("currentLineNumber", currentLineNumber)
        var currentTextLine = allTextLines[currentLineNumber - 1];
        //console.log("currentTextLine", currentTextLine, currentTextLine.length);
        var updatedTextLine
        if (currentTextLine.length === 0)
            updatedTextLine = content.concat(">");
        else
            updatedTextLine = content.replace(currentTextLine, ">" + currentTextLine);
        setContent(updatedTextLine);
        parseMarkdown(updatedTextLine);
        refText.current.focus()
    }

    function parseMarkdown(markdownText) {
        var htmlText = markdownText
            .replace(/^#(.*$)/gim, '<h6 class="heading-color"><b>$1</b></h6>')
            .replace(/^>(.*$)/gim, '<li>$1</li>')
            .replace(/\_(.+?)\_/gim, '<u>$1</u>')
            .replace(/\%(.+?)\%/gim, '<i>$1</i>')
            .replace(/\*(.+?)\*/gim, '<b>$1</b>')
            .replace(/\n*$/gim, '<p />')

        setContent(markdownText);
        setHtmlName(htmlText.trim())
        return htmlText.trim();
    }
    // const togglePopup = () => {
    //     setShowPopup(!showPopup)
    // }
    const submitPost = () => {
        if (question && (!option1 || !option2 || !option3 || !option4)) {
            setError("Please add Options")
          //  console.log("eroor12345", question, option1, option2, option3, option4)
            return
        }
        document.getElementById('post').style.display = 'flex'
    }
    const showNone = () => {
        document.getElementById('post').style.display = 'none'
    }
    const handleProcedureContentChange = (text, delta, source, editor) => {
        const words = text.split(' ').length;
        if (words <= wLength) {
            setContent(text);
            setErrorMessage(``);
        } else {
            setErrorMessage(`Maximum word limit reached.`);
        }
    };
    const handleKeyDown = (event) => {
        if (content.split(/\s+/).length > wLength && event.keyCode !== 8) {
           // console.log("content", content.split(' ').length)
            event.preventDefault();
            setErrorMessage(`Maximum word limit reached.`);
        } else {
            setErrorMessage('');
        }
    };

    if (redirect) {
        return <Redirect to="/articles" />;
    }
    if (savedArticles) {
        return <Redirect to="/articles/savedarticles" />;
    }

    // if (showPopup) {
    //     return <NewPopUp closePopup={togglePopup} editPost={editPost} review={review} />
    // }
    if (isLoading) {
        return <div className="App"></div>;
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "4.8rem", marginLeft: "260px" }}>
                <Row style={{ width: "100%" }} className="no-gutters">
                    <Col md={9}>
                        <Form style={{ marginLeft: "1.2rem", width: "96%" }} >
                            {/* <div className="form-group mt-2">
                                <select name="topicId" onChange={onChange} class="web-font form-control text-box-radius">
                                    <option value={topicId}>{topic}</option>
                                    {allTopics.map((topic, id) => (
                                        <option value={topic.id}>{topic.topicName}</option>

                                    ))}
                                </select>
                            </div>
                            <Row style={{ width: "100%" }}>
                                <Col md={1}>
                                    <div className="image_topicId">
                                        {articleImages.map((data, i) => (
                                            <div><img src={config.userUrl + data.articleUrl} onClick={() => setImage(data.articleUrl)} className={image === data.articleUrl ? "border-url create-imageurl" : "create-imageurl"} /> &nbsp;</div>
                                        ))}
                                    </div>
                                </Col>
                                <Col md={11}>
                                    <textarea type="text"
                                        className="web-font form-control text-box-radius"
                                        aria-describedby="text"
                                        name="title"
                                        rows={4}
                                        style={{ overflowY: "none", marginLeft: "1.8rem", width: "100%", marginTop: "-5px", resize: "none" }}
                                        value={title}
                                        placeholder="Article Title"
                                        onChange={e => setTitle(e.target.value)}
                                    />
                                </Col>

                            </Row> */}
                            <Row style={{ width: "100%" }}>
                                <Col md={1}>
                                    <div className="image_topicId mt-2">
                                        {articleImages.map((data, i) => (
                                            <div><img src={config.userUrl + data.articleUrl} alt="" onClick={() => setImage(data.articleUrl)} className={image === data.articleUrl ? "border-url create-imageurl" : "create-imageurl"} /> &nbsp;</div>
                                        ))}
                                    </div>
                                </Col>
                                <Col md={11}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col md={12}>
                                            <div className="form-group" style={{ marginLeft: "2.3rem", width: "98.5%" }}>
                                                <select name="topicId" onChange={onChange} class="web-font form-control text-box-radius">
                                                    <option value={topicId}>{topic}</option>
                                                    {allTopics.map((topic, id) => (
                                                        <option value={topic.id}>{topic.topicName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <textarea type="text"
                                                className="web-font form-control text-box-radius"
                                                aria-describedby="text"
                                                name="title"
                                                rows={2}
                                                style={{ overflowY: "none", marginLeft: "2.3rem", width: "98.5%", marginTop: "-5px", resize: "none" }}
                                                value={title}
                                                placeholder="Article Title"
                                                onChange={e => setTitle(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {/* <div style={{ display: "flex", float: "right", marginLeft: "-20px" }}>

                                <OverlayTrigger overlay={<Tooltip className="tooltip">*<b>B</b>*</Tooltip>}>

                                    <Button variant="default" className="bold" onClick={handleB} type="submit">
                                        B
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip className="tooltip">_<>U</>_</Tooltip>}>
                                    <Button variant="default" className="bold" onClick={handleU} type="submit">
                                        U
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip className="tooltip">%<i>I</i>%</Tooltip>}>
                                    <Button variant="default" className="boldI" onClick={handleI} type="submit">
                                        I
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip className="tooltip">#<b>H</b></Tooltip>}>
                                    <Button variant="default" className="bold" onClick={handleH} type="submit">
                                        H
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip className="tooltip">&gt;<>L</></Tooltip>}>
                                    <Button variant="default" className="bold" onClick={handleL} type="submit">
                                        L
                                    </Button>
                                </OverlayTrigger>
                            </div> */}
                            <div className="form-group">
                                <div id="editor">
                                    {/* {content.split(" ").length <= 500 ?
                                    <div>    <div className="mt-1"><span style={{ marginLeft: "4px" }}> {content.split(" ").length - 1} / 500</span></div> */}
                                    {/* <textarea name="content" value={content}
                                            className="web-font richTextField description-radius"
                                            ref={refText}
                                            style={{ overflowY: "none", resize: "none" }}
                                            onChange={(e) => parseMarkdown(e.target.value)}
                                        /> */}
                                    <div className="split-content">{countWords(content)} / {wLength} &nbsp;{sessionStorage.getItem('role') === 'admin' ? ({ userName }) : ""}</div>
                                    {errorMessage && <div className="error ml-1"> {errorMessage}</div>}
                                    <ReactQuill
                                        style={{ overflowY: "none", resize: "none", height: "80%", background: "white" }}
                                        modules={modules}
                                        formats={formats}
                                        onKeyDown={handleKeyDown}
                                        value={content}
                                        onChange={handleProcedureContentChange}
                                    />
                                </div>
                                {/* : <div>    <div className="mt-1"><span style={{ marginLeft: "4px" }}> {content.split(" ").length - 1} / 500</span></div><br />
                                        <div className="error ml-1">   You cannot write more than 500 words</div>
                                        <textarea name="content" value={content}
                                            className="web-font richTextField description-radius"
                                            ref={refText}
                                            style={{ overflowY: "none", resize: "none" }}
                                            onChange={(e) => parseMarkdown(e.target.value)}
                                        /></div>} */}
                                {/* </div> */}
                            </div>
                            <Card style={{ width: "100%", border: "none" }}>
                                <div className="ml-3 mt-2">
                                    <div >
                                        <div className="form-group mt-1" style={{ width: "98%" }}>
                                            <input type="text"
                                                className="web-font form-control form-bg"
                                                aria-describedby="text"
                                                value={question}
                                                name="question"
                                                placeholder="Write Your Question"
                                                onChange={(e) => setQuestion(e.target.value)}
                                            />
                                        </div>
                                        <Row style={{ width: "100%" }}>
                                            <Col>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option1"
                                                        placeholder={`Write your option 1`}
                                                        value={option1}
                                                        disabled={!question ? true : false}
                                                        onChange={(e) => setOption1(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option3"
                                                        placeholder={`Write your option 3`}
                                                        value={option3}
                                                        disabled={!question ? true : false}
                                                        onChange={(e) => setOption3(e.target.value)}
                                                    />
                                                </div>    {(question !== "") && (option1 !== "") && (option2 !== "") && (option3 !== "") && (option4 !== "") ? "" : <div className="error">{error}</div>}
                                            </Col>
                                            <Col>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option2"
                                                        placeholder={`Write your option 2`}
                                                        value={option2}
                                                        disabled={!question ? true : false}
                                                        onChange={(e) => setOption2(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option4"
                                                        placeholder={`Write your option 4`}
                                                        value={option4}
                                                        disabled={!question ? true : false}
                                                        onChange={(e) => setOption4(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Card>
                            {((topicId !== "") && (image !== "") && (title !== "") && (content !== "")) ?
                                <div>
                                    <Button variant="default" onClick={submitPost} className="sendreview-btn mt-2 ml-3">Submit </Button>
                                    <div id="post" className="modal_new">
                                        <form className="modal-content-log">
                                            <div className="md-container">
                                                <p className="mt-2">Do you want to submit the post?</p>
                                                <center><Button variant="default" onClick={() => editPost(review)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                    <Button variant="default" onClick={showNone} className="deletebutton">No</Button></center>
                                            </div>
                                        </form>
                                    </div><Button onClick={() => editPost(update)} className="save-btn mt-2 ml-3" variant="default">Update</Button>
                                </div> :
                                <div>
                                    <Button variant="default" disabled="true" className="sendreview-btn mt-2 ml-3">Submit </Button>
                                    <Button disabled="true" className="save-btn mt-2 ml-3" variant="default">Update</Button>
                                </div>}
                            <Link to="/articles/savedarticles"><Button variant="default" className="cancelreview-btn mt-2 ml-4">Back</Button></Link>
                        </Form>
                    </Col>
                    <Col md={3} >
                        <div style={{ width: "100%" }}>
                            <div class="scrolling-area">
                                <div class="scrolling-element-inside">
                                    {/* {image.name ? (
                                        <img src={URL.createObjectURL(image)} alt="dummy" width="100%" height="150px" />
                                    ) : null} */}
                                    <img src={config.userUrl + image} alt="" width="100%" height="250px" />
                                    {/* <p className="article-title mt-2"> {this.state.topic}</p> */}
                                    <p className="article-title article-title-color mt-2"> {title}</p>
                                    <div className="web-font article-content" style={{ whiteSpace: "pre-wrap", width: "100%" }} dangerouslySetInnerHTML={{ __html: content }}></div>
                                    {question === null || question === "" ? <div></div>
                                        :
                                        <Card className="mt-3 poll-answer-card-post" style={question === null || question === "" ? { display: "none" } : { borderRadius: "10px", padding: "5px 10px" }}>
                                            <center className="question_post">{question}</center>
                                            <div className="polloptions-card-post"><div className="poll-text">{option1}</div></div>
                                            <div className="polloptions-card-post"><div className="poll-text">{option2}</div></div>
                                            <div className="polloptions-card-post"><div className="poll-text">{option3}</div></div>
                                            <div className="polloptions-card-post"><div className="poll-text">{option4}</div></div>
                                        </Card>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div >
    );
};

// const NewPopUp = (props) => {
//     console.log("props.review", props.review)
//     return (
//         <div className='popup'>
//             <div className='popup_inner'>
//                 {/* <span className="close-icon-new" onClick={props.closePopup}> &times;</span> */}
//                 <center>
//                     <h5>Are you sure you want to submit?</h5><br />
//                     <Button variant="default" onClick={() => props.editPost(props.review)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
//                     <Button variant="default" onClick={props.closePopup} className="deletebutton">No</Button>
//                 </center>
//             </div>
//         </div >
//     );
// };



