import React, { useParams, useRef, useState, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, useRouteMatch, NavLink, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ReportRequests(props) {
    const [selected, setSelected] = useState(0)
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [requestById, setRequestById] = useState([])
    const [htmlName, setHtmlName] = useState('');
    const [userName, setUserName] = useState('');
    const [count, setCount] = useState('');
    const [interestType, setInterestType] = useState('');
    const [description, setDescription] = useState('');
    const refText = useRef('myTextarea');
    const [reports, setReports] = useState([])
    const [articleData, setArticleData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [likesCount, setLikesCount] = useState('');
    const [pollsAnsweredCount, setPollsAnsweredCount] = useState('');
    const [pollsCreatedCount, setPollsCreatedCount] = useState('');
    const [redirect, setRedirect] = useState(false)
    const [topicDetails, setTopicDetails] = useState([])

    let { path, url } = useRouteMatch();
    // console.log(path)

    useEffect(() => {
        getPendingReports();
    }, []);
    const getPendingReports = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getPendingReports', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setReports(response.data.getPendingReports)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getPendingReportsById = (id) => {
        //console.log("id", id)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + `user/getPendingReportById/${id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setRequestById(response.data.getPendingReports)
                    setLikesCount(response.data.likedPost)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const acceptAndRejectReports = (data, flag) => {
      //  console.log("edit", data)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "articleId": data,
            "status": flag,
            "description": description
        }
       // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/rejectAndBlock', payload, { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status == true) {
                    window.location.href = '/reportrequests'
                 //   console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

    if (!sessionStorage.getItem('junkStore')) {
        return <Redirect to='/' />;
    }
    if (isLoading) {
        return <div></div>
    }
    const updatedDate = reports.map((i, idx) => {
        var a = new Date(i.userPost.publishOn)
        var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return updated;
    })
    const createdDate = requestById.map((i, idx) => {
        var a = new Date(i.createdAt)
        var updatedDate = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return updatedDate;
    })
    const showBlock = () => {
        document.getElementById('block').style.display = 'flex'
    }
    const showBlockNone = () => {
        document.getElementById('block').style.display = 'none'
    }
    const showReject = () => {
        document.getElementById('reject').style.display = 'flex'
    }
    const showRejectNone = () => {
        document.getElementById('reject').style.display = 'none'
    }
    const showArticle = (id) => {
        document.getElementById('article').style.display = 'flex';
        // getArticleById(id);
    }
    const showArticleNone = () => {
        document.getElementById('article').style.display = 'none'
    }

    function parseMarkdown(markdownText) {
        var newMarkdownText = markdownText
            .replace(/^#(.*$)/gim, '<h6 class="heading-color"><b>$1</b></h6>')
            .replace(/^>(.*$)/gim, '<li>$1</li>')
            .replace(/\_(.+?)\_/gim, '<u>$1</u>')
            .replace(/\%(.+?)\%/gim, '<i>$1</i>')
            .replace(/\*(.+?)\*/gim, '<b>$1</b>')
            .replace(/\n*$/gim, '<p />')
        return newMarkdownText
    }
    const getArticleById = (id) => {
        document.getElementById('article').style.display = 'flex';
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + `user/getPostById/${id}`, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                  //  console.log("success!!");
                    setArticleData(response.data.post)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                <Row style={{ width: "100%" }}>
                    <Col md={5}>
                        <div class="scrolling-area-review">
                            <div class="scrolling-element-inside">
                                <div className="inbox">Objections</div>
                                {reports.length !== 0 ?
                                    <div>
                                        {reports.map((data, i) => (
                                            <div>
                                                <Card style={{ border: "none" }} onClick={() => getPendingReportsById(data.articleId)}>
                                                    <NavLink to={`${path}/${data.articleId}`} className="inbox_Card link" activeClassName="_Active" key={i} >
                                                        <Row style={{ width: "100%" }}>
                                                            <Col md={2}>
                                                                <img src={orange} style={{ width: "32px" }} />
                                                            </Col>
                                                            <Col md={8}>
                                                                <div className="article-approved">{data.userPost.title} </div>
                                                                <div className="messages">{data.userPost.user.name}</div>
                                                                <div className="messages">Total Reports: {data.count}</div>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div className="mt-1 time">{updatedDate[i]}</div>
                                                            </Col>
                                                        </Row>
                                                    </NavLink>
                                                </Card>
                                                <hr /></div>
                                        ))}
                                    </div>
                                    : <p>No Requests to Accept or Reject</p>}


                            </div>
                        </div>
                    </Col>
                    <Col md={7}>
                        <Card style={{ height: "85vh" }}>
                            {reports.map((data, i) => (
                                <Route key={i} path={`${path}/${data.articleId}`} >
                                    <div class="ml-3 mr-2">
                                        <div class="scrolling-area-review-queue">
                                            <div class="scrolling-element-inside">
                                                <p className="article-title mt-4"> "{data.userPost.title}" <br />-By {data.userPost.user.name} <span style={{ float: "right", marginRight: "10px" }}><Button variant="default" onClick={() => getArticleById(data.articleId)} className="sendreview-btn">View Article</Button></span></p>
                                                <div id="article" className="modal-article">
                                                    <form className="modal-article-content">
                                                        <div className="md-article-container">
                                                            {articleData.map((article, id) => {
                                                                return (
                                                                    <>
                                                                        <div>
                                                                            <div className="view_article">
                                                                                <span className="article-modal-fixed" onClick={showArticleNone}>&times;</span><br />
                                                                                <div className="article-title-color">{article.title}</div>
                                                                            </div>
                                                                        </div><br /><br />
                                                                        <img src={config.userUrl + article.image} style={{ marginTop: "2rem", width: "100%", height: "220px" }} />

                                                                        <div className="quill-size" style={{ marginTop: "2rem", fontWeight: "500", fontSize: "12px", wordWrap: "break-word", fontFamily: "Raleway,sans-serif" }} dangerouslySetInnerHTML={{ __html: article.content }} />
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="tableReportsFixHead">
                                                    <table class="mt-3 ml-1 table-striped" >
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="th-heading" style={{ width: "50%" }}>Type of Report</th>
                                                                <th scope="col" className="th-heading" style={{ width: "50%" }}>Created On</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {requestById.map((item, i) => (
                                                                <tr className={i / 2 === 0 ? "" : "green"}>
                                                                    <td className="td-text" >{item.typeOfReport == "1" ? "Unrelated" : (item.typeOfReport == "2" ? "Plagiarism" : "Unformatted")}</td>
                                                                    <td className="td-text">{createdDate[i]}</td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                     
                                        <div>
                                            <div className="ml-3 mr-3 mt-3 form-group">
                                                <textarea
                                                    rows={2}
                                                    name="description"
                                                    value={description}
                                                    placeholder="Write your comments"
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    className="web-font form-control"
                                                    style={{ resize: "none", wordWrap: "break-word", }}
                                                />
                                            </div>
                                            <div className="float_Right mr-3" >
                                                <div>
                                                    <div>{description !== "" ?
                                                        <div ><Button onClick={showReject} className="save-btn mt-1 ml-3" variant="default">Reject</Button>
                                                            <div id="reject" className="modal_new">
                                                                <form className="modal-content-log">
                                                                    <div className="md-container">
                                                                        <p className="mt-2">Do you really want to Reject?</p>
                                                                        <center><Button variant="default" onClick={() => acceptAndRejectReports(data.articleId, 3)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                            <Button variant="default" onClick={showRejectNone} className="deletebutton">No</Button></center>
                                                                    </div>
                                                                </form>
                                                            </div><Button variant="default" onClick={showBlock} className="sendreview-btn-new mt-1 ml-4 ">Block</Button>
                                                            <div id="block" className="modal_new">
                                                                <form className="modal-content-log">
                                                                    <div className="md-container">
                                                                        <p className="mt-2">Do you really want to Block?</p>
                                                                        <center><Button variant="default" onClick={() => acceptAndRejectReports(data.articleId, 1)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                            <Button variant="default" onClick={showBlockNone} className="deletebutton">No</Button></center>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div> :
                                                        <div><Button disabled="true" className="save-btn mt-1 ml-3" variant="default">Reject</Button><Button variant="default" disabled="true" className="sendreview-btn-new mt-1 ml-4">Block</Button></div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Route>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div >
        </div >
    );
}






