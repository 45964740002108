import React, { Component } from "react";
import { Button, Form, Table, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Advertisement.css';
import advertisementData from './Advertisement.json';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Announcement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            announcement: [],
            redirect: false,
            isLoading: true
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
       // console.log("announcement", this.state.announcement)
        this.getActiveAnnouncements();
    }

    handleChange = (e) => {
        const id = e.target.id;
        const elementsIndex = this.state.announcement.findIndex(element => element.id == id)
        let newArray = [...this.state.announcement]
        newArray[elementsIndex] = { ...newArray[elementsIndex], value: !newArray[elementsIndex].value }
        this.setState({ announcement: newArray });
       // console.log("hi", newArray)
    }
    getActiveAnnouncements = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "status": 1
        }
        var self = this
        axios.post(config.userUrl + 'user/getAnnouncement', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        announcement: response.data.getAnnouncement,
                        isLoading: false
                    })
                   // console.log("announcements", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, announcement } = this.state
        const b = announcement.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        const endAdvDate = announcement.map((i, idx) => {
            var end = new Date(i.endDate)
            var updatedEndDate = end.getDate() + "-" + parseInt(end.getMonth() + 1) + "-" + end.getFullYear();
            return updatedEndDate;
        })
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >

                    <div >
                        <div> <Link to="/announcement/createannouncement"><Button variant="default" className="createadv-btn">+ &nbsp;Create</Button></Link>
                            <Link to="/announcement/savedannouncement"><Button variant="default" className="ml-3 createadv-btn">&nbsp;Saved</Button></Link>
                        </div>
                        {announcement.length === 0 ? <center><h4>No Published Announcements</h4></center> :
                            <div>
                                <div className="mt-3 active-add-h-text">Published Announcements</div>
                                <div class="mt-3 tableFixHead" >
                                    <table class="table-striped" >
                                        <thead style={{ background: "#eee" }}>
                                            <tr>
                                                <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                                <th scope="col" className="th-heading" style={{ width: "54%" }}>Announcement</th>
                                                <th scope="col" className="th-heading" style={{ width: "15%" }}>Created At</th>
                                                {/* <th scope="col" className="th-heading" style={{ width: "15%" }}>End Date</th>
                                        <th scope="col" className="th-heading" style={{ width: "6%" }}>Views</th> */}
                                                <th ></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {announcement.map((data, i) => (
                                                <tr className={i / 2 === 0 ? "" : "green"}>
                                                    <td className="td-text" >&nbsp;&nbsp;{i + 1}</td>
                                                    <td className="td-text">{data.announcement}</td>
                                                    <td className="td-text">{b[i]}</td>
                                                    {/* <td className="td-text">{endAdvDate[i]}</td>
                                            <td className="td-text">{data.numberOfViews}</td> */}
                                                    <td className="cursor"><Link to={`/announcement/viewannouncement/${data.id}`} className="poll-link"> <i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div >
            </div>
        );
    };
}

