import React, { useState, useRef, useEffect } from "react";
import { Button, Form, OverlayTrigger, FormControl, Tooltip, Card, Col, Row } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import addimage from '../images/addimage.svg';
import profile from '../images/profilepicture.svg';
import './WriteArticle.css';
import axios from 'axios';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function WriteArticle() {
    const [allTopics, setAllTopics] = useState([]);
    const [articleImages, setArticleImages] = useState([]);
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [image, setImage] = useState("")
    const [selectedTopic, setSelectedTopic] = useState("")
    const [topicId, setTopicId] = useState("")
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [redirect, setRedirect] = useState(false)
    const [savedPost, setSavedPost] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [htmlName, setHtmlName] = useState('');
    const [question, setQuestion] = useState("")
    const [option1, setOption1] = useState("")
    const [option2, setOption2] = useState("")
    const [option3, setOption3] = useState("")
    const [option4, setOption4] = useState("")
    const [error, setError] = useState("")
    const [errorMessage, setErrorMessage] = useState('');
    const refText = useRef('myTextarea');
    const [searchValue, setSearchValue] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [searchModal, setSearchModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [uuid, setUuid] = useState('')
    const [wLength, setWLength] = useState(750)

    useEffect(() => {
    }, []);
    const onChangeHandler = (e) => {
       // console.log("length", e.target.value)
        search(e.target.value);
        setSearchValue(e.target.value)
       // console.log("a", e.target.value.length)
    };
    const search = async val => {
        setLoading(true)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + `user/searchActiveUsers?search=${val}`, { headers: headers })
            .then(function (response) {
              //  console.log("searchActiveUsers", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                   // console.log("searchActiveUsers retrieved successfull", response.data);
                    if (searchValue.length !== 0) {
                        // self.setState({ searchResults: response.data.searchProduct, searchModal: true })
                        setSearchResults(response.data.searchUser)
                        setSearchModal(true)
                    } else {
                        // setSearchResults: [], searchModal: false })
                        setSearchResults([])
                        setSearchModal(false)
                    }

                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });

        setLoading(false)
    };
    const closeSearch = (e) => {
       // console.log("search")
        setSearchModal(false)
        setSearchValue("")
    }



    const fileHandler = (e) => {
        setImage(e.target.files[0])
    }
    useEffect(() => {
        getAllTopics();
    }, []);
    const modules = {
        toolbar: [
            [{ 'header': '1' }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
        ]
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "list",
        "bullet",

    ];
    const getAllTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllTopics', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    //console.log("getAllTopics");
                    setAllTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const createPost = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "topicId": topicId,
            "title": title,
            "imageUrl": image,
            "content": content,
            "question": question,
            "options": [option1, option2, option3, option4]
        }
        //console.log("payload", payload)
        axios.post(config.userUrl + 'user/createPost', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    setRedirect(true)
                   // console.log("success", response.data.message, payload);
                } else {
                    //console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const savePost = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "topicId": topicId,
            "title": title,
            "imageUrl": image,
            "content": content,
            "question": question,
            "options": [option1, option2, option3, option4]
        }
       // console.log("payload", payload)
        axios.post(config.userUrl + 'user/savePost', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    setSavedPost(true)
                    //console.log("success", response.data.message, payload);
                } else {
                   // console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const submitPost = () => {
        if (question && (!option1 || !option2 || !option3 || !option4)) {
            setError("Please add Options")
           // console.log("eroor12345", question, option1, option2, option3, option4)
            return
        }
        document.getElementById('post').style.display = 'flex'
    }
    const showNone = () => {
        document.getElementById('post').style.display = 'none'
    }
    const onTopicChange = (e) => {
        setTopicId(parseInt(e.target.value))
        setSelectedTopic(e.target.value)
       // console.log("topic Id", e.target.value, selectedTopic, topicId)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "topicId": parseInt(e.target.value)
        }
        //console.log("payload", payload)
        axios.post(config.userUrl + 'user/getAllArticlesUrlByTopicId', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                   // console.log("articleImg", response, payload);
                    setArticleImages(response.data.getAllArticlesUrlByTopicId)
                    setImage(response.data.getAllArticlesUrlByTopicId[0].articleUrl)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    }
    function countWords(content) {
        const arr = content.split(' ');
        return arr.filter(word => word !== '').length;
    }
    const handleProcedureContentChange = (text, delta, source, editor) => {
        const words = text.split(' ').length;
        if (words <= wLength) {
            setContent(text);
            setErrorMessage(``);
        } else {
            setErrorMessage(`Maximum word limit reached.`);
        }
    };
    const handleKeyDown = (event) => {
        if (content.split(/\s+/).length > wLength && event.keyCode !== 8) {
            //console.log("content", content.split(' ').length)
            event.preventDefault();
            setErrorMessage(`Maximum word limit reached.`);
        } else {
            setErrorMessage('');
        }
    };
    const hideSearch = (name, id) => {
        setSearchModal(false);
        setSearchValue(name)
        setUuid(id)
    }
    const createPostByAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "topicId": topicId,
            "title": title,
            "forUserId":uuid,
            "imageUrl": image,
            "content": content,
            "question": question,
            "options": [option1, option2, option3, option4]
        }
        //console.log("payload", payload)
        axios.post(config.userUrl + 'user/createPostByAdmin', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    setRedirect(true)
                  //  console.log("success", response.data.message, payload);
                } else {
                   // console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const savePostByAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "topicId": topicId,
            "title": title,
            "forUserId":uuid,
            "imageUrl": image,
            "content": content,
            "question": question,
            "options": [option1, option2, option3, option4]
        }
        //console.log("payload", payload)
        axios.post(config.userUrl + 'user/savePostByAdmin', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    setSavedPost(true)
                    //console.log("success", response.data.message, payload);
                } else {
                   // console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    if (redirect || savedPost) {
        return <Redirect to="/articles" />;
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "4.8rem", marginLeft: "260px" }}>
                <Row style={{ width: "100%" }} className="no-gutters">
                    <Col md={9}>
                        {(sessionStorage.getItem("role") == "admin") ?
                            <div  style={{ marginLeft: "7.6%", marginBottom: "0.3rem" }}>
                                <FormControl
                                    type="text"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={e => onChangeHandler(e)}
                                    id="search" className="abc form-control search_box"
                                    aria-label="Search keyword"
                                />
                                {searchModal ?
                                    <div id="card-search">
                                        <div >
                                            {searchResults.length == 0 ? <div onKeyUp={e => closeSearch(e)}>No Results found</div> :
                                                <Card>
                                                    {searchResults.slice(0, 5).map((data, i) =>
                                                    (
                                                        <div style={{ cursor: "pointer",color:"black" }} className="border-underline" onClick={() => hideSearch(data.name, data.uuid)}>
                                                            <div className="link mb-1" style={{ display: "flex",color:"black" }}><img style={{ width: "45px", padding: "2px 4px" }} src={(data.profilePicture === "" || data.profilePicture === null) ? profile : config.userUrl + data.profilePicture} />&nbsp;<span className="mt-1">{data.name}</span><span className="mt-1">, &nbsp;{data.uniquecode}</span></div>

                                                        </div>
                                                    ))}
                                                </Card>}
                                        </div>
                                    </div> : ""
                                }
                            </div>
                            : <></>}
                        <Form style={{ marginLeft: "1.2rem", width: "96%" }} >
                            {/* <div className="form-group mt-2">
                                <select name="topicId" value={selectedTopic} onChange={onTopicChange} class="web-font form-control text-box-radius">
                                    <option value="">Select Topic</option>
                                    {allTopics.map((topic, id) => (
                                        <option value={topic.id}>{topic.topicName}</option>

                                    ))}
                                </select>
                            </div>
                            <Row style={{ width: "100%" }}>
                                <Col md={1}>
                                    <div className="image_topicId">
                                        {articleImages.map((data, i) => (
                                            <div><img src={config.userUrl + data.articleUrl} alt="" onClick={() => setImage(data.articleUrl)} className={image === data.articleUrl ? "border-url create-imageurl" : "create-imageurl"} /> &nbsp;</div>
                                        ))}
                                    </div>
                                </Col>
                                <Col md={11}>
                                    <textarea type="text"
                                        className="web-font form-control text-box-radius"
                                        aria-describedby="text"
                                        rows={4}
                                        style={{ overflowY: "none", marginLeft: "1.8rem", width: "100%", marginTop: "-5px", resize: "none" }}
                                        name="title"
                                        value={title}
                                        placeholder="Article Title"
                                        onChange={e => setTitle(e.target.value)}
                                    />
                                </Col>
                            </Row> */}

                            <Row style={{ width: "100%" }}>
                                <Col md={1}>
                                    <div className="image_topicId mt-2">
                                        {articleImages.map((data, i) => (
                                            <div><img src={config.userUrl + data.articleUrl} alt="" onClick={() => setImage(data.articleUrl)} className={image === data.articleUrl ? "border-url create-imageurl" : "create-imageurl"} /> &nbsp;</div>
                                        ))}
                                    </div>
                                </Col>
                                <Col md={11}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col md={12}>
                                            <div className="form-group" style={{ marginLeft: "2.3rem", width: "98.5%" }}>
                                                <select name="topicId" value={selectedTopic} onChange={onTopicChange} class="web-font form-control text-box-radius">
                                                    <option value="">Select Topic</option>
                                                    {allTopics.map((topic, id) => (
                                                        <option value={topic.id}>{topic.topicName}</option>

                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <textarea type="text"
                                                className="web-font form-control text-box-radius"
                                                aria-describedby="text"
                                                name="title"
                                                rows={2}
                                                style={{ overflowY: "none", marginLeft: "2.3rem", width: "98.5%", marginTop: "-5px", resize: "none" }}
                                                value={title}
                                                placeholder="Article Title"
                                                onChange={e => setTitle(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="form-group">
                                <div id="editor" >
                                    <div className="split-content">{countWords(content)} / {wLength}</div>
                                    {errorMessage && <div className="error ml-1"> {errorMessage}</div>}
                                    <ReactQuill
                                        style={{ overflowY: "none", resize: "none", height: "80%", background: "white" }}
                                        modules={modules}
                                        formats={formats}
                                        onKeyDown={handleKeyDown}
                                        value={content}
                                        onChange={handleProcedureContentChange}
                                    />

                                    {/*     <div>    <div className="mt-1"><span style={{ marginLeft: "4px" }}> {content.split(" ").length - 1} / 500</span></div><br />
                                        <div className="error ml-1">   You cannot write more than 500 words</div>
                                        <textarea name="content" value={content}
                                            className="web-font richTextField description-radius"
                                            ref={refText}
                                            style={{ overflowY: "none", resize: "none" }}
                                        /></div>} */}
                                </div>
                            </div>
                            <Card style={{ width: "100%", border: "none" }}>
                                <div className="ml-3 mt-1">
                                    <div >
                                        <div className="form-group mt-1" style={{ width: "98%" }}>
                                            <input type="text"
                                                className="web-font form-control form-bg"
                                                aria-describedby="text"
                                                value={question}
                                                name="question"
                                                placeholder="Write Your Question"
                                                onChange={(e) => setQuestion(e.target.value)}
                                            />
                                        </div>
                                        <Row style={{ width: "100%" }}>
                                            <Col>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option1"
                                                        placeholder={`Write your option 1`}
                                                        disabled={!question ? true : false}
                                                        value={option1}
                                                        onChange={(e) => setOption1(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option3"
                                                        disabled={!question ? true : false}
                                                        placeholder={`Write your option 3`}
                                                        value={option3}
                                                        onChange={(e) => setOption3(e.target.value)}
                                                    />
                                                </div>
                                                {(question !== "") && (option1 !== "") && (option2 !== "") && (option3 !== "") && (option4 !== "") ? "" : <div className="error">{error}</div>}
                                            </Col>
                                            <Col>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option2"
                                                        disabled={!question ? true : false}
                                                        placeholder={`Write your option 2`}
                                                        value={option2}
                                                        onChange={(e) => setOption2(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option4"
                                                        disabled={!question ? true : false}
                                                        placeholder={`Write your option 4`}
                                                        value={option4}
                                                        onChange={(e) => setOption4(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Card>
                            {/* {( (topicId !== "") && (image !== "") && (title !== "") && (content !== "")) ?
                                <div><Button variant="default" onClick={submitPost} className="sendreview-btn mt-2 ml-4">Submit</Button>
                                    <div id="post" className="modal_new">
                                        <form className="modal-content-log">
                                            <div className="md-container">
                                                <p className="mt-2">Do you want to submit the post?</p>
                                                <center><Button variant="default" onClick={createPost} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                    <Button variant="default" onClick={showNone} className="deletebutton">No</Button></center>
                                            </div>
                                        </form>
                                    </div><Button onClick={savePost} className="save-btn mt-2 ml-3" variant="default">Save</Button></div> :
                        <div><Button variant="default" disabled={true} className="sendreview-btn mt-2 ml-3">Submit</Button><Button onClick={savePost} disabled={true} className="save-btn mt-2 ml-3" variant="default">Save</Button></div>
                                } */}
                            {(sessionStorage.getItem('role') === "admin") ? (
                                (uuid !== "" && topicId !== "" && image !== "" && title !== "" && content !== "") ? (
                                    <div>
                                        <Button variant="default" onClick={submitPost} className="sendreview-btn mt-2 ml-4">Submit</Button>
                                        <div id="post" className="modal_new">
                                            <form className="modal-content-log">
                                                <div className="md-container">
                                                    <p className="mt-2">Do you want to submit the post?</p>
                                                    <center>
                                                        <Button variant="default" onClick={createPostByAdmin} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                        <Button variant="default" onClick={showNone} className="deletebutton">No</Button>
                                                    </center>
                                                </div>
                                            </form>
                                        </div>
                                        <Button onClick={savePostByAdmin} className="save-btn mt-2 ml-3" variant="default">Save</Button>
                                    </div>
                                ) : (
                                    <div>
                                        <Button variant="default" disabled={true} className="sendreview-btn mt-2 ml-3">Submit</Button>
                                        <Button onClick={savePost} disabled={true} className="save-btn mt-2 ml-3" variant="default">Save</Button>
                                    </div>
                                )
                            ) : (
                                (topicId !== "" && image !== "" && title !== "" && content !== "") ? (
                                    <div><Button variant="default" onClick={submitPost} className="sendreview-btn mt-2 ml-4">Submit</Button>
                                    <div id="post" className="modal_new">
                                        <form className="modal-content-log">
                                            <div className="md-container">
                                                <p className="mt-2">Do you want to submit the post?</p>
                                                <center><Button variant="default" onClick={createPost} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                    <Button variant="default" onClick={showNone} className="deletebutton">No</Button></center>
                                            </div>
                                        </form>
                                    </div>
                                        <Button onClick={savePost} className="save-btn mt-2 ml-3" variant="default">Save</Button>
                                    </div>
                                ) : (
                                    <div>
                                        <Button variant="default" disabled={true} className="sendreview-btn mt-2 ml-3">Submit</Button>
                                        <Button onClick={savePost} disabled={true} className="save-btn mt-2 ml-3" variant="default">Save</Button>
                                    </div>
                                )
                            )}

                        </Form>
                    </Col>
                    <Col md={3}>
                        <div class="scrolling-area">
                            <div class="scrolling-element-inside">{image !== "" ?
                                <img src={config.userUrl + image} alt="" className="write-article-img" />
                                : <div></div>}
                                <p className="article-title article-title-color mt-2"> {title}</p>
                                <div className="web-font article-content" style={{ wordWrap: "break-word", width: "100%" }} dangerouslySetInnerHTML={{ __html: content }}></div>
                                {question === "" ? <div></div>
                                    :
                                    <Card className="mt-3 poll-answer-card-post" style={question === null ? { display: "none" } : { borderRadius: "10px", padding: "5px 10px" }}>
                                        <center className="question_post">{question}</center>
                                        <div className="polloptions-card-post"><div className="poll-text">{option1}</div></div>
                                        <div className="polloptions-card-post"><div className="poll-text">{option2}</div></div>
                                        <div className="polloptions-card-post"><div className="poll-text">{option3}</div></div>
                                        <div className="polloptions-card-post"><div className="poll-text">{option4}</div></div>
                                    </Card>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div >
    );
}


