import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { moment } from 'moment-timezone';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class GamesReach extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            gamesReach: [],
            quizReach: [],
            isLoading: true

        }
    }

    componentDidMount() {
        this.getGamesReach()
    }
    getGamesReach = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getGamesReach', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({ gamesReach: response.data.gamesReach, quizReach: response.data.quizReach, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, gamesReach, quizReach } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        const combinedArray = [...gamesReach, ...quizReach];
        const orderedArray = combinedArray.sort((a, b) => parseInt(b.count) - parseInt(a.count));

        orderedArray.forEach((item, index) => {
            if (!item.gameId && !item.gameName) {
                orderedArray[index] = {
                    ...item,
                    gameId: "q1",
                    gameName: "Quiz"
                };
            }
        });

       // console.log("order", orderedArray);
        const updatedDate = orderedArray?.map((i, idx) => {
            var a = new Date(i.updatedAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        const updatedQuizDate = quizReach?.map((i, idx) => {
            var a = new Date(i.updatedAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <div style={{ display: "flex" }}>
                        <NavLink to="/history/usersreach" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Users Reach</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/articles" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Articles</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/polls" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Polls</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/sceemlorereach" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Sceemlore </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/advertisements" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Advertisements</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/referrals" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Referrals </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/opportunities" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Opportunities </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/q&a" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Q&A </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/interests" activeClassName="history-active" className="Link" >
                            <div className="history-Text">EOI</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/blocked" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Blocked Articles</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <NavLink to="/history/claimhistory" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Claims</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                        <NavLink to="/history/reviewers" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Reviewers</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/gamesreach" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Games</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/smereach" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Sme</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div >
                    <div className="mt-3 active-add-h-text"> Games Reach </div>
                    {gamesReach.length === 0 ? <center><h4>No games Played.</h4></center> :
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr  >
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>S.No</th>
                                        <th scope="col" className="th-heading" style={{ width: "35%" }}>Game Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Count</th>
                                        <th scope="col" className="th-heading" style={{ width: "25%" }}>Last Played</th>

                                    </tr>
                                </thead>

                                <tbody>{orderedArray?.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text">{i + 1}</td>
                                        <td className="td-text">{data.gameName}</td>
                                        <td className="td-text">{data.count}</td>
                                        <td className="td-text">{updatedDate[i]}</td>
                                    </tr>
                                ))}
                                </tbody>
                                {/* <tbody>{quizReach.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text">{gamesReach.length + 1}</td>
                                        <td className="td-text">Quiz</td>
                                        <td className="td-text">{data.count}</td>
                                        <td className="td-text">{updatedQuizDate[i]}</td>
                                    </tr>
                                ))}
                                </tbody> */}
                            </table>
                        </div>
                    }
                </div>
            </div >
        );
    };
}