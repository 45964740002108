import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Reviews.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ReviewJson } from './Review-Json';
import axios from 'axios';
import { config } from '../Config/Config';
import '../Advertisements/Advertisement.css';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Reviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            review: ReviewJson,
            pageNo: 1,
            pageSize: 4,
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            reviewedPosts: [],
            isLoading: true

        }
    }
    nextPage = () => {
        let totalPages = Math.ceil((this.state.review.length) / (this.state.pageSize));
       // console.log("no.of pages", totalPages);
        if (this.state.pageNo < totalPages) {
            this.setState({
                pageNo: this.state.pageNo + 1,
            })
        }
       // console.log("testingNext", this.state.pageNo);
    }
    previousPage = () => {
        if (this.state.pageNo > 1) {
            this.setState({
                pageNo: this.state.pageNo - 1,
            })
        }
    }
    componentDidMount() {
        this.getHistoricPosts()
    }
    getHistoricPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getHistoricPosts', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({ reviewedPosts: response.data.getHistoricPosts, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, reviewedPosts } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        const updatedDate = reviewedPosts.map((i, idx) => {
            var a = new Date(i.updatedAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        const statusNew = reviewedPosts.map((i, idx) => {
            var a = (i.status).charAt(0).toUpperCase() + (i.status).slice(1)
            return a;
        })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <div className="font-weight">Reviewed (History)</div>
                    {reviewedPosts.length === 0 ? <center><h5>No Articles Reviewed</h5></center> :
                        <div class="mt-2 tableFixHead">
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr>
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Title</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Status</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Reviewed On</th>
                                        <th scope="col" className="th-heading" style={{ width: "45%" }}>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reviewedPosts.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text">{data.title}</td>
                                            <td className="td-text">{statusNew[i]}</td>
                                            <td className="td-text">{updatedDate[i]}</td>
                                            <td className="td-text">{data.description}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                    <br />

                    <br /><br />
                </div>
            </div >
        );
    };
}


