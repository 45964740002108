import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function EditSequence(props) {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [cardDescription, setCardDescription] = useState("")
    const [description, setDescription] = useState("")
    const [theme, setTheme] = useState("")
    const [status, setStatus] = useState("")
    const [redirect, setRedirect] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const refText = useRef('myTextarea');

    useEffect(() => {
        getSequenceCardById();
    }, []);
    const getSequenceCardById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
       // console.log("token", token)
        axios.get(config.userUrl + `user/getSequenceCardById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                   // console.log("success!!", response);
                    setTheme(response.data.sequenceCardById[0].theme)
                    setCardDescription(response.data.sequenceCardById[0].cardDescription)
                    setDescription(response.data.sequenceCardById[0].description)
                    setStatus(response.data.sequenceCardById[0].status)
                    setIsLoading(false)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    }
    const showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    const showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    const editSequenceCard = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${token}`,
            "applicationType": "web"
        }
        var subItems = cardDescription.split(',');
        if (cardDescription.length == 0) {
            subItems = []
        } else {
            subItems = cardDescription.split(',');
        }
        var payload = {
            "sequenceId": parseInt(props.match.params.id),
            "theme": theme,
            "cardDescription": subItems,
            "description": description,
            "status": data
        }
      //  console.log("payload", payload)
        axios.post(config.userUrl + 'user/updateSequenceCards', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    setRedirect(true)
                   // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };


    if (isLoading) {
        return <div className="App"></div>;
    }
    if (redirect) {
        return <Redirect to="/sequence" />
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                <h5 className="font-heading-web">Edit Sequence Card</h5>
                <Form onSubmit={handleSubmit}>
                    <div className="mt-1 poll-date">Add Theme</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <input type="text" className="web-font form-control" name="theme"
                            placeholder="Theme"
                            value={theme}
                            style={{ resize: "none" }}
                            onChange={e => setTheme(e.target.value)} />
                    </div>
                    <div className="mt-3 poll-date">Add Card Description</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="cardDescription"
                            placeholder="Card Description"
                            value={cardDescription}
                            rows={3}
                            style={{ resize: "none" }}
                            onChange={e => setCardDescription(e.target.value)} />
                    </div>
                    <div className="mt-3 poll-date">Description</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="description"
                            placeholder="Description"
                            value={description}
                            rows={2}
                            style={{ resize: "none" }}
                            onChange={e => setDescription(e.target.value)} />
                    </div>
                    <br />
                    <div className="float_right_poll">
                        {/* {console.log("data", (newStartDate > todaysDate < newEndDate),(newStartDate > todaysDate), (todaysDate < newEndDate))} */}

                        <>
                            <Button onClick={showPublish} variant="default" className="sendreview-btn ml-3">Submit </Button>
                            <div id="publish" className="modal_new">
                                <form className="modal-content-log">
                                    <div className="md-container">
                                        <p className="mt-2">Do you want to publish the sequence card?</p>
                                        <center><Button variant="default" onClick={() => editSequenceCard(1)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                            <Button variant="default" onClick={showNone} className="deletebutton">No</Button></center>
                                    </div>
                                </form>
                            </div>
                            <Button className="save-btn ml-3" onClick={() => editSequenceCard(2)} variant="default">Update</Button></>

                        <Link to="/sequence/savedcards"><Button variant="default" className="cancelreview-btn ml-4">Back</Button></Link></div>
                </Form><br /><br />
            </div >
        </div >
    );
};





