import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, Container, Col, Row, Image } from "react-bootstrap";
import { Link, NavLink, Switch, Route } from 'react-router-dom';
import writearticle from '../images/writearticle.svg';
import reviewqueue from '../images/reviewqueue.svg';
import reviews from '../images/reviews.svg';
import user from '../images/user.svg';
import './Settings.css';
import Settings from "./Settings";

export default class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {

        return (
            <div>
                <Settings />
                <div style={{ marginLeft: "460px", marginTop: "5rem" }}>
                    <Card style={{ width: "80%" }}>
                        <p className="notification-heading">Your Account</p>
                    </Card>
                </div>
            </div >
        );
    };
}

