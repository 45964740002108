import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { config } from '../Config/Config';
import moment from 'moment-timezone';

const key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
const simpleCrypto = new SimpleCrypto(key);

const modules = {
    toolbar: [
        [{ 'header': '1' }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
    ]
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
];
let currentDate = new Date();
let currentMonth = currentDate.getMonth();
const AddNewFeatureText = () => {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')));
    const [text, setText] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [image, setImage] = useState("");
    const imgRef = useRef(null);
    const contentEditableRef = useRef(null);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    useEffect(() => {
        getNewFeatureTextByAdminMonthly(currentMonth);
    }, []);

    const onChange = (event) => {
        const { name, value } = event.target;
        setText(value);
    };

    const addNewFeatureText = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        };

        var payload = {
            "text": text,
            "image": image,
            "month": selectedMonth
        };
    
        axios.post(config.userUrl + 'user/addNewFeatureTextByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setRedirect(true);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

    const getNewFeatureTextByAdminMonthly = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        };
        var payload = {
            "month": val
        }
        axios.post(config.userUrl + 'user/getNewFeatureTextByAdminMonthly', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data?.data === null) {
                        //  console.log("no data");
                        setText("")
                        setImage("")
                    } else {
                        setText(response.data?.data?.text);
                        setImage(response.data?.data?.image);
                    }
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    const uploadImage = async (image) => {
        let data = new FormData();
        data.append("file", image);
        data.append("upload_preset", "sceem_");
        data.append("cloud_name", "sceem");

        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        });

        let res = await resp.json();
        return res.secure_url;
    };

    const handleImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const res = await uploadImage(e.target.files[0]);
            if (res) {
                setImage(res);
               // console.log("res", res)
            }
        }
    };

    // const handleProcedureContentChange = (text, delta, source, editor) => {
    //     setText(text);
    // };
    const handleProcedureContentChange = () => {
        if (contentEditableRef.current) {
            const updatedHtml = contentEditableRef.current.innerHTML;
            setText(updatedHtml);
        }
    };

    const handleInput = (event) => {
        handleProcedureContentChange();

        // Save the current cursor position
        const sel = window.getSelection();
        const range = sel.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(contentEditableRef.current);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        const cursorPosition = preCaretRange.toString().length;

        // Restore the cursor position
        setTimeout(() => {
            const textNode = contentEditableRef.current.firstChild;
            if (textNode) {
                const newRange = document.createRange();
                newRange.setStart(textNode, cursorPosition);
                newRange.setEnd(textNode, cursorPosition);
                sel.removeAllRanges();
                sel.addRange(newRange);
            }
        }, 0);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default Enter behavior

            const sel = window.getSelection();
            const range = sel.getRangeAt(0);
            const br = document.createElement('br');
            range.deleteContents();
            range.insertNode(br);

            // Move the cursor after the inserted <br> element
            range.setStartAfter(br);
            range.setEndAfter(br);
            sel.removeAllRanges();
            sel.addRange(range);

            handleProcedureContentChange();
        }
    };

    // Sync the contentEditable with the text state
    useEffect(() => {
        if (contentEditableRef.current && contentEditableRef.current.innerHTML !== text) {
            contentEditableRef.current.innerHTML = text;
        }
    }, [text]);

    const handleButtonClick = (monthNumber) => {
        setSelectedMonth(monthNumber);
        getNewFeatureTextByAdminMonthly(monthNumber)
    };
    if (redirect) {
        return <Redirect to="/configuration/list" />
    }

    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5rem", marginLeft: "275px" }} >
                <Form>
                    <Card style={{ width: "95%" }}>
                        <div style={{ marginLeft: "1.2rem" }}>
                            <h5 className="mt-2 font-heading-web">Add/Edit New Feature Text</h5><br />
                            <div style={{ display: "flex", jutifyContent: "space-between" }} >
                                {monthNames.map((monthName, index) => (
                                    <Button
                                        variant="default"
                                        key={index}
                                        className="newFText-btn"
                                        onClick={() => handleButtonClick(index + 1)}
                                        style={selectedMonth === index + 1 ? { backgroundColor: "#FF942C" } : { backgroundColor: "#31197C" }}
                                    >
                                        {monthName}&nbsp;{moment(new Date()).format("YY")}
                                    </Button>
                                ))}
                            </div>
                            <Row style={{ width: "100%" }} className="mt-5">
                                <Col md={3}>
                                    <label className="poll-date">Select Image<span className="red">*</span></label><br />
                                    <div style={{ height: "120px", textAlign: "center", objectFit: "contain" }}>
                                        <label htmlFor="upload-button">
                                            {image !== "" ? (
                                                <img src={image} style={{ marginTop: "8px", width: "130px", height: "220px" }} alt="uploaded" />
                                            ) : (
                                                <>
                                                    <i className="fa fa-upload fa-2x mt-4" aria-hidden="true"></i>
                                                    <div className="up-load">Upload Image</div>
                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="upload-button"
                                            name="image"
                                            ref={imgRef}
                                            onChange={(e) => handleImage(e)}
                                        />
                                    </div>
                                </Col>
                                <Col md={9}>
                                    <label className="poll-date">Text<span className="red">*</span></label>
                                    {/* <div className="form-group">
                                        <div className="html-content" dangerouslySetInnerHTML={{ __html: text }} />
                                        <textarea
                                            className="web-font form-control"
                                            aria-describedby="text"
                                            rows="14"
                                            style={{ resize: "none" }}
                                            value={text}
                                            name="text"
                                            onChange={onChange}
                                        />
                                    </div> */}
                                    {/* <div
                                        className="html-content"
                                        style={{ border: "1px solid #ccc", width: "100%", borderRadius: "5px", height: "300px", padding: "10px", overflowY: "auto" }}
                                        contentEditable={true}
                                        handleProcedureContentChange={handleProcedureContentChange}
                                        dangerouslySetInnerHTML={{ __html: text }}
                                        onBlur={(e) => {
                                            const updatedHtml = e.target.innerHTML;
                                        }}
                                    /> */}
                                    {/* <div
                                        className="html-content"
                                        ref={contentEditableRef}
                                        style={{ border: "1px solid #ccc", width: "100%", borderRadius: "5px", height: "300px", padding: "10px", overflowY: "auto" }}
                                        contentEditable={true}
                                        onInput={handleInput}
                                        onKeyDown={handleKeyDown}
                                        onBlur={(e) => {
                                            const updatedHtml = e.target.innerHTML;
                                            setText(updatedHtml);
                                        }}
                                        dangerouslySetInnerHTML={{ __html: text }}
                                    /> */}
                                    <div
                                        className="html-content"
                                        ref={contentEditableRef}
                                        style={{ border: "1px solid #ccc", width: "100%", borderRadius: "5px", height: "300px", padding: "10px", overflowY: "auto" }}
                                        contentEditable={true}
                                        onInput={handleInput}
                                        onKeyDown={handleKeyDown}
                                        onBlur={handleProcedureContentChange}
                                    />
                                </Col>
                            </Row><br />


                        </div>
                    </Card><br />
                    {text !== "" ? (
                        <div className="cancel-right-text">
                            <Button variant="default" onClick={addNewFeatureText} className="sendreview-btn mt-1 ml-4">Submit</Button>
                            <Link to="/configuration/list"><Button className="save-btn mt-1 ml-3" variant="default">Cancel</Button></Link>
                        </div>
                    ) : (
                        <div className="cancel-right-text">
                            <Button variant="default" disabled="true" className="sendreview-btn mt-1 ml-4">Submit</Button>
                            <Link to="/configuration/list"><Button className="save-btn mt-1 ml-3" variant="default">Cancel</Button></Link>
                        </div>
                    )}
                    <br />
                </Form>
            </div>
        </div >
    );
};

export default AddNewFeatureText;