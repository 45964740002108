import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class CreateOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            offerName: "",
            startDate: "",
            endDate: "",
            url: "",
            rewardPoints: "",
            save: false,
            dateErr: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
      //  console.log("createOffer")
    }
    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    changeStartDate(date) {
        if (date.toDateString() === new Date().toDateString()) {
            this.setState({ dateErr: "You cannot select today" })
        } else {
           // console.log("start", date.toDateString(), "new", new Date().toDateString())
            this.setState({
                startDate: date,
                dateErr: ""
            })
        }
    }
    changeEndDate(date) {
        this.setState({ endDate: date })
    }
    function(date) {
        return (date.getDay() === 0 || date.getDay() === 6);  // disable weekends
    }
    createOffer = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "offerName": this.state.offerName,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "rewardPoints": parseInt(this.state.rewardPoints),
            "url": this.state.url,
            "status": data
        }
       // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/createOffer', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                 //   console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    // saveOffer = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": `Bearer ${this.state.token}`,
    //         "applicationType": "web"
    //     }
    //     var payload = {
    //         "offerName": this.state.offerName,
    //         "startDate": this.state.startDate,
    //         "endDate": this.state.endDate,
    //         "rewardPoints": parseInt(this.state.rewardPoints),
    //         "url": this.state.url
    //     }
    //     console.log("payload", payload)
    //     var self = this
    //     axios.post(config.userUrl + 'user/saveOffer', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status == true) {
    //                 self.setState({
    //                     save: true
    //                 })
    //                 console.log("success", response.data.message, payload);
    //             } else {
    //                 console.log("Error", payload);
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("error", error, payload);
    //         });

    // };
    handleSubmit(event) {
      //  console.log("startDate", this.state)
        event.preventDefault();
    }
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    render() {
        const { redirect, save } = this.state;
        if (redirect) {
            return <Redirect to="/offers" />
        }
        if (save) {
            return <Redirect to="/offers" />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5>Create Offer</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <div className="mt-3 poll-date">Offer Name</div>
                        <div className="form-group" style={{ width: "71.5%" }}>
                            <input className="web-font form-control" name="offerName"
                                placeholder="Offer Name"
                                value={this.state.offerName}
                                onChange={this.onChange} />
                        </div>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Start Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={this.state.startDate}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        onChange={(date) => this.changeStartDate(date)}
                                        filterDate={(date) => date.getDay() === 1}
                                        placeholderText="dd/mm/yyyy"
                                    />
                                    <div className="error">{this.state.dateErr}</div>
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className="poll-date">End Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={this.state.endDate}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date(Date.now() + 5 * 86400000)}
                                        onChange={(date) => this.changeEndDate(date)}
                                        filterDate={(date) => date.getDay() === 5}
                                        placeholderText="dd/mm/yyyy"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Reward Points</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="rewardPoints"
                                        placeholder="Reward Points"
                                        value={this.state.rewardPoints}
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Url</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="url"
                                        placeholder="url"
                                        value={this.state.url}
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                        </Row>
                        {((this.state.offerName !== "") && (this.state.rewardPoints !== "") && (this.state.url !== "") && (this.state.startDate !== "") && (this.state.endDate !== "")) ?

                            <div className="float_right_poll mt-4"> <Button variant="default" onClick={this.showPublish} className="sendreview-btn ml-4">Submit </Button> <Button className="save-btn ml-3" onClick={() => this.createOffer(0)} variant="default">Save</Button></div> :
                            <div className="float_right_poll mt-4"> <Button variant="default" disabled="true" onClick={this.showPublish} className="sendreview-btn ml-4">Submit </Button> <Button className="save-btn ml-3" disabled="true" variant="default">Save</Button></div>}
                        <div id="publish" className="modal_new">
                            <form className="modal-content-log">
                                <div className="md-container">
                                    <p className="mt-2">Do you want to publish the offer?</p>
                                    <center><Button variant="default" onClick={() => this.createOffer(1)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                        <Button variant="default" onClick={this.showNone} className="deletebutton">No</Button></center>
                                </div>
                            </form>
                        </div>
                    </Form>
                </div >
            </div >
        );
    };
}
