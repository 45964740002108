import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
// import { PieChart, Pie, Sector, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Line, Bar, Pie } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
import { CampaignsGraph } from "./CampaignsGraph";
import { LikesGraph } from "./LikesGraph";
import { ReadsGraph } from "./ReadsGraph";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ViewsRewardsCount() {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [activeIndex, setActiveIndex] = useState(0);
    const [totalArticleViews, setTotalArticleViews] = useState("");
    const [totalRewards, setTotalRewards] = useState("");
    const [likesCount, setLikesCount] = useState("");
    const [topicDetails, setTopicDetails] = useState([]);
    const [games, setGames] = useState([]);
    const [gamesPlayed, setGamesPlayed] = useState();

    useEffect(() => {
        getAllCountDetails();
        getTotalRewardPoints();
    }, []);

    const getAllCountDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/aboutPageDetails', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setTotalArticleViews(response.data.totalArticleViews);
                    setLikesCount(response.data.likesCount);
                    setTopicDetails(response.data.topicDetails);
                    setGamesPlayed(response.data.gamesPlayedCount);
                    setGames(response.data.gamesPlayed);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const getTotalRewardPoints = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getTotalRewardPoints', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setTotalRewards(response.data.totalRewardPoints);

                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    let filterTopics = [];
    for (let i = 0; i < topicDetails.length; i++) {
        if (topicDetails[i].postsCount >= 2) {
            filterTopics.push(topicDetails[i]);
        }
    }
   // console.log("labels", filterTopics)

    const data = {
        // labels: [topicDetails[0].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[1].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[2].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[3].topicName.replace(/ *\([^]*\) */g, "")],
        labels: topicDetails.map((data) => data.topicName.replace(/ *\([^]*\) */g, "")),
        datasets: [
            {
                data: topicDetails.map((data) => data.postsCount),
                backgroundColor: ['rgba(255, 203, 113)'],
                borderWidth: 1,
                barThickness: 5,
                borderThickness: 20
            }

        ]
    }
    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
    }
    const gamesData = {
        labels: games.map((data) => data.gameName),
        datasets: [
            {
                data: games.map((data) => data.count),
                backgroundColor: ['rgba(255, 206, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(255, 159, 64, 0.8)'],
                borderWidth: 1,
            }
        ]
    }
    // const data = [
    //     { name: 'Articles Views', value: totalArticleViews },
    //     { name: 'Rewards', value: totalRewards },
    // ];
    const COLORS = ['#31197C', '#FF942C']
    return (
        <div>
            <div className="row">
                <Col>
                    <Card className="mt-3 bar-graph-card" style={{ padding: "15px 25px", border: "none" }}>
                        <center><h4><b>{totalArticleViews}</b></h4></center>
                        <center><h6><b>Views</b></h6></center>
                    </Card>
                </Col>
                <Col>
                    <Card className="mt-3 bar-graph-card" style={{ padding: "15px 25px", border: "none" }}>
                        <center><h4><b>{totalRewards}</b></h4></center>
                        <center><h6><b>Rewards</b></h6></center>
                    </Card>
                </Col>
                <Col>
                    <Card className="mt-3 bar-graph-card" style={{ padding: "15px 25px", border: "none" }}>
                        <center><h4><b>{likesCount}</b></h4></center>
                        <center><h6><b>Likes</b></h6></center>
                    </Card>
                </Col>
            </div>
            {/* <div className="row">
                <Col>
                    <Card className="mt-3 bar-graph-card" style={{ padding: "15px 25px", border: "none" }}>
                   <LikesGraph data={likesCount}/>
                    </Card>
                </Col>
                <Col>
                    <Card className="mt-3 bar-graph-card" style={{ padding: "15px 25px", border: "none" }}>
                       <ReadsGraph data={totalArticleViews}/>
                    </Card>
                </Col>
            </div> */}
            <div className="mt-2 row">
                <Col md={8}>
                    <Card className="mt-3 bar-graph-card">
                        <center><div className="graph-heading-T">Articles per Topic</div></center>
                        <div style={{ height: "200px" }}>
                            <Bar data={data}
                                options={{
                                    elements: {
                                        line: {
                                            tension: 1
                                        },
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                display: false
                                            },
                                            grid: {
                                                drawBorder: false,
                                                display: false,
                                            },

                                        },
                                        y: {
                                            ticks: {
                                                display: true,
                                            },
                                            grid: {
                                                drawBorder: false,
                                                display: false,
                                            },
                                        },
                                    },
                                    plugins: {
                                        title: {
                                            display: false,
                                        },
                                        legend: {
                                            display: false,
                                        },
                                    }
                                }
                                } />
                        </div>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mt-3 bar-graph-card">
                        <center><div className="graph-heading-T">Games</div>
                            <div >
                                <Pie data={gamesData} className="mt-1 ml-2 mr-2" style={{ padding: "10px" }}
                                    options={{
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                        }
                                    }
                                    } />
                            </div>
                        </center>
                    </Card>

                </Col>
            </div>
            {/* <PieChart width={500} height={350}>
                    <Tooltip/>
                    <Legend/>
                    <Pie
                        activeIndex={activeIndex}
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                    >
                          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
                    </Pie>
                </PieChart> */}

        </div >
    )
}
