import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import EditFeature from "./EditFeature";
import CreateSubscription from "./CreateSubscription";
import UpdateSubscription from "./UpdateSubscription";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            popup:false,
            editPopup:false,
            subscriptions: [],
            isLoading: true,
            planId:"",
            planName:"",
            planDescription:"",
            planDuration:"",
            planAmount:"",
            status:"",
            currency:""

        }
    }

    componentDidMount() {
    this.getAllSubscriptions()
    }
    getAllSubscriptions = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getPlans', { headers: headers })
            .then(function (response) {
          //      console.log(response);
                if (response.data.status == true) {
                    self.setState({ subscriptions: response.data.planData, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    createNewFeature=()=>{
        this.setState({popup:true})
    }
    togglePopup=()=>{
        this.setState({popup:false})
    }
    toggleEditPopup=()=>{
        this.setState({editPopup:false})
    }
    editFeaturePopup=(data)=>{
        this.setState({editPopup:true,planId:data.id,planName:data.planName,status:data.status,planDescription:data.planDescription,planAmount:data.planAmount,planDuration:data.planDuration,currency:data.currency})
    }
    render() {
        const { isLoading, subscriptions,popup,editPopup,planId,planName,planDescription,planDuration,status,planAmount,currency } = this.state;

        return (
            <div>
                {popup && <CreateSubscription closePopup={this.togglePopup} getAllSubscriptions={this.getAllSubscriptions}/>}
                {editPopup && <UpdateSubscription closePopup={this.toggleEditPopup} planId={planId} planName={planName} getAllSubscriptions={this.getAllSubscriptions} planDescription={planDescription} status={status} currency={currency} planAmount={planAmount} planDuration={planDuration}/>}
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <div className="mt-3 active-add-h-text">Subscriptions</div>
                    <Button variant="default" className="mt-2 createadv-btn" onClick={this.createNewFeature}>+ &nbsp;Create</Button>
                    {subscriptions.length === 0 ? <center><h4>No Subscriptions.</h4></center> :
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr  >
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Plan Id</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Plan Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Plan Description</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Plan Duration</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Currency</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Status</th>
                                        <th scope="col" className="th-heading" style={{ width: "5%" }}></th>
                                    </tr>
                                </thead>

                                <tbody>{subscriptions.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text">{data.planId}</td>
                                        <td className="td-text">{data.planName}</td>
                                        <td className="td-text">{data.planDescription}</td>
                                        <td className="td-text">{data.planDuration}</td>
                                        <td className="td-text">{data.currency}</td>
                                        <td className="td-text">{data.status == 1 ? "Active" : "Inactive"}</td>
                                        <td className="cursor" onClick={()=>this.editFeaturePopup(data)}><i class="fa fa-pencil left" aria-hidden="true"></i></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div >
        );
    };
}