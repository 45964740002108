import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import EditFeature from "./EditFeature";
import CreateSubscription from "./CreateSubscription";
import UpdateSubscription from "./UpdateSubscription";
import EditFeatureAndSubscription from "./EditFeatureAndSubscription";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class FeaturesAndSubscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            popup: false,
            editPopup: false,
            subscriptions: [],
            newItems: [],
            // subscriptions: [
            //     {
            //         "id": 1, "planName": "Anticipator", "planDuration": "Monthly",
            //         "features": [
            //             { "id": 5, "feature": "Users", "status": false },
            //             { "id": 6, "feature": "Referrals", "status": false },
            //             { "id": 7, "feature": "Polls", "status": false },
            //             { "id": 8, "feature": "New Members", "status": false },
            //         ],
            //     },
            //     {
            //         "id": 2, "planName": "Anticipator", "planDuration": "Yearly",
            //         "features": [
            //             { "id": 5, "feature": "Users", "status": false },
            //             { "id": 6, "feature": "Referrals", "status": false },
            //             { "id": 7, "feature": "Polls", "status": false },
            //             { "id": 8, "feature": "New Members", "status": false },
            //         ],
            //     },
            //     {
            //         "id": 3, "planName": "Integrator", "planDuration": "Monthly",
            //         "features": [
            //             { "id": 5, "feature": "Users", "status": false },
            //             { "id": 6, "feature": "Referrals", "status": false },
            //             { "id": 7, "feature": "Polls", "status": false },
            //             { "id": 8, "feature": "New Members", "status": false },
            //         ],
            //     },
            //     {
            //         "id": 4, "planName": "Integrator", "planDuration": "Yearly",
            //         "features": [
            //             { "id": 5, "feature": "Users", "status": false },
            //             { "id": 6, "feature": "Referrals", "status": false },
            //             { "id": 7, "feature": "Polls", "status": false },
            //             { "id": 8, "feature": "New Members", "status": false },
            //         ]
            //     }
            // ],

            isLoading: true,
            planId: "",
            featureId: "",
            popup:false,
            data:[],
            features:[]

        }
    }

    componentDidMount() {
        this.getPlanBaseFeature()
    }
    getPlanBaseFeature = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllPlanBaseFeature', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    self.setState({ subscriptions: response.data.result })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    editPlanBaseFeature = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = this.state.newItems
        var self = this;
        //console.log("payload",payload)
        axios.post(config.userUrl + 'user/editPlanBaseFeature',payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                  self.getPlanBaseFeature()
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    checkEachfeature = (e, item) => {
        const isChecked = e.target.checked;
        const value = e.target.value;
        const { subscriptions } = this.state
        let newItems = []
       // console.log("item", isChecked, e.target.value)
        var newArr = [];
        const updated = subscriptions.map((item, index) => {
            item.features.map((value, i) => {
                if (e.target.value == value.id) {
                    item.features[i].status = true;
                }
            })
        })
        
       // console.log("newArr", item.features)
        for (var i = 0; i < item.features.length; i++) {
           newArr[i] = ({ planId: item.features[i].planId, featureId:item.features[i].id, status:item.features[i].status })
       }
       //console.log("Arr", newArr)
       this.setState({newItems:newArr})
        //   console.log("i",item.features)
        //   for (var i = 0; i < item.features.length; i++) {
        //       newArr[i] = ({ planId: item.planId, featureId: value.id, status:item.status })
        //   }

        return item.features;
        // const updated = subscriptions.map((data) =>
        // (data.features.map((i) => (e.target.value == i.id) ?
        //     { ...{ "planId": item.planId, "featureId": i.id }, status: !i.status }
        //     : { "planId": item.planId, "featureId": i.id, status: false })))
        // this.setState({ newItems: updated })
        // console.log("update", updated)

        // if(isChecked){
        //     newItems.push({"status":isChecked,"planId":item.id,"featureId":value})
        // }
        // console.log("update", newItems)

        // const updated = allTopics.map(data => (data.id === item + 1) ?
        //     { ...data, isAdded: !data.isAdded } : data)
        // console.log("update", updated)
        // var topic = updated.filter(item => (item.isAdded === true) ? item.id : null)
    }
    // (data.features.map((i) => (e.target.value == i.id) ?
    // { ...{ "planId": item.id, "featureId": value }, status: !i.status }
    // : { "status": false, "planId": item.id, "featureId": value })))
    openPopup=(data)=>{
        this.setState({popup:true,data:data})
    }
    togglePopup=()=>{
        this.setState({popup:false})  
    }
    render() {
        const { subscriptions,popup,data,features } = this.state;

        return (
            <div>
                 {popup && <EditFeatureAndSubscription closePopup={this.togglePopup} data={data} features={data.features} getPlanBaseFeature={this.getPlanBaseFeature}/>}
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <div className="mt-3 active-add-h-text">Subscriptions</div>
                    <div>
                        {/* {subscriptions.map((data, i) => (
                            <Card style={{ width: "95%", padding: "10px" }}>
                                <div style={{ display: "flex" }}>
                                    <div>{data.planName}</div>
                                    <div className="ml-4">{data.planDuration}</div>
                                </div>
                                {data.features.map((item, i) => (
                                    <div key={i}><input type="checkbox" checked={item.status} value={item.id+data.planId}
                                        onChange={(e) => this.checkEachfeature(e, data)} />{item.feature}</div>
                                ))}
                                <div><Button variant="default" onClick={this.editPlanBaseFeature} className="sendreview-btn mt-1 ml-2">Submit</Button></div>
                            </Card>
                        ))} */}
                        <table class="mt-3 table-striped" >
                            <tbody>
                                {subscriptions.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text" >{data.planName}</td>
                                        <td className="td-text">{data.planDuration}</td>
                                        <td className="td-text">
                                            {data.features.map((item, i) => (
                                                <div key={item.id}><li className={item.status?"f-cir":""}>{item.feature}</li></div>
                                            ))}
                                        </td>
                                        <td className="cursor" onClick={()=>this.openPopup(data)}> <i class="fa fa-pencil left" aria-hidden="true"></i></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                    </div>
                </div>
            </div >
        );
    };
}