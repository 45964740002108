import React, { Component } from "react";
import { Button, Form, Col, Row, Card, } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './AuthorProfile.css';
import authorprofile from '../images/authorprofile.svg';
import location from '../images/location.svg';
import { FaStar } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import contact from '../images/contact.svg';
import email from '../images/email.svg';

export default class AuthorProfile extends Component {

    render() {
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "4.8rem", marginLeft: "260px" }}>
                    <Row style={{ width: "50%" }}>
                        <Col md={4}>
                            <img src={authorprofile} style={{ width: "85%" }} />
                        </Col>
                        <Col md={8}>
                            <div className="profile-Text-align">
                                <div className="author-Heading">Franklin Jr</div>
                                <div className="email-text-wt">Author</div>
                                <div><img src={location} style={{ width: "4.5%" }} /> Medan, Suatera Utara-ID</div>
                                <div style={{ display: "flex" }} className="mt-2"><FaStar size="20px" className="fa-star" />&nbsp; <FaStar size="20px" className="fa-star" />
                                    &nbsp;<FaStar size="20px" className="fa-star" />&nbsp;<FaStar size="20px" className="fa-star" />
                                    &nbsp;<FaStar size="20px" />&nbsp; <span>Article Reviews</span></div>
                            </div>
                        </Col>
                    </Row>
                    {/* <Card className="card-width-sm mt-2 mb-3"> */}
                    <Row style={{ width: "100%" }}>
                        {/* <Link to="/profile/followers" className="link" >
                                <Col md={4} style={{
                                    display: "flex"
                                }}>
                                    <div className="numbers ml-2">245 Followers</div>
                                </Col>
                            </Link> */}
                        <div class="-container card-width-sm mt-2 mb-3">
                            <Link to="/profile/followers" className="link" >
                                <div className="numbers item a">245 Followers</div></Link>
                            <Link to="/profile/following" className="link" >
                                <div className="numbers item a">872 Following</div></Link>
                            <div className=" item a"> <Button style={{ display: "flex" }} variant="default" className="edit-profile_btn"><FiEdit className="edit-data" size="20px" /> &nbsp;Edit Profile</Button></div>
                        </div>
                        {/* <Col md={4}>
                                <div className="numbers">872 Following</div>
                            </Col>
                            <Col md={4} >
                                <Button style={{ display: "flex" }} variant="default" className="edit-profile_btn"><FiEdit className="edit-data" size="20px" /> &nbsp;Edit Profile</Button>
                            </Col> */}
                    </Row>
                    {/* </Card> */}
                    <div className="card-about-width-sm">
                        <div className="about-me">About</div>
                        <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</div>
                        <div className="about-me mt-2">Contact</div>
                        <Row style={{ width: "100%" }}>
                            <Col md={5}>
                                <Row style={{ width: "100%", marginTop: "5px" }}>
                                    <Col md={4}>
                                        <img src={contact} className="contact-img-width mt-1" />
                                    </Col>
                                    <Col md={8}>
                                        <div className="phone-text">Phone</div>
                                        <div className="email-text-wt">9848099221</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={7}>
                                <Row style={{ width: "100%" }}>
                                    <Col md={3}>
                                        <img src={email} className="email-img-width mt-2" />
                                    </Col>
                                    <Col md={9}>
                                        <div className="phone-text mt-1">Email</div>
                                        <div className="email-text-wt">franklinjunior@gmail.com</div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div >
        );
    }
}
