import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Redirect, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import createpoll from '../images/createpoll.svg';
import savedpolls from '../images/savedpolls.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ClaimHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            claims: [],
            isLoading: true
        }
    }
    componentDidMount() {
        this.getClaimHistoryForAdmin();
    }
    getClaimHistoryForAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getClaimHistoryForAdmin', { headers: headers })
            .then(function (response) {
              //  console.log("claims", response);
                if (response.data.status == true) {
                  //  console.log("getClaimHistoryForAdmin");
                    self.setState({ claims: response.data.claimHistory, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, claims } = this.state;
        const created = claims.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
         //   console.log("up", updated)
            return updated;
        })
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <div style={{ display: "flex" }}>
                    <NavLink to="/history/usersreach" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Users Reach</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/articles" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Articles</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/polls" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Polls</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/sceemlorereach" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Sceemlore </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/advertisements" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Advertisements</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/referrals" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Referrals </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/opportunities" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Opportunities </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/q&a" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Q&A </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/interests" activeClassName="history-active" className="Link" >
                        <div className="history-Text">EOI</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/blocked" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Blocked Articles</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <NavLink to="/history/claimhistory" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Claims</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                    <NavLink to="/history/reviewers" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Reviewers</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/gamesreach" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Games</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to="/history/smereach" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Sme</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div >
                    <div className="mt-3 active-add-h-text">Redeemed Rewards Reach (Last 90 days)</div>
                    {claims.length === 0 ? <center><h4>No Redeems</h4></center> :
                        <div class="mt-3 tableFixHead">
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr >
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Offer Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "14%" }}>Created On</th>
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Reward Points</th>
                                        <th scope="col" className="th-heading" style={{ width: "26%" }}>Url</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {claims.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text" key={i} >{data.name}</td>
                                            <td className="td-text" key={i} >{data.offerName}</td>
                                            <td className="td-text" key={i} >{created[i]}</td>
                                            <td className="td-text" key={i}>{data.rewardPoints}</td>
                                            <td className="td-text" key={i}>{data.url}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                </div>
            </div >
        );
    };
}

