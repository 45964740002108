import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import CreateNewFeature from "./CreateNewFeature";
import EditFeature from "./EditFeature";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            popup:false,
            editPopup:false,
            features: [],
            isLoading: true,
            featureId:"",
            feature:"",
            subFeature:[],
            relatedTo:"",
            status:"",
            description:""

        }
    }

    componentDidMount() {
    this.getAllFeatures()
    }
    getAllFeatures = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllFeatures', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({ features: response.data.allFeature, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    createNewFeature=()=>{
        this.setState({popup:true})
    }
    togglePopup=()=>{
        this.setState({popup:false})
    }
    toggleEditPopup=()=>{
        this.setState({editPopup:false})
    }
    editFeaturePopup=(data)=>{
        this.setState({editPopup:true,featureId:data.id,subFeature:data.subFeature !== null? data.subFeature:"",feature:data.feature,status:data.status,relatedTo:data.relatedTo,description:data.description})
    }
    render() {
        const { isLoading, features,popup,editPopup,featureId,subFeature,feature,status,relatedTo,description } = this.state;

        return (
            <div>
                {popup && <CreateNewFeature closePopup={this.togglePopup} getAllFeatures={this.getAllFeatures}/>}
                {editPopup && <EditFeature closePopup={this.toggleEditPopup} subFeature={subFeature} featureId={featureId} getAllFeatures={this.getAllFeatures} feature={feature} description={description} status={status} relatedTo={relatedTo}/>}
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <div className="mt-3 active-add-h-text">Features</div>
                    <Button variant="default" className="mt-2 createadv-btn" onClick={this.createNewFeature}>+ &nbsp;Create</Button>
                    {features.length === 0 ? <center><h4>No Features.</h4></center> :
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr  >
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Feature</th>
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Sub Features</th>
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Description</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Status</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Related To</th>
                                        <th scope="col" className="th-heading" style={{ width: "5%" }}></th>
                                    </tr>
                                </thead>

                                <tbody>{features.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text">{data.feature}</td>
                                        <td className="td-text">{data.subFeature != null? <div>{data.subFeature.map((data,i)=>(<div>{data},</div>))}</div>: ""}</td>
                                        <td className="td-text">{data.description}</td>
                                        {/* <td className="td-text">{data.subscriptionType==1?"Anticipator":(data.subscriptionType==2?"Integrator":(data.subscriptionType==3?"Collaborator":"Orchestrator"))}</td> */}
                                        <td className="td-text">{data.status == 1 ? "Active" : "Inactive"}</td>
                                        <td className="td-text">{data.relatedTo == 1 ? "Mobile App" : (data.relatedTo == 2 ? "Web App Author" : (data.relatedTo == 3 ? "Web App Reviewer" : (data.relatedTo == 4 ? "Web App Sme" : "Web App Admin")))}</td>
                                        <td className="cursor" onClick={()=>this.editFeaturePopup(data)}><i class="fa fa-pencil left" aria-hidden="true"></i></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div >
        );
    };
}