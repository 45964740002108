import React, { Component } from 'react';
import './App.css';
import Routes from './Routes';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';

class App extends React.Component {


  render() {
    return (
      <div>
        <Router>
          <Routes />
        </Router>
      </div>
    );
  }
}

export default App;
