import React from 'react';
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import user from '../images/usericon.png';
import pricing from '../images/pricing.png';
import referral from '../images/referral.svg';
import requests from '../images/requests.svg';
import reward from '../images/rewards cir.svg';
import articles from '../images/articles published.png';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            users: [],
            isLoading: true,
            expressionOfInterest: [],
            referrals: [],
            purchases: [],
            posts: [],
            rewards: ""
        }
    }
    componentDidMount() {
        this.getUserTimeline(this.props.id);
      //  console.log("props", this.props)
    }
    getUserTimeline = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + `user/getUserTimeline/${id}`, { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        users: response.data.user,
                        expressionOfInterest: response.data.expressionOfInterest,
                        purchases: response.data.purchases,
                        referrals: response.data.referrals,
                        posts: response.data.posts,
                        rewards: response.data.rewards,
                        isLoading: false
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { users, expressionOfInterest, purchases, referrals, posts, rewards, isLoading } = this.state
       // console.log("users", users, users.name)
        var a = new Date(users.createdAt)
        var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <Modal
                    size="lg"
                    show={true}
                    onHide={this.props.closePopup}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    keyboard={false}
                >
                    <Modal.Body>
                        {/* <div style={{ paddingLeft: "2rem", marginTop: "-10px", paddingBottom: "2rem" }}>
                            <div>
                                1. {users.name} joined on {updated}
                            </div>
                            <div>2. {expressionOfInterest.length === 0 ? <span>No Expression of Interest</span> :
                                <span>
                                    {expressionOfInterest.map((data, i) => (
                                        <span>{data.roleType} shown interest on {data.updatedAt.split("T")[0].split("-").reverse().join("-")}</span>))}</span>
                            }
                            </div>
                            <div>3. {purchases.length === 0 ? <span>No purchases.</span> :
                                <span>
                                    {purchases.map((data, i) => (
                                        <span>{data.planName} purchased on {data.updatedAt.split("T")[0].split("-").reverse().join("-")}</span>))}
                                </span>
                            }
                            </div>
                            <div>4. {referrals.length === 0 ? <span>No Referrals.</span> :
                                <span>
                                    {referrals.map((data, i) => (
                                        <span>Referred {data.name} and emailId: {data.email}, </span>))}</span>
                            }
                            </div>
                            <div>5. {posts.length === 0 ? <span>No Posts</span> :
                                <span>
                                    {posts.map((data, i) => (
                                        <span>{data.title} created on {data.updatedAt.split("T")[0].split("-").reverse().join("-")}</span>))}</span>
                            }

                            </div>
                        </div> */}
                        <div style={{ paddingLeft: "2rem", background: "white", width: "100%", height: "100%", zIndex: "999", paddingBottom: "0.5rem" }}>
                            <center> <h3>User Timeline</h3></center>
                            <center> <h5><b>{users.name} ({users.fourEs})</b></h5></center>
                            <div class="t-container" style={{ marginTop: "-2.5rem" }}>
                                <div class="timeline-block timeline-block-right">
                                    <div class="marker"></div>
                                    <div class="timeline-content">
                                        <img src={user} className='time-user-img' />
                                        <h5>Joined</h5>
                                        <span className='timeline-text'>Joined on {updated}</span>
                                    </div>
                                </div>

                                <div class="timeline-block timeline-block-left">
                                    <div class="marker"></div>
                                    <div class="timeline-content">
                                        <img src={requests} className='time-img' />
                                        <h5>Expression of Interest</h5>
                                        <div>{expressionOfInterest.length === 0 ? <span>No Expression of Interest</span> :
                                            <span className='timeline-text'>
                                                {expressionOfInterest.map((data, i) => (
                                                    <span>Shown interest as {data.roleType} on {data.updatedAt.split("T")[0].split("-").reverse().join("-")}</span>))}</span>
                                        }
                                        </div>
                                    </div>
                                </div>

                                <div class="timeline-block timeline-block-right">
                                    <div class="marker"></div>
                                    <div class="timeline-content">
                                        <img src={pricing} className='time-img' />
                                        <h5>Purchases</h5>
                                        <div>{purchases.length === 0 ? <span>No purchases.</span> :
                                            <span className='timeline-text'>
                                                {purchases.map((data, i) => (
                                                    <span>Purchased plan name {data.planName}  on {data.updatedAt.split("T")[0].split("-").reverse().join("-")}, </span>))}
                                            </span>
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div class="timeline-block timeline-block-left">
                                    <div class="marker"></div>
                                    <div class="timeline-content">
                                        <img src={referral} className='time-img' />
                                        <h5>Referrals</h5>
                                        <div>{referrals.length === 0 ? <span>No Referrals</span> :
                                            <span className='timeline-text'>
                                                <span>Referred {referrals.length} members</span></span>
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div class="timeline-block timeline-block-right">
                                    <div class="marker"></div>
                                    <div class="timeline-content">
                                        <img src={articles} className='time-article-img' />
                                        <h5>Articles</h5>
                                        <div >{posts.length === 0 ? <span>No Posts</span> :
                                            <span className='timeline-text'>
                                                {posts.map((data, i) => (
                                                    <span>Published an article on {data.updatedAt.split("T")[0].split("-").reverse().join("-")}</span>))}</span>
                                        }

                                        </div>
                                    </div>
                                </div>
                                <div class="timeline-block timeline-block-left">
                                    <div class="marker"></div>
                                    <div class="timeline-content">
                                        <img src={reward} className='time-reward-img' />
                                        <h5>Rewards</h5>
                                        <span className='timeline-text'>
                                            <span>{rewards}</span></span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        );
    }
}

export default Popup;

