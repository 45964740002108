import React from 'react';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

class EditFeature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            edit: true,
            featureId:this.props.featureId,
            feature: this.props.feature,
            description: this.props.description,
            status:this.props.status,
            subFeature:this.props.subFeature.toString(),
            relatedTo:this.props.relatedTo,
            redirect: false
        }
    }
    componentDidMount() {
        //console.log("props",this.props.subFeature)
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    updateFeature = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var subItems=this.state.subFeature.split(',')
        if (this.state.subFeature.length == 0) {
            subItems = []
        } else {
            subItems=this.state.subFeature.split(',');
        }
        var payload = {
            "featureId":this.state.featureId,
            "feature": this.state.feature,
            "relatedTo": parseInt(this.state.relatedTo),
            "subFeature":subItems,
            "description": this.state.description,
            "status": val
        }
        //console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'user/updateFeatureById', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.props.getAllFeatures()
                    self.props.closePopup()
                    //console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    }
    render() {
        const { edit, feature, description, subFeature,status, relatedTo } = this.state
        return (
            <Modal
                size="sm"
                show={edit}
                onHide={this.props.closePopup}
                centered
            >
                <Modal.Header closeButton>
                    <div style={{ color: "#FF942C", fontSize:"20px",fontFamily: "poppins" }}>&nbsp;Edit Feature</div>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Form style={{ marginTop: "-0.8rem" }}>
                        <div className="form-group">
                            <input type="text"
                                className="mt-2 web-font form-control text-box-radius"
                                aria-describedby="text"
                                name="feature"
                                value={feature}
                                onChange={this.onChange}
                                placeholder="Feature Name"
                            />
                        </div>
                        <div className="form-group">
                            <input type="text"
                                className="mt-2 web-font form-control text-box-radius"
                                aria-describedby="text"
                                name="subFeature"
                                value={subFeature}
                                onChange={this.onChange}
                                placeholder="Sub Features"
                            />
                        </div>
                        <div className="form-group">
                            <select name="relatedTo" value={relatedTo} onChange={this.onChange} class="mt-2 web-font form-control text-box-radius">
                                <option value="">Select Related To</option>
                                <option value={1}>Mobile App</option>
                                <option value={2}>Web App Author</option>
                                <option value={3}>Web App Reviewer</option>
                                <option value={4}>Web App Sme</option>
                                <option value={5}>Web App Admin</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <textarea name="description"
                                onChange={this.onChange}
                                value={description}
                                placeholder="Description"
                                className="web-font richTextField_feature description-radius"
                                style={{ overflowY: "none", resize: "none" }}
                            />
                        </div>
                        <div>{feature !== "" && relatedTo != "" && description !== "" ?
                            <Button variant="default" onClick={()=>this.updateFeature(1)} className="sendreview-btn mt-1 ml-2">Update</Button> :
                            <Button variant="default" disabled="true" className="sendreview-btn mt-1 ml-2">Update</Button>}
                            {feature !== "" && relatedTo != "" && description !== "" ?
                            <Button variant="default" onClick={()=>this.updateFeature(2)} className="close-btn-popup mt-1">Save</Button>
                            :<Button variant="default" disabled="true" className="close-btn-popup mt-1">Save</Button>}</div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default EditFeature;

