import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, Container, Col, Row, Image } from "react-bootstrap";
import { Link, NavLink, Switch, Route } from 'react-router-dom';
import generalsettings from '../images/general-settings.svg';
import notifications from '../images/general-notifications.svg';
import security from '../images/general-security.svg';
import support from '../images/general-support.svg';
import './Settings.css';
import Sidebar from "../Sidebar/Sidebar";
import NavBar from "../NavBar/NavBar";
import { useLocation } from "react-router-dom";

export default function Settings() {
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    return (
        <div>
            <NavBar />
            <Sidebar />
            <center class="settings-sidebar">
                <h6 className="text-setting-left">Settings</h6>
                <hr style={{ width: "90%" }} />
                <div className={splitLocation[1] === "settings" ? "active-settings top-margin" : "border-settings top-margin"}> <Link to="/settings" className="link"><img src={generalsettings} className="settings-sidebar-icons" /><br /><span className="general-text">General Settings</span><br /></Link></div>
                <div className={splitLocation[1] === "security" ? "active-settings top-margin" : "border-settings top-margin"}> <Link to="/security" className="link"><img src={security} className="settings-sidebar-icons" /><br /><span className="general-text">Security & Login</span><br /></Link ></div>
                <div className={splitLocation[1] === "notifications" ? "active-settings top-margin" : "border-settings top-margin"}><Link to="/notifications" className="link"><img src={notifications} className="settings-sidebar-icons" /><br /><span className="general-text">Notifcations</span><br /></Link></div>
                <div className={splitLocation[1] === "support" ? "active-settings top-margin" : "border-settings top-margin"}><Link to="/support" className="link"><img src={support} className="settings-sidebar-icons" /><br /><span className="general-text">Support</span></Link></div>
            </center >
            {/* <div style={{ marginLeft: "460px", marginTop: "5rem" }}>
                <Card style={{ width: "80%" }}>
                    <p className="notification-heading">Your Account</p>
                </Card>
            </div> */}
        </div >
    );
}


