import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import adv from '../images/advertisement.png';
import './Advertisement.css';
import axios from 'axios';
import sceemimage from '../images/sceem-logo.svg';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i
);
export default class ViewAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            announcements: [],
            isLoading: false,
            redirect: false

        }
    }
    componentDidMount() {
       // console.log(this.state.currentDate)
        this.viewAnnouncement();
    }

    viewAnnouncement = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "announcementId": this.props.match.params.id,
        }
        //console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/getAnnouncementById', payload, { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        announcements: response.data.getAnnouncement,
                        isLoading: false
                    })
                   // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    render() {
        const { redirect,announcements } = this.state;
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5rem", marginLeft: "275px" }} >
                    <Form>

                        <Card style={{ width: "80%" }}>
                            <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                                <h5 className="mt-2 font-heading-web">View Announcement</h5><br />
                                {announcements.map((data, id) => (
                                    <div>
                                        <label className="poll-date">Announcement<span className="red">*</span></label>
                                        <div className="form-group">
                                            <textarea type="text"
                                                className="web-font form-control"
                                                aria-describedby="text"
                                                rows="5"
                                                readOnly
                                                value={data.announcement}
                                            />
                                        </div><br />
                                    </div>
                                    ))}
                            </div>
                        </Card><br />

                    </Form>
                    <Link to="/announcement"><div className="cancel-right"><Button variant="default" className="cancelreview-btn mt-1 ml-4">Cancel</Button>
                    </div></Link>
                </div >
            </div >
        );
    };
}

