import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import Chart from 'chart.js/auto';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export class PollsParticipatedGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            polls: [],
        }
    }

    componentDidMount() {
        this.getPollsParticipated()
    }
    getPollsParticipated = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getPollsParticipated', { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        polls: response.data.pollsParticipated,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { polls } = this.state;
        const pollsData = {
            labels: polls.map((data) => data.pollName),
            datasets: [
                {
                    data: polls.map((data) => data.count),
                    borderColor: '#32a852',
                    backgroundColor: '#32a852',
                    borderWidth: 1,
                    borderThickness: 20
                }
            ]
        }

        return (
            <div>
                <Card className="ml-1 mr-1 bar-graph-card box-shad" style={{background:"rgba(89, 247, 179,0.1)"}}>
                    <center><div className="graph-heading-T">Polls Participated</div></center>
                    <div style={{ marginTop: "-3px", height: "158px", width: "auto" }}>
                        <Line data={pollsData} className="mt-1"
                            options={{
                                responsive: true,
                                elements: {
                                    line: {
                                        tension: 1
                                    }
                                },
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        }
                                    },
                                    y: {
                                        ticks: {
                                            precision: 0
                                        },
                                        beginAtZero: true,
                                        grid: {
                                            display: false,
                                        },
                                    },
                                },
                                plugins: {
                                    title: {
                                        display: false,
                                    },
                                    legend: {
                                        display: false
                                    }
                                }
                            }
                            } />
                    </div>
                </Card>
            </div>
        );
    };
}