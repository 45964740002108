import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import Chart from 'chart.js/auto';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export class PollsGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            polls: [],
            selectedValue: 11
        }
    }

    componentDidMount() {
        this.getPollsAddedByMonth()
    }
    subtractMonths = (val, date = new Date()) => {
        date.setMonth(date.getMonth() - val);
      //  console.log("date", date)
        return date;
    }
    onChange = (e) => {
        this.setState({ selectedValue: e.target.value })
        this.getPollsAddedByMonth(e.target.value)
    }
    getPollsAddedByMonth = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        if (val == undefined) {
            val = 11
        } else {
            val = val
        }
        var startD = this.subtractMonths(parseInt(val), new Date()).getFullYear() + "-" + parseInt(this.subtractMonths(parseInt(val), new Date()).getMonth() + 1) + "-" + this.subtractMonths(parseInt(val), new Date()).getDate();
        var endD = new Date().getFullYear() + "-" + parseInt(new Date().getMonth() + 1) + "-" + new Date().getDate();
        var payload = {
            "startDate": startD,
            "endDate": endD
        }
      //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/getPollsAddedByMonth', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        polls: response.data.pollsData,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { polls, selectedValue } = this.state;
        const pollsData = {
            labels: polls.map((data) => data.month),
            datasets: [
                {
                    data: polls.map((data) => data.count),
                    borderColor: '#10a0e3',
                    backgroundColor: "#10a0e3",
                    borderWidth: 1,
                    borderThickness: 20
                }
            ]
        }

        return (
            <Card className="bar-graph-card box-shad">
                <div className="row" style={{marginTop:"2px"}}>
                    <Col> <center className="mt-4"><h6  style={{marginTop:"10px"}}><b>Polls in 1 year</b></h6></center></Col>
                    <Col>
                    <div style={{width:"12vh", height:"12vh"}}>
                        <Line data={pollsData} className="mt-3"
                            options={{
                                aspectRatio: 1,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                            lineWidth: 0,
                                        },
                                        ticks: {
                                            display: false,
                                            beginAtZero: true
                                        },
                                    },
                                    y: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                            lineWidth: 0,
                                        },
                                        ticks: {
                                            display: false,
                                            beginAtZero: true
                                        },
                                    },
                                },
                                plugins: {
                                    title: {
                                        display: false,
                                    },
                                    legend: {
                                        display: false
                                    }
                                }
                            }
                            } />
                            </div>

                    </Col>
                </div>
            </Card>
        );
    };
}