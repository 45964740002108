
import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import addimage from '../images/addimage.svg';
import './WriteArticle.css';
import axios from 'axios';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ViewArticle(props) {
    const [allTopics, setAllTopics] = useState([]);
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [image, setImage] = useState("")
    const [topic, setTopic] = useState("")
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("")
    const [redirect, setRedirect] = useState(false)
    const [review, setReview] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [update, setUpdate] = useState(false)
    const [htmlName, setHtmlName] = useState('');
    const [topicId, setTopicId] = useState("")
    const [question, setQuestion] = useState('')
    const [option1, setOption1] = useState('')
    const [option2, setOption2] = useState('')
    const [option3, setOption3] = useState('')
    const [option4, setOption4] = useState('')
    const [savedPost, setSavedPost] = useState(false)
    const refText = useRef('myTextarea');
    const [userName, setUserName] = useState('');
    const [wLength, setWLength] = useState(750)

    const modules = {
        toolbar: [
            [{ header: '1' }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }]
        ]
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "list",
        "bullet"
    ];

    const handleProcedureContentChange = (text, delta, source, editor) => {
        setContent(text);
    };
    function countWords(content) {
        const arr = content.split(' ');
        return arr.filter(word => word !== '').length;
    }
    const fileHandler = (e) => {
        setImage(e.target.files[0])
    }
    const goBack = () => {
        props.history.goBack()
    }
    useEffect(() => {
        getPostById();
    }, []);

    const getPostById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + `user/getPostById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    setTopicId(response.data.topicDetails.id)
                    setTopic(response.data.topicDetails.topicName)
                    setTitle(response.data.post[0].title)
                    setImage(response.data.post[0].image)
                    setContent(response.data.post[0].content)
                    setStatus(response.data.post[0].status)
                    setDescription(response.data.post[0].description)
                    setQuestion(response.data.post[0]?.question)
                    setOption1(response.data.postQuestionData[0]?.option)
                    setOption2(response.data.postQuestionData[1]?.option)
                    setOption3(response.data.postQuestionData[2]?.option)
                    setOption4(response.data.postQuestionData[3]?.option)
                    setUserName(response.data.userName)
                    setIsLoading(false)
                    // parseMarkdown(response.data.post[0].content)
                   // console.log("success", response.data);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    function handleB(e) {
        e.preventDefault();
        let textVal = refText.current;
        let cursorStart = textVal.selectionStart;
        let cursorEnd = textVal.selectionEnd;
        var selectedText = content.substring(cursorStart, cursorEnd)
        var updatedText = content.replace(selectedText, "*" + selectedText + "*");
        setContent(updatedText);
        parseMarkdown(updatedText);
        refText.current.focus()
    }

    function handleI(e) {
        e.preventDefault();
        let textVal = refText.current;
        let cursorStart = textVal.selectionStart;
        let cursorEnd = textVal.selectionEnd;
        var selectedText = content.substring(cursorStart, cursorEnd)
        var updatedText = content.replace(selectedText, "%" + selectedText + "%");
        setContent(updatedText);
        parseMarkdown(updatedText);
        refText.current.focus()
    }
    function handleU(e) {
        e.preventDefault();
        let textVal = refText.current;
        let cursorStart = textVal.selectionStart;
        let cursorEnd = textVal.selectionEnd;
        var selectedText = content.substring(cursorStart, cursorEnd)
        var updatedText = content.replace(selectedText, "_" + selectedText + "_");
        setContent(updatedText);
        parseMarkdown(updatedText);
        refText.current.focus()
    }

    function handleH(e) {
        e.preventDefault();
        let textVal = refText.current;
        var allTextLines = textVal.value.substring(0).split("\n");
        var textLines = textVal.value.substring(0, textVal.selectionStart).split("\n");
        var currentLineNumber = textLines.length;
        //console.log("currentLineNumber", currentLineNumber)
        var currentTextLine = allTextLines[currentLineNumber - 1];
        var updatedTextLine
        if (currentTextLine.length === 0)
            updatedTextLine = content.concat("#");
        else
            updatedTextLine = content.replace(currentTextLine, "#" + currentTextLine);
        setContent(updatedTextLine);
        parseMarkdown(updatedTextLine);
        refText.current.focus()
    }
    function handleL(e) {
        e.preventDefault();
        let textVal = refText.current;
        var allTextLines = textVal.value.substring(0).split("\n");
        var textLines = textVal.value.substring(0, textVal.selectionStart).split("\n");
        var currentLineNumber = textLines.length;
       // console.log("currentLineNumber", currentLineNumber)
        var currentTextLine = allTextLines[currentLineNumber - 1];
       // console.log("currentTextLine", currentTextLine, currentTextLine.length);
        var updatedTextLine
        if (currentTextLine.length === 0)
            updatedTextLine = content.concat(">");
        else
            updatedTextLine = content.replace(currentTextLine, ">" + currentTextLine);
        setContent(updatedTextLine);
        parseMarkdown(updatedTextLine);
        refText.current.focus()
    }

    function parseMarkdown(markdownText) {
        var htmlText = markdownText
            .replace(/^#(.*$)/gim, '<h6 class="heading-color"><b>$1</b></h6>')
            .replace(/^>(.*$)/gim, '<li>$1</li>')
            .replace(/\_(.+?)\_/gim, '<u>$1</u>')
            .replace(/\%(.+?)\%/gim, '<i>$1</i>')
            .replace(/\*(.+?)\*/gim, '<b>$1</b>')
            .replace(/\n*$/gim, '<p />')

        setContent(markdownText);
        setHtmlName(htmlText.trim())
        return htmlText.trim();
    }
    const savePost = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "postId": props.match.params.id,
            "topicId": topicId,
            "title": title,
            "imageUrl": image,
            "content": content,
            "sendToReview": false,
            "question": question,
            "options": [option1, option2, option3, option4]
        }
        //console.log("payload", payload)
        axios.post(config.userUrl + 'user/editPost', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    setSavedPost(true)
                   // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    if (isLoading) {
        return <div className="App"></div>;
    }
    if (savedPost) {
        return <Redirect to="/articles" />;
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "4.8rem", marginLeft: "260px" }}>
                <Row style={{ width: "100%" }} className="no-gutters">
                    <Col md={9}>
                        <Form style={{ marginLeft: "1.2rem", width: "96%" }} >
                            <Row style={{ width: "100%" }}>
                                <Col md={1}>
                                    <div className="image_topicId mt-2" >
                                        <div><img src={config.userUrl + image} className={"create-imageurl"} /></div>
                                    </div>
                                </Col>
                                <Col md={11}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col md={12}>
                                            <div className="form-group" style={{ marginLeft: "2.3rem", width: "98.5%" }}>
                                                <select name="topicId" readOnly class="web-font form-control text-box-radius">
                                                    <option value={topicId}>{topic}</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <textarea type="text"
                                                className="web-font form-control text-box-radius"
                                                aria-describedby="text"
                                                name="title"
                                                readOnly
                                                rows={2}
                                                style={{ overflowY: "none", marginLeft: "2.3rem", width: "98.5%", marginTop: "-5px", resize: "none" }}
                                                value={title}
                                                placeholder="Article Title"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {/* <div style={{ display: "flex", float: "right", marginRight: "-4px" }}>

                                <Button disabled="true" variant="default" className="boldview" onClick={handleH} type="submit">
                                    H
                                </Button>

                                <Button disabled="true" variant="default" className="boldview" onClick={handleB} type="submit">
                                    B
                                </Button>

                                <Button disabled="true" variant="default" className="boldview" onClick={handleU} type="submit">
                                    U
                                </Button>
                                <Button disabled="true" variant="default" className="boldIview" onClick={handleI} type="submit">
                                    I
                                </Button>
                                <Button disabled="true" variant="default" className="boldview" onClick={handleL} type="submit">
                                    L
                                </Button>
                            </div> */}
                            <div className="form-group">
                                <div className="form-group">
                                    <div>
                                        {/* {content.split(" ").length <= 500 ? */}
                                        <div id="editor" style={{ background: "#e9ecef" }}>
                                            {/* <div className="mt-1">  <span style={{ marginLeft: "4px" }}> {content.split(" ").length - 1} / 500</span></div> */}
                                            {/* <textarea name="content" value={content}
                                                className="web-font richTextField-read  description-radius"
                                                ref={refText}
                                                readOnly
                                                style={{ overflowY: "none", resize: "none" }}
                                                onChange={(e) => parseMarkdown(e.target.value)}
                                            /> */}
                                            <div className="split-content">{countWords(content)} / {wLength} &nbsp;{sessionStorage.getItem('role') === 'admin' ? ({ userName }) : ""}</div>
                                            <ReactQuill
                                                style={{ overflowY: "none", resize: "none", height: "80%", background: "#e9ecef" }}
                                                modules={modules}
                                                readOnly="true"
                                                formats={formats}
                                                value={content}
                                                onChange={handleProcedureContentChange}
                                            />
                                        </div>

                                        {/* :<div><div className="mt-1"><span style={{ marginLeft: "4px" }}> {content.split(" ").length - 1} / 500</span></div><br />
                                            <div className="error ml-1">   You cannot write more than 500 words</div>
                                            <textarea name="content" value={content}
                                                readOnly
                                                className="web-font richTextField-read description-radius"
                                                ref={refText}
                                                style={{ overflowY: "none", resize: "none" }}
                                                onChange={(e) => parseMarkdown(e.target.value)}
                                            /></div>} */}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                                <textarea
                                    rows={2}
                                    readOnly
                                    name="description"
                                    value={description}
                                    className="web-font form-control"
                                    style={{ resize: "none", wordWrap: "break-word", }}
                                />
                            </div>
                            <div style={{ width: "100%", border: "none" }}>
                                <div className="mt-1">
                                    <div >
                                        <div className="form-group mt-1" style={{ width: "100%" }}>
                                            <input type="text"
                                                className="web-font form-control form-bg"
                                                aria-describedby="text"
                                                value={question}
                                                readOnly
                                                name="question"
                                                placeholder="Write Your Question"
                                                onChange={(e) => setQuestion(e.target.value)}
                                            />
                                        </div>
                                        <Row style={{ width: "100%" }}>
                                            <Col>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option1"
                                                        placeholder={`Write your option 1`}
                                                        readOnly
                                                        value={option1}
                                                        onChange={(e) => setOption1(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option3"
                                                        placeholder={`Write your option 3`}
                                                        readOnly
                                                        value={option3}
                                                        onChange={(e) => setOption3(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option2"
                                                        placeholder={`Write your option 2`}
                                                        readOnly
                                                        value={option2}
                                                        onChange={(e) => setOption2(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mt-1 display">
                                                    <div className="radio-check"></div>&nbsp;&nbsp;
                                                    <input type="text" className="web-font form-control"
                                                        name="option4"
                                                        placeholder={`Write your option 4`}
                                                        readOnly
                                                        value={option4}
                                                        onChange={(e) => setOption4(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{display:"flex",justifyContent:"space-between"}}>
                                {status === "rejected" ?
                                    <Button variant="default" onClick={savePost} style={{float:"left"}} className="cursor cancelreview-btn_ mt-1">Copy</Button> :
                                    <Button variant="default" style={{float:"left",visibility:"hidden"}} className="cancelreview-btn_ mt-1">Copy</Button>}
                                <Link to="/articles"><Button variant="default" style={{float:"right"}} className="cancelreview-btn_ mt-1">Back</Button></Link>
                            </div> */}
                            <div>
                                <Link to="/articles"><Button variant="default" className="cancelreview-btn mt-1 ml-4">Back</Button></Link>
                                {status === "rejected" ?
                                    <Button variant="default" onClick={savePost} className="cursor save-btn mt-1 ml-4">Rework</Button> :
                                    <Button variant="default" style={{ visibility: "hidden" }} className="save-btn mt-1 ml-4">Rework</Button>}
                            </div>
                        </Form>
                    </Col>
                    <Col md={3} >
                        <div style={{ width: "100%" }}>
                            <div class="scrolling-area">
                                <div class="scrolling-element-inside">
                                    <img src={config.userUrl + image} alt="" width="100%" height="250px" />
                                    <p className="article-title article-title-color mt-2"> {title}</p>
                                    <div className="web-font article-content" style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", width: "100%" }} dangerouslySetInnerHTML={{ __html: content }}></div>
                                    {question === null || question === "" ? <div></div>
                                        :
                                        // <div style={question === null ? { display: "none" } : { border: "1px solid #31197C", borderRadius: "10px", padding: "5px 10px" }}>
                                        //     <p className="article-title mt-2"> {question}</p>
                                        //     <div className="web-font article-content">{option1}</div>
                                        //     <div className="web-font article-content">{option2}</div>
                                        //     <div className="web-font article-content">{option3}</div>
                                        //     <div className="web-font article-content">{option4}</div>
                                        // </div>
                                        <Card className="mt-3 poll-answer-card-post" style={question === null || question === "" ? { display: "none" } : { borderRadius: "10px", padding: "5px 10px" }}>
                                            <center className="question_post">{question}</center>
                                            <div className="polloptions-card-post"><div className="poll-text">{option1}</div></div>
                                            <div className="polloptions-card-post"><div className="poll-text">{option2}</div></div>
                                            <div className="polloptions-card-post"><div className="poll-text">{option3}</div></div>
                                            <div className="polloptions-card-post"><div className="poll-text">{option4}</div></div>
                                        </Card>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div >
    );
};



