import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            usersData: [],
            selectedValue: 11
        }
    }

    componentDidMount() {
        this.getUsersAddedByMonth()
    }
    subtractMonths = (val, date = new Date()) => {
        date.setMonth(date.getMonth() - val);
      //  console.log("date", date)
        return date;
    }
    onChange = (e) => {
        this.setState({ selectedValue: e.target.value })
        this.getUsersAddedByMonth(e.target.value)
    }
    getUsersAddedByMonth = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        if (val == undefined) {
            val = 11
        } else {
            val = val
        }
        var startD = this.subtractMonths(parseInt(val), new Date()).getFullYear() + "-" + parseInt(this.subtractMonths(parseInt(val), new Date()).getMonth() + 1) + "-" + this.subtractMonths(parseInt(val), new Date()).getDate();
        var endD = new Date().getFullYear() + "-" + parseInt(new Date().getMonth() + 1) + "-" + new Date().getDate();
        var payload = {
            "startDate": startD,
            "endDate": endD
        }
       // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/getUsersAddedByMonth', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        usersData: response.data.usersData,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { usersData, selectedValue } = this.state;
        return (
            <div >
                <Card className="mt-3 bar-graph-card">
                    <form style={{ position: "absolute", right: "10px", width: "20%" }}> <select name="selectedValue" value={selectedValue} onChange={this.onChange} class="mt-2 form-drop form-control">
                        <option value={2}>3 Months</option>
                        <option value={5}>6 Months</option>
                        <option value={11}>1 Year</option>
                    </select>
                    </form>
                    <center><h5>Users</h5></center>
                        <LineChart
                            width={500}
                            height={300}
                            data={usersData}
                            margin={{
                                top: 10,
                                right: 10,
                                left: 5,
                                bottom: 5
                            }}
                        >
                            <XAxis dataKey="month" scale="point" style={{ fontFamily: 'Open Sans', fontSize: "10px" }} />
                            <YAxis style={{ fontFamily: 'Open Sans', fontSize: "10px" }} />
                            <Tooltip cursor={false} style={{ fontFamily: 'Open Sans', fontSize: "10px" }} />
                            <Line
                                type="monotone"
                                dataKey="count"
                                stroke="#8884d8"
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                </Card>
            </div>
        );
    }
}
