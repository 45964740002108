import React, { useParams, useRef, useState, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, useRouteMatch, NavLink, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Sme.css';
import orange from '../images/orange-circle.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function Sme() {
    const [selected, setSelected] = useState(0)
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [questions, setQuestions] = useState([])
    const [htmlName, setHtmlName] = useState('');
    const [content, setContent] = useState('');
    const [answer, setAnswer] = useState('');
    const refText = useRef('myTextarea');
    const [userTopics, setUserTopics] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [topicsLoading, setTopicsLoading] = useState(true)

    let { path, url } = useRouteMatch();
   // console.log(path)

    useEffect(() => {
        getQuestionDetails();
        getAllRoleRequests();
    }, []);
    const getAllRoleRequests = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllRoleRequests', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setUserTopics(response.data.topicDetails)
                    setTopicsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const getQuestionDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }

        axios.get(config.userUrl + 'user/getQuestionDetails', { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status === true) {
                    setQuestions(response.data.questionData)
                    setIsLoading(false)
                //    console.log("getReviewQueue", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const reviewPost = (data) => {
     //   console.log("edit", data)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "questionId": data,
            "answer": answer
        }
        //console.log("payload", payload)
        axios.post(config.userUrl + 'user/saveAnswerDetails', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    window.location.href = '/smequeue'
                 //   console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    if (!sessionStorage.getItem('junkStore')) {
        return <Redirect to='/' />;
    }
    if (isLoading) {
        return <div></div>
    }
    if (topicsLoading) {
        return <div></div>
    }
    const updatedDate = questions.map((i, idx) => {
        var a = new Date(i.createdAt)
        var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return updated;
    })
    const showAccept = () => {
        document.getElementById('accept').style.display = 'flex'
    }
    const showAcceptNone = () => {
        document.getElementById('accept').style.display = 'none'
    }
    const showReject = () => {
        document.getElementById('reject').style.display = 'flex'
    }
    const showRejectNone = () => {
        document.getElementById('reject').style.display = 'none'
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>

                <Row style={{ width: "100%" }}>
                    <Col md={4}>
                        <div class="scrolling-area-review">
                            <div class="scrolling-element-inside">
                                <div className="inbox">Questions</div>
                                {questions.length !== 0 ?
                                    <div>
                                        {questions.map((data, i) => (
                                            <div>
                                                <Card style={{ border: "none" }}>
                                                    <NavLink to={`${path}/${data.id}`} className="inbox_Card link" activeClassName="_Active" key={i} >
                                                        <Row style={{ width: "100%" }}>
                                                            <Col md={2}>
                                                                <img src={orange} style={{ width: "32px" }} />
                                                            </Col>
                                                            <Col md={7}>
                                                                <div className="article-approved">{data.name}</div>
                                                                {data.topicDetails.map((item, i) => (
                                                                    <div className="messages">{item.topicName.replace(/ *\([^]*\) */g, "")}</div>
                                                                ))}

                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="time">{updatedDate[i]}</div>
                                                            </Col>
                                                        </Row>
                                                    </NavLink>
                                                </Card>
                                                <hr /></div>
                                        ))}
                                    </div> : <p>No Questions</p>}


                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <Card style={{ height: "85vh" }}>
                            {questions.map((data, i) => (
                                <Route key={i} path={`${path}/${data.id}`} >
                                    <div>
                                        <div class="scrolling-area-review-queue">
                                            <div class="scrolling-element-inside">
                                                {/* <img src={config.userUrl + data.image} className="article-title mt-2 ml-5" style={{ width: "76%", height: "200px" }} /> */}
                                                <p className="article-title mt-2 ml-5">&nbsp;{data.name}</p>
                                                <div className="form-group">
                                                    <div
                                                        readOnly
                                                        className=" richTextFieldreview description-radius"
                                                        style={{ resize: "none", wordWrap: "break-word", }}
                                                    >{data.question}</div>
                                                    <p className="article-title mt-2 ml-5">&nbsp;Topics</p>
                                                    <div className="richTextFieldSme">
                                                        {data.topicDetails.map((item, i) => (
                                                            <div className="messages">&nbsp;&nbsp; {item.topicName.replace(/ *\([^]*\) */g, "")}</div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {(data.topicDetails.filter(o1 => userTopics.some(o2 => o1.topicName === o2.topicName)).length !== 0) ?
                                            <div>
                                                <div className="ml-5 mr-3 mt-3 form-group">
                                                    <textarea
                                                        rows={2}
                                                        name="answer"
                                                        value={answer}
                                                        placeholder="Write your answer"
                                                        onChange={(e) => setAnswer(e.target.value)}
                                                        className="ml-4 web-font form-control"
                                                        style={{ width: "90%", resize: "none", wordWrap: "break-word", }}
                                                    />
                                                </div>
                                                <div className="mr-3">
                                                    <div className="float_Right mr-5" >
                                                        <div>{answer !== "" ?
                                                            <div ><Button onClick={showAccept} className="sendreview-btn mt-1" variant="default">Submit</Button>
                                                                <div id="accept" className="modal_new">
                                                                    <form className="modal-content-log">
                                                                        <div className="md-container">
                                                                            <p className="mt-2">Do you really want to Submit?</p>
                                                                            <center><Button variant="default" onClick={() => reviewPost(data.id)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                                <Button variant="default" onClick={showAcceptNone} className="deletebutton">No</Button></center>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div> :
                                                            <div><Button variant="default" disabled="true" className="sendreview-btn mt-1">Submit</Button></div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div ><center className="mt-2 error-review">This topic is not of your choice to answer. You can view the question.</center><br />
                                                <div className="mr-3"><div className="float_Right mr-5" style={{ marginTop: "32px" }}>
                                                    <Button variant="default" disabled="true" className="sendreview-btn mt-1">Submit</Button></div></div>
                                            </div>}
                                    </div>
                                </Route>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div >
        </div >
    );
}





