import React from 'react';
import { render } from 'react-dom';
import './Example.css';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class SequencePopupCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
      edit: true,
      cards: this.props.data,
      items: [],

    };

    this.itemRenderer = this.itemRenderer.bind(this);
    this.handleRLDDChange = this.handleRLDDChange.bind(this);
  }
  componentDidMount() {
  //  console.log("cards", this.props.data)
    var newArray = this.state.cards.map(data => ({ id: data.cardNumber, cardNumber: data.cardNumber, cardDetailId: data.id, cardTitle: data.cardTitle }));
    this.setState({ items: newArray })
  }
  modifyCards = () => {
    let headers = {
      "Content-Type": 'application/json',
      "Authorization": "Bearer " + this.state.token,
      "applicationType": "web"
    }
   // console.log("items", this.state.items)
    var sequenceArr = this.state.items.map((data, i) => ({ cardDetailId: data.cardDetailId, cardNumber: i + 1 }))
    var payload = {
      "sequenceData": sequenceArr
    }
    //console.log("payload", payload)
    var self = this;
    axios.post(config.userUrl + 'user/cardSequenceChange', payload, { headers: headers })
      .then(function (response) {
      //  console.log(response);
        if (response.data.status == true) {
          self.props.getCardById()
          self.props.closePopup()
         // console.log("success", response.data.message, payload);
        } else {
          console.log("Error", payload);
        }
      })
      .catch(function (error) {
        console.log("error", error, payload);
      });
  };
  render() {
    const items = this.state.items;

    return (
      <div>
        <Modal
          size="sm"
          show={this.state.edit}
          onHide={this.props.closePopup}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <center style={{ color: "#FF942C" }}>Sequence Cards</center>
            <RLDD
              cssClasses="example"
              items={items}
              itemRenderer={this.itemRenderer}
              onChange={this.handleRLDDChange}
            /><br /><br />
            <Button variant="default" onClick={this.modifyCards} className="publish-btn mt-1">Modify</Button>
          </Modal.Body>
        </Modal>
      </div >
    );
  }

  itemRenderer(item, index) {
    return (
      <div className="item">
        <p className="body">{item.cardTitle}</p>
        <div className="small">
          item.id: {item.id} - index: {index}
        </div>
      </div>
    );
  }

  handleRLDDChange(reorderedItems) {
    this.setState({ items: reorderedItems });
  }
}