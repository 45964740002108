import React, { Component } from "react";
import { Button, Form, Col, Row, Image } from "react-bootstrap";
import logo from '../images/logo.svg';
import nebutech from '../images/nebutech.svg';
import google from '../images/google.svg';
import { Link } from 'react-router-dom';
import './Register.css';

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
export default class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            password: "",
            showPassword: false,
            redirect: false,
            nameError: '',
            emailError: '',
            passwordError: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateName = this.validateName.bind(this);
        this.validateEmailAddress = this.validateEmailAddress.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateField = this.validateField.bind(this);
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }

    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }

    handleSubmit(event) {
        event.preventDefault();
      //  console.log(this.state);
        let formFileds = [
            "name",
            "email",
            "password"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        alert("Registered Successfully!!")
    }

    validateField(name) {
        let isValid = false;
        if (name === "name") isValid = this.validateName();
        else if (name === "email") isValid = this.validateEmailAddress();
        else if (name === "password") isValid = this.validatePassword();
        return isValid;
    }

    validateName() {
        let nameError = "";
        const value = this.state.name;
        if (value.trim() === "") nameError = "Name is required";
        else if (value.length < 5)
            nameError = 'Name must be at least 5 characters long!';
        this.setState({
            nameError
        });
        return nameError === "";
    }

    validateEmailAddress() {
        let emailError = "";
        const value = this.state.email;
        if (value.trim === "") emailError = "Email is required";
        else if (!validEmailRegex.test(value))
            emailError = "Please Enter Email";
        this.setState({
            emailError
        });
        return emailError === "";
    }
    validatePassword() {
        let passwordError = "";
        const value = this.state.password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Minimum 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        this.setState({
            passwordError
        });
        return passwordError === "";
    }
    render() {
        return (
            <div className="background">
                <div class="log">
                    <center>  <img src={logo} className="logo-width" /></center>
                    <div className="login">REGISTER</div>
                    <div className="login-sm">Create a new account</div><br />

                    <form onSubmit={this.handleSubmit}>
                        <div class="input-border">
                            <input type="text" name="name" class="text" required
                                onBlur={this.handleBlur}
                                onChange={this.onChange} />
                            <label className="label">Full Name</label>
                            <div class="border "></div>
                            <div className="div">{this.state.nameError && (
                                <div className="error">{this.state.nameError}</div>
                            )}</div>
                        </div>
                        <div class="input-border">
                            <input type="text" name="email" class="text" required
                                onBlur={this.handleBlur}
                                onChange={this.onChange} />
                            <label className="label">Email address</label>
                            <div class="border "></div>
                            <div className="div">{this.state.emailError && (
                                <div className="error">{this.state.emailError}</div>
                            )}</div>
                        </div>
                        <div class="input-border">
                            <input type="password" name="password" class="text" required
                                onBlur={this.handleBlur}
                                onChange={this.onChange} />
                            <label className="label">Password</label>
                            <div class="border"></div>
                            <div className="div">{this.state.passwordError && (
                                <div className="error">{this.state.passwordError}</div>
                            )}</div>
                        </div>
                        <div class="input-border">
                            <input type="checkbox" /><label className="label me">I accept <span className="agree"> Terms & Conditions</span></label>
                        </div>
                        <Button variant="default" type="submit" className="btn-register">SIGN UP</Button><br /><br />
                        <center>
                            <div className="login-top"><span className="color-grey">Or </span><span style={{ color: "#31179C" }}>Sign up</span><span className="color-grey"> using with</span> <img src={google} className="google" /></div>
                            <span className="color-grey">Already have an account ?</span><Link to="/register"><span style={{ color: "#31197C" }}> Login Here</span></Link></center>
                    </form><br />

                </div>
                <div className="nebutech" ><img src={nebutech} /></div>
            </div >
        )
    }
}