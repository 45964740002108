import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import Chart from 'chart.js/auto';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export class PurchasesGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            purchases: [],
            selectedValue: 11
        }
    }

    componentDidMount() {
        this.getPurchasesAddedByMonth()
    }
    subtractMonths = (val, date = new Date()) => {
        date.setMonth(date.getMonth() - val);
      //  console.log("date", date)
        return date;
    }
    onChange = (e) => {
        this.setState({ selectedValue: e.target.value })
        this.getPurchasesAddedByMonth(e.target.value)
    }
    getPurchasesAddedByMonth = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        if (val == undefined) {
            val = 11
        } else {
            val = val
        }
        var startD = this.subtractMonths(parseInt(val), new Date()).getFullYear() + "-" + parseInt(this.subtractMonths(parseInt(val), new Date()).getMonth() + 1) + "-" + this.subtractMonths(parseInt(val), new Date()).getDate();
        var endD = new Date().getFullYear() + "-" + parseInt(new Date().getMonth() + 1) + "-" + new Date().getDate();
        var payload = {
            "startDate": startD,
            "endDate": endD
        }
      //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/getPurchasesAddedByMonth', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        purchases: response.data.sortedPurchasesData,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { purchases, selectedValue } = this.state;
        const purchasesData = {
            labels: purchases.map((data) => data.status == 1 ? "Waiting" : data.status == 2 ? "Completed" : data.status == 3 ? "Accepted" : data.status == 4 ? "Rejected" : data.status == 5 ? "Refunded" : "Pending Refund"),
            datasets: [
                {
                    data: purchases.map((data) => data.count),
                    borderColor: '#10a0e3',
                    backgroundColor: "#10a0e3",
                    borderWidth: 1,
                    borderThickness: 20
                }
            ]
        }
        return (
            <div>
                    <form style={{ position: "absolute", zIndex: 5, marginTop:"-4px",right: "15px", width: "30%" }}> <select name="selectedValue" style={{background:"rgba(16, 160, 227,0.01)"}} value={selectedValue} onChange={this.onChange} class="mt-2 select-font form-drop form-control">
                        <option value={2}>3 Months</option>
                        <option value={5}>6 Months</option>
                        <option value={11}>1 Year</option>
                    </select> </form>
                    <Card className="ml-1 bar-graph-card box-shad mr-1" style={{background:"rgba(16, 160, 227,0.1)"}}>
                        <center><div className="graph-heading-T">Purchases</div></center>
                        <div style={{marginTop:"-4px",height:"158px",width:"auto"}}>
                            <Line data={purchasesData}
                                options={{
                                    responsive: true,
                                    scales: {
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                display: false,
                                            }
                                        },
                                        y: {
                                            ticks: {
                                                precision: 0
                                            },
                                            beginAtZero: true,
                                            grid: {
                                                display: false,
                                            },
                                        },
                                    },
                                    plugins: {
                                        title: {
                                            display: false,
                                            text: "Users"
                                        },
                                        legend: {
                                            display: false
                                        },
                                    }
                                }
                                } />
                        </div>
                        {/* <Bar data={campaignsData}
                                options={{
                                    scales: {
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                display: false,
                                            },
                                        },

                                        y: {
                                            grid: {
                                                display: false,
                                            },
                                        },
                                    },
                                    plugins: {
                                        title: {
                                            display: false,
                                            text: 'Purchases',
                                        },
                                        legend: {
                                            display: false,
                                            labels: {
                                                usePointStyle: true,
                                                pointStyle: "line"
                                            },
                                        },
                                    }
                                }
                                } /> */}
                    </Card>
            </div>
        );
    };
}