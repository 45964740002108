import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { moment } from 'moment-timezone';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class SmeReach extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            smeReach: [],
            allTimeSlots: [],
            allOfferings: [],
            allDays: [],
            commercialPlans: [],
            isLoading: true

        }
    }

    componentDidMount() {
        this.getAllSmeOfferings();
        this.getAllDays();
        this.getAllTimeSlots();
        this.getAllCommercialPlans();
        this.getAllSme()
    }
    getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    self.setState({ allOfferings: response.data.offerings })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getAllDays = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    self.setState({ allDays: response.data.days })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getAllTimeSlots = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    self.setState({ allTimeSlots: response.data.timeSlots })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getAllCommercialPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllCommercialPlans', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    self.setState({ commercialPlans: response.data.commercialPlans })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getAllSme = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllSme', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    self.setState({ smeReach: response.data.data, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, smeReach, allOfferings, allTimeSlots, commercialPlans } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }

        const getTimeSlot = (slotId) => {
            const item = allTimeSlots.find(txt => txt.id === slotId);
            return item ? item.time : "";
        }
        const getPlan = (planId) => {
            const item = commercialPlans.find(txt => txt.id === planId);
            return item ? item.plan : "";
        }
        const getOfferings = (off) => {
            const items = allOfferings
                .filter(data => off.includes(data.id))
                .map(data => data.text);
            const result = items.join(', ');

            console.log("result", result);
            return result;
        }
        const formatRatingNumber = (number) => {
            const roundedNumber = Math.round(number * 100) / 100;
            const decimalPart = roundedNumber - Math.floor(roundedNumber);

            if (decimalPart === 0) {
                return Math.floor(roundedNumber);
            } else {
                return roundedNumber.toFixed(1);
            }
        }
        // const updatedDate = articlesReach.map((i, idx) => {
        //     var a = new Date(i.updatedAt)
        //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        //     return updated;
        // })
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                    <div style={{ display: "flex" }}>
                        <NavLink to="/history/usersreach" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Users Reach</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/articles" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Articles</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/polls" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Polls</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/sceemlorereach" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Sceemlore </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/advertisements" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Advertisements</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/referrals" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Referrals </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/opportunities" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Opportunities </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/q&a" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Q&A </div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/interests" activeClassName="history-active" className="Link" >
                            <div className="history-Text">EOI</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/blocked" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Blocked Articles</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <NavLink to="/history/claimhistory" activeClassName="history-active" className="Link" >
                        <div className="history-Text">Claims</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                        <NavLink to="/history/reviewers" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Reviewers</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/gamesreach" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Games</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink to="/history/smereach" activeClassName="history-active" className="Link" >
                            <div className="history-Text">Sme</div></NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div >
                    <div className="mt-3 active-add-h-text"> Sme Reach</div>
                    {smeReach.length === 0 ? <center><h4>No Sme's.</h4></center> :
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr  >
                                        <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Sme Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Offerings</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Time Slot</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Commercial</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Appointements</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Assessment</th>

                                    </tr>
                                </thead>

                                <tbody>{smeReach.map((data, i) => (
                                    <tr className={i / 2 === 0 ? "" : "green"}>
                                        <td className="td-text">{i + 1}</td>
                                        <td className="td-text">{data.name}</td>
                                        <td className="td-text">{getOfferings(data.offering)}</td>
                                        <td className="td-text">{getTimeSlot(data.time)}</td>
                                        <td className="td-text">{getPlan(data.commercial)}</td>
                                        <td className="td-text">{data.bookings}</td>
                                        <td className="td-text">{formatRatingNumber(data.avg_assessment)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div >
        );
    };
}







