// "__method__": "GET",
// "__input__": "/:id",
// "__errors__": [
//     "100": id not found
//     "101 - Some error occurred while retrieving the id"
// ],
export const PollData = [
    {
        id: 1,
        image: "article1.png",
        topic: "Assets",
        question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod?",
        options: [
            "Lorem1",
            "Lorem2",
            "Lorem3",
            "Lorem4"
        ]
    }
]
// "__method__": "GET",
// "__input__": "/:id",
// "__errors__": [
//     "100": id not found
//     "101 - Some error occurred while retrieving the id"
// ],
export const PollOutput = [
    {
        id: 1,
        topic: "Assets",
        question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod?",
        options: [
            "Lorem1",
            "Lorem2",
            "Lorem3",
            "Lorem4"
        ],
        count: [5, 30, 50, 15],
        uAns: 1
    }
]