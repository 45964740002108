import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class EditRewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            term: "",
            definition: "",
            usage: "",
            submit: true,
            save: false,
            termError: '',
            definitionError: '',
            usageError: '',
            rewardType: this.props.location.state.data.rewardType,
            rewardPoints: this.props.location.state.data.rewardPoints,
            generalUser: this.props.location.state.data.generalUser,
            author: this.props.location.state.data.author,
            reviewer: this.props.location.state.data.reviewer,
            sme: this.props.location.state.data.sme,
            anticipator: this.props.location.state.data.anticipator,
            integrator: this.props.location.state.data.integrator,
            collaborator: this.props.location.state.data.collaborator,
            orchestrator: this.props.location.state.data.orchestrator
        }
    }
    componentDidMount() {
      //  console.log("reward", this.props.location.state.data.id)

    }
    // getTermById = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token,
    //         "applicationType": "web"
    //     }
    //     console.log("token", this.state.token)
    //     var self = this
    //     axios.get(config.userUrl + `user/getTermById/${this.props.match.params.id}`, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 console.log("success!!");
    //                 self.setState({
    //                     term: response.data.terms[0].term,
    //                     definition: response.data.terms[0].definition,
    //                     usage: response.data.terms[0].usage
    //                 })
    //             } else {
    //                 console.log(response.data)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
       // console.log(this.state)
    }
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    editReward = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "id": this.props.location.state.data.id,
            "rewardType": this.state.rewardType,
            "rewardPoints": this.state.rewardPoints,
            "generalUser": this.state.generalUser,
            "author": this.state.author,
            "reviewer": this.state.reviewer,
            "sme": this.state.sme,
            "integrator": this.state.integrator,
            "anticipator": this.state.anticipator,
            "collaborator": this.state.collaborator,
            "orchestrator": this.state.orchestrator
        }
       // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/editIndividualRewardPoints', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                   // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    render() {
        const { redirect, term, definition, usage, isLoading } = this.state

        if (isLoading) {
            return <div className="App"></div>;
        }
        if (redirect) {
            return <Redirect to="/rewards" />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5 className="font-heading-web">Edit Rewards</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <Row style={{ width: "74.5%" }}>
                            <Col md={6}>
                                <div className="poll-date">Reward Type</div>
                                <div className="form-group">
                                    <input type="text"
                                        className="web-font form-control form-bg"
                                        aria-describedby="text"
                                        value={this.state.rewardType}
                                        disabled="true"
                                        name="rewardType"
                                        placeholder="Add Term"
                                    />
                                </div>
                                <div className="mt-3 poll-date">General User</div>
                                <div className="form-group">
                                    <input type="number"
                                        className="web-font form-control form-bg"
                                        aria-describedby="number"
                                        value={this.state.generalUser}
                                        name="generalUser"
                                        placeholder="General User"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="mt-3 poll-date">Reviewer</div>
                                <div className="form-group">
                                    <input type="number"
                                        className="web-font form-control form-bg"
                                        aria-describedby="number"
                                        value={this.state.reviewer}
                                        name="reviewer"
                                        placeholder="Reviewer"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="mt-3 poll-date">Anticipator</div>
                                <div className="form-group">
                                    <input type="number"
                                        className="web-font form-control form-bg"
                                        aria-describedby="number"
                                        value={this.state.anticipator}
                                        name="anticipator"
                                        placeholder="Anticipator"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="mt-3 poll-date">Collaborator</div>
                                <div className="form-group">
                                    <input type="number"
                                        className="web-font form-control form-bg"
                                        aria-describedby="number"
                                        value={this.state.collaborator}
                                        name="collaborator"
                                        placeholder="Collaborator"
                                        onChange={this.onChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Reward Points</div>
                                <div className="form-group">
                                    <input type="number"
                                        className="web-font form-control form-bg"
                                        aria-describedby="number"
                                        value={this.state.rewardPoints}
                                        name="rewardPoints"
                                        placeholder="Reward Points"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="mt-3 poll-date">Author</div>
                                <div className="form-group">
                                    <input type="number"
                                        className="web-font form-control form-bg"
                                        aria-describedby="number"
                                        value={this.state.author}
                                        name="author"
                                        placeholder="Author"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="mt-3 poll-date">SME</div>
                                <div className="form-group">
                                    <input type="number"
                                        className="web-font form-control form-bg"
                                        aria-describedby="number"
                                        value={this.state.sme}
                                        name="sme"
                                        placeholder="SME"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="mt-3 poll-date">Integrator</div>
                                <div className="form-group">
                                    <input type="number"
                                        className="web-font form-control form-bg"
                                        aria-describedby="number"
                                        value={this.state.integrator}
                                        name="integrator"
                                        placeholder="Integrator"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="mt-3 poll-date">Orchestrator</div>
                                <div className="form-group">
                                    <input type="number"
                                        className="web-font form-control form-bg"
                                        aria-describedby="number"
                                        value={this.state.orchestrator}
                                        name="orchestrator"
                                        placeholder="Orchestrator"
                                        onChange={this.onChange}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <div className="float_right_poll">
                            {((this.state.rewardPoints !== "") && (this.state.generalUser !== "") && (this.state.author !== "") &&
                                (this.state.reviewer !== "") && (this.state.sme !== "")) && (this.state.anticipator !== "") && (this.state.integrator !== "")
                                && (this.state.collaborator !== "") && (this.state.orchestrator !== "") ?
                                <>
                                    <Button className="mt-1 save-btn ml-3" onClick={this.editReward} variant="default">Update</Button>
                                </> :
                                <>
                                    <Button className="mt-1 save-btn ml-3" onClick={this.editReward} disabled="true" variant="default">Update</Button>
                                </>}
                            <Link to="/rewards"><Button variant="default" className="mt-1 cancelreview-btn ml-4">Back</Button></Link></div>
                    </Form><br /><br />
                </div >
            </div >
        );
    };
}





