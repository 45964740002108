import React from 'react';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

class DeletePopupCard extends React.Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this.state = {
            edit: true,
            cardTitle: this.props.cardTitle,
            cardDescription: this.props.cardDescription,
            cardPicture: this.props.cardPicture,
            cardId: this.props.cardId,
            cardManagementId: this.props.cardManagementId,
            image: this.props.cardPicture,
            count: 1,
            cardVal: "",
            redirectCard: false
        }
    }
    componentDidMount() {
       // console.log("props", this.props)
    }
    deleteEachCard = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "cardDetailId": this.state.cardId
        }
        var self = this;
        axios.post(config.userUrl + 'user/deleteCardById', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                  //  console.log("success", response.data.message, payload);
                    self.props.getCardById()
                    self.props.closePopup()
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    render() {
        const { edit, cardTitle, cardDescription, image, count, imgRef, cardVal } = this.state
        return (
            <Modal
                size="sm"
                show={edit}
                onHide={this.props.closePopup}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        <h5 style={{ color: "#FF942C", fontFamily: "poppins" }}>&nbsp;Card # {this.props.cardNumber}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Form style={{ marginTop: "-0.8rem" }}>
                        <div className="form-group">
                            <input type="text"
                                className="mt-2 web-font form-control text-box-radius"
                                aria-describedby="text"
                                name="cardTitle"
                                value={cardTitle}
                                disabled={true}
                                placeholder="Card Title"
                                onChange={e => this.setState({ cardTitle: e.target.value })}
                            />
                        </div>
                        <div style={{ height: "120px", textAlign: "center", objectFit: "contain" }}>
                            <label htmlFor="upload-button">
                                {image != "" ? (
                                    <img src={image} style={{ width: "100px", height: "100px" }} />
                                ) : (
                                    <>
                                        <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                        <div className="up-load">Upload Image</div>
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                id="upload-button"
                                ref={imgRef}
                            />
                        </div>
                        <div className="form-group">
                            <textarea name="cardDescription" value={cardDescription}
                                placeholder="Card Description"
                                disabled={true}
                                className="web-font richTextField_card description-radius"
                                style={{ overflowY: "none", resize: "none" }}
                                onChange={e => this.setState({ cardDescription: e.target.value })}
                            />
                        </div>
                        <div>
                            <div><Button variant="default" onClick={this.deleteEachCard} className="sendreview-btn mt-1 ml-2">Delete Card</Button>
                                <Button variant="default" onClick={this.props.closePopup} className="close-btn-popup mt-1">Close</Button></div>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default DeletePopupCard;

