import React, { Component } from "react";
import { Button, ProgressBar, Nav, Card, Container, Col, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Poll.css';
import { PollData, PollOutput } from './PollData';

export default class PollQuestion extends Component {
    state = {
        userAnswer: null,
        currentIndex: 0,
        options: [],
        count: [],
        pollEnd: false,
        disabled: true,
        completed: 0
    }
    loadQuiz = () => {
        const { currentIndex } = this.state
        this.setState(() => {
            return {
                question: PollOutput[currentIndex].question,
                options: PollOutput[currentIndex].options,
                count: PollOutput[currentIndex].count,
                topic: PollOutput[currentIndex].topic,
                uAns: PollOutput[currentIndex].uAns,
            }
        }
        )
    }
    componentDidMount() {
        this.loadQuiz();


    }
    checkAnswer = answer => {
        const { options } = this.state
        var opt = options.findIndex(op => {
            if (op === answer) { return true; }
        });
        this.setState({
            userAnswer: opt,
            disabled: false
        })
    }

    finishHandler = () => {
        const { currentIndex } = this.state
        this.setState(() => {
            return {
                question: PollOutput[currentIndex].question,
                options: PollOutput[currentIndex].options,
                count: PollOutput[currentIndex].count,
                uAns: PollOutput[currentIndex].uAns,
                pollEnd: true
            }
        }
        )

    }
    render() {

        const { question, options, count, topic, uAns, userAnswer, pollEnd } = this.state

        return (
            <div> <div >
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "4.8rem", marginLeft: "240px" }}>
                    <Col xs={12}><br /><br />
                        <Card className="poll-card"><br />
                            <div className="topic ml-4">Topic "{topic}"</div>
                            <hr />
                            <div style={{ textAlign: "left" }} className="poll-question ml-3">{question}</div>
                            <Progressbar id={0} options={options[0]} percentage={count[0]} />
                            <Progressbar id={1} selected={uAns} options={options[1]} percentage={count[1]} />
                            <Progressbar id={2} options={options[2]} percentage={count[2]} />
                            <Progressbar id={3} selected={uAns} options={options[3]} percentage={count[3]} />

                            <br /><br />
                        </Card></Col>
                    <br />
                </div><br /><br /></div >
            </div >
        )

    }
}

const Progressbar = (props) => {
    const { percentage, options, selected, id } = props;

    const containerStyles = {
        height: 50,
        width: '40%',
        backgroundColor: "white",
        borderRadius: 10,
        marginTop: 10,
        marginLeft: 20,
        marginRight: 50,
        marginBottom: 10,
        border: "2px solid lightgrey"
    }
    const fillerStyles = {
        height: '100%',
        width: `${percentage}%`,
        backgroundColor: "#EEEBFF",
        transition: 'width 1s ease-in-out',
        border: "1.5px solid #b0a4f5",
        borderRadius: "8px",
        textAlign: 'right'
    }
    const isSelected = {
        border: "2px solid #DB6C00",
        height: 50,
        width: '40%',
        backgroundColor: "white",
        borderRadius: 10,
        marginTop: 10,
        marginLeft: 20,
        marginRight: 50,
        marginBottom: 10,
    }
    const labelStyles = {
        color: 'black',
        borderColor: "#362B79",
        fontWeight: 600
    }
    return (
        <div style={selected === id ? isSelected : containerStyles}><span style={{ marginTop: "8px", marginRight: "4px", fontWeight: "600", float: "right" }}>{`${percentage}%`}</span>
            <div style={fillerStyles} >
                <span style={labelStyles} ><span style={{ marginLeft: "5px", marginTop: "8px", marginBottom: "8px", float: "left" }}>{options}</span></span>
            </div>
        </div>
    );
};
