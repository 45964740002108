import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Redirect, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import createpoll from '../images/createpoll.svg';
import savedpolls from '../images/savedpolls.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class SavedSequenceCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            savedCards: [],
            isLoading: true
        }
    }
    componentDidMount() {
       // console.log("SavedGames")
        this.getAllSavedSequenceCards();
    }
    getAllSavedSequenceCards = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllSavedSequenceCards', { headers: headers })
            .then(function (response) {
                //console.log("savedCards", response);
                if (response.data.status == true) {
                   // console.log("getAllSavedSequenceCards");
                    self.setState({ savedCards: response.data.allSequenceCards, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, savedCards } = this.state;
        const updatedDate = savedCards.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })

        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <div className="mt-3 active-add-h-text">Saved Sequence Cards</div>
                    {savedCards.length === 0 ? <center><h4>No Saved Sequence Cards</h4></center> :
                        <div class="mt-3 tableFixHead">
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr>
                                        <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Theme</th>
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Card Description</th>
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Description</th>
                                        <th scope="col" className="th-heading" style={{ width: "10%" }}>Status</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Updated At</th>
                                        <th ></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {savedCards.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text" >&nbsp;&nbsp;{i + 1}</td>
                                            <td className="td-text">{data.theme}</td>
                                            <td className="td-text">{data.cardDescription != null ? <div>{data.cardDescription.map((data, i) => (<div>{data},</div>))}</div> : ""}</td>
                                            <td className="td-text">{data.description}</td>
                                            <td className="td-text">{data.status == 1 ? "Active" : "Saved"}</td>
                                            <td className="td-text">{updatedDate[i]}</td>
                                            <td className="cursor" ><Link to={`/sequence/editcard/${data.id}`} className="poll-link"> <i class="fa fa-pencil left" aria-hidden="true"></i></Link> </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                </div>
            </div >
        );
    };
}

