import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, Overlay, Popover, Tooltip, OverlayTrigger, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import OpenPopup from "./OpenPopup";
import PopupCard from "./PopupCard";
import EditPopupCard from "./EditPopupCard";
import DeletePopupCard from "./DeletePopupCard";
import SequencePopupCards from "./SequencePopupCards";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewEditCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            cardManagementData: [],
            cards: [],
            allTopics: [],
            isLoading: false,
            popup: false,
            overlay: false,
            cardNumber: "",
            cardManagementId: "",
            title: "",
            description: "",
            version: "",
            topicId: "",
            topic: "",
            cardId: "",
            cardTitle: "",
            cardDescription: "",
            cardPicture: "",
            popupCard: false,
            editPopup: false,
            deletePopup: false,
            sequencePopup: false,
            redirect: false
        }
    }
    componentDidMount() {
        this.getCardById();
        this.getAllTopics();
      //  console.log("id", this.props)
    }
    getCardById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "cardManagementId": this.props.match.params.id
        }
        var self = this
        axios.post(config.userUrl + "user/getCardDetailByAdmin", payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                  //  console.log("success!!");
                    self.setState({
                        cardManagementId: response.data.cardManagementData[0].id,
                        cardManagementData: response.data.cardManagementData,
                        title: response.data.cardManagementData[0].title,
                        topic: response.data.cardManagementData[0].topicName,
                        topicId: response.data.cardManagementData[0].topicId,
                        description: response.data.cardManagementData[0].description,
                        version: response.data.cardManagementData[0].version,
                        cards: response.data.cardDetail,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    publishCards = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "cardManagementId": this.props.match.params.id
        }
       // console.log("payload", payload)
        this.editCardManagement(1, 1);
        var self = this;
        axios.post(config.userUrl + 'user/cardPublishedByCardManagementId', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({ redirect: true })
                  //  console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    getAllTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllTopics', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                  //  console.log("getAllTopics");
                    self.setState({ allTopics: response.data.topics })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    editCardManagement = (id, version) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "cardManagementId": this.props.match.params.id,
            "topicId": parseInt(this.state.topicId),
            "title": this.state.title,
            "status": id,
            "description": this.state.description,
            "version": parseInt(this.state.version) + parseInt(version)
        }
       // console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'user/editCardManagement', payload, { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status == true) {
                 //   console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

    openBox = () => {
      //  console.log("open")
        document.getElementById('open').style.display = 'flex'
    }
    closeBox = () => {
       // console.log("sequence")
        document.getElementById('open').style.display = 'none'
    }
    togglePopup = (data, id) => {
        this.setState({ popup: true, cardId: id, cardNumber: data.cardNumber, cardTitle: data.cardTitle, cardDescription: data.cardDescription, cardPicture: data.cardPicture })
       // console.log("dataPopup", this.state.popup, data)
    }
    close = () => {
        this.setState({ popup: false })
    }
    openPopupCard = () => {
        this.setState({ popupCard: !this.state.popupCard })
    }
    openEditPopUp = () => {
        this.setState({ editPopup: !this.state.editPopup, popupCard: false })
    }
    toggleEditPopup = () => {
        this.setState({ editPopup: false, popupCard: false })
    }
    openSequencePopUp = () => {
        this.setState({ sequencePopup: !this.state.sequencePopup, popupCard: false })
    }
    toggleSequencePopup = () => {
        this.setState({ sequencePopup: false, popupCard: false })
    }
    openDeletePopUp = () => {
        this.setState({ deletePopup: !this.state.deletePopup, popupCard: false })
    }
    toggleDeletePopup = () => {
        this.setState({ deletePopup: false })
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    onTopicChange = (e) => {
        if (e.target.name === "topicId") {
            this.setState({ [e.target.name]: parseInt(e.target.value) })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    render() {
        const { isLoading, title, allTopics, topic, topicId, description, redirect, cardId, cardNumber, overlay, sequencePopup, editPopup, deletePopup, popupCard, cardManagementId, cardManagementData, cardTitle, cardDescription, cardPicture, cards, popup } = this.state
        if (isLoading) {
            return <div className="App"></div>;
        }
        if (redirect) {
            return <Redirect to="/carddetails" />;
        }
        return (
            <div>
                {popupCard && <PopupCard closePopup={this.openPopupCard} cardLength={cards.length} cardManagementId={cardManagementId} getCardById={this.getCardById} />}
                {sequencePopup && <SequencePopupCards closePopup={this.toggleSequencePopup} data={cards} getCardById={this.getCardById} />}
                {editPopup && <EditPopupCard closePopup={this.toggleEditPopup} cardLength={cards.length} getCardById={this.getCardById} cardNumber={cardNumber} cardId={cardId} cardManagementId={cardManagementId} cardTitle={cardTitle} cardDescription={cardDescription} cardPicture={cardPicture} />}
                {deletePopup && <DeletePopupCard closePopup={this.toggleDeletePopup} getCardById={this.getCardById} cardId={cardId} cardNumber={cardNumber} cardManagementId={cardManagementId} cardTitle={cardTitle} cardDescription={cardDescription} cardPicture={cardPicture} />}
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.4rem", marginLeft: "275px" }} >
                    <div className="ml-3 create-learning-card-heading">Edit Sceemlore</div>
                    <Card className="ml-3 mt-4 mb-2 e-card-create">
                        <Row style={{ width: "100%" }}>
                            <Col md={6}>
                                {cardManagementData.map((data, i) => (
                                    <Form style={{ marginLeft: "5px", width: "100%" }} >
                                        <div className="form-group">
                                            <select name="topicId" onChange={this.onTopicChange}
                                                style={{ marginTop: "2px" }} class="web-font form-control text-box-radius">
                                                <option value={this.state.topicId}>{topic}</option>
                                                {allTopics.map((topic, id) => (
                                                    <option value={topic.id}>{topic.topicName}</option>

                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <input type="text"
                                                className="web-font form-control text-box-radius"
                                                aria-describedby="text"
                                                name="title"
                                                value={title}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <textarea value={description}
                                                name="description"
                                                className="web-font richTextField-c description-radius"
                                                style={{ overflowY: "none", resize: "none" }}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        {cards.length == 0 ?
                                            <Button variant="default" onClick={this.openBox} disabled={true} className="sendreview-btn mt-1 ml-4">Publish</Button>
                                            : <Button variant="default" onClick={this.openBox} className="sendreview-btn mt-1 ml-4">Publish</Button>}
                                        <div id="open" className="modal_new">
                                            <form className="modal-content-log">
                                                <div className="md-container">
                                                    <p className="mt-2">Do you want to Publish?</p>
                                                    <center><Button variant="default" onClick={this.publishCards} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                        <Button variant="default" onClick={this.closeBox} className="deletebutton">No</Button></center>
                                                </div>
                                            </form>
                                        </div>
                                        {((title !== "") && (description !== "")) ?
                                            <Link to="/carddetails"><Button variant="default" onClick={() => this.editCardManagement(0, 0)} style={{ float: "left" }} className="sendreview-btn mt-1">Save</Button></Link>
                                            :
                                            <Link to="/carddetails"><Button variant="default" style={{ float: "left" }} disabled="true" className="sendreview-btn mt-1">Save</Button></Link>}
                                    </Form>
                                ))}
                            </Col>
                            <Col md={6}>
                                <Card className="ml-2 l-card-create-height">
                                    <div><div className="row no-gutters">
                                        {cards.map((data, i) => (
                                            <div className="col-md-3" style={{ padding: "2px" }}>
                                                {/* <Card className="_card" onClick={() => this.togglePopup(data, data.id)}> 
                                               <Button onClick={handleClick}>Holy guacamole!</Button>*/}
                                                <OverlayTrigger
                                                    placement="left"
                                                    trigger={overlay ?
                                                        ""
                                                        : "click"}

                                                    rootClose
                                                    overlay={(
                                                        <Popover className={editPopup || sequencePopup || deletePopup ? "hidden" : "index"}
                                                            id="test">
                                                            <Popover.Content >
                                                                <div className="mt-1 sequence-hover" onClick={this.openSequencePopUp}>Sequence</div>
                                                                <div className="mt-1 sequence-hover" onClick={this.openEditPopUp}>Edit</div>
                                                                <div className="mt-1 sequence-hover" onClick={this.openDeletePopUp}>Delete</div>
                                                            </Popover.Content>
                                                        </Popover>
                                                    )}>
                                                    {overlay ?
                                                        "" :
                                                        <Card className="_card" onClick={() => this.togglePopup(data, data.id)}>
                                                            <center><img src={data.cardPicture} style={{ width: "50px", height: "50px" }} /></center>
                                                            {data.cardTitle !== null && data.cardTitle > data.cardTitle.substring(0, 18) ?
                                                                <div className="l-card-description">{data.cardTitle.substring(0, 18)}...</div>
                                                                : <div className="l-card-description">{data.cardTitle}</div>}
                                                        </Card>
                                                    }
                                                </OverlayTrigger><br />
                                            </div>
                                        ))}
                                    </div>
                                        {cards.length == 0 ?
                                            <center className="center-text" style={{ marginTop: "30%" }}>No Cards Added</center> :
                                            <center className="center-text"></center>}
                                        <Button variant="default" onClick={this.openPopupCard} style={{ float: "right" }} className="publish-btn mt-1">Add Card</Button></div>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </div >
            </div >
        );
    };
}




