import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function AddSequenceGame() {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [cardPhrase, setCardPhrase] = useState("")
    const [redirect, setRedirect] = useState(false)
    const [description, setDescription] = useState("")
    const [theme, setTheme] = useState("")
    const [sequenceGame, setSequenceGame] = useState("")

    useEffect(() => {
    }, []);
    const addSequenceGame = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${token}`,
            "applicationType": "web"
        }
        // var subItems = cardPhrase.split(',');
        // if (cardPhrase.length == 0) {
        //     subItems = []
        // } else {
        //     subItems = cardPhrase.split(',');
        // }
        var payload = {
            "theme": theme,
            "cardPhrase": cardPhrase,
            "description": description,
            "status": data
        }
      //  console.log("payload", payload)
        axios.post(config.userUrl + 'user/createSequenceCards', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    setRedirect(true)
                 //   console.log("success", response.data.addGame.id);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };

    const handleSubmit = (event) => {
       // console.log("startDate", this.state)
        event.preventDefault();
    }
    const showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    const showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }

    if (redirect) {
        return <Redirect to="/sequence" />
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                <h5>Sequence Game</h5>
                <Form onSubmit={handleSubmit}>
                    <div className="mt-1 poll-date">Add Theme</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <input type="text" className="web-font form-control" name="theme"
                            placeholder="Theme"
                            value={theme}
                            style={{ resize: "none" }}
                            onChange={e => setTheme(e.target.value)} />
                    </div>
                    <div className="mt-3 poll-date">Add Card Phrase</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="cardPhrase"
                            placeholder="Card Phrase"
                            value={cardPhrase}
                            rows={3}
                            style={{ resize: "none" }}
                            onChange={e => setCardPhrase(e.target.value)} />
                    </div>
                    <div className="mt-3 poll-date">Description</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="description"
                            placeholder="Description"
                            value={description}
                            rows={2}
                            style={{ resize: "none" }}
                            onChange={e => setDescription(e.target.value)} />
                    </div>
                    {/* <div className="mt-1 poll-date">How to Play</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="howToPlay"
                            placeholder="How To Play"
                            rows={5}
                            ref={refText}
                            style={{ resize: "none" }}
                            value={howToPlay}
                            onChange={(e) => parseMarkdown(e.target.value)}
                        />
                    </div> */}
                    {((cardPhrase !== "")) ?
                        <div className="float_right_poll mt-4"> <Button variant="default" onClick={showPublish} className="sendreview-btn ml-4">Submit </Button>
                        </div>
                        :
                        <div className="float_right_poll mt-4"> <Button variant="default" disabled="true" className="sendreview-btn ml-4">Submit </Button>
                        </div>}

                    <div id="publish" className="modal_new">
                        <form className="modal-content-log">
                            <div className="md-container">
                                <p className="mt-2">Do you want to publish the Sequence game?</p>
                                <center><Button variant="default" onClick={() => addSequenceGame(1)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                    <Button variant="default" onClick={showNone} className="deletebutton">No</Button></center>
                            </div>
                        </form>
                    </div>
                </Form>
            </div >
        </div >
    );
};

