import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import Chart from 'chart.js/auto';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export class ReadsGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            reads: [],
            selectedValue: 11
        }
    }

    componentDidMount() {
        this.getReadArticlesAddedByMonth()
    }
    subtractMonths = (val, date = new Date()) => {
        date.setMonth(date.getMonth() - val);
        //console.log("date", date)
        return date;
    }
    onChange = (e) => {
        this.setState({ selectedValue: e.target.value })
        this.getReadArticlesAddedByMonth(e.target.value)
    }
    getReadArticlesAddedByMonth = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        if (val == undefined) {
            val = 11
        } else {
            val = val
        }
        var startD = this.subtractMonths(parseInt(val), new Date()).getFullYear() + "-" + parseInt(this.subtractMonths(parseInt(val), new Date()).getMonth() + 1) + "-" + this.subtractMonths(parseInt(val), new Date()).getDate();
        var endD = new Date().getFullYear() + "-" + parseInt(new Date().getMonth() + 1) + "-" + new Date().getDate();
        var payload = {
            "startDate": startD,
            "endDate": endD
        }
     //   console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/getReadArticlesAddedByMonth', payload, { headers: headers })
            .then(function (response) {
            //    console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        reads: response.data.articleViewsCount,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { reads, selectedValue } = this.state;
        const readsData = {
            labels: reads.map((data) => data.month),
            datasets: [
                {
                    data: reads.map((data) => data.count),
                    borderColor: '#e43cfa',
                    backgroundColor: "#e43cfa",
                    borderWidth: 1,
                    borderThickness: 20
                }
            ]
        }

        return (
            <div style={{marginTop:"6px"}}>
                <Card className="mt-1 bar-graph-card box-shad" style={{background:"rgba(228, 60, 250,0.1)"}}>
                    {/* <center><h6><b>Reads in 1 year </b></h6></center>
                <div className="row">
                    <Col><center><h4 className="mt-3"><b>{this.props.data}</b></h4></center></Col>
                    <Col></Col> */}
                    <div className="row no-gutters" style={{marginTop:"11.5px"}}>
                        <Col md={5}> <center className="ml-1 mt-4 graph-heading-T">Reads in 1 year</center></Col>
                        <Col md={7}>
                            <div style={{ width: "70px", height: "70px" }}>
                                <Line data={readsData} className="mt-2"
                                    options={{
                                        aspectRatio: 1,
                                        elements: {
                                            line: {
                                                tension: 0.5
                                            }
                                        },
                                        scales: {
                                            x: {
                                                grid: {
                                                    display: false,
                                                    drawBorder: false,
                                                    lineWidth: 0,
                                                },
                                                ticks: {
                                                    display: false,
                                                    beginAtZero: true
                                                },
                                            },
                                            y: {
                                                grid: {
                                                    display: false,
                                                    drawBorder: false,
                                                    lineWidth: 0,
                                                },
                                                ticks: {
                                                    display: false,
                                                    beginAtZero: true
                                                },
                                            },
                                        },
                                        plugins: {
                                            title: {
                                                display: false,
                                            },
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }
                                    } />
                            </div>

                        </Col>
                    </div>
                    <div className="mt-3"></div>
                </Card>
            </div>
        );
    };
}