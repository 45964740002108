import React, { Component } from "react";
import { Button, Form, Col, Row, Image } from "react-bootstrap";
import logo from '../images/logo.svg';
import nebutech from '../images/nebutech.svg';
import google from '../images/google.svg';
import { Link, Redirect } from "react-router-dom";
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            token: "",
            role: "",
            showPassword: false,
            author: false,
            reviewer: false,
            admin: false,
            verify: false,
            generalUser: false,
            sme: false
        };
        this.currentPathname = null;
        this.currentSearch = null;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onChange = (e) => {
      //  console.log("name")
        this.setState({ [e.target.name]: e.target.value })
    }
    handleSubmit(event) {
        event.preventDefault();
       // console.log("email")
        this.validateLoginDetails();
        if (this.state.email.length > 0 && this.state.password.length > 3) {
            sessionStorage.setItem('email', JSON.stringify(this.state.email));

        } else {
            alert("Please Enter the Details");
         //   console.log("Please Enter the Details");
        }
    }

    checkUserStatus = (accessToken) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + accessToken,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/checkUserStatus', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                 //   console.log("successfull");
                    if (response.data.userStatus === "profileAdded") {
                        self.setState({ descriptionAdded: true })
                    } else if (response.data.userStatus === "descriptionAdded") {
                        self.setState({ descriptionAdded: true })
                    }else if (response.data.userStatus === "topicsAdded") {
                        self.setState({ topicsAdded: true })
                    } else if (response.data.userStatus === "peopleFollowed") {
                        self.setState({ followersAdded: true })
                    } else {
                        self.setState({ author: true })
                    }
                } else {
                //    console.log("error")
                    document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                }
            })
            .catch(function (error) {
                console.log("error");
            });
    };
    validateLoginDetails = (accessToken) => {
        var encryptedPassword = simpleCrypto.encrypt(this.state.password);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + accessToken,
            "applicationType": "web"
        }
        var payload = {
            "email": this.state.email,
            "password": encryptedPassword
        }
        var self = this
        axios.post(config.apiUrl + 'auth/login', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true && response.data.role === "author") {
                    sessionStorage.setItem("junkStore", simpleCrypto.encrypt(response.data.accessToken))
                    sessionStorage.setItem('role', response.data.role)
                    sessionStorage.setItem('name', response.data.name)
                    self.setState({
                        token: response.data.accessToken,
                        name: response.data.name,
                        role: response.data.role,
                        author: true
                    })
                    const accessToken = response.data.accessToken
                  //  console.log("accessToken", accessToken)
                } else if (response.data.role === "generalUser" && response.data.status === true) {
                   // console.log("general User");
                    document.getElementById('status').innerText = "General User do not have access to login"

                    // self.checkUserStatus(accessToken)
                } else if (response.data.status == true && response.data.role === "reviewer") {
                    sessionStorage.setItem("junkStore", simpleCrypto.encrypt(response.data.accessToken))
                    sessionStorage.setItem('role', response.data.role)
                    sessionStorage.setItem('name', response.data.name)
                    self.setState({
                        token: response.data.accessToken,
                        name: response.data.name,
                        role: response.data.role,
                        reviewer: true
                    })
                    const accessToken = response.data.accessToken
                 //   console.log("accessToken", accessToken)
                } else if (response.data.status === true && response.data.role === "sme") {
                    sessionStorage.setItem("junkStore", simpleCrypto.encrypt(response.data.accessToken))
                    sessionStorage.setItem('role', response.data.role)
                    sessionStorage.setItem('name', response.data.name)
                    self.setState({
                        token: response.data.accessToken,
                        name: response.data.name,
                        role: response.data.role,
                        sme: true
                    })
                    const accessToken = response.data.accessToken
                   // console.log("accessToken", accessToken)
                } else if (response.data.status === true && response.data.role === "admin") {
                    sessionStorage.setItem("junkStore", simpleCrypto.encrypt(response.data.accessToken))
                    sessionStorage.setItem('role', response.data.role)
                    sessionStorage.setItem('name', response.data.name)
                    self.setState({
                        token: response.data.accessToken,
                        name: response.data.name,
                        role: response.data.role,
                        admin: true
                    })
                    const accessToken = response.data.accessToken
                  //  console.log("accessToken", accessToken)
                } else if (response.data.status === false && response.data.userStatus === "unverified") {
                    self.setState({ verify: true })
                } else {
                    console.log(msg)
                    document.getElementById('status').innerText = msg

                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    showPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    render() {
        const { author, reviewer, admin, generalUser, sme } = this.state;
        if (author) {
          //  console.log("author", this.state.author);
            return <Redirect
                to={{
                    pathname: "/articles",
                    state: { email: this.state.email, token: this.state.token, password: this.state.password }
                }}
            />
        }
        if (reviewer) {
           // console.log("reviewer", this.state.reviewer);
            return <Redirect
                to={{
                    pathname: "/reviewqueue",
                    state: { email: this.state.email, token: this.state.token, password: this.state.password }
                }}
            />
        }
        if (sme) {
          //  console.log("sme", this.state.sme);
            return <Redirect
                to={{
                    pathname: "/smequeue",
                    state: { email: this.state.email, token: this.state.token, password: this.state.password }
                }}
            />
        }
        if (admin) {
          //  console.log("admin", this.state.admin);
            return <Redirect
                to={{
                    pathname: "/dashboard",
                    state: { email: this.state.email, token: this.state.token, password: this.state.password }
                }}
            />
        }
        return (
            <body>
                <div class="log">
                    <center>  <img src={logo} className="logo-width" /></center>
                    {/* <div className="login">LOGIN</div> */}
                    <div className="login-sm">Login to access as Author, Reviewer,<br /> SME, Admin</div><br />
                    <form onSubmit={this.handleSubmit}>
                        <div id="border" class="input-border">
                            <input type="text" id="email" name="email" onChange={this.onChange} class="text" required />
                            <label className="label">Email Address</label>
                            <div class="border "></div>
                        </div>
                        <div class="input-border">
                            <input type={this.state.showPassword ? "text" : "password"} id="password" name="password" onChange={this.onChange} class="text" required />
                            <label className="label">Password</label>
                            <div class="border"></div>
                            <span className="showPassword"
                                onClick={this.showPassword}
                            >
                                {this.state.showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                            </span>
                        </div>
                        {/* <Link to="/forgotpassword" class="forgot-right">Forgot Password?</Link> */}
                        <br /><br />
                        <center id="status" className="error-login" ></center><br />
                        <Button variant="default" type="submit" className="btn-login">Login</Button><br /><br />

                    </form>

                </div>
                <div className="nebutech" ><img src={nebutech} className="nebutech-login-img" /></div>
            </body >
        )
    }
}