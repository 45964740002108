import React, { useParams, useRef, useState, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, useRouteMatch, NavLink, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import orange from '../images/orange-circle.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function AcceptRejectTransactions() {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [transactions, setTransactions] = useState([])
    const [htmlName, setHtmlName] = useState('');
    const [content, setContent] = useState('');
    const [description, setDescription] = useState('');
    const [userTopics, setUserTopics] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [picture, setPicture] = useState('');
    const [id, setId] = useState();

    let { path, url } = useRouteMatch();
   // console.log(path)

    useEffect(() => {
        getCompletedTransactions();
    }, []);
    const getCompletedTransactions = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }

        axios.get(config.userUrl + 'user/getCompletedTransactionDetails', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setTransactions(response.data.getCompletedTransactions)
                    setIsLoading(false)
                    //console.log("getCompletedTransactions", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const reviewTransaction = (data, flag) => {
       // console.log("edit", data)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "id": data.id,
            "uuid":data.userId,
            "planName":data.planName,
            "status": flag,
            "description": description
        }
       // console.log("payload", payload)
        axios.post(config.userUrl + 'user/acceptOrRejectTransaction', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    if (flag == 6) {
                        showRefundNone()
                        setId(data)
                        showRefundRaised()
                        //console.log("6",);
                    } else {
                        window.location.href = '/verifytransactions'

                       // console.log("5");
                    }
                    //console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const refundTransaction = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "id": id,
            "status": 5,
            "upiId": "abc@ybl",
            "refTransactionImage": picture
        }
        //console.log("payload", payload)
        axios.post(config.userUrl + 'user/refundTransaction', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    window.location.href = '/verifytransactions'
                    //console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    const handleImage = async (e) => {
       // console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await uploadImage(e.target.files[0]);
            if (res) {
              //  console.log("res", res)
                setPicture(res)
            }
        }
    }
    if (!sessionStorage.getItem('junkStore')) {
        return <Redirect to='/' />;
    }
    if (isLoading) {
        return <div></div>
    }
    const updatedDate = transactions.map((i, idx) => {
        var a = new Date(i.updatedAt)
        var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return updated;
    })
    const showAccept = () => {
        document.getElementById('accept').style.display = 'flex'
    }
    const showAcceptNone = () => {
        document.getElementById('accept').style.display = 'none'
    }
    const showReject = () => {
        document.getElementById('reject').style.display = 'flex'
    }
    const showRejectNone = () => {
        document.getElementById('reject').style.display = 'none'
    }
    const showRefund = () => {
        document.getElementById('refund').style.display = 'flex'
    }
    const showRefundNone = () => {
        document.getElementById('refund').style.display = 'none'
    }
    const showRefundRaised = () => {
        document.getElementById('refundraised').style.display = 'flex'
    }
    const hideRefundRaised = () => {
        document.getElementById('refundraised').style.display = 'none'
    }
    // let result = reviewQueue.filter(item => userTopics.some(data => item.topicName === data.topicName));
    // // console.log("result", result)
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>

                <Row style={{ width: "100%" }}>
                    <Col md={4}>
                        <div class="scrolling-area-review">
                            <div class="scrolling-element-inside">
                                <div className="inbox">Inbox</div>
                                {transactions.length !== 0 ?
                                    <div>
                                        {transactions.map((data, i) => (
                                            <div>
                                                <Card style={{ border: "none" }}>
                                                    <NavLink to={`${path}/${data.id}`} className="inbox_Card link" activeClassName="_Active" key={i} >
                                                        <Row style={{ width: "100%" }}>
                                                            <Col md={2}>
                                                                <img src={orange} style={{ width: "32px" }} />
                                                            </Col>
                                                            <Col md={7}>
                                                                <div className="messages">{data.name}</div>
                                                                <div className="messages">{data.planName}</div>
                                                                <div className="article-approved">&#8377; {data.planAmount} </div>

                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="time">{updatedDate[i]}</div>
                                                            </Col>
                                                        </Row>
                                                    </NavLink>
                                                </Card>
                                                <hr /></div>
                                        ))}
                                    </div> : <p>No Transactions to check</p>}


                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <Card style={{ height: "85vh" }}>
                            {transactions.map((data, i) => (
                                <Route key={i} path={`${path}/${data.id}`} >
                                    <div>
                                        <div class="scrolling-area-review-queue">
                                            <div class="scrolling-element-inside">
                                                <img src={data.transactionImage} className="article-title mt-2 ml-5" style={{ width: "70%", height: "450px" }} />

                                                <p className="article-title article-title-color mt-2 ml-5">Plan Name:&nbsp;{data.planName}</p>
                                                <p className="article-title article-title-color mt-2 ml-5">Plan Amount: &#8377; &nbsp;{data.planAmount}</p>
                                                <p className="article-title article-title-color mt-2 ml-5">Status:&nbsp;{data.status == 2 ? "Completed" : ""}</p>
                                                <div className="article-title article-title-color mt-2 ml-5">{data.name} , {data.details} </div>
                                                <div className="article-title article-title-color mt-2 ml-5">UPI ID: &nbsp;{data.upiId}</div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="ml-3 mr-3 mt-3 form-group">
                                                <textarea
                                                    rows={2}
                                                    name="description"
                                                    value={description}
                                                    placeholder="Write your comments"
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    className="web-font form-control"
                                                    style={{ resize: "none", wordWrap: "break-word", }}
                                                />
                                            </div>
                                            <div className="float_Right mr-3" >
                                                <div>
                                                    <div>{description !== "" ?
                                                        <div ><Button onClick={showAccept} className="sendreview-btn mt-1 ml-4" variant="default">Accept</Button>
                                                            <div id="accept" className="modal_new">
                                                                <form className="modal-content-log">
                                                                    <div className="md-container">
                                                                        <p className="mt-2">Do you really want to Accept?</p>
                                                                        <center><Button variant="default" onClick={() => reviewTransaction(data, 3)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                            <Button variant="default" onClick={showAcceptNone} className="deletebutton">No</Button></center>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <Button variant="default" onClick={showReject} className="save-btn mt-1 ml-3">Reject</Button>
                                                            <div id="reject" className="modal_new">
                                                                <form className="modal-content-log">
                                                                    <div className="md-container">
                                                                        <p className="mt-2">Do you really want to Reject?</p>
                                                                        <center><Button variant="default" onClick={() => reviewTransaction(data, 4)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                            <Button variant="default" onClick={showRejectNone} className="deletebutton">No</Button></center>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <Button onClick={showRefund} className="sendreview-btn mt-1 ml-4" variant="default">Refund</Button>
                                                            <div id="refund" className="modal_new">
                                                                <form className="modal-content-log">
                                                                    <div className="md-container">
                                                                        <p className="mt-2">Do you really want to Raise a Refund?</p>
                                                                        <center><Button variant="default" onClick={() => reviewTransaction(data, 6)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                            <Button variant="default" onClick={showRefundNone} className="deletebutton">No</Button></center>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div id="refundraised" className="modal_new">
                                                                <form className="modal-content-log">
                                                                    <div className="md-container">
                                                                        <center style={{ height: "150px" }}>
                                                                            <label htmlFor="upload-button">
                                                                                {picture != "" ? (
                                                                                    <img src={picture} style={{ width: "100px", height: "100px" }} />
                                                                                ) : (
                                                                                    <>
                                                                                        <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                                                                        <div className="up-load">Upload Image</div>
                                                                                    </>
                                                                                )}
                                                                            </label>
                                                                            <input
                                                                                type="file"
                                                                                style={{ display: "none" }}
                                                                                id="upload-button"
                                                                                name="image"
                                                                                onChange={(e) => handleImage(e)}
                                                                            />
                                                                        </center>
                                                                        <center>{picture !== "" ? <Button variant="default" onClick={() => refundTransaction()} className="mb-2 cancelbutton">Yes</Button>
                                                                            : <Button variant="default" disabled={true} className="mb-2 cancelbutton">Yes</Button>}&nbsp;&nbsp;
                                                                            <Button variant="default" onClick={hideRefundRaised} className="mb-2 deletebutton">No</Button></center>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div> :
                                                        <div><Button variant="default" disabled="true" className="sendreview-btn mt-1 ml-4">Accept</Button><Button disabled="true" className="save-btn mt-1 ml-3" variant="default">Reject</Button><Button variant="default" disabled="true" className="sendreview-btn mt-1 ml-4 mr-2">Refund</Button></div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Route>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div >
        </div >
    );
}

