import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import WriteArticle from './WriteArticle/WriteArticle';
import Login from './Login/Login';
import Register from './Register/Register';
import ReviewQueue from './ReviewQueue/ReviewQueue';
import Reviews from './Reviews/Reviews';
import Poll from './Poll/Poll';
import CreatePoll from './Poll/CreatePoll';
import ShowPoll from './Poll/ShowPoll';
import SavedPolls from './Poll/SavedPolls';
import EditPollScreen from './Poll/EditPollScreen';
import PollQuestion from './Poll/PollQuestion';
import AuthorProfile from './AuthorProfile/AuthorProfile';
import Notifications from './Settings/Notifications';
import Followers from './AuthorProfile/Followers';
import Following from './AuthorProfile/Following';
import Settings from './Settings/Settings';
import Security from './Settings/Security';
import SupportData from './Settings/Support';
import GeneralSettings from './Settings/GeneralSettings';
import ForgotPassword from './Login/ForgotPassword';
import VerifyEmail from './Login/VerifyEmail';
import NewPassword from './Login/NewPassword';
import ReviewComments from './ReviewQueue/ReviewComments';
import Articles from './WriteArticle/Articles';
import SavedArticles from './WriteArticle/SavedArticles';
import EditArticle from './WriteArticle/EditArticle';
import Advertisement from './Advertisements/Advertisement';
import CreateCampaign from './Advertisements/CreateCampaign';
import EditCampaign from './Advertisements/EditCampaign';
import SavedAdds from './Advertisements/SavedAdds';
import ViewPoll from './Poll/ViewPoll';
import Editor from './WriteArticle/Editor';
import ViewArticle from './WriteArticle/ViewArticle';
import ViewCampaign from './Advertisements/ViewCampaign';
import Requests from './Advertisements/Requests';
import History from './Advertisements/History';
import ReportRequests from './Advertisements/ReportRequests';
import BlockedHistory from './Advertisements/BlockedHistory';
import ArticlesReach from './Advertisements/ArticlesReach';
import AdvertisementsReach from './Advertisements/AdvertisementsReach';
import PollsReach from './Advertisements/PollsReach';
import UsersReach from './Advertisements/UsersReach';
import Sme from './Sme/Sme';
import QAReach from './Advertisements/Q&AReach';
import ToBeReviewedByAdmin from './Advertisements/ToBeReviewedByAdmin';
import PendingOpportunities from './Advertisements/PendingOpportunities';
import OpportunityReach from './Advertisements/OpportunityReach';
import ReferralReach from './Advertisements/ReferralReach';
import Purchases from './Advertisements/Purchases';
import Cards from './Advertisements/Cards';
import SceemCards from './Advertisements/SceemCards';
import ViewLearningCard from './Advertisements/ViewLearningCard';
import SavedCards from './Advertisements/SavedCards';
import ViewEditCards from './Advertisements/ViewEditCards';
import SceemloreReach from './Advertisements/SceemloreReach';
import CreateAnnouncement from './Advertisements/CreateAnnouncement';
import Announcement from './Advertisements/Announcement';
import SavedAnnouncements from './Advertisements/SavedAnnouncements';
import EditAnnouncement from './Advertisements/EditAnnouncement';
import ViewAnnouncement from './Advertisements/ViewAnnouncement';
import AdvertisementRequests from './Advertisements/AdvertisementRequests';
import ViewAllStatusAdds from './Advertisements/ViewAllStatusAdds';
import ViewUserCampaignById from './Advertisements/ViewUserCampaignById';
import PublishAdvertisement from './Advertisements/PublishAdvertisement';
import Features from './Advertisements/Features';
import Configuration from './Advertisements/Configuration';
import Subscription from './Advertisements/Subscription';
import FeaturesAndSubscriptions from './Advertisements/FeaturesAndSubscriptions';
import AddTerm from './Term/AddTerm';
import Term from './Term/Term';
import SavedTerms from './Term/SavedTerms';
import EditTerm from './Term/EditTerm';
import Rewards from './Advertisements/Rewards';
import EditRewards from './Advertisements/EditRewards';
import PublishedOffers from './Offers/PublishedOffers';
import SavedOffers from './Offers/SavedOffers';
import CreateOffer from './Offers/CreateOffer';
import ViewOffer from './Offers/ViewOffer';
import EditOffer from './Offers/EditOffer';
import ClaimHistory from './Offers/ClaimHistory';
// import GroupsHistory from './Advertisements/GroupsHistory';
import SavedGames from './Games/SavedGames';
import CreateGame from './Games/CreateGame';
import ViewGame from './Games/ViewGame';
import EditGame from './Games/EditGame';
import PublishedGames from './Games/PublishedGames';
import AcceptRejectTransactions from './Advertisements/AcceptRejectTransactions';
import RefundTransaction from './Advertisements/RefundTransaction';
import AddSequenceGame from './Games/AddSequenceGame';
import SequenceGame from './Games/SequenceGame';
import SavedSequenceCards from './Games/SavedSequenceCards';
import EditSequence from './Games/EditSequence';
import ViewSequence from './Games/ViewSequence';
import Dashboard from './Dashboard/Dashboard';
import AllReviewers from './Advertisements/AllReviewers';
import GamesReach from './Advertisements/GamesReach';
import CreateQuiz from './Quiz/CreateQuiz';
import AllQuizzes from './Quiz/AllQuizzes';
import ViewQuiz from './Quiz/ViewQuiz';
import EditQuiz from './Quiz/EditQuiz';
import SmeReach from './Advertisements/SmeReach';
import AddNewFeatureText from './Advertisements/AddNewFeatureText';
import AllIncidents from './Incidents/AllIncidents';
import UpdateIncident from './Incidents/UpdateIncident';
import ViewIncident from './Incidents/ViewIncident';

export default class Routes extends React.Component {
    constructor(props) {
        super(props);
    }
    isLoggedIn() {
        return sessionStorage.getItem('token') != null && sessionStorage.getItem('token') !== 'undefined';
    };
    render() {


        // if (!(this.isLoggedIn())) {
        //     console.log("isLoggedIn")
        //     return <Login />
        // } else {
        return (

            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route path="/verify" component={VerifyEmail} />
                <Route path="/newpassword" component={NewPassword} />
                <Route path="/register" component={Register} />
                <Route exact path="/articles/writearticle" component={WriteArticle} />
                <Route exact path="/articles/viewarticle/:id" component={ViewArticle} />
                <Route exact path="/articles/savedarticles" component={SavedArticles} />
                <Route exact path="/articles/editarticle/:id" component={EditArticle} />
                {/* <Route path="/reviewqueue" element={<Navigate to="/0" />} /> */}
                {/* <Redirect from="/reviewqueue" to="/reviewqueue/0" /> */}
                {/* <Route exact path="/reviewqueue" element={<Redirect to="/reviewqueue/0" />} /> */}
                {/* <Route  path="/reviewqueue" component={ReviewQueue} /> */}
                <Route path="/reviewqueue" component={ReviewQueue} />
                {/* <Route path="/reviewqueue" component={<ReviewQueue to="/0" />} /> */}
                {/* <Route path={`/reviewqueue/:id/comments`} component={ReviewComments} /> */}
                <Route path="/reviews" component={Reviews} />
                <Route exact path="/profile" component={AuthorProfile} />
                <Route exact path="/profile/followers" component={Followers} />
                <Route exact path="/profile/following" component={Following} />
                <Route exact path="/poll" component={Poll} />
                <Route exact path="/articles" component={Articles} />
                <Route exact path="/article/savedarticles" component={SavedArticles} />
                <Route exact path="/poll/createpoll" component={CreatePoll} />
                <Route exact path="/poll/showpollquestion" component={ShowPoll} />
                <Route exact path="/poll/savedpolls" component={SavedPolls} />
                <Route exact path="/poll/editpoll/:id" component={EditPollScreen} />
                <Route exact path="/poll/viewpoll/:id" component={ViewPoll} />
                <Route path="/notifications" component={Notifications} />
                <Route path="/settings" component={Settings} />
                <Route path="/notifications" component={Notifications} />
                <Route path="/security" component={Security} />
                <Route path="/support" component={SupportData} />
                <Route path="/requests" component={Requests} />
                <Route path="/advertisement/advertisementrequests" component={AdvertisementRequests} />
                <Route path="/tobereviewedarticles" component={ToBeReviewedByAdmin} />
                <Route path="/opportunities" component={PendingOpportunities} />
                <Route path="/reportrequests" component={ReportRequests} />
                <Route path="/purchases" component={Purchases} />
                <Route path="/verifytransactions" component={AcceptRejectTransactions} />
                <Route path="/refund/:id" component={RefundTransaction} />
                <Route exact path="/history/interests" component={History} />
                <Route exact path="/history/blocked" component={BlockedHistory} />
                <Route exact path="/history/articles" component={ArticlesReach} />
                <Route exact path="/history/polls" component={PollsReach} />
                <Route exact path="/history/advertisements" component={AdvertisementsReach} />
                <Route exact path="/history/usersreach" component={UsersReach} />
                <Route exact path="/history/q&a" component={QAReach} />
                <Route exact path="/history/opportunities" component={OpportunityReach} />
                <Route exact path="/history/referrals" component={ReferralReach} />
                <Route exact path="/history/sceemlorereach" component={SceemloreReach} />
                <Route exact path="/history/claimhistory" component={ClaimHistory} />
                <Route exact path="/history/reviewers" component={AllReviewers} />
                <Route exact path="/history/gamesreach" component={GamesReach} />
                <Route exact path="/history/smereach" component={SmeReach} />
                {/* <Route exact path="/history/grouphistory" component={GroupsHistory} /> */}
                <Route path="/smequeue" component={Sme} />
                <Route exact path="/advertisement" component={Advertisement} />
                <Route exact path="/advertisement/createcampaign" component={CreateCampaign} />
                <Route exact path="/advertisement/savedads" component={SavedAdds} />
                <Route exact path="/advertisement/edit/:id" component={EditCampaign} />
                <Route exact path="/advertisement/viewcampaign/:id" component={ViewCampaign} />
                <Route exact path="/rewards" component={Rewards} />
                <Route exact path="/rewards/editreward/:id" component={EditRewards} />
                <Route exact path="/term" component={Term} />
                <Route exact path="/term/createterm" component={AddTerm} />
                <Route exact path="/term/savedterms" component={SavedTerms} />
                <Route exact path="/term/editterm/:id" component={EditTerm} />
                <Route exact path="/carddetails/addcard" component={Cards} />
                <Route exact path="/carddetails" component={SceemCards} />
                <Route exact path="/carddetails/viewcard/:id" component={ViewLearningCard} />
                <Route exact path="/carddetails/editcard/:id" component={ViewEditCards} />
                <Route exact path="/carddetails/savedcards" component={SavedCards} />
                <Route exact path="/announcement/createannouncement" component={CreateAnnouncement} />
                <Route exact path="/announcement/savedannouncement" component={SavedAnnouncements} />
                <Route exact path="/announcement" component={Announcement} />
                <Route exact path="/announcement/editannouncement/:id" component={EditAnnouncement} />
                <Route exact path="/announcement/viewannouncement/:id" component={ViewAnnouncement} />
                <Route exact path="/advertisement/viewalladvertisementrequest" component={ViewAllStatusAdds} />
                <Route exact path="/advertisement/viewuseradd/:id" component={ViewUserCampaignById} />
                <Route exact path="/advertisement/publishuseradvertisement/:id" component={PublishAdvertisement} />
                <Route exact path="/configuration/list" component={Configuration} />
                <Route exact path="/configuration/features" component={Features} />
                <Route exact path="/configuration/addfeaturetext" component={AddNewFeatureText} />
                <Route exact path="/configuration/subscription" component={Subscription} />
                <Route exact path="/configuration/features&subscription" component={FeaturesAndSubscriptions} />
                <Route exact path="/offers" component={PublishedOffers} />
                <Route exact path="/offers/savedoffers" component={SavedOffers} />
                <Route exact path="/offers/createoffer" component={CreateOffer} />
                <Route exact path="/offers/viewoffer/:id" component={ViewOffer} />
                <Route exact path="/offers/editoffer/:id" component={EditOffer} />
                <Route exact path="/games" component={PublishedGames} />
                <Route exact path="/games/savedgames" component={SavedGames} />
                <Route exact path="/games/creategame" component={CreateGame} />
                <Route exact path="/games/viewgame/:id" component={ViewGame} />
                <Route exact path="/games/editgame/:id" component={EditGame} />
                <Route exact path="/sequence" component={SequenceGame} />
                <Route exact path="/sequence/createcard" component={AddSequenceGame} />
                <Route exact path="/sequence/savedcards" component={SavedSequenceCards} />
                <Route exact path="/sequence/editcard/:id" component={EditSequence} />
                <Route exact path="/sequence/viewsequence/:id" component={ViewSequence} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/quiz" component={AllQuizzes} />
                <Route exact path="/quiz/createquiz" component={CreateQuiz} />
                <Route exact path="/quiz/viewquiz/:id" component={ViewQuiz} />
                <Route exact path="/quiz/editquiz/:id" component={EditQuiz} />
                <Route exact path="/incidents" component={AllIncidents} />
                <Route exact path="/incidents/updateincident/:id" component={UpdateIncident} />
                <Route exact path="/incidents/viewincident/:id" component={ViewIncident} />
                <Route path="/editor" component={Editor} />
            </Switch>

        )
    }
    // }
}



