import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import Chart from 'chart.js/auto';
import { Bar, Line, Pie, Doughnut, PolarArea } from 'react-chartjs-2';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export class ArticlesGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            selectedValue: 11
        }
    }

    componentDidMount() {
        this.getArticlesAddedByMonth()
    }
    subtractMonths = (val, date = new Date()) => {
        date.setMonth(date.getMonth() - val);
       // console.log("date", date)
        return date;
    }
    onChange = (e) => {
        this.setState({ selectedValue: e.target.value })
        this.getArticlesAddedByMonth(e.target.value)
    }
    getArticlesAddedByMonth = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        if (val == undefined) {
            val = 11
        } else {
            val = val
        }
        var startD = this.subtractMonths(parseInt(val), new Date()).getFullYear() + "-" + parseInt(this.subtractMonths(parseInt(val), new Date()).getMonth() + 1) + "-" + this.subtractMonths(parseInt(val), new Date()).getDate();
        var endD = new Date().getFullYear() + "-" + parseInt(new Date().getMonth() + 1) + "-" + new Date().getDate();
        var payload = {
            "startDate": startD,
            "endDate": endD
        }
      //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/getArticlesAddedByMonth', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        articles: response.data.articlesData,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { articles, selectedValue } = this.state;
        const articlesData = {
            labels: articles.map((data) => data.month),
            datasets: [
                {
                    label: 'Articles',
                    data: articles.map((data) => data.count),
                    backgroundColor: [
                        '#441cfc',
                    ],
                    borderWidth: 1,
                    barThickness: 12,
                    borderThickness: 10
                }
            ]
        }

        return (
            // <div>
            // <Card className="ml-1 mr-1 bar-graph-card box-shad">
            //     <form style={{ position: "absolute", marginTop: "-4px", right: "10px", width: "35%" }}> <select name="selectedValue" value={selectedValue} onChange={this.onChange} class="mt-2 select-font form-drop form-control">
            //         <option value={2}>3 Months</option>
            //         <option value={5}>6 Months</option>
            //         <option value={11}>1 Year</option>
            //     </select>
            //     </form>
            //     <center><div className="graph-heading-T">Articles</div></center>
            //     <div style={{ marginTop: "6vh", height: "160px", width: "auto" }}>
            //         <Bar data={articlesData}
            //             options={{
            //                 responsive: true,
            //                 scales: {
            //                     indexAxis: 'y',
            //                     x: {
            //                         stacked: true,
            //                         grid: {
            //                             display: false,
            //                         },
            //                         ticks: {
            //                             display: false,
            //                         }
            //                     },

            //                     y: {
            //                         stacked: true,
            //                         grid: {
            //                             display: false,
            //                         },
            //                     },
            //                 },
            //                 plugins: {
            //                     title: {
            //                         display: false,
            //                         text: 'Articles',
            //                     },
            //                     legend: {
            //                         display: false,
            //                         labels: {
            //                             usePointStyle: true,
            //                             pointStyle: "line"
            //                         },
            //                     },
            //                 }
            //             }
            //             } />
            //     </div>
            // </Card>
            // </div>
            <div>
            <Card className="ml-1 mr-2 bar-graph-card box-shad" style={{background:"rgba(68, 28, 252,0.1)"}}>
                            <center><div className="graph-heading-T">Articles</div></center>
                            <form style={{ position: "absolute", marginTop:"-4px", right: "10px", width: "35%"}}> <select name="selectedValue" style={{background:"rgba(68, 28, 252,0.01)"}} value={selectedValue} onChange={this.onChange} class="mt-2 select-font form-drop form-control">
                                <option value={2}>3 Months</option>
                                <option value={5}>6 Months</option>
                                <option value={11}>1 Year</option>
                            </select> </form>
                            <div style={{marginTop:"35px",height:"164px",width:"auto"}}>
                            <Bar data={articlesData}
                                options={{
                                    responsive: true,
                                    elements: {
                                        line: {
                                            tension: 1
                                        }
                                    },
                                    scales: {
                                        indexAxis: 'y',
                                        x: {
                                            stacked: true,
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                display: false,
                                            }
                                        },
                                        y: {
                                            stacked: true,
                                            ticks: {
                                                precision: 0
                                            },
                                            beginAtZero: true,
                                            grid: {
                                                display: false,
                                            },
                                        },
                                    },
                                    plugins: {
                                        title: {
                                            display: false,
                                            text: "Users"
                                        },
                                        legend: {
                                            display: false
                                        },
                                    }
                                }
                                } />
                        </div>
                </Card>
                </div>
        );
    };
}