import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './Poll.css';
import pollData from './Poll.json'
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class EditPoll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            allTopics: [],
            topic: "",
            startDate: "",
            endDate: "",
            options: ["", "", "", ""],
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            pollQuestion: "",
            startDate: "",
            endDate: "",
            topicId: "",
            isLoading: true,
            submit: true,
            save: false,
            dateErr: "",
            startDateErr: "",
            endDateErr: ""
        }
    }
    componentDidMount() {
        this.getPollById();
        this.getAllTopics();
      //  console.log("topicId", this.state.topicId, this.props.match.params.id)

    }
    getAllTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllTopics', { headers: headers })
            .then(function (response) {
            //    console.log(response);
                if (response.data.status == true) {
                //    console.log("getAllTopics");
                    self.setState({ allTopics: response.data.topics })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getPollById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
     //   console.log("token", this.state.token)
        var self = this
        axios.get(config.userUrl + `user/getPollById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                 //   console.log("success!!");
                    self.setState({
                        pollQuestion: response.data.pollQuestion,
                        options: response.data.pollData,
                        option1: response.data.pollData[0].option,
                        option2: response.data.pollData[1].option,
                        option3: response.data.pollData[2].option,
                        option4: response.data.pollData[3].option,
                        topic: response.data.topicName,
                        topicId: response.data.pollDetails[0].topicId,
                        startDate: response.data.pollDetails[0].startDate,
                        endDate: response.data.pollDetails[0].endDate,
                        isLoading: false
                    })
                    var x = new Date()
                    var todaysDate = x.getFullYear() + '-' + (x.getMonth() + 1) + '-' + x.getDate();
                    var y = new Date(response.data.pollDetails[0].startDate)
                    var newStartDate = y.getFullYear() + '-' + (y.getMonth() + 1) + '-' + y.getDate();
                    if ((Date.parse(newStartDate) < Date.parse(todaysDate))) {
                        self.setState({ startDateErr: "Start Date should be greater than Today's Date" })
                    } else {
                        self.setState({startDateErr: "" })
                    }
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    onChange = (e) => {
        if (e.target.name === "topicId") {
            this.setState({ [e.target.name]: parseInt(e.target.value) })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    changeStartDate(date) {
        this.setState({ startDateErr: "", startDate: date })
    }
    changeEndDate(date) {
        this.setState({ endDate: date })

    }
    handleSubmit = (e) => {
        e.preventDefault();
      //  console.log(this.state)
    }
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    editPoll = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "isActive": data,
            "pollId": this.props.match.params.id,
            "topicId": this.state.topicId,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "pollQuestion": this.state.pollQuestion,
            "options": [this.state.option1, this.state.option2, this.state.option3, this.state.option4]
        }
      //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/editPoll', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                //    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    render() {
        const { allTopics, redirect, topic, topicId, startDate, endDate, isLoading } = this.state

        if (isLoading) {
            return <div className="App"></div>;
        }
        var a = new Date(startDate)
        var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
        var b = new Date(endDate)
        var endD = parseInt(b.getMonth() + 1) + "-" + b.getDate() + "-" + b.getFullYear();
        const selectedStartDate = new Date(start)
        const selectedEndDate = new Date(endD)
        console.log("start", startDate)

        if (redirect) {
            return <Redirect to="/poll" />
        }
        var x = new Date()
        var todaysDate = x.getFullYear() + '-' + (x.getMonth() + 1) + '-' + x.getDate();
        var y = new Date(this.state.startDate)
        var newStartDate = y.getFullYear() + '-' + (y.getMonth() + 1) + '-' + y.getDate();
        var z = new Date(this.state.endDate)
        var newEndDate = z.getFullYear() + '-' + (z.getMonth() + 1) + '-' + z.getDate();
      //  console.log("dtes", todaysDate, newStartDate, newEndDate)

        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5 className="font-heading-web">Edit Poll</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <div className="poll-date">Topic</div>
                        <div className="form-group" style={{ width: "72%" }}>
                            <select name="topicId" onChange={this.onChange} class="web-font form-control">
                                <option value={this.state.topicId}>{topic}</option>
                                {allTopics.map((topic, id) => (
                                    <option value={topic.id}>{topic.topicName}</option>

                                ))}
                            </select>
                        </div>
                        <Row style={{ width: "74.5%" }}>
                            <Col md={6}>
                                <div className="poll-date">Start Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={selectedStartDate}
                                        minDate={new Date(Date.now())}
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => this.changeStartDate(date)}
                                        filterDate={(date) => date.getDay() === 1}
                                    />
                                    <div className="error">{this.state.startDateErr}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">End Date</div>
                                <div className="form-group">
                                    <DatePicker
                                        className="web-font form-control form-bg"
                                        selected={selectedEndDate}
                                        minDate={new Date(selectedStartDate + 5 * 86400000)}
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => this.changeEndDate(date)}
                                        filterDate={(date) => date.getDay() === 5}
                                    />
                                    <div className="error">{this.state.endDateErr}</div>
                                </div>

                            </Col>
                        </Row>
                        <Card style={{ width: "72%" }}>
                            <div className="ml-3 mt-3">
                                <div >
                                    <div className="form-group mt-1" style={{ width: "98%" }}>
                                        <input type="text"
                                            className="web-font form-control form-bg"
                                            aria-describedby="text"
                                            value={this.state.pollQuestion}
                                            row="3"
                                            name="pollQuestion"
                                            onChange={this.onChange}
                                            placeholder="Write Your Question"
                                        />
                                    </div>
                                    <div style={{ width: "98%" }} >
                                        <div className="form-group mt-3 display">
                                            <div className="radio-check"></div>&nbsp;&nbsp;
                                            <input type="text" className="web-font form-control"
                                                name="option1"
                                                placeholder={`Write your option 1`}
                                                value={this.state.option1}
                                                onBlur={this.handleBlur}
                                                onChange={this.onChange}
                                            />  <div className="div">{this.state.optionError1 && (
                                                <div className="error">{this.state.optionError1}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-3 display">
                                            <div className="radio-check"></div>&nbsp;&nbsp;
                                            <input type="text" className="web-font form-control"
                                                name="option2"
                                                placeholder={`Write your option 2`}
                                                value={this.state.option2}
                                                onBlur={this.handleBlur}
                                                onChange={this.onChange}
                                            />  <div className="div">{this.state.optionError2 && (
                                                <div className="error">{this.state.optionError2}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-3 display">
                                            <div className="radio-check"></div>&nbsp;&nbsp;
                                            <input type="text" className="web-font form-control"
                                                name="option3"
                                                placeholder={`Write your option 3`}
                                                value={this.state.option3}
                                                onBlur={this.handleBlur}
                                                onChange={this.onChange}
                                            /> <div className="div">{this.state.optionError3 && (
                                                <div className="error">{this.state.optionError3}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-3 display">
                                            <div className="radio-check"></div>&nbsp;&nbsp;
                                            <input type="text" className="web-font form-control"
                                                name="option4"
                                                placeholder={`Write your option 4`}
                                                value={this.state.option4}
                                                onBlur={this.handleBlur}
                                                onChange={this.onChange}
                                            /> <div className="div">{this.state.optionError4 && (
                                                <div className="error">{this.state.optionError4}</div>
                                            )}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card><br />
                        <div className="float_right_poll">
                           {/*  {console.log("data Poll1", (newStartDate > todaysDate < newEndDate),(newStartDate > todaysDate), (todaysDate < newEndDate))} */}
                            {(newStartDate > todaysDate) ?
                                <>
                                    <Button onClick={() => this.editPoll(this.state.submit)} variant="default" disabled="true" className="sendreview-btn ml-3">Submit </Button>
                                    <Button className="save-btn ml-3" onClick={() => this.editPoll(this.state.save)} disabled="true" variant="default">Update</Button></> :
                                (todaysDate < newEndDate) ?
                                    <>
                                        <Button onClick={() => this.editPoll(this.state.submit)} variant="default" disabled="true" className="sendreview-btn ml-3">Submit </Button>
                                        <Button className="save-btn ml-3" onClick={() => this.editPoll(this.state.save)} disabled="true" variant="default">Update</Button></> :
                                    <>
                                        <Button onClick={this.showPublish} variant="default" className="sendreview-btn ml-3">Submit </Button>
                                        <div id="publish" className="modal_new">
                                            <form className="modal-content-log">
                                                <div className="md-container">
                                                    <p className="mt-2">Do you want to publish the poll?</p>
                                                    <center><Button variant="default" onClick={() => this.editPoll(this.state.submit)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                        <Button variant="default" onClick={this.showNone} className="deletebutton">No</Button></center>
                                                </div>
                                            </form>
                                        </div>
                                        <Button className="save-btn ml-3" onClick={() => this.editPoll(this.state.save)} variant="default">Update</Button></>}
                            <Link to="/poll"><Button variant="default" className="cancelreview-btn ml-4">Back</Button></Link></div>
                    </Form><br /><br />
                </div >
            </div >
        );
    };
}





