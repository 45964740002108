import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function CreateGame() {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [gameName, setGameName] = useState("")
    const [gameUrl, setGameUrl] = useState("")
    const [rewardPoints, setRewardPoints] = useState("")
    const [numberOfTrials, setNumberOfTrials] = useState("")
    const [description, setDescription] = useState("")
    const [picture, setPicture] = useState("")
    const [howToPlay, setHowToPlay] = useState("")
    const [redirect, setRedirect] = useState(false)
    const [sequence, setSequence] = useState(false)
    const [gameId,setGameId]=useState()
    const [sequenceGame, setSequenceGame] = useState("")
    const refText = useRef('myTextarea');

    useEffect(() => {
    }, []);
    const createGame = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${token}`,
            "applicationType": "web"
        }
        var htmlText = howToPlay.split('\n')
        htmlText = htmlText.join('<br>')
        var payload = {
            "gameName": gameName,
            "gameUrl": gameUrl,
            "description": description,
            "rewardPoints": parseInt(rewardPoints),
            "picture": picture,
            "numberOfTrials": numberOfTrials,
            "howToPlay": htmlText,
            "status": data
        }
      //  console.log("payload", payload)
        axios.post(config.userUrl + 'user/createGame', payload, { headers: headers })
            .then(function (response) {
            //    console.log(response);
                if (response.data.status == true) {
                    setRedirect(true)
                    // if (sequenceGame == "yes") {
                    //     setSequence(true)
                    //     setGameId(response.data.addGame.id)
                    // } else {
                    //     setRedirect(true)
                    // }
                 //   console.log("success", response.data.addGame.id);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };

    const handleSubmit = (event) => {
       // console.log("startDate", this.state)
        event.preventDefault();
    }
    const showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    const showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    const handleImage = async (e) => {
       // console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await uploadImage(e.target.files[0]);
            if (res) {
              //  console.log("res", res)
                setPicture(res)
            }
        }
    }
    const parseMarkdown = (markdownText) => {
      //  console.log("a", markdownText)
        var htmlText = markdownText
            .replace(/\n*$/gim, '<br/>')
     //   console.log("length", markdownText.split(" ").length)
        setHowToPlay(markdownText)
      //  console.log("html", markdownText.trim())
        return htmlText.trim();
    }
    if (redirect) {
        return <Redirect to="/games" />
    }
    const onSequenceChange = (e) => {
        setSequenceGame(e.target.value)
    }
    if (sequence) {
        return <Redirect to={`/sequencegame/${2}`} />
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                <h5>Create Game</h5>
                <Form onSubmit={handleSubmit}>
                    <Row style={{ width: "74%" }}>
                        <Col md={6}>
                            <div className="poll-date">Game Name</div>
                            <div className="form-group">
                                <input className="web-font form-control" name="gameName"
                                    placeholder="Game Name"
                                    value={gameName}
                                    onChange={e => setGameName(e.target.value)} />
                            </div>

                        </Col>
                        <Col md={6}>
                            <Row style={{ width: "107%" }}>
                                <Col md={6}>
                                    <div className="poll-date">Number of Trials</div>
                                    <div className="form-group">
                                        <input className="web-font form-control" name="numberOfTrials"
                                            placeholder="Number of Trials"
                                            value={numberOfTrials}
                                            onChange={e => setNumberOfTrials(e.target.value)} />
                                    </div>
                                </Col>
                                <Col md={6}>

                                    <div className="poll-date">Reward Points</div>
                                    <div className="form-group">
                                        <input className="web-font form-control" name="rewardPoints"
                                            placeholder="Reward Points"
                                            value={rewardPoints}
                                            onChange={e => setRewardPoints(e.target.value)} />
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ width: "74%" }}>
                        <Col md={6}>
                            <div className="form-group">
                                <div className="poll-date">Sequence Game</div>
                                <div className="form-group">
                                    <select name="sequenceGame" value={sequenceGame} onChange={onSequenceChange} class="web-font form-control text-box-radius">
                                        <option value="">Select Sequence Game</option>
                                        <option value={"yes"}>Yes</option>
                                        <option value={"no"}>No</option>

                                    </select>
                                </div>

                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="poll-date">Game Url</div>
                            <div className="form-group">
                                <input className="web-font form-control" name="gameUrl"
                                    placeholder="gameUrl"
                                    value={gameUrl}
                                    onChange={e => setGameUrl(e.target.value)} />
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-1 poll-date">Description</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="description"
                            placeholder="Description"
                            value={description}
                            rows={2}
                            style={{ resize: "none" }}
                            onChange={e => setDescription(e.target.value)} />
                    </div>
                    <div className="mt-1 poll-date">How to Play</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="howToPlay"
                            placeholder="How To Play"
                            rows={5}
                            ref={refText}
                            style={{ resize: "none" }}
                            value={howToPlay}
                            onChange={(e) => parseMarkdown(e.target.value)}
                        />
                    </div>
                    <center style={{ height: "120px", width: "120px", textAlign: "center", objectFit: "contain" }}>
                        <label htmlFor="upload-button">
                            {picture != "" ? (
                                <img src={picture} style={{ width: "100px", height: "100px" }} />
                            ) : (
                                <>
                                    <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                    <div className="up-load">Upload Image</div>
                                </>
                            )}
                        </label>
                        <input
                            type="file"
                            style={{ display: "none" }}
                            id="upload-button"
                            name="image"
                            onChange={(e) => handleImage(e)}
                        />
                    </center>
                    {((gameName !== "") && (rewardPoints !== "") && (gameUrl !== "") && (description !== "") && (howToPlay !== "") && (numberOfTrials !== "") && (picture !== "")) ?

                        <div className="float_right_poll mt-4"> <Button variant="default" onClick={showPublish} className="sendreview-btn ml-4">Submit </Button> <Button className="save-btn ml-3" onClick={() => createGame("Saved")} variant="default">Save</Button></div>
                        :
                        <div className="float_right_poll mt-4"> <Button variant="default" disabled="true" onClick={showPublish} className="sendreview-btn ml-4">Submit </Button> <Button className="save-btn ml-3" disabled="true" variant="default">Save</Button></div>}
                    <div id="publish" className="modal_new">
                        <form className="modal-content-log">
                            <div className="md-container">
                                <p className="mt-2">Do you want to publish the game?</p>
                                <center><Button variant="default" onClick={() => createGame("Published")} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                    <Button variant="default" onClick={showNone} className="deletebutton">No</Button></center>
                            </div>
                        </form>
                    </div>
                </Form>
            </div >
        </div >
    );
};

