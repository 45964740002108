import React from 'react';
import { Row, Col, Modal, Form, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

class EditPopupCard extends React.Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this.state = {
            edit: true,
            cardTitle: this.props.cardTitle,
            cardDescription: this.props.cardDescription,
            cardNumber: this.props.cardNumber,
            cardPicture: this.props.cardPicture,
            cardId: this.props.cardId,
            cardManagementId: this.props.cardManagementId,
            image: this.props.cardPicture,
            count: 1,
            cardVal: "",
            redirectCard: false
        }
    }
    componentDidMount() {
        //console.log("props", this.props)
    }
    editEachCard = () => {
        this.setState((prevState, props) => ({
            count: prevState.count + 1
        }));
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            "applicationType": "web"
        }
        var payload = {
            "cardManagementId": this.state.cardManagementId,
            "cardDetailId": this.state.cardId,
            "cardTitle": this.state.cardTitle,
            "cardNumber": this.state.cardNumber,
            "cardDescription": this.state.cardDescription,
            "cardPicture": this.state.image
        }
        //console.log("payload", payload, this.state.count)
        // this.setState({ cardTitle: "", cardDescription: "", cardPicture: "", cardVal: val })
        // console.log("count", this.state.count,this.state.cardVal)
        var self = this;
        axios.post(config.userUrl + 'user/editCardDetail', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    //console.log("success", response.data.message, payload);
                    self.props.getCardById()
                    self.props.closePopup()
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
        //console.log("count", this.state.count, this.state.cardVal)
    };
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
       // console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
             //   console.log("res", res)
                this.setState({ image: res })
            }
        }
    }
    render() {
        const { edit, cardTitle, cardDescription, cardNumber, image, count, imgRef, cardVal } = this.state
        return (
            <Modal
                size="sm"
                show={edit}
                onHide={this.props.closePopup}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <h5 style={{ color: "#FF942C", fontFamily: "poppins" }}>&nbsp;Card # {this.props.cardNumber}</h5>
                </Modal.Header>
                <Modal.Body style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Form style={{ marginTop: "-0.8rem" }}>
                        <div className="form-group">
                            <input type="text"
                                className="mt-2 web-font form-control text-box-radius"
                                aria-describedby="text"
                                name="cardTitle"
                                value={cardTitle}
                                placeholder="Card Title"
                                onChange={e => this.setState({ cardTitle: e.target.value })}
                            />
                        </div>
                        <div style={{ height: "120px", textAlign: "center", objectFit: "contain" }}>
                            <label htmlFor="upload-button">
                                {image != "" ? (
                                    <img src={image} style={{ width: "100px", height: "100px" }} />
                                ) : (
                                    <>
                                        <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                        <div className="up-load">Upload Image</div>
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                id="upload-button"
                                ref={imgRef}
                                onChange={(e) => this.handleImage(e)}
                            />
                        </div>
                        <div className="form-group">
                            <textarea name="cardDescription" value={cardDescription}
                                placeholder="Card Description"
                                className="web-font richTextField_card description-radius"
                                style={{ overflowY: "none", resize: "none" }}
                                onChange={e => this.setState({ cardDescription: e.target.value })}
                            />
                        </div>
                        {(cardTitle != "") && (cardDescription != "") ?
                            <div>
                                <div><Button variant="default" onClick={() => this.editEachCard(count)} className="sendreview-btn mt-1 ml-2">Save</Button>
                                    <Button variant="default" onClick={this.props.closePopup} className="close-btn-popup mt-1">Close</Button></div>
                            </div>
                            : <div><Button variant="default" className="sendreview-btn mt-1 ml-2">Save Cards</Button>
                                <Button variant="default" onClick={this.props.closePopup} className="close-btn-popup mt-1">Close</Button></div>}
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default EditPopupCard;

