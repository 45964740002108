// "__method__": "GET",
// "__input__": "/:id",
// "__errors__": [
//     "100 - id not found"
// ],
export const Edit = [
    {
        id: 0,
        image: "articleicon1.svg",
        campaignName: "Campaign Name 1",
        typesOfAdvertisement: "Commercial",
        description: "Lorem ipsum",
        paidOrFree: "Paid",
        tireToAdvertise: "",
        payment: "",
        url: "",
        startDate: "",
        endDate: "",
    }
]