import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Redirect, Route, NavLink, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import createpoll from '../images/createpoll.svg';
import savedpolls from '../images/savedpolls.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class SavedOffers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            savedOffers: [],
            isLoading: true
        }
    }
    componentDidMount() {
      //  console.log("SavedOffers")
        this.getSavedOffers();
    }
    getSavedOffers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getsavedOffer', { headers: headers })
            .then(function (response) {
              //  console.log("savedoffer", response);
                if (response.data.status == true) {
                 //   console.log("getSavedOffers");
                    self.setState({ savedOffers: response.data.savedOffer, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, savedOffers } = this.state;
        const created = savedOffers.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        const updatedDate = savedOffers.map((i, idx) => {
            var a = new Date(i.endDate)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <div className="mt-3 active-add-h-text">Saved Offers</div>
                    {savedOffers.length === 0 ? <center><h4>No Saved Offers</h4></center> :
                        <div class="mt-3 tableFixHead-saved">
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr >
                                        <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Offer Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Created At</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>End Date</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Reward Points</th>
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Url</th>
                                        <th ></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {savedOffers.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text" >&nbsp;&nbsp;{i + 1}</td>
                                            <td className="td-text">{data.offerName}</td>
                                            <td className="td-text">{created[i]}</td>
                                            <td className="td-text">{updatedDate[i]}</td>
                                            <td className="td-text">{data.rewardPoints}</td>
                                            <td className="td-text">{data.url}</td>
                                            <td className="cursor" ><Link to={`/offers/editoffer/${data.id}`} className="poll-link"> <i class="fa fa-pencil left" aria-hidden="true"></i></Link> </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                </div>
            </div >
        );
    };
}

