import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import OpenPopup from "./OpenPopup";
import ViewPopupCard from "./ViewPopupCard";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewLearningCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            cardManagementData: [],
            cards: [],
            isLoading: false,
            popup: false,
            cardManagementId: "",
            cardId: "",
            cardTitle: "",
            cardNumber: "",
            cardDescription: "",
            cardPicture: "",
        }
    }
    componentDidMount() {
        this.getCardById();
       // console.log("id", this.props)
    }
    getCardById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "cardManagementId": this.props.match.params.id
        }
        var self = this
        axios.post(config.userUrl + "user/getCardDetailByAdmin", payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                  //  console.log("success!!");
                    self.setState({
                        cardManagementId: response.data.cardManagementData[0].id,
                        cardManagementData: response.data.cardManagementData,
                        cards: response.data.cardDetail,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    // openBox = (id) => {
    //     console.log("open", id)
    //     document.getElementById('open').style.display = 'flex'
    // }
    // showSequence = () => {
    //     console.log("sequence")
    //     document.getElementById('open').style.display = 'none'
    // }
    togglePopup = (data, id) => {
       // console.log("dataPopup", data)
        this.setState({ popup: !this.state.popup, cardId: id, cardTitle: data.cardTitle, cardDescription: data.cardDescription, cardNumber: data.cardNumber, cardPicture: data.cardPicture })
    }
    close = () => {
        this.setState({ popup: false })
    }
    render() {
        const { isLoading, cardId, cardManagementId, cardNumber, cardManagementData, cardTitle, cardDescription, cardPicture, cards, popup } = this.state


        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                {/* {popup && <ViewPopupCard closePopup={this.close} cardId={cardId} cardManagementId={cardManagementId} cardTitle={cardTitle} cardDescription={cardDescription} cardPicture={cardPicture} />} */}
                {popup && <ViewPopupCard closePopup={this.close} cardLength={cards.length} getCardById={this.getCardById} cardNumber={cardNumber} cardId={cardId} cardManagementId={cardManagementId} cardTitle={cardTitle} cardDescription={cardDescription} cardPicture={cardPicture} />}
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.4rem", marginLeft: "275px" }} >
                    <div className="ml-3 create-learning-card-heading">View Sceemlore</div>
                    <Card className="ml-3 mt-4 mb-2 l-card-create">
                        <Row style={{ width: "100%" }}>
                            <Col md={6}>
                                {cardManagementData.map((data, i) => (
                                    <Form style={{ marginLeft: "5px", width: "100%" }} >
                                        <div className="form-group" >
                                            <select name="topic" style={{ marginTop: "7px" }} readOnly class="web-font form-control text-box-radius">
                                                <option value="">{data.topicName}</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <input type="text"
                                                className=" web-font form-control text-box-radius"
                                                aria-describedby="text"
                                                value={data.title}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <textarea value={data.description}
                                                className="web-font richTextField-c description-radius"
                                                style={{ overflowY: "none", resize: "none" }}
                                                disabled={true} />
                                        </div>
                                        <Button variant="default" disabled={true} className="sendreview-btn mt-1 ml-4">Publish</Button>

                                        <Button variant="default" disabled={true} style={{ float: "left" }} className="sendreview-btn mt-1">Save</Button>

                                    </Form>
                                ))}
                            </Col>
                            <Col md={6}>
                                <Card className="ml-2 mt-1 l-card-create-height">
                                    <div><div className="row no-gutters">
                                        {cards.map((data, i) => (
                                            <div className="col-md-3" style={{ padding: "2px", cursor: "pointer" }} onClick={() => this.togglePopup(data, data.id)}>
                                                <Card className="_card">
                                                    <center><img src={data.cardPicture} style={{ width: "50px", height: "50px" }} /></center>
                                                    {data.cardTitle !== null && data.cardTitle > data.cardTitle.substring(0, 12) ?
                                                        <div className="l-card-description">{data.cardTitle.substring(0, 12)}...</div>
                                                        : <div className="l-card-description">{data.cardTitle}</div>}
                                                </Card><br />
                                            </div>
                                        ))}
                                    </div>
                                        <Button variant="default" disabled="true" className="publish-btn mt-1 ml-3">Add Card</Button></div>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </div >
            </div >
        );
    };
}




